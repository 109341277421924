import {
  getNetworkConfig,
  ChainId,
} from '../../functions/utils/marketsAndNetworksConfig';

export function NetworkIcon({
  chainId,
  className,
}: {
  chainId: ChainId;
  className?: string;
}) {
  const networkConfig = getNetworkConfig(chainId);
  const { name, logoPath } = networkConfig;

  return logoPath ? (
    <img src={logoPath} alt={name} className={className || 'h-4 w-4'} />
  ) : (
    <div
      className={`rounded-full flex justify-center items-center h-4 w-4 text-xs theme-dark theme-disabled-bg ${className}`}
    >
      ?
    </div>
  );
}
