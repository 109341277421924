import { utils } from 'ethers';
import { TransactionStatus, useContractFunction } from '@usedapp/core';
import { BigNumber, ethers } from 'ethers';
import { ExecuteReturn } from '../../../../types';
import { useProtocolDataContext } from '../../../useProtocolDataProvider';
import { ClaimManager } from '../../../../types/typechain';

const { parseUnits } = utils;

export type ParamsWithdrawCompensation = {
  claimId: number;
};

type FormattedParams = {
  claimId: number;
};

function formatParams(
  params: ParamsWithdrawCompensation
): FormattedParams {
  return {
    claimId: params.claimId,
  };
}

type Instance = {
  send: (
    ...args: Parameters<ClaimManager['withdrawCompensation']>
  ) => Promise<ethers.providers.TransactionReceipt | undefined>;
  state: TransactionStatus;
};

function makeInstance(): Instance {
  const { chainContracts } = useProtocolDataContext();
  const contract = chainContracts?.ClaimManager;

  const { send, state } = useContractFunction(
    contract,
    'withdrawCompensation',
    {
      transactionName: 'withdrawCompensation',
      gasLimitBufferPercentage: 10,
    }
  );

  return { send, state };
}

function checkParams(params: ParamsWithdrawCompensation) {
  // do checks relative to this fn
}

function execute(
  instance: Instance,
  params: ParamsWithdrawCompensation
): ExecuteReturn {
  const { claimId } = formatParams(params);

  const txReceiptPromise = instance.send(claimId);

  return {
    txReceipt: txReceiptPromise,
    txStatus: instance.state,
  };
}

export const configWithdrawCompensation = {
  makeInstance: makeInstance,
  checkParams: checkParams,
  execute: execute,
};
