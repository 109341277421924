import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { ConfigProvider } from 'antd';
import { DAppProvider } from '@usedapp/core';
//
import App from './App';
import reportWebVitals from './reportWebVitals';
// Configs
import { configDappProvider } from './useDappConfig';
// Css
import './assets/css/tailwind.css';
import './assets/css/antdOverride.css';
import './assets/css/main.css';
import './assets/css/fonts.css';
import './assets/css/theme.css';
// Fonts
import './assets/fonts/Avenir-Book.ttf';
import './assets/fonts/Avenir-Roman.ttf';
// Context
import { AppDataProvider } from './hooks/useAppDataProvider';
import { ProtocolDataProvider } from './hooks/useProtocolDataProvider';
import { Web3ContextProvider } from './hooks/useWeb3ContextProvider';
import { BasketProvider } from './hooks/useBasketProvider';
// Components
import { PreFetch } from './components/utils/PreFetch';
import { ScrollToTop } from './components/utils/ScrollToTop';
import { ScrollToAnchor } from './components/utils/ScrollToAnchor';
import { NetworkCheckModal } from './components/wallet/NetworkCheckModal';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <DAppProvider config={configDappProvider}>
    <BrowserRouter>
      <Web3ContextProvider>
        <ProtocolDataProvider>
          <AppDataProvider>
            <BasketProvider>
              <ConfigProvider>
                <ScrollToAnchor />
                <ScrollToTop />
                {/* <PreFetch /> */}
                <App />
              </ConfigProvider>
            </BasketProvider>
          </AppDataProvider>
        </ProtocolDataProvider>
      </Web3ContextProvider>
    </BrowserRouter>
  </DAppProvider>
);

reportWebVitals();
