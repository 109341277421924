// import { Tooltip } from 'antd';
import { Tooltip, Button } from 'antd';
import { TooltipIcon } from '../icons/TooltipIcon';
import { getTooltip } from '../../data/tooltipData';

export function TooltipInfo({ label }: { label: string }) {
  const content = getTooltip(label);

  return content ? (
    <Tooltip
      color="grey"
      trigger={['hover', 'click']}
      title={<p className="text-sm">{content}</p>}
      arrow={{ pointAtCenter: true }}
    >
      <Button
        type="text"
        className="!border-transparent !bg-transparent h-5 w-5 p-0 ml-1"
      >
        <TooltipIcon />
      </Button>
    </Tooltip>
  ) : (
    <></>
  );
}
