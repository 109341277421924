import { ethers, BigNumber } from 'ethers';
import { CONFIG } from '../../appConfig';
import { HumanTimestamp } from '../../types';

// ==================== //
// ======= URLS ======= //
// ==================== //

export const makeIpfsUrl = (fileURI: string) => {
  const IPFS_GATEWAY = CONFIG.IPFS_ENDPOINT ?? 'https://ipfs.io';
  return `${IPFS_GATEWAY}${fileURI}`;
};

export function removeUrlTrailingSlash(url: string | undefined) {
  if (!url) return '';
  if (url[url.length - 1] === '/') {
    return url.slice(0, -1);
  }
  return url;
}

// =========================== //
// ======= BIG NUMBERS ======= //
// =========================== //

export const bnSort = (a: BigNumber, b: BigNumber) => {
  if (a.gt(b)) return 1;
  if (b.gt(a)) return -1;
  return 0;
};

export const bnPercent = (
  numerator: BigNumber | undefined,
  denominator: BigNumber | undefined,
  baseHundred?: boolean // if true return value of 1 = 1%
): number => {
  try {
    if (!numerator || !denominator || denominator.isZero()) return 0;

    // if (denominator.isZero()) return Infinity;

    const percent =
      numerator.mul(1_000_000_000).div(denominator).toNumber() /
      1_000_000_000;

    return percent * (baseHundred ? 100 : 1);
  } catch (error) {
    console.error('bnPercent error:', error);
    return 0;
  }
};

// ==================== //
// ======= TEXT ======= //
// ==================== //

export function commaSeparateNumber(input: string) {
  input = input.replace(/\,/g, '');

  const x = input.split('.');
  let x1 = x[0];
  let x2 = x.length > 1 ? `.${x[1]}` : '';

  // Remove trailing 0
  if (x2.length) {
    while (x2[x2.length - 1] === '0' && 3 < x2.length)
      x2 = x2.slice(0, -1);
  }

  const rgx = /(\d+)(\d{3})/;
  while (rgx.test(x1)) {
    x1 = x1.replace(rgx, `$1,$2`);
  }

  return `${x1}${x2}`;
}

export function isNumbersString(str: string): boolean {
  if (str.split('').filter((char) => char === '.').length > 1)
    return false;
  const regex = /^[0-9.]*$/;
  return regex.test(str);
}

// Converts 4bit character strings to 8bit character strings
export function hexToAscii(_hex: string): string {
  const hex = _hex.toString();
  let str = '';
  for (let n = 0; n < hex.length; n += 2) {
    str += String.fromCharCode(parseInt(hex.substr(n, 2), 16));
  }
  return str;
}

export const textCenterEllipsis = (
  str: string,
  nbCharsAtStart: number,
  nbCharsAtEnd: number
) => {
  if (str.length <= nbCharsAtStart + nbCharsAtEnd) return str;
  return `${str.slice(0, nbCharsAtStart)}...${str.slice(
    str.length - nbCharsAtEnd,
    str.length
  )}`;
};

// ===================== //
// ======= DATES ======= //
// ===================== //

// output :  "December 5, 2022"
export const formatTimeToText = (time: number) => {
  if (!time) return '';

  return `${new Date(time).toLocaleDateString('en-EN', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  })}`;
};

// output : "Mon, 05 Dec 2022 13:50:55 GMT"
export const formatTimeToGMT = (time: number) => {
  if (!time) return '';

  return `${new Date(time).toUTCString()}`;
};

export const fromHumanTimestamp = (timestamp: HumanTimestamp): number => {
  const { year, month, day, hour, minute } = timestamp;
  return new Date(year, month - 1, day, hour, minute).valueOf();
};

//  format(Date.now(), 'MM/dd/yyyy hh:mm:ss') // 12/14/2022 03:38:31
//  format(Date.now(), 'yyyy-MM-dd HH:mm:ss') // 2022-12-14 15:38:31

export const formatDate = function (
  date: Date | number | undefined,
  format: string
): string {
  if (!date) return '';

  const toDate = typeof date === 'number' ? new Date(date) : date;
  return Object.entries({
    YYYY: toDate.getFullYear(),
    YY: toDate.getFullYear().toString().substring(2),
    yyyy: toDate.getFullYear(),
    yy: toDate.getFullYear().toString().substring(2),
    MMMM: toDate.toLocaleString('default', { month: 'long' }),
    MMM: toDate.toLocaleString('default', { month: 'short' }),
    MM: (toDate.getMonth() + 1).toString().padStart(2, '0'),
    M: toDate.getMonth() + 1,
    DDDD: toDate.toLocaleDateString('default', { weekday: 'long' }),
    DDD: toDate.toLocaleDateString('default', { weekday: 'short' }),
    DD: toDate.getDate().toString().padStart(2, '0'),
    D: toDate.getDate(),
    dddd: toDate.toLocaleDateString('default', { weekday: 'long' }),
    ddd: toDate.toLocaleDateString('default', { weekday: 'short' }),
    dd: toDate.getDate().toString().padStart(2, '0'),
    d: toDate.getDate(),
    HH: toDate.getHours().toString().padStart(2, '0'), // military
    H: toDate.getHours().toString(), // military
    hh: (toDate.getHours() % 12).toString().padStart(2, '0'),
    h: (toDate.getHours() % 12).toString(),
    mm: toDate.getMinutes().toString().padStart(2, '0'),
    m: toDate.getMinutes(),
    SS: toDate.getSeconds().toString().padStart(2, '0'),
    S: toDate.getSeconds(),
    ss: toDate.getSeconds().toString().padStart(2, '0'),
    s: toDate.getSeconds(),
    TTT: toDate.getMilliseconds().toString().padStart(3, '0'),
    ttt: toDate.getMilliseconds().toString().padStart(3, '0'),
    AMPM: toDate.getHours() < 13 ? 'AM' : 'PM',
    ampm: toDate.getHours() < 13 ? 'am' : 'pm',
  }).reduce((acc, entry) => {
    return acc.replace(entry[0], entry[1].toString());
  }, format);
};
