import { utils } from 'ethers';
import { TransactionStatus, useContractFunction } from '@usedapp/core';
import { BigNumber, ethers } from 'ethers';
import { ExecuteReturn } from '../../../../types';
import { useProtocolDataContext } from '../../../useProtocolDataProvider';
import { LiquidityManager } from '../../../../types/typechain';

const { parseUnits } = utils;

export type ParamsOpenCover = {
  poolId: number;
  amountTokenDecimals: number;
  coverAmount: string;
  premiumsTokenDecimals: number;
  premiums: string;
};

type FormattedParams = {
  poolId: number;
  coverAmount: BigNumber;
  premiums: BigNumber;
};

function formatParams(params: ParamsOpenCover): FormattedParams {
  return {
    poolId: params.poolId,
    coverAmount: parseUnits(
      params.coverAmount,
      params.amountTokenDecimals
    ),
    premiums: parseUnits(params.premiums, params.premiumsTokenDecimals),
  };
}

type Instance = {
  send: (
    ...args: Parameters<LiquidityManager['openCover']>
  ) => Promise<ethers.providers.TransactionReceipt | undefined>;
  state: TransactionStatus;
};

function makeInstance(): Instance {
  const { chainContracts } = useProtocolDataContext();
  const contract = chainContracts?.LiquidityManager;

  const { send, state } = useContractFunction(contract, 'openCover', {
    transactionName: 'openCover',
    gasLimitBufferPercentage: 10,
  });

  return { send, state };
}

function checkParams(params: ParamsOpenCover): string | undefined {
  // do checks relative to this fn
  const { poolId, coverAmount, premiums } = formatParams(params);

  if (!coverAmount || coverAmount.eq(0)) {
    return 'Missing amount input';
  }
  if (!premiums || premiums.eq(0)) {
    return 'Missing premiums input';
  }
}

function execute(
  instance: Instance,
  params: ParamsOpenCover
): ExecuteReturn {
  const { poolId, coverAmount, premiums } = formatParams(params);

  const txReceiptPromise = instance.send(poolId, coverAmount, premiums);

  return {
    txReceipt: txReceiptPromise,
    txStatus: instance.state,
  };
}

export const configOpenCover = {
  makeInstance: makeInstance,
  checkParams: checkParams,
  execute: execute,
};
