import { utils } from 'ethers';
import { TransactionStatus, useContractFunction } from '@usedapp/core';
import { BigNumber, ethers } from 'ethers';
import { ExecuteReturn } from '../../../../types';
import { useProtocolDataContext } from '../../../useProtocolDataProvider';
import { LiquidityManager } from '../../../../types/typechain';

const { parseUnits } = utils;

export type ParamsAddLiquidity = {
  positionId: number;
  amount: string;
  tokenDecimals: number;
  isWrapped: boolean;
};

type FormattedParams = {
  positionId: number;
  amount: BigNumber;
  isWrapped: boolean;
};

function formatParams(params: ParamsAddLiquidity): FormattedParams {
  return {
    positionId: params.positionId,
    amount: parseUnits(params.amount, params.tokenDecimals),
    isWrapped: params.isWrapped,
  };
}

type Instance = {
  send: (
    ...args: Parameters<LiquidityManager['addLiquidity']>
  ) => Promise<ethers.providers.TransactionReceipt | undefined>;
  state: TransactionStatus;
};

function makeInstance(): Instance {
  const { chainContracts } = useProtocolDataContext();
  const contract = chainContracts?.LiquidityManager;

  const { send, state } = useContractFunction(contract, 'addLiquidity', {
    transactionName: 'addLiquidity',
    gasLimitBufferPercentage: 10,
  });

  return { send, state };
}

function checkParams(params: ParamsAddLiquidity) {
  // do checks relative to this fn
  const { amount, isWrapped, positionId } = formatParams(params);

  if (!amount || amount.eq(0)) {
    return 'Missing amount input';
  }
}

function execute(
  instance: Instance,
  params: ParamsAddLiquidity
): ExecuteReturn {
  const { positionId, amount, isWrapped } = formatParams(params);

  const txReceiptPromise = instance.send(positionId, amount, isWrapped);

  return {
    txReceipt: txReceiptPromise,
    txStatus: instance.state,
  };
}

export const configAddLiquidity = {
  makeInstance: makeInstance,
  checkParams: checkParams,
  execute: execute,
};
