import { CONFIG } from './appConfig';
import { Navigate, Route, Routes } from 'react-router-dom';
// Pages
import { DashboardPage } from './pages/new/Dashboard';
import { MarketPage } from './pages/new/Market';
import { CourtPage } from './pages/new/Court';
import { FarmingPage } from './pages/new/Farming';
import { StakingPage } from './pages/new/Staking';
import { DaoPage } from './pages/new/Dao';
import { TokenSalePage } from './pages/new/TokenSale';
//
import { Dashboard } from './pages/Dashboard';
import { BuyCover } from './pages/BuyCover';
import { SupplyCover } from './pages/SupplyCover';
import { Pool } from './pages/Pool';
import { BuyBasket } from './pages/BuyBasket';
import { SupplyBasket } from './pages/SupplyBasket';
import { Governance } from './pages/Governance';
import { Stake } from './pages/Stake';
import { Claims } from './pages/Claims';
import { ClaimDetails } from './pages/ClaimDetails';
import { AddClaimEvidence } from './pages/AddClaimEvidence';
import { MyCover } from './pages/MyCover';
import { MyPosition } from './pages/MyPosition';
import { ClaimCreate } from './pages/ClaimCreate';
import { DashboardClaims } from './pages/dashboard/DashboardClaims';
import { DashboardCovers } from './pages/dashboard/DashboardCovers';
import { DashboardStaking } from './pages/dashboard/DashboardStaking';
import { DashboardPositions } from './pages/dashboard/DashboardPositions';
import { ErrorPage } from './pages/new/Error';
// Components
import { RootLayout } from './components/layout/RootLayout';

export default function App() {
  return (
    <Routes>
      <Route element={<RootLayout />}>
        {/* New */}
        {/* <Route path="/dash" element={<DashboardPage />} />
        <Route path="/market" element={<MarketPage />} />
        <Route path="/court" element={<CourtPage />} />
        <Route path="/farming" element={<FarmingPage />} />
        <Route path="/staking" element={<StakingPage />} />
        <Route path="/governance" element={<DaoPage />} /> */}

        <Route index path="/" element={<TokenSalePage />} />

        {CONFIG.DEV_MODE && (
          <>
            {/* General */}
            <Route path="/buycover" element={<BuyCover />} />
            <Route path="/supplycover" element={<SupplyCover />} />

            <Route path="/buycover/basket" element={<BuyBasket />} />
            <Route path="/supplycover/basket" element={<SupplyBasket />} />

            <Route path="/stake" element={<Stake />} />
            <Route path="/governance" element={<Governance />} />
            <Route path="/claims" element={<Claims />} />
            <Route
              path="/add-evidence/:claimId"
              element={<AddClaimEvidence />}
            />

            {/* Dashboard */}
            <Route path="/dashboard/" element={<Dashboard />}>
              <Route index element={<Navigate to="covers" replace />} />
              <Route path="covers" element={<DashboardCovers />} />
              <Route path="positions" element={<DashboardPositions />} />
              <Route path="staking" element={<DashboardStaking />} />
              <Route path="claims" element={<DashboardClaims />} />
            </Route>

            {/* Detail */}
            <Route path="/pool/:poolId" element={<Pool />} />
            <Route path="/claim/:claimId" element={<ClaimDetails />} />
            {/* Separated because we don't want the usual dashboard layout */}
            <Route
              path="/dashboard/cover/:coverId"
              element={<MyCover />}
            />
            <Route
              path="/dashboard/position/:positionId"
              element={<MyPosition />}
            />
            <Route
              path="/dashboard/claim/:claimId"
              element={<ClaimDetails />}
            />
            <Route
              path="/dashboard/openclaim/:coverId"
              element={<ClaimCreate />}
            />
          </>
        )}

        <Route path="/error" element={<ErrorPage />} />
        <Route path="*" element={<Navigate to="/" replace />} />
      </Route>
    </Routes>
  );
}
