import { Button, Col, Table, Typography } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { Link } from 'react-router-dom';
import { formatDate } from '../../functions/utils/formatting';
import { textCenterEllipsis } from '../../functions/utils/formatting';
import { useRegisteredClaimEvidence } from '../../hooks/api';
import { EvidenceAPIResponseItem, ClaimStatus } from '../../types';
// Function
import { makeIpfsUrl } from '../../functions/utils/formatting';
import { openAndFocusTab } from '../../functions/utils/openAndFocusTab';

const columnConfig: ColumnsType<EvidenceAPIResponseItem> = [
  {
    title: 'Name',
    dataIndex: 'name',
    render: (text: string) =>
      30 < text.length ? textCenterEllipsis(text, 15, 10) : text,
  },
  {
    title: 'Uploaded',
    dataIndex: 'uploaded',
    align: 'center',
    render: (_, record: EvidenceAPIResponseItem) =>
      formatDate(record.uploadTimestamp, 'dd/MM/yyyy HH:mm:ss'),
  },
  {
    title: 'Registered',
    dataIndex: 'registered',
    align: 'center',
    render: (_, record: EvidenceAPIResponseItem) =>
      formatDate(record.validationTimestamp, 'dd/MM/yyyy HH:mm:ss'),
  },
  {
    title: '',
    dataIndex: 'view',
    render: (_, record: any) => (
      <a
        href={makeIpfsUrl(record.fileURI)}
        target="_blank"
        rel="noreferrer"
      >
        <Button type="default" size="small" className="text-sm">
          View Evidence
        </Button>
      </a>
    ),
  },
];

export const EvidenceDisplay = ({
  claimId,
  isParty,
  claimStatus,
}: {
  claimId: string | undefined;
  isParty: boolean;
  claimStatus: ClaimStatus;
}) => {
  const allEvidence = useRegisteredClaimEvidence(claimId);
  const claimEvidence = allEvidence.filter(
    (evidence) => evidence.party === 'claimant'
  );
  const claimCounterEvidence = allEvidence.filter(
    (evidence) => evidence.party === 'challenger'
  );

  const claimStatusAllowsEvidence =
    claimStatus === 'Initiated' || claimStatus === 'Disputed';

  return (
    <Col span={24} className="content-card flex flex-col mt-8 py-8 px-24">
      <Typography.Title
        className="flex self-center items-center"
        level={3}
      >
        Case Evidence
      </Typography.Title>
      <p className="mt-3 mb-6">
        Evidence related to this claim is displayed here. The claimant can
        submit evidence to support their claim, and the challenger can
        submit counter-evidence to disprove the claim payout.
      </p>

      <Typography.Title className="flex items-center" level={4}>
        Claimant Evidence
      </Typography.Title>

      <Table
        className={`my-4 ${!!claimEvidence.length && 'nav-table'}`}
        rowKey="fileURI"
        columns={columnConfig}
        dataSource={claimEvidence}
        size="small"
        pagination={false}
        showSorterTooltip={false}
        onRow={(record, rowIndex) => {
          return {
            onClick: (event) =>
              openAndFocusTab(makeIpfsUrl(record.fileURI)),
          };
        }}
      />

      <Typography.Title className="flex items-center my-8" level={4}>
        Challenger Counter-Evidence
      </Typography.Title>
      <Table
        className={`my-4 ${!!claimCounterEvidence.length && 'nav-table'}`}
        rowKey="fileURI"
        columns={columnConfig}
        dataSource={claimCounterEvidence}
        size="small"
        pagination={false}
        showSorterTooltip={false}
        onRow={(record, rowIndex) => {
          return {
            onClick: (event) =>
              openAndFocusTab(makeIpfsUrl(record.fileURI)),
          };
        }}
      />

      {isParty && claimStatusAllowsEvidence && (
        <Link
          to={`/add-evidence/${claimId}`}
          className="mt-8 mb-4 self-center"
        >
          <Button
            type="primary"
            disabled={!isParty && !claimStatusAllowsEvidence}
            className="min-w-[220px]"
          >
            Add Evidence
          </Button>
        </Link>
      )}
    </Col>
  );
};
