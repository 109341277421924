import { useEffect, useState } from 'react';

export const Countdown = ({
  eventTimestamp,
  delay,
  withDays,
  withSeconds,
  compact,
}: {
  eventTimestamp: number; // ms
  delay: number; // ms
  withDays?: boolean;
  withSeconds?: boolean;
  compact?: boolean;
}) => {
  function calculateTimeLeft(): {
    days: number;
    hours: number;
    minutes: number;
    seconds: number;
  } {
    const difference =
      new Date(eventTimestamp + delay).valueOf() - new Date().valueOf();
    let timeLeft = {
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
    };

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }

    return timeLeft;
  }

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  const { days, hours, minutes, seconds } = timeLeft;

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearTimeout(timer);
  });

  function formatTime(time: number) {
    return time < 10 ? `0${time}` : time;
  }

  const daysText = withDays ? `${days}d ` : '';
  const hoursText = formatTime(hours);
  const minutesText = formatTime(minutes);
  const secondsText = withSeconds
    ? `${compact ? ':' : ''}${formatTime(seconds)}`
    : '';

  return compact ? (
    <>{`${daysText}${hoursText}:${minutesText}${secondsText}`}</>
  ) : (
    <>{`${daysText}${hoursText}h ${minutesText}m ${secondsText}s`}</>
  );
};
