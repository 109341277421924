// Hooks
import { useState } from 'react';
import { useClickAway } from '../../hooks/utils/useClickAway';
// Context
import { useWeb3Context } from '../../hooks/useWeb3ContextProvider';
// Components
import { AnimateFadeInOut } from '../animation/AnimateFadeInOut';
// Icon
import { NetworkIcon } from '../icons/NetworkIcon';
import { ChevronDownIcon } from '../icons/ChevronDownIcon';
import { DotIcon } from '../icons/DotIcon';
import { SpinIcon } from '../animation/SpinIcon';
// Data
import { getNetworkConfigs } from '../../functions/utils/marketsAndNetworksConfig';

export function MenuNetwork() {
  const {
    appChainId,
    handleSwitchNetwork,
    currentNetworkConfig,
    isChangingNetwork,
  } = useWeb3Context();

  const ref = useClickAway(() => setIsOpen(false));
  const [isOpen, setIsOpen] = useState(false);

  function toggle() {
    if (isChangingNetwork) return;
    setIsOpen(!isOpen);
  }

  function handleChangeNetwork(newChainid: number) {
    handleSwitchNetwork(newChainid);
    toggle();
  }

  const networkConfigs = getNetworkConfigs();

  return (
    <div ref={ref} className="z-50 relative">
      <button
        onClick={toggle}
        className="text-sm rounded-sm theme-dark-bg p-3 flex items-center border-white md:min-w-[140px]"
      >
        {isChangingNetwork ? (
          <>
            <SpinIcon />
            <span className="hidden md:block md:grow">Requesting</span>
          </>
        ) : (
          <>
            <NetworkIcon
              chainId={currentNetworkConfig.chainId}
              className="h-4 w-4 shrink mr-2"
            />
            <span className="hidden md:block md:mr-auto">
              {currentNetworkConfig.name}
            </span>
          </>
        )}
        <ChevronDownIcon
          className={`shrink transition-transform ${
            isOpen ? 'rotate-180' : ''
          } ${isChangingNetwork ? 'opacity-50' : ''}`}
        />
      </button>

      <AnimateFadeInOut
        isOpen={isOpen}
        className="right-0 top-16 lg:top-[4.63rem] absolute w-60 space-y-4 overflow-hidden rounded-sm border border-white/[.3] theme-secondary-bg p-4 drop-shadow-2xl"
      >
        {networkConfigs.map(({ name, chainId, isImplemented }) => (
          <button
            key={chainId}
            className={`flex w-full cursor-pointer items-center space-x-2 text-sm transition-all duration-500 ${
              appChainId === chainId
                ? 'theme-highlight'
                : 'text-white/[.6] hover:text-white'
            }`}
            onClick={
              isImplemented
                ? () => handleChangeNetwork(chainId)
                : () => toggle()
            }
          >
            <NetworkIcon chainId={chainId} />
            <div className="grow text-start">{name}</div>

            {appChainId !== chainId && !isImplemented && (
              <span>(soon)</span>
            )}
            {appChainId === chainId && (
              <DotIcon className="!h-3 !w-3 mt-1" />
            )}
          </button>
        ))}
      </AnimateFadeInOut>
    </div>
  );
}
