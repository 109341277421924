import { CloseOutlined } from '@ant-design/icons';
import { Button, Col, Row, Select, Space, Typography, Input } from 'antd';
// Hooks
import { usePageTitle } from '../hooks/usePageTitle';
// Context
import { useAppDataContext } from '../hooks/useAppDataProvider';
import { useBasketContext } from '../hooks/useBasketProvider';
// Components
import { BackButton } from '../components/BackButton';
import { BasketCardBuy } from '../components/BasketCardBuy';
import { BasketTable } from '../components/tables/BasketTable';
import {
  BasketBuyInputCover,
  BasketBuyInputAten,
  BasketBuyInputPremium,
} from '../components/data/BasketBuyInput';
import { TableLogoWrapper } from '../components/icons/TableLogoWrapper';
// Types
import {
  BuyBasketItem,
  BuyBasketTableConfigItem,
  BuyBasketUpdate,
} from '../types';
import { BigNumber } from 'ethers';

const columnConfig = (
  updateBasket: (update: BuyBasketUpdate) => void,
  removeFromBuyBasket: (poolId: string) => void,
  atenPrice: BigNumber
): BuyBasketTableConfigItem[] => [
  {
    title: 'Protocol',
    key: 'protocol',
    render: (_, record) => <TableLogoWrapper poolId={record.poolId} />,
  },
  {
    title: 'Cover Amount',
    key: 'amountCover',
    render: (_, record) => (
      <BasketBuyInputCover
        poolId={record.poolId}
        value={record.coverAmount}
        updateAmount={(newAmount: string) =>
          updateBasket({ poolId: record.poolId, coverAmount: newAmount })
        }
      />
    ),
  },
  {
    title: 'Staked ATEN Amount',
    key: 'amountAten',
    render: (_, record) => (
      <BasketBuyInputAten
        value={record.stakeAmount}
        updateAmount={(newAmount: string) =>
          updateBasket({ poolId: record.poolId, stakeAmount: newAmount })
        }
      />
    ),
  },
  {
    title: 'Premium Amount',
    key: 'period',
    render: (_, record) => (
      <BasketBuyInputPremium
        poolId={record.poolId}
        value={record.premiumAmount}
        coverAmount={record.coverAmount}
        updateAmount={(newAmount: string) =>
          updateBasket({
            poolId: record.poolId,
            premiumAmount: newAmount,
          })
        }
      />
    ),
  },
  {
    title: '',
    key: 'remove',
    render: (_, record) => (
      <Space size="middle">
        <Button
          type="text"
          className="close-button"
          onClick={() => removeFromBuyBasket(record.poolId)}
          icon={<CloseOutlined />}
        ></Button>
      </Space>
    ),
  },
];

export function BuyBasket() {
  usePageTitle('Buy Cover');
  const {
    buyBasket,
    updateBuyBasket,
    emptyBuyBasket,
    removeFromBuyBasket,
  } = useBasketContext();
  const { atenPrice } = useAppDataContext();

  const buyBasketItems = buyBasket.poolIds.map(
    (poolId, i) =>
      ({
        poolId,
        coverAmount: buyBasket.coverAmounts[i],
        stakeAmount: buyBasket.stakeAmounts[i],
        premiumAmount: buyBasket.premiumAmounts[i],
      } as BuyBasketItem)
  );
  const hasItems = !!buyBasketItems.length;

  const config = columnConfig(
    updateBuyBasket,
    removeFromBuyBasket,
    atenPrice
  );

  return (
    <Col span={24} className="content-card p-4">
      <Typography.Title
        level={3}
        className="mt-2 flex justify-start items-center"
      >
        <BackButton />
        Buy Cover
      </Typography.Title>
      <Row>
        <Col span={17} className="p-4 pr-6">
          <div className="mb-4">
            <div className="flex mb-3">
              <div className="font-bold min-w-[150px]">Reminder: </div>
              Select multiple insurance policies to cover a whole portfolio
              in one transaction and save on gas fees.
            </div>
            <div className="flex items-center">
              <div className="font-bold min-w-[150px]">
                Cover Currency:
              </div>
              <Select defaultValue="usdt" onChange={() => ''}>
                <Select.Option value="usdt">USDT</Select.Option>
              </Select>
            </div>
          </div>
          <BasketTable
            config={config}
            data={buyBasketItems}
            emptyBasket={emptyBuyBasket}
            itemsInBasket={buyBasketItems.length}
          />
        </Col>
        <Col span={7}>
          <BasketCardBuy hasItems={hasItems} />
        </Col>
      </Row>
    </Col>
  );
}
