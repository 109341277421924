import { Space, Typography } from 'antd';
// Hooks
// Types
// Components

export type ActionCardParams = {
  title: string;
  bottomActions: JSX.Element;
  text?: string;
  infoData?: {
    name: string;
    data: string | JSX.Element;
  }[];
};

export const ActionCard = ({
  cardData,
  index,
}: {
  cardData: ActionCardParams;
  index: number;
}) => {
  const { title, bottomActions, text, infoData } = cardData;

  return (
    <div
      className={`content-card rounded flex flex-col p-4 ${
        index !== 0 ? 'mt-4' : ''
      }`}
    >
      <Typography.Title level={3} className="theme-secondary">
        {title}
      </Typography.Title>

      {text && <p className="my-4">{text}</p>}

      {infoData && (
        <Space direction="vertical" className="my-4">
          {infoData.map((el, i) => (
            <Space key={i} direction="horizontal">
              <span>{el.name}</span>
              <b className="info-text">{el.data}</b>
            </Space>
          ))}
        </Space>
      )}

      <div className="flex flex-col items-center">{bottomActions}</div>
    </div>
  );
};
