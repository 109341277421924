import { useCall } from '@usedapp/core';
import {
  formatFeeLevels,
  formatStakingLevels,
} from '../../../../functions/athenaDataFormat';
import { useProtocolDataContext } from '../../../useProtocolDataProvider';

// Value that is returned if the hook encounters an error
const defaultValue = {
  0: 0,
};

// Your fee level in cover positions according to your amount of staked ATEN
export const useGetPositionFeeLevels = () => {
  // @bw @todo @dev send static to change prev behaviour of fee amount
  // in Stake page search = title: 'Fee discount'

  // const { chainContracts } = useProtocolDataContext();
  // const contract = chainContracts?.ATHENA;

  // const { value, error } =
  //   useCall(
  //     contract && {
  //       contract: contract,
  //       method: 'getAtenStakingFeeLevels', // Method to be called
  //       args: [], // Method arguments
  //     }
  //   ) ?? {};
  // if (error) {
  //   console.error(error.message);
  //   return defaultValue;
  // }
  // if (value?.[0]) {
  //   return formatFeeLevels(value?.[0]);
  // }
  // return defaultValue;

  return {
    0: 0,
    1000: 0.025,
    100000: 0.05,
    1000000: 0.075,
  };
};

// Your staking APR according to your supplied capital
export const useGetStakingRewardsLevels = () => {
  return defaultValue;
};
