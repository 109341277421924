import { BigNumber, Contract } from 'ethers';
import { useCall } from '@usedapp/core';
import { useProtocolDataContext } from '../../../useProtocolDataProvider';
import { useWeb3Context } from '../../../useWeb3ContextProvider';

const defaultValueChallengeDelay = 0;
const defaultValue = BigNumber.from(0);

export const useChallengeDelay = () => {
  const { chainContracts } = useProtocolDataContext();
  const { currentNetworkConfig } = useWeb3Context();
  const blocksRefresh = currentNetworkConfig.secondsToBlocks(20);

  const contract = chainContracts?.ClaimManager;

  const conditionsOk = !!blocksRefresh;

  const { value, error } =
    useCall(
      conditionsOk &&
        contract && {
          contract: contract,
          method: 'challengePeriod', // Method to be called
          args: [], // Method arguments
        },
      { refresh: blocksRefresh }
    ) ?? {};
  if (error) {
    console.error(error.message);
    return defaultValueChallengeDelay;
  }
  if (value?.[0]) {
    return value?.[0]?.toNumber() * 1000;
  }
  return defaultValueChallengeDelay;
};

export const useArbitrationCost = () => {
  const { chainContracts } = useProtocolDataContext();
  const { currentNetworkConfig } = useWeb3Context();
  const blocksRefresh = currentNetworkConfig.secondsToBlocks(20);

  const contract = chainContracts?.ClaimManager;

  const conditionsOk = !!blocksRefresh;

  const { value, error } =
    useCall(
      conditionsOk &&
        contract && {
          contract: contract,
          method: 'arbitrationCost', // Method to be called
          args: [], // Method arguments
        },
      { refresh: blocksRefresh }
    ) ?? {};
  if (error) {
    console.error(error.message);
    return defaultValue;
  }
  if (value?.[0]) {
    return value?.[0];
  }
  return defaultValue;
};

export const useCollateralAmount = () => {
  const { chainContracts } = useProtocolDataContext();
  const { currentNetworkConfig } = useWeb3Context();
  const blocksRefresh = currentNetworkConfig.secondsToBlocks(20);

  const contract = chainContracts?.ClaimManager;

  const conditionsOk = !!blocksRefresh;

  const { value, error } =
    useCall(
      conditionsOk &&
        contract && {
          contract: contract,
          method: 'collateralAmount', // Method to be called
          args: [], // Method arguments
        },
      { refresh: blocksRefresh }
    ) ?? {};
  if (error) {
    console.error(error.message);
    return defaultValue;
  }
  if (value?.[0]) {
    return value?.[0];
  }
  return defaultValue;
};
