import { CONFIG } from '../appConfig';
import React, {
  ReactElement,
  createContext,
  useCallback,
  useEffect,
  useState,
  useContext,
} from 'react';
import { BigNumber, providers } from 'ethers';
import {
  JsonRpcProvider,
  TransactionResponse,
} from '@ethersproject/providers';
import {
  useEtherBalance,
  useEthers,
  useNotifications,
  useUpdateConfig,
} from '@usedapp/core';
// Data
import { transactionType, ERC20TokenType } from '../types';
// Functions
import { hexToAscii } from '../functions/utils/formatting';
import {
  getNetworkConfig,
  ChainId,
  NetworkConfig,
  getProvider,
} from '../functions/utils/marketsAndNetworksConfig';

type AddChainParameter = {
  /** A 0x-prefixed hexadecimal string */
  chainId: string;
  /** The chain name. */
  chainName: string;
  /** Native currency for the chain. */
  nativeCurrency?: {
    name: string;
    symbol: string;
    decimals: number;
  };
  rpcUrls: (string | undefined)[];
  blockExplorerUrls?: string[];
  iconUrls?: string[];
};

export type Web3ContextData = {
  connectWallet: () => Promise<void>;
  disconnectWallet: () => void;
  currentAccount: string;
  isConnected: boolean;
  loading: boolean;
  provider: JsonRpcProvider | undefined;
  chainId: number | undefined;
  etherBalance: BigNumber | undefined;
  getTxError: (txHash: string) => Promise<string>;
  sendTx: (txData: transactionType) => Promise<TransactionResponse>;
  addERC20Token: (args: ERC20TokenType) => Promise<boolean>;
  error: Error | undefined;
  //
  currentNetworkConfig: NetworkConfig;
  jsonRpcProvider: providers.Provider | undefined;
  //
  appChainId: number;
  isChangingNetwork: boolean;
  handleSwitchNetwork: (newChainId: number) => Promise<void>;
};

export const Web3Context = createContext({} as Web3ContextData);

export const Web3ContextProvider: React.FC<{ children: ReactElement }> = ({
  children,
}) => {
  const {
    account,
    chainId,
    library: provider,
    activateBrowserWallet,
    active,
    error,
    deactivate,
    switchNetwork,
  } = useEthers();
  const updateConfig = useUpdateConfig();

  const isConnected = !!account && active;
  // @note need to force typing of causes errors at deployment
  const typedProvider = provider as providers.JsonRpcProvider | undefined;

  const { secondsToBlocks } = getNetworkConfig(chainId as ChainId);

  const etherBalance = useEtherBalance(account, {
    refresh: secondsToBlocks ? secondsToBlocks(15) : 10,
  });

  const [loading, setLoading] = useState(false);

  const disconnectWallet = useCallback(async () => {
    console.log('////// DISCONNECT WALLET //////');
    deactivate();
    setLoading(false);
  }, [typedProvider]);

  const connectWallet = useCallback(async () => {
    setLoading(true);
    console.log('////// CONNECT WALLET //////');
    try {
      activateBrowserWallet();
      setLoading(false);
    } catch (e: any) {
      console.log('////// error on activation //////', e);
      setLoading(false);
    }
  }, [disconnectWallet]);

  // TODO: we use from instead of currentAccount because of the mock wallet.
  // If we used current account then the tx could get executed
  const sendTx = async (
    txData: transactionType
  ): Promise<TransactionResponse> => {
    if (typedProvider) {
      const { from, ...data } = txData;
      const signer = typedProvider.getSigner(from);
      const txResponse: TransactionResponse = await signer.sendTransaction(
        {
          ...data,
          value: data.value ? BigNumber.from(data.value) : undefined,
        }
      );
      return txResponse;
    }
    throw new Error('Error sending transaction. Provider not found');
  };

  const getTxError = async (txHash: string): Promise<string> => {
    if (typedProvider) {
      const tx = await typedProvider.getTransaction(txHash);
      // @ts-expect-error
      const code = await typedProvider.call(tx, tx.blockNumber);
      const error = hexToAscii(code.substr(138));
      return error;
    }
    throw new Error('Error getting transaction. Provider not found');
  };

  // This chain ID is for the app even when no wallet is connected
  const offlineChainId = parseInt(
    localStorage.getItem('chainId') || CONFIG.DEFAULT_CHAIN.toString()
  );

  const [appChainId, setAppChainId] = useState<number>(offlineChainId);
  const [isChangingNetwork, setIsChangingNetwork] = useState(false);

  useEffect(() => {
    if (isConnected && chainId && appChainId !== chainId)
      handleSwitchNetwork(chainId);
  }, [isConnected, chainId]);

  async function handleSwitchNetwork(newChainId: number) {
    if (newChainId === appChainId) return;

    setIsChangingNetwork(true);

    // Set the app chain ID even if not connected
    if (!isConnected) {
      setAppChainId(newChainId);
      localStorage.setItem('chainId', String(newChainId));
    }

    updateConfig({
      readOnlyChainId: newChainId,
    });

    if (isConnected) {
      try {
        await switchNetwork(newChainId).then(() => {
          setAppChainId(newChainId);
          localStorage.setItem('chainId', String(newChainId));
        });
      } catch (err: any) {
        // Error code 4001 means user rejected request
        if (err.code !== 4001 && provider) {
          // Fallback to try and add network & switch
          await addNetworkToWallet(provider as JsonRpcProvider, newChainId)
            .then(() => {
              setAppChainId(newChainId);
              localStorage.setItem('chainId', String(newChainId));
            })
            .catch((err) => {
              // console.log('Error - Change network: ', err.message);
            });
        }
      }
    }

    setIsChangingNetwork(false);
  }

  async function addNetworkToWallet(
    wallet: JsonRpcProvider,
    newChainId: number
  ): Promise<boolean> {
    if (!wallet) return false;

    const networkInfo = getNetworkConfig(newChainId);

    return wallet
      .send('wallet_addEthereumChain', [
        {
          chainId: `0x${newChainId.toString(16)}`,
          chainName: networkInfo.name,
          nativeCurrency: {
            name: networkInfo.baseAssetName,
            symbol: networkInfo.baseAssetSymbol,
            decimals: networkInfo.baseAssetDecimals,
          },
          rpcUrls: [
            ...networkInfo.publicJsonRPCUrl,
            networkInfo.publicJsonRPCWSUrl,
          ],
          blockExplorerUrls: [networkInfo.explorerLink],
          iconUrls: ['networkInfo.icon'], // @bw todo
        } satisfies AddChainParameter,
      ])
      .then(() => true);
  }

  const addERC20Token = async ({
    address,
    symbol,
    decimals,
    image,
  }: ERC20TokenType): Promise<boolean> => {
    // using window.ethereum as looks like its only supported for metamask
    const injectedProvider = (window as any).ethereum;
    if (typedProvider && account && window && injectedProvider) {
      await injectedProvider.request({
        method: 'wallet_watchAsset',
        params: {
          type: 'ERC20',
          options: {
            address,
            symbol,
            decimals,
            image,
          },
        },
      });

      return true;
    }
    return false;
  };

  return (
    <Web3Context.Provider
      value={{
        connectWallet,
        disconnectWallet,
        provider: typedProvider,
        isConnected,
        loading,
        chainId: appChainId,
        getTxError,
        sendTx,
        currentAccount: account?.toLowerCase() || '',
        addERC20Token,
        etherBalance,
        error,
        //
        currentNetworkConfig: getNetworkConfig(appChainId),
        jsonRpcProvider: getProvider(appChainId),
        //
        appChainId,
        isChangingNetwork,
        handleSwitchNetwork,
      }}
    >
      {children}
    </Web3Context.Provider>
  );
};

export const useWeb3Context = () => useContext(Web3Context);
