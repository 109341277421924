import { Button, Col } from 'antd';
import cx from 'classnames';
// Hooks
import { usePageTitle } from '../../hooks/usePageTitle';
import { useAppDataContext } from '../../hooks/useAppDataProvider';
// Components
import { Link } from 'react-router-dom';
import { BaseTable } from '../../components/tables/BaseTable';
import { StatLine } from '../../components/StatLine';
import { TableLogoWrapper } from '../../components/icons/TableLogoWrapper';
import { FormatTokenAmount } from '../../components/primitives/FormattedNumber';
// Type
import { ClaimTableConfigItem, ClaimData } from '../../types';
// Functions
import { formatTimeToText } from '../../functions/utils/formatting';
import {
  isClaimPayable,
  isClaimResolved,
  isClaimDisputed,
} from '../../functions/checkAthenaData';

const columnConfigCover = (): ClaimTableConfigItem[] => [
  {
    title: 'Protocol',
    key: 'protocol',
    width: 250,
    render: (_, record) => (
      <TableLogoWrapper className="ml-2" poolId={record.poolId} />
    ),
  },
  {
    title: 'Creation Date',
    key: 'date',
    cellContent: (record) => `${formatTimeToText(record.creationDate)}`,
  },
  {
    title: 'Amount Claimed',
    key: 'available',
    render: (_, record) => FormatTokenAmount(record.amountClaimed, 'USD'),
  },
  {
    title: 'Status',
    key: 'status',
    cellContent: (record) => record.status,
  },
  {
    title: '',
    key: 'action',
    width: 300,
    render: (_, record) => {
      const displayWithdraw = isClaimPayable(record.status);

      return (
        <div className="flex justify-end">
          <Link
            className="mx-2 table-link"
            to={'/dashboard/cover/' + record.coverId}
          >
            <Button type="default">View Cover</Button>
          </Link>
          <Link className="mx-2" to={'/claim/' + record.claimId}>
            <Button type="default">View Claim</Button>
          </Link>
        </div>
      );
    },
  },
];

export function DashboardClaims() {
  usePageTitle('My Claims');
  const { userClaims } = useAppDataContext();

  const availableRefundClaims = userClaims
    .filter((el) => isClaimPayable(el.status))
    .sort((a, b) => b.creationDate - a.creationDate);
  const disputedClaims = userClaims
    .filter((el) => isClaimDisputed(el.status))
    .sort((a, b) => b.creationDate - a.creationDate);
  const ongoingClaims = userClaims
    .filter((el) => !isClaimResolved(el.status))
    .sort((a, b) => b.creationDate - a.creationDate);
  const resolvedClaims = userClaims
    .filter((el) => isClaimResolved(el.status))
    .sort((a, b) => b.creationDate - a.creationDate);

  const statLineData = [
    {
      title: 'Available Refunds',
      content: availableRefundClaims.length,
    },
    {
      title: 'Disputed Claims',
      content: disputedClaims.length,
    },
    {
      title: 'Ongoing Claims',
      content: ongoingClaims.length,
    },
    {
      title: 'Resolved Claims',
      content: resolvedClaims.length,
    },
  ];

  const configClaims = columnConfigCover();
  const makeNavUrl = (record: ClaimData) =>
    `/dashboard/claim/${record.claimId}`;

  return (
    <>
      <StatLine title="My Claims" data={statLineData} className="mb-6" />

      <BaseTable
        title="Available Refunds"
        rowKey="claimId"
        config={configClaims}
        data={availableRefundClaims}
        isLoading={false}
        makeNavUrl={makeNavUrl}
      />

      <BaseTable
        title="Disputed Claims"
        rowKey="claimId"
        config={configClaims}
        data={disputedClaims}
        isLoading={false}
        makeNavUrl={makeNavUrl}
      />

      <BaseTable
        title="Ongoing Claims"
        rowKey="claimId"
        config={configClaims}
        data={ongoingClaims}
        isLoading={false}
        makeNavUrl={makeNavUrl}
      />

      <BaseTable
        title="Resolved Claims"
        rowKey="claimId"
        config={configClaims}
        data={resolvedClaims}
        isLoading={false}
        makeNavUrl={makeNavUrl}
      />
    </>
  );
}
