import { BigNumber } from 'ethers';
import { bnPercent } from '../functions/utils/formatting';
import { FormatTokenAmount } from '../components/primitives/FormattedNumber';

export function PoolUsageBar({
  amountTotal,
  amountUsed,
}: {
  amountTotal: BigNumber;
  amountUsed: BigNumber;
}) {
  const usedPercent = amountUsed.eq(0)
    ? 0
    : Math.round(bnPercent(amountUsed, amountTotal, true));
  const unusedPercent = 100 - usedPercent;

  let usedWidth: string;
  let unusedWidth: string;
  if (usedPercent < 20) {
    usedWidth = '20%';
    unusedWidth = '80%';
  } else if (usedPercent > 80) {
    usedWidth = '80%';
    unusedWidth = '20%';
  } else {
    usedWidth = `${usedPercent}%`;
    unusedWidth = `${100 - usedPercent}%`;
  }

  return (
    // @bw should split in line with text + line with bar to have real width
    <div className="flex w-[400px] items-center justify-center mb-8">
      <div
        className={`flex flex-col items-center`}
        style={{ width: usedWidth }}
      >
        <p className="font-bold whitespace-nowrap">Supply in Use</p>
        <div className="my-1 whitespace-nowrap">
          {FormatTokenAmount(amountUsed, 'USD')}
        </div>
        <div
          className="w-full text-center justify-center theme-tertiary-bg"
          style={{
            borderRadius: '15px 0 0 15px',
          }}
        >
          {usedPercent} %
        </div>
      </div>
      <div
        className={`flex flex-col items-center`}
        style={{ width: unusedWidth }}
      >
        <p className="font-bold whitespace-nowrap">Unused Supply</p>
        <div className="my-1 whitespace-nowrap">
          {FormatTokenAmount(amountTotal.sub(amountUsed), 'USD')}
        </div>
        <div
          className="w-full text-center theme-disabled-bg"
          style={{
            borderRadius: '0 15px 15px 0',
          }}
        >
          {unusedPercent} %
        </div>
      </div>
    </div>
  );
}
