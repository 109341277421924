import { Layout } from 'antd';
import { Outlet } from 'react-router-dom';
import { NavHeader } from './NavHeader';
import { PageFooter } from './PageFooter';

export function RootLayout({
  children,
}: {
  children?: JSX.Element | JSX.Element[];
}) {
  return (
    <Layout className="layout min-h-[100vh] bg">
      <NavHeader />
      <div className="py-4 px-2 md:py-6 md:px-12 mb-32">
        {children || <Outlet />}
      </div>
      <PageFooter />
    </Layout>
  );
}
