import { Space } from 'antd';
import { BigNumber } from 'ethers';
import { FormatPercent } from '../primitives/FormattedNumber';
import { TokenInput } from '../TokenInput';
// Context
import { useAppDataContext } from '../../hooks/useAppDataProvider';

const DAY_IN_MS = 24 * 60 * 60 * 1000;

export const BasketSupplyData = ({ poolId }: { poolId: string }) => {
  const { findPoolById } = useAppDataContext();

  const pool = findPoolById(poolId);

  let apr = 0;
  let commitDelay = 0;
  if (pool) {
    // apr = pool.supplyRate;
    // commitDelay = pool.commitDelay;
  }

  return (
    <Space size="middle">
      <div className="flex flex-col">
        <span className="mt-1">APR: {FormatPercent(apr)}</span>
        <span>
          Commit Delay: {Math.round(commitDelay / DAY_IN_MS)} days
        </span>
      </div>
    </Space>
  );
};
