import { atom } from 'jotai';
import { focusAtom } from 'jotai/optics';
import { atomWithStorage } from 'jotai/utils';

// -------------------- UI --------------------

// Defines the default or last viewed dashboard page
export const dashboardPageAtom = atomWithStorage<string>(
  'dashboardPage',
  'covers'
);

export const darkModeAtom = atomWithStorage<boolean>('darkMode', false);
