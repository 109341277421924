import { BigNumber } from 'ethers';
import { Col, Typography, Button } from 'antd';
// Hooks
import { usePageTitle } from '../../hooks/usePageTitle';
import { useAppDataContext } from '../../hooks/useAppDataProvider';
import { useProtocolDataContext } from '../../hooks/useProtocolDataProvider';
// Components
import { Link } from 'react-router-dom';
import { BaseTable } from '../../components/tables/BaseTable';
import { TableMiniLogo } from '../../components/icons/TableMiniLogo';
import { StatLine } from '../../components/StatLine';
import { PoolUsageBar } from '../../components/PoolUsageBar';
import {
  FormatTokenAmount,
  FormatPercent,
} from '../../components/primitives/FormattedNumber';
import { LevelTag } from '../../components/LevelTag';
// Type
import { PositionTableConfigItem, UserPosition } from '../../types';
// Functions
import { bnPercent } from '../../functions/utils/formatting';

// Colums Table
const columnConfigCover = (
  calcPositionApr: (position: UserPosition) => number
): PositionTableConfigItem[] => [
  {
    title: 'Position ID',
    key: 'positionId',
    render: (_, record) => (
      <div className="text-center">{record.positionId}</div>
    ),
  },
  {
    title: 'Protocols Covered',
    key: 'amount',
    render: (_, record) => <TableMiniLogo poolIds={record.poolIds} />,
  },
  {
    title: 'Start Date',
    key: 'startDate',
    cellContent: (record) =>
      `${new Date(record.startDate).toLocaleDateString()}`,
  },
  {
    title: 'Amount Supplied',
    key: 'amountSupplied',
    render: (_, record) => FormatTokenAmount(record.amountSupplied, 'USD'),
  },
  {
    title: 'Current APR',
    key: 'apr',
    render: (_, record) => FormatPercent(calcPositionApr(record)),
  },
  {
    title: 'Premiums Received',
    key: 'premiumRewards',
    render: (_, record) => FormatTokenAmount(record.premiumRewards, 'USD'),
  },
  {
    title: '',
    key: 'action',
    render: (_, record) => (
      <div className="flex justify-end">
        <Link
          className="mx-2"
          to={'/dashboard/position/' + record.positionId}
        >
          <Button type="default">View Position</Button>
        </Link>
      </div>
    ),
  },
];

export function DashboardPositions() {
  usePageTitle('My Covers');
  const {
    userPositions,
    userGeneralStaking,
    userSuppliedCapital,
    userUsedSuppliedCapital,
    calcPositionApr,
    getCoverSupplyLevel,
  } = useAppDataContext();

  const sumApr =
    userPositions
      .reduce(
        (acc, position) =>
          acc.add(
            position.amountSupplied.mul(
              Math.round(calcPositionApr(position) * 1_000_000)
            )
          ),
        BigNumber.from(0)
      )
      .div(userSuppliedCapital.isZero() ? 1 : userSuppliedCapital)
      .toNumber() / 1_000_000;

  const sumRewards = userPositions?.reduce(
    (sum, el) => sum.add(el.premiumRewards),
    BigNumber.from(0)
  );

  const userFeeRateLevel = getCoverSupplyLevel(userGeneralStaking.amount);

  const statLineData = [
    {
      title: 'Supply Fee Level',
      content: <LevelTag level={userFeeRateLevel} />,
    },
    {
      title: 'Total Supplied',
      content: FormatTokenAmount(userSuppliedCapital, 'USD'),
    },
    {
      title: 'Global Est. APR',
      content: FormatPercent(sumApr),
    },
    {
      title: 'Total Premiums Received',
      content: FormatTokenAmount(sumRewards, 'USD'),
    },
  ];

  const configCover = columnConfigCover(calcPositionApr);
  const makeNavUrl = (record: UserPosition) =>
    `/dashboard/position/${record.positionId}`;

  return (
    <>
      <StatLine title="My Positions" data={statLineData} className="mb-6">
        {userSuppliedCapital.gt(0) && (
          <PoolUsageBar
            amountTotal={userSuppliedCapital}
            amountUsed={userUsedSuppliedCapital}
          />
        )}
      </StatLine>

      <BaseTable
        title="Positions"
        rowKey="positionId"
        config={configCover}
        data={userPositions}
        isLoading={false}
        makeNavUrl={makeNavUrl}
      />
    </>
  );
}
