// Hooks
import { useCopyToClipboard } from '../../hooks/utils/useCopyToClipboard';
// Context
import { useWeb3Context } from '../../hooks/useWeb3ContextProvider';
// Components
import { Link } from 'react-router-dom';
import { FormattedNumber } from '../primitives/FormattedNumber';
// Icon
import { ArrowRightIcon } from '../icons/ArrowRightIcon';
import { ArrowTopRightIcon } from '../icons/ArrowTopRightIcon';
import { CopyIcon } from '../icons/CopyIcon';
import { PowerOffIcon } from '../icons/PowerOffIcon';
// Functions
import { textCenterEllipsis } from '../../functions/utils/formatting';

type AccountInfoProps = {
  blockieAddress: string;
  ellipsisAddress: string;
  AccountIcon: (props: { account: string; size: number }) => JSX.Element;
  setViewHistory: (viewHistory: boolean) => void;
};

export function AccountInfo({
  blockieAddress,
  AccountIcon,
  setViewHistory,
}: AccountInfoProps) {
  const {
    currentAccount,
    etherBalance,
    disconnectWallet,
    currentNetworkConfig,
  } = useWeb3Context();

  const { copyToClipboard } = useCopyToClipboard();

  const explorerUrl = currentNetworkConfig.explorerLinkBuilder?.({
    address: blockieAddress,
  });

  const ellipsisAddress = textCenterEllipsis(currentAccount, 6, 4);

  return (
    <div className="flex w-60 flex-col items-center space-y-4">
      <div className="flex flex-1 items-center">
        <div className="mr-2 flex-none flex items-center">
          <AccountIcon account={blockieAddress} size={24} />
        </div>
        <div className="mr-4 grow">{ellipsisAddress}</div>

        <button
          onClick={() => copyToClipboard(currentAccount)}
          className="relative mx-2 flex-1"
        >
          <CopyIcon className="h-4 w-4" />
        </button>
        <div className="ml-2 mr-4 flex-1">
          {explorerUrl ? (
            <a href={explorerUrl} target="_blank" rel="noreferrer">
              <ArrowTopRightIcon />
            </a>
          ) : (
            <ArrowTopRightIcon />
          )}
        </div>

        <button onClick={disconnectWallet}>
          <PowerOffIcon className="theme-highlight" />
        </button>
      </div>

      <div className="flex-1 text-center text-xl font-bold">
        <FormattedNumber
          className="theme-primary"
          value={etherBalance || '0'}
          tokenDecimals={currentNetworkConfig.baseAssetDecimals}
          symbol={currentNetworkConfig.baseAssetSymbol}
          visibleDecimals={2}
        />
      </div>

      {/* <Link
        className="theme-primary-border flex items-center space-x-2 px-4 py-1"
        to="/swap"
      >
        <span>Swap to ATEN</span>
        <ArrowTopRightIcon />
      </Link> */}
      {/* 
      <hr className="w-full flex-1 border-white/[0.25]" />

      <button
        onClick={() => setViewHistory(true)}
        className="mt-1 flex w-full flex-1 items-center space-x-2 rounded-sm p-1 transition-shadow duration-300 hover:ring-1 hover:ring-white"
      >
        <div className="grow text-left">Transactions</div>
        <div className="flex-none">
          <ArrowRightIcon />
        </div>
      </button> */}
    </div>
  );
}
