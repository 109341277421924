import { utils, BigNumber } from 'ethers';
import { Button, Col, Row, Tag } from 'antd';
import { Link } from 'react-router-dom';
// Hooks
import { usePageTitle } from '../hooks/usePageTitle';
import { useAppDataContext } from '../hooks/useAppDataProvider';
// Components
import { HeaderMarket } from '../components/layout/HeaderMarket';
import { StatLine } from '../components/StatLine';
import { BaseTable } from '../components/tables/BaseTable';
import {
  FormatTokenAmount,
  FormatPercent,
} from '../components/primitives/FormattedNumber';
// Types
import { BonusTableConfigItem, BonusData } from '../types';
// Functions
import { bnPercent } from '../functions/utils/formatting';

const formatTableBonus = (bonus: { [key: number]: number }): BonusData[] =>
  Object.entries(bonus).map(([key, value], i) => ({
    level: i + 1,
    amount: parseInt(key),
    bonus: value,
  }));

const userLevelTag = (level: number) => (
  <Tag
    style={{
      color: '#eeee2a',
      borderColor: '#eeee2a',
      backgroundColor: 'transparent',
      marginLeft: 12,
    }}
  >
    Your level: {level}
  </Tag>
);

const columnConfigStaking = (
  userFeeLevel: number
): BonusTableConfigItem[] => [
  {
    title: 'Level',
    key: 'level',
    align: 'center',
    width: 60,
    render: (_, record) => {
      const isUserLevel = record.level === userFeeLevel;
      return (
        <p
          className={`${
            isUserLevel && 'rounded-full theme-dark theme-secondary-bg'
          }`}
        >
          {record.level}
        </p>
      );
    },
  },
  {
    title: 'Amount Staked',
    key: 'amount',
    render: (_, record) =>
      FormatTokenAmount(
        BigNumber.from(record.amount).mul(BigNumber.from(10).pow(18)),
        'ATEN'
      ),
  },
  {
    title: 'Fee discount',
    key: 'bonus',
    render: (_, record) => FormatPercent(record.bonus),
  },
];

const columnConfigSupply = (
  userStakingLevel: number
): BonusTableConfigItem[] => [
  {
    title: 'Level',
    key: 'amount',
    align: 'center',
    width: 60,
    render: (_, record) => {
      const isUserLevel = record.level === userStakingLevel;
      return (
        <p
          className={`${
            isUserLevel && 'rounded-full theme-dark theme-secondary-bg'
          }`}
        >
          {record.level}
        </p>
      );
    },
  },
  {
    title: 'Amount Supplied',
    key: 'amount',
    render: (_, record) =>
      FormatTokenAmount(
        BigNumber.from(record.amount).mul(BigNumber.from(10).pow(6)),
        'USD'
      ),
  },
  {
    title: 'Staking APR',
    key: 'amount',
    render: (_, record) => FormatPercent(record.bonus),
  },
];

export function Stake() {
  usePageTitle('Staking');

  const {
    userGeneralStaking,
    userSuppliedCapital,
    atenTotalSupply,
    atenPrice,
    amountStakedAtenGp,
    refundRate,
    refundBasePenaltyRate,
    refundDurationPenaltyRate,
    refundShortCoverDuration,
    athenaFeeLevels,
    athenaStakingLevels,
    getAtenStakingLevel,
    getCoverSupplyLevel,
  } = useAppDataContext();

  // @bw possible lost precision because of immediate 10_000 division
  const stakedValue = utils.parseUnits(
    amountStakedAtenGp.mul(10_000).div(atenPrice).div(10_000).toString(),
    6
  );

  const proportionStaked = bnPercent(amountStakedAtenGp, atenTotalSupply);
  const maxStakingApr = Object.values(athenaStakingLevels).reduce(
    (acc, apr) => (acc < apr ? apr : acc),
    0
  );

  const statLineStakingData = [
    {
      title: 'Amount Staked',
      content: FormatTokenAmount(amountStakedAtenGp, 'ATEN'),
    },
    {
      title: 'Supply Locked',
      content: FormatPercent(proportionStaked),
    },
    {
      title: 'Value Staked',
      content: FormatTokenAmount(stakedValue, 'USD'),
    },
    {
      title: 'Max Staking APR',
      content: FormatPercent(maxStakingApr),
    },
  ];

  const statLineCoverRefundData = [
    {
      title: 'Refund Rate',
      content: FormatPercent(refundRate),
    },
    {
      title: 'Penalty Period',
      content: refundShortCoverDuration / (24 * 60 * 60 * 1000) + ' days',
    },
    {
      title: 'Base Penalty Rate',
      content: FormatPercent(refundBasePenaltyRate),
    },
    {
      title: 'Duration Penalty Rate',
      content: FormatPercent(refundDurationPenaltyRate),
    },
  ];

  const userFeeRateLevel = getCoverSupplyLevel(userGeneralStaking.amount);
  const userStakingAprLevel = getAtenStakingLevel(userSuppliedCapital);

  const dataStakingApr = formatTableBonus(athenaStakingLevels);
  const dataFeeRate = formatTableBonus(athenaFeeLevels);

  const configStakingApr = columnConfigStaking(userFeeRateLevel);
  const configFeeRate = columnConfigSupply(userStakingAprLevel);

  return (
    <>
      <HeaderMarket title={'Stake'} />

      <StatLine
        title="General Staking Pool"
        data={statLineStakingData}
        className="mb-6"
      >
        <div className="flex flex-col">
          <Row>
            <Col span={11} className="mx-auto">
              <BaseTable
                title={
                  <>
                    ATEN Staking Incentive
                    {userLevelTag(userFeeRateLevel)}
                  </>
                }
                description={
                  'Stake ATEN to pay less fees on your earnings in Athena pools.'
                }
                rowKey="level"
                config={configStakingApr}
                data={dataFeeRate}
                isLoading={false}
              />
            </Col>
            <Col span={11} className="mx-auto">
              <BaseTable
                title={
                  <>
                    Cover Provider Incentive
                    {userLevelTag(userStakingAprLevel)}
                  </>
                }
                description={
                  'Deposit capital in Athena pools and get a higher staking APR.'
                }
                rowKey="level"
                config={configFeeRate}
                data={dataStakingApr}
                isLoading={false}
              />
            </Col>
          </Row>
          <Row className="self-center">
            <Link to={'/dashboard/staking/'}>
              <Button type="default">Start Staking ATEN</Button>
            </Link>
          </Row>
        </div>
      </StatLine>

      <StatLine
        title="Cover Refund Staking Pool"
        data={statLineCoverRefundData}
        className="mb-6"
      >
        <Link to={'/dashboard/staking/#cover-refund'}>
          <Button type="default">View My Refund Staking</Button>
        </Link>
      </StatLine>
    </>
  );
}
