import { Skeleton } from 'antd';

const availableLogos: { [key: number]: string } = {
  42161: '/icons/networks/arbitrum.svg',
  43114: '/icons/networks/avalanche.svg',
  56: '/icons/networks/bsc.png',
  250: '/icons/networks/fantom.svg',
  1666600000: '/icons/networks/harmony.svg',
  10: '/icons/networks/optimism.svg',
  137: '/icons/networks/polygon.svg',
  1: '/icons/networks/ethereum.svg',
  5: '/icons/networks/ethereum.svg',
  80001: '/icons/networks/polygon.svg',
};

const DEFAULT_SIZE = 48;

export const ChainLogo = ({
  chainId,
  size,
  className,
}: {
  chainId: number | undefined;
  size?: number;
  className?: string;
}) => {
  let content: JSX.Element;

  if (chainId === 31337 || chainId === 1337) {
    content = <>{'👷'}</>;
  } else if (chainId && availableLogos[chainId]) {
    content = (
      <img
        height={size || DEFAULT_SIZE}
        width={size || DEFAULT_SIZE}
        className={`rounded-full px-1 py-1 ${className || ''}`}
        src={availableLogos[chainId]}
      />
    );
  } else {
    content = (
      <Skeleton.Avatar
        size={(size || DEFAULT_SIZE) * 0.83}
        active={!chainId}
        className={className}
      />
    );
  }

  return content;
};
