import { useCalls } from '@usedapp/core';
import { Contract } from 'ethers';
import { ERC20, ERC20__factory } from '../../../../types/typechain';

export function useTokenInfo(tokenAddresses: string[] | undefined):
  | {
      name: string;
      symbol: string;
      decimals: number;
      address: string;
    }[]
  | undefined {
  const tokensFiltered = [...new Set(tokenAddresses)].filter(
    (address) => address !== '0x0000000000000000000000000000000000000000'
  );

  const calls =
    tokensFiltered
      ?.map((address) => [
        {
          contract: new Contract(address, ERC20__factory.abi) as ERC20,
          method: 'name',
          args: [],
        },
        {
          contract: new Contract(address, ERC20__factory.abi) as ERC20,
          method: 'symbol',
          args: [],
        },
        {
          contract: new Contract(address, ERC20__factory.abi) as ERC20,
          method: 'decimals',
          args: [],
        },
      ])
      .flat() ?? [];

  const results = useCalls(calls) ?? [];

  results.forEach((result, i) => {
    if (result?.error) {
      // console.error(
      //   `Error while fetching token data of ${calls[i]?.contract?.address}: ${result.error.message}`
      // );
      console.error(
        `Error while fetching token data of ${calls[i]?.contract?.address}`
      );
    }
  });

  const tokenDatas = [];

  for (let i = 0; i < results.length; i += 3) {
    if (
      results[i]?.error ||
      results[i + 1]?.error ||
      results[i + 2]?.error
    )
      continue;

    tokenDatas.push({
      name: results[i]?.value?.[0] as string,
      symbol: results[i + 1]?.value?.[0] as string,
      decimals: results[i + 2]?.value?.[0] as number,
      address: calls[i].contract?.address,
    });
  }

  return tokenDatas;
}
