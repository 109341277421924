import { useAppDataContext } from '../../hooks/useAppDataProvider';
import { PoolLogo, wrapInLink } from './PoolLogo';

export const TableLogoWrapper = ({
  poolId,
  className,
  size,
}: {
  poolId: string | undefined;
  className?: string;
  size?: number;
}) => {
  const { getPoolNameById } = useAppDataContext();
  return (
    <div className={`font-bold theme-secondary flex ${className || ''}`}>
      {wrapInLink(<PoolLogo size={size || 40} poolId={poolId} />, poolId)}
      {wrapInLink(
        <div className="ml-3">{getPoolNameById(poolId)}</div>,
        poolId
      )}
    </div>
  );
};
