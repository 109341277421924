import { Button, Col, Input, Row, Typography, UploadFile } from 'antd';
import { useParams, Link } from 'react-router-dom';
// Hooks
import { usePageTitle } from '../hooks/usePageTitle';
// Components
import { OtherClaimsTable } from '../components/claims/OtherClaimsTable';
import { EvidenceDisplay } from '../components/claims/EvidenceDisplay';
import { ChallengeClaim } from '../components/claims/ChallengeClaim';
import { ClaimContextData } from '../components/claims/ClaimContextData';
import { HeaderPool } from '../components/layout/HeaderPool';
import { StatLine } from '../components/StatLine';
import { FormatTokenAmount } from '../components/primitives/FormattedNumber';
import { Countdown } from '../components/primitives/Countdown';
// import {
//   ClaimReleaseCompensationButton,
//   ClaimWithdrawWithoutDisputeButton,
// } from '../components/contracts';
// Functions
import { formatTimeToText } from '../functions/utils/formatting';
import {
  isClaimPayable,
  isClaimAccepted,
  isClaimResolved,
} from '../functions/checkAthenaData';
// Context
import { useWeb3Context } from '../hooks/useWeb3ContextProvider';
import { useAppDataContext } from '../hooks/useAppDataProvider';
import { BigNumber } from 'ethers';
// Type
import { UserCover, ClaimStatus } from '../types';

const DAY_IN_MS = 24 * 60 * 60 * 1000;

const statusContent = (isCoverExpired: boolean) =>
  isCoverExpired ? (
    <p className="text-orange-500">Expired</p>
  ) : (
    <p className="text-lime-500">Active</p>
  );

const periodContent = (
  userPolicy: UserCover | undefined,
  isCoverExpired: boolean
) => {
  const { startDate, duration } = userPolicy || {
    startDate: Date.now(),
    duration: 0,
  };
  return `${new Date(startDate).toLocaleDateString()} - ${
    isCoverExpired
      ? new Date(startDate + duration * DAY_IN_MS).toLocaleDateString()
      : 'now'
  }`;
};

function makeStatusTag(claimStatus: ClaimStatus) {
  let statusColor = '';
  if (isClaimAccepted(claimStatus)) {
    statusColor = 'text-lime-500';
  } else if (claimStatus === 'Disputed') {
    statusColor = 'text-orange-500';
  } else if (
    isClaimResolved(claimStatus) &&
    !isClaimAccepted(claimStatus)
  ) {
    statusColor = 'text-red-500';
  } else {
    statusColor = 'theme-secondary';
  }
  return <p className={`${statusColor}`}>{`${claimStatus}`}</p>;
}

// @bw need to be able to find claim by chainId + coverId + coverClaimIndex (in url params)
// @bw need to provide an rpc for the claim to be able to fetch evidence for jurors

export function ClaimDetails() {
  const { claimId } = useParams();
  usePageTitle(`Claim n°${claimId}`);
  const {
    getPoolNameById,
    findClaimById,
    findClaimsByCoverId,
    findCoverById,
    challengeDelay,
    arbitrationCost,
    collateralAmount,
  } = useAppDataContext();
  const { currentAccount } = useWeb3Context();

  const userClaim = findClaimById(claimId);
  const userCover = findCoverById(userClaim?.coverId);

  // We want to remove the current claim from this list
  const coverClaims = findClaimsByCoverId(userClaim?.coverId).filter(
    (el) => el.claimId !== claimId
  );

  const poolId = userClaim?.poolId || undefined;
  const claimStatus = userClaim?.status || 'RejectedWithDispute';
  const creationDate = userClaim?.creationDate || Date.now();
  const isCoverExpired = userCover?.endDate !== 0;

  const userAddress = userClaim?.userAddress || '';
  const challengerAddress = userClaim?.challenger || '';
  const isCurrentUserClaim =
    userAddress.toLowerCase() === currentAccount.toLowerCase();
  const isChallengerUser =
    challengerAddress.toLowerCase() === currentAccount.toLowerCase();

  const statLineData = [
    {
      title: 'Claim ID',
      content: claimId,
    },
    {
      title: 'Dispute ID',
      content: userClaim?.disputeId || 'N/A',
    },
    {
      title: 'Amount Claimed',
      content: FormatTokenAmount(
        userClaim?.amountClaimed || BigNumber.from(0),
        'USD'
      ),
    },
    {
      title: 'Status',
      content: makeStatusTag(claimStatus),
    },
    {
      title: 'Policy Status',
      content: statusContent(isCoverExpired),
    },
    {
      title: 'Amount Covered',
      content: FormatTokenAmount(
        userCover?.amountCovered || BigNumber.from(0),
        'USD'
      ),
    },
    {
      title: 'Cover Period',
      content: periodContent(userCover, isCoverExpired),
    },
    {
      title: 'Challenge Window',
      content:
        creationDate + challengeDelay < Date.now() ? (
          'ended'
        ) : (
          <Countdown
            eventTimestamp={creationDate}
            delay={challengeDelay - 30_000} // -5min for security
            withDays
          />
        ),
    },
    {
      title: 'Arbitration Cost',
      content: FormatTokenAmount(arbitrationCost, 'ETH'),
    },
    {
      title: 'Dispute Collateral',
      content: FormatTokenAmount(collateralAmount, 'ETH'),
    },
  ];

  const titleLineData = [
    {
      title: 'Protocol',
      content: getPoolNameById(poolId),
    },
    {
      title: 'Claimant',
      content: `${userAddress} ${isCurrentUserClaim ? '(you)' : ''}`,
    },
    {
      title: 'Challenger',
      content: `${challengerAddress} ${isChallengerUser ? '(you)' : ''}`,
    },
    {
      title: 'Creation Date',
      content: `${formatTimeToText(creationDate)}`,
    },
  ];

  const activateWithdraw =
    isCurrentUserClaim && isClaimPayable(claimStatus);

  return (
    <>
      <HeaderPool poolId={userClaim?.poolId} />

      <StatLine title={'Claim'} data={statLineData} className="mb-6">
        {isCurrentUserClaim &&
          (claimStatus === 'Accepted' ? (
            // <ClaimWithdrawWithoutDisputeButton
            //   params={{ claimId }}
            //   disabled={!activateWithdraw}
            //   className="w-[220px]"
            // />
            <button>WITHDRAW</button>
          ) : (
            // <ClaimReleaseCompensationButton
            //   params={{ claimId }}
            //   disabled={!activateWithdraw}
            //   className="w-[220px]"
            // />
            <button>RELEASE</button>
          ))}
      </StatLine>

      <Col span={24} className="content-card p-4">
        {titleLineData.map((el, i) => (
          <div key={i} className="flex">
            <span className="font-bold text-lg theme-secondary">
              {el.title}:
            </span>
            <span className="ml-2 text-lg">{el.content}</span>
          </div>
        ))}

        <hr
          style={{
            marginLeft: -16,
            width: 'calc(100% + 32px)',
            marginTop: 16,
          }}
        />

        <ClaimContextData claimId={claimId} />
      </Col>

      <EvidenceDisplay
        claimId={claimId}
        isParty={isCurrentUserClaim || isChallengerUser}
        claimStatus={claimStatus}
      />

      <OtherClaimsTable
        title="Others claims related to this cover"
        coverClaims={coverClaims}
        className="mt-8"
      />

      <ChallengeClaim
        claimId={claimId}
        claimStatus={claimStatus}
        claimantAddres={userAddress}
      />
    </>
  );
}
