import { useCall } from '@usedapp/core';
import { formatUserPolicy } from '../../../../functions/athenaDataFormat';
import { useProtocolDataContext } from '../../../useProtocolDataProvider';
import { useSingleCall } from '../../../useSingleCall';
import { useState } from 'react';
import { UserCover } from '../../../../types';

// Value that is returned if the hook encounters an error
const defaultValue: UserCover[] = [];

export const usePoliciesByAccount = (account: string) => {
  return defaultValue;

  // const [previousValue, setPreviousValue] = useState(defaultValue);
  // const [canCall, isCalled] = useSingleCall();

  // const { chainContracts } = useProtocolDataContext();
  // const contract = chainContracts?.POLICY_MANAGER;

  // const conditionsOk = !!account && canCall;

  // const { value, error } =
  //   useCall(
  //     conditionsOk &&
  //       contract && {
  //         contract: contract,
  //         method: 'fullCoverDataByAccount', // Method to be called
  //         args: [account], // Method arguments
  //       }
  //   ) ?? {};

  // if (error) {
  //   console.error(error.message);
  //   return defaultValue;
  // } else if (value?.[0]) {
  //   const formattedResult = formatUserPolicy(value[0]);
  //   setPreviousValue(formattedResult);
  //   isCalled();
  //   return formattedResult;
  // } else if (previousValue.length) {
  //   return previousValue;
  // } else {
  //   return defaultValue;
  // }
};
