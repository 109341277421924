import { BigNumber, utils } from 'ethers';
import { Typography } from 'antd';

const { formatUnits } = utils;

const MAX_SAFE_INTEGER = 9_007_199_254_740_990;
const PERCENT_PRECISION = 10_000;
const PERCENT_PRECISION_LENGTH = PERCENT_PRECISION.toString().length;
const POSTFIXES = ['', 'k', 'M', 'B', 'T', 'P', 'E', 'Z', 'Y'];

function CompactNumber({
  value,
  visibleDecimals,
  style,
}: {
  value: number;
  visibleDecimals?: number;
  style?: any;
  percent?: boolean;
}) {
  const integerPlaces = Math.floor(value).toString().length;
  const significantDigitsGroup = Math.min(
    Math.floor(integerPlaces ? (integerPlaces - 1) / 3 : 0),
    POSTFIXES.length - 1
  );
  const postfix = POSTFIXES[significantDigitsGroup];
  const formattedValue = parseFloat(
    (value / 10 ** (3 * significantDigitsGroup)).toFixed(
      visibleDecimals || 2
    )
  );

  return (
    <p style={style}>
      {new Intl.NumberFormat('en-US', {
        maximumFractionDigits: visibleDecimals,
        minimumFractionDigits: visibleDecimals,
      }).format(formattedValue)}
      {` ${postfix}`}
    </p>
  );
}

const fontSizeConfig = {
  normal: 16,
  large: 18,
  larger: 20,
};

export const FormatTokenAmount = (
  value: BigNumber,
  symbol: 'USD' | 'ATEN' | 'USDT' | 'ETH',
  decimals?: number,
  size?: 'large' | 'larger',
  bold?: boolean
) =>
  FormattedNumber({
    value,
    tokenDecimals: symbol.includes('USD') ? 6 : 18,
    symbol,
    visibleDecimals: decimals || 2,
    compact: true,
    size,
    bold,
  });

export const FormatPercent = (
  value: number,
  size?: 'large' | 'larger',
  bold?: boolean
) =>
  FormattedNumber({
    value: value,
    percent: true,
    compact: true,
    size,
    bold,
  });

export function FormattedNumber({
  value,
  tokenDecimals,
  symbol,
  visibleDecimals,
  compact,
  size,
  bold,
  percent,
  symbolsColor,
  className,
}: {
  value: string | number | BigNumber;
  tokenDecimals?: number;
  symbol?: string;
  visibleDecimals?: number;
  compact?: boolean;
  size?: 'large' | 'larger';
  bold?: boolean;
  percent?: boolean;
  symbolsColor?: string;
  className?: string;
}) {
  tokenDecimals ?? 0;

  let parsedBigNumber: number = 0;
  if (tokenDecimals) {
    const toBigNumber = BigNumber.isBigNumber(value)
      ? value
      : BigNumber.from(value);

    if (
      toBigNumber.gt(
        BigNumber.from(MAX_SAFE_INTEGER).mul(
          BigNumber.from(10).pow(tokenDecimals)
        )
      )
    ) {
      throw Error('FormattedNumber - Token value overflow');
    } else {
      parsedBigNumber = parseFloat(
        formatUnits(toBigNumber, tokenDecimals)
      );
    }
  }

  // Priority to bignumber
  const number =
    parsedBigNumber || (percent ? Number(value) * 100 : Number(value));

  let decimals = visibleDecimals;
  if (number === 0) {
    decimals = 0;
  } else if (visibleDecimals === undefined) {
    if (number > 1 || percent || symbol === 'USD') {
      decimals = 2;
    } else {
      decimals = 7;
    }
  }

  const minValue = 10 ** -(decimals as number);
  const isSmallerThanMin =
    number !== 0 && Math.abs(number) < Math.abs(minValue);
  const formattedNumber = isSmallerThanMin ? minValue : number;
  const forceCompact = compact !== false && (compact || number > 99_999);
  const fontSize = fontSizeConfig[size || 'normal'];

  const typoStyle = (side: 'Left' | 'Right') => ({
    fontSize: fontSize,
    color: symbolsColor || '#A0A0B0',
    [`margin${side}`]: 4,
  });

  return (
    <Typography
      className={className}
      style={{
        fontSize: fontSize,
        display: 'inline-flex',
        flexDirection: 'row',
        alignItems: 'center',
        position: 'relative',
      }}
    >
      {isSmallerThanMin && (
        <Typography style={typoStyle('Right')}>{'<'}</Typography>
      )}
      {symbol?.toLowerCase() === 'usd' && !percent && (
        <Typography style={typoStyle('Right')}>{'$'}</Typography>
      )}

      {!forceCompact ? (
        new Intl.NumberFormat('en-US', {
          maximumFractionDigits: decimals,
          minimumFractionDigits: decimals,
        }).format(formattedNumber)
      ) : (
        <CompactNumber
          style={{
            fontSize: fontSize,
            fontWeight: bold ? 'bold' : 'normal',
          }}
          value={formattedNumber}
          visibleDecimals={decimals}
          percent={percent}
        />
      )}

      {percent && <Typography style={typoStyle('Left')}>{'%'}</Typography>}
      {symbol?.toLowerCase() !== 'usd' &&
        typeof symbol !== 'undefined' && (
          <Typography
            style={{ ...typoStyle('Left'), fontSize: fontSize - 2 }}
          >
            {symbol}
          </Typography>
        )}
    </Typography>
  );
}
