import { Button, Col, Row, Space, Typography } from 'antd';
import { BigNumber } from 'ethers';
import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
// Hooks
import { useAppDataContext } from '../hooks/useAppDataProvider';
import { useBasketContext } from '../hooks/useBasketProvider';
import { usePageTitle } from '../hooks/usePageTitle';
// Components
import { HeaderPool } from '../components/layout/HeaderPool';
import { DisclaimerRow } from '../components/DisclaimerRow';
import { ApyGraphContainer } from '../components/graphs/ApyGraphContainer';
import {
  FormatTokenAmount,
  FormatPercent,
} from '../components/primitives/FormattedNumber';
// Types
import { FormattedPool } from '../types';

const DAY_IN_MS = 24 * 60 * 60 * 1000;

type PoolCardParams = {
  title: string;
  ctaTitle: string;
  ctaSelectedTitle: string;
  ctaAction: any;
  isSelected: boolean;
  infoData: {
    name: string;
    data: string | JSX.Element;
  }[];
};

const PoolCard = ({
  cardData,
  index,
}: {
  cardData: PoolCardParams;
  index: number;
}) => {
  return (
    <div
      className={`content-card rounded flex flex-col p-4 ${
        index !== 0 ? 'mt-4' : ''
      }`}
    >
      <Typography.Title
        level={3}
        className="theme-secondary flex flex-nowrap break-normal items-center"
      >
        {cardData.title}
      </Typography.Title>

      <Space direction="vertical" className="my-4">
        {cardData.infoData.map((el, i) => (
          <Space key={i} direction="horizontal">
            <span>{el.name}</span>
            <b className="info-text">{el.data}</b>
          </Space>
        ))}
      </Space>

      <Button
        type="primary"
        onClick={cardData.ctaAction}
        className="self-center"
      >
        {cardData.isSelected
          ? cardData.ctaSelectedTitle
          : cardData.ctaTitle}
      </Button>
    </div>
  );
};

export function Pool() {
  const { poolId } = useParams();

  const {
    buyBasket,
    updateBuyBasket,
    supplyBasket,
    addToSupplyBasket,
    addToBuyBasket,
    removeFromSupplyBasket,
    removeFromBuyBasket,
  } = useBasketContext();
  const { findPoolById, userUsdtBalance } = useAppDataContext();

  const poolData = findPoolById(poolId);
  usePageTitle(`${poolData?.name || 'Unknown Pool'}`);

  const inBuyBasket = !!buyBasket.poolIds.find(
    (el: string) => el === poolId
  );
  const inSupplyBasket = !!supplyBasket.poolIds.find(
    (el: string) => el === poolId
  );

  const handleCart = (type: 'buy' | 'supply', poolId?: string) => {
    if (!poolId) return;

    if (type === 'buy') {
      if (inBuyBasket) removeFromBuyBasket(poolId);
      else addToBuyBasket(poolId);
    } else {
      if (inSupplyBasket) removeFromSupplyBasket(poolId);
      else addToSupplyBasket(poolId);
    }
  };

  let availableCapacity = BigNumber.from(0);
  let premiumRate = 0;
  let supplyRate = 0;
  let commitDelay = 0;
  let dailyPriceOneK = BigNumber.from(0);
  if (poolData) {
    // availableCapacity = poolData.availableCapacity;
    // premiumRate = poolData.premiumRate;
    // supplyRate = poolData.supplyRate;
    // commitDelay = poolData.commitDelay;
    // dailyPriceOneK = poolData.dailyPriceOneK;
  }

  const [height, setHeight] = useState(0);
  const elementRef = useRef(null);

  useEffect(() => {
    if (height === 0) setHeight((elementRef.current as any).clientHeight);
  }, []);

  const PoolCardsData: PoolCardParams[] = [
    {
      title: 'Buy Cover',
      ctaTitle: 'Add to Basket',
      ctaSelectedTitle: 'Remove from Basket',
      ctaAction: () => handleCart('buy', poolId),
      isSelected: inBuyBasket,
      infoData: [
        {
          name: 'Available Capacity:',
          data: FormatTokenAmount(availableCapacity, 'USDT'),
        },
        {
          name: 'Daily cost per $1000:',
          data: FormatTokenAmount(dailyPriceOneK, 'USD'),
        },
        {
          name: 'Premium rate:',
          data: FormatPercent(premiumRate),
        },
      ],
    },
    {
      title: 'Supply Cover',
      ctaTitle: 'Add to Basket',
      ctaSelectedTitle: 'Remove from Basket',
      ctaAction: () => handleCart('supply', poolId),
      isSelected: inSupplyBasket,
      infoData: [
        {
          name: 'Supply APR:',
          data: FormatPercent(supplyRate),
        },
        {
          name: 'Withdrawal delay:',
          data: `${Math.round(commitDelay / DAY_IN_MS)} days`,
        },
      ],
    },
  ];

  return (
    <>
      <HeaderPool poolId={poolId || ''} />

      <Row ref={elementRef}>
        <Col span={8} offset={1} className="">
          {PoolCardsData.map((cardData, i) => {
            return <PoolCard key={i} cardData={cardData} index={i} />;
          })}
        </Col>

        <Col offset={1} span={13}>
          <ApyGraphContainer
            queryType="simple"
            populate={{
              supplyRate: true,
              premiumRate: true,
            }}
            poolIds={poolId ? [poolId] : []}
            height={height}
          />
        </Col>
      </Row>

      <DisclaimerRow />
    </>
  );
}
