import { Button, Col } from 'antd';
import { BigNumber } from 'ethers';
import cx from 'classnames';
// Hooks
import { usePageTitle } from '../../hooks/usePageTitle';
// Components
import { Link } from 'react-router-dom';
import { BaseTable } from '../../components/tables/BaseTable';
import { StatLine } from '../../components/StatLine';
import { TableLogoWrapper } from '../../components/icons/TableLogoWrapper';
import {
  FormatTokenAmount,
  FormatPercent,
} from '../../components/primitives/FormattedNumber';
// Type
import {
  BuyBasketItem,
  BuyBasketUpdate,
  PolicyTableConfigItem,
  UserCover,
} from '../../types';
// Context
import { useAppDataContext } from '../../hooks/useAppDataProvider';
import { useBasketContext } from '../../hooks/useBasketProvider';

// Colums Table
const columnConfigOngoing = (
  calcCoverDailyCost: (policy?: UserCover) => BigNumber
): PolicyTableConfigItem[] => [
  {
    title: 'Contract',
    key: 'protocol',
    render: (_, record) => <TableLogoWrapper poolId={record.poolId} />,
  },
  {
    title: 'Amount Insured',
    key: 'amount',
    render: (_, record) => FormatTokenAmount(record.amountCovered, 'USDT'),
  },
  {
    title: 'Premium Locked',
    key: 'lpremium',
    render: (_, record) => FormatTokenAmount(record.premiumDeposit, 'USD'),
  },
  {
    title: 'Premium Left',
    key: 'premiumLeft',
    render: (_, record) => FormatTokenAmount(record.premiumLeft, 'USD'),
  },
  {
    title: 'Daily Cost',
    key: 'premiumLeft',
    render: (_, record) =>
      FormatTokenAmount(calcCoverDailyCost(record), 'USD'),
  },
  {
    title: 'Est. Remaining',
    key: 'duration',
    cellContent: (record) =>
      `${Math.floor(record.estimatedDaysLeft)} days`,
  },
  {
    title: '',
    key: 'action',
    width: 180,
    render: (_, record) => (
      <div className="flex justify-end">
        <Link className="mx-2" to={'/dashboard/cover/' + record.coverId}>
          <Button type="default">View Cover</Button>
        </Link>
      </div>
    ),
  },
];

const columnConfigExpired = (
  basket: BuyBasketItem[],
  handleBuyAgain: (selected: boolean, record: UserCover) => void
): PolicyTableConfigItem[] => [
  {
    title: 'Contract',
    key: 'protocol',
    render: (_, record) => <TableLogoWrapper poolId={record.poolId} />,
  },
  {
    title: 'Amount Insured',
    key: 'amount',
    render: (_, record) => FormatTokenAmount(record.amountCovered, 'USDT'),
  },
  {
    title: 'Premium Spent',
    key: 'lpremium',
    render: (_, record) => FormatTokenAmount(record.premiumPaid, 'USD'),
  },
  {
    title: 'Daily Cost',
    key: 'premiumLeft',
    render: (_, record) => FormatTokenAmount(record.dailyCost, 'USD'),
  },
  {
    title: 'Duration',
    key: 'duration',
    cellContent: (record) =>
      `${Math.round(record.duration / (24 * 60 * 60 * 1000))} days`,
  },
  {
    title: '',
    key: 'action',
    width: 180,
    render: (_, record) => {
      return (
        <div className="flex justify-end">
          <Link className="mx-2" to={'/dashboard/cover/' + record.coverId}>
            <Button type="default">View Cover</Button>
          </Link>
        </div>
      );
    },
  },
];

export function DashboardCovers() {
  usePageTitle('My Policies');

  const { buyBasket, removeFromBuyBasket, updateBuyBasket } =
    useBasketContext();
  const { userPolicies, userExpiredPolicies, calcCoverDailyCost } =
    useAppDataContext();

  const handleBuyAgain = (selected: boolean, record: UserCover) => {
    selected
      ? removeFromBuyBasket(record.poolId)
      : updateBuyBasket({
          poolId: record.poolId,
          coverAmount: record.amountCovered.toString(),
          stakeAmount: '0',
          premiumAmount: record.premiumDeposit.toString(),
        });
  };

  const statLineData = [
    {
      title: 'Active Covers',
      content: userPolicies?.length || 0,
    },
    {
      title: 'Expired Covers',
      content: userExpiredPolicies?.length || 0,
    },
  ];

  const buyBasketItems = buyBasket.poolIds.map(
    (poolId, i) =>
      ({
        poolId,
        coverAmount: buyBasket.coverAmounts[i],
        stakeAmount: buyBasket.stakeAmounts[i],
        premiumAmount: buyBasket.premiumAmounts[i],
      } as BuyBasketItem)
  );

  const configOngoing = columnConfigOngoing(calcCoverDailyCost);
  const configExpired = columnConfigExpired(
    buyBasketItems,
    handleBuyAgain
  );
  const makeNavUrl = (record: UserCover) =>
    `/dashboard/cover/${record.coverId}`;

  return (
    <>
      <StatLine title="My Covers" data={statLineData} className="mb-6" />

      <BaseTable
        title="Ongoing Covers"
        rowKey="coverId"
        config={configOngoing}
        data={userPolicies}
        isLoading={false}
        makeNavUrl={makeNavUrl}
      />

      <BaseTable
        title="Expired Covers"
        rowKey="coverId"
        config={configExpired}
        data={userExpiredPolicies}
        isLoading={false}
        makeNavUrl={makeNavUrl}
      />
    </>
  );
}
