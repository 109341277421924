import { Col, Row } from 'antd';
import { useEffect, useRef, useState } from 'react';
// Hooks
import { useAppDataContext } from '../hooks/useAppDataProvider';
// Types
import { UserCover } from '../types';
// Components
import { ActionCard, ActionCardParams } from '../components/ActionCard';
import { ApyGraphContainer } from '../components/graphs/ApyGraphContainer';
import { FormatTokenAmount } from '../components/primitives/FormattedNumber';
import { TokenInput } from '../components/TokenInput';
// import {
//   PolicyIncreaseCoverButton,
//   PolicyDecreaseCoverButton,
//   PolicyAddPremiumsButton,
//   PolicyRemovePremiumsButton,
// } from './contracts';
import { BigNumber } from 'ethers';

export const ActionSectionCover = ({
  coverId,
  cover,
}: {
  coverId: string | undefined;
  cover: UserCover | undefined;
}) => {
  const { findPoolById } = useAppDataContext();

  const [coverAmount, setCoverAmount] = useState('0');
  const [premiumAmount, setPremiumAmount] = useState('0');

  const poolId: any = cover?.poolId ?? '';
  const pool = findPoolById(poolId);
  const amountCovered = cover?.amountCovered ?? BigNumber.from(0);
  // const availableCapacity = pool?.availableCapacity ?? BigNumber.from(0);
  const availableCapacity = BigNumber.from(0);
  const estimatedDaysLeft = cover?.estimatedDaysLeft ?? 0;
  const premiumLeft = cover?.premiumLeft ?? BigNumber.from(0);

  // Graph height
  const [height, setHeight] = useState(0);
  const elementRef = useRef(null);
  useEffect(() => {
    if (height === 0) setHeight((elementRef.current as any).clientHeight);
  }, []);

  const CoverCardsData: ActionCardParams[] = [
    {
      title: 'Modify Coverage',
      infoData: [
        {
          name: 'Current cover:',
          data: FormatTokenAmount(amountCovered, 'USDT'),
        },
        {
          name: 'Available capacity:',
          data: FormatTokenAmount(availableCapacity, 'USDT'),
        },
      ],
      bottomActions: (
        <>
          <TokenInput
            token="USDT"
            value={coverAmount}
            onChange={setCoverAmount}
          />
          <Row className="w-full">
            {/* <PolicyDecreaseCoverButton
              className="w-[50%] pr-1"
              params={{
                coverId: coverId || '',
                amount: coverAmount,
              }}
            /> */}
            <button>DECREASE COVER</button>
            {/* <PolicyIncreaseCoverButton
              className="w-[50%] pl-1"
              params={{
                coverId: coverId || '',
                amount: coverAmount,
              }}
              /> */}
            <button>INCREASE COVER</button>
          </Row>
        </>
      ),
    },
    {
      title: 'Modify Premiums',
      text: '',
      infoData: [
        {
          name: 'Premiums left:',
          data: FormatTokenAmount(premiumLeft, 'USDT'),
        },
        {
          name: 'Est. time left:',
          data: `${Math.round(estimatedDaysLeft)} days`,
        },
      ],
      bottomActions: (
        <>
          <TokenInput
            token="USDT"
            value={premiumAmount}
            onChange={setPremiumAmount}
          />
          <Row className="w-full">
            {/* <PolicyRemovePremiumsButton
              className="w-[50%] pr-1"
              params={{
                coverId: coverId || '',
                amount: premiumAmount,
              }}
            /> */}
            <button>REMOVE PREMIUMS</button>
            {/* <PolicyAddPremiumsButton
              className="w-[50%] pl-1"
              params={{
                coverId: coverId || '',
                amount: premiumAmount,
              }}
            /> */}
            <button>ADD PREMIUMS</button>
          </Row>
        </>
      ),
    },
  ];

  return (
    <Row ref={elementRef} className="mt-6">
      <Col span={8} offset={1} className="">
        {CoverCardsData.map((cardData, i) => {
          return <ActionCard key={i} cardData={cardData} index={i} />;
        })}
      </Col>

      <Col offset={1} span={13}>
        <ApyGraphContainer
          queryType="composed"
          populate={{
            premiumRate: true,
          }}
          poolIds={[poolId]}
          height={height}
        />
      </Col>
    </Row>
  );
};
