import { BigNumber } from 'ethers';
import { useCall } from '@usedapp/core';
import { useWeb3Context } from '../../../useWeb3ContextProvider';
import { useProtocolDataContext } from '../../../useProtocolDataProvider';

// Value that is returned if the hook encounters an error
const defaultValue = BigNumber.from(0);

export const useBalance = (
  tokenName: 'ATEN' | 'USDT',
  ownerAddress?: string
) => {
  const { chainContracts } = useProtocolDataContext();
  const { currentNetworkConfig } = useWeb3Context();
  const blocksRefresh = currentNetworkConfig.secondsToBlocks(20);

  const contract = chainContracts?.[tokenName];

  const conditionsOk = !!ownerAddress && !!blocksRefresh;

  const { value, error } =
    useCall(
      conditionsOk &&
        contract && {
          contract: contract,
          method: 'balanceOf', // Method to be called
          args: [ownerAddress], // Method arguments
        },
      { refresh: blocksRefresh }
    ) ?? {};
  if (error) {
    console.error(error.message);
    return defaultValue;
  }
  if (value?.[0]) {
    return value?.[0];
  }
  return defaultValue;
};
