import { Col, Button } from 'antd';
import { BigNumber } from 'ethers';
import { useParams } from 'react-router-dom';
// Hooks
import { usePageTitle } from '../hooks/usePageTitle';
// Components
import { SectionCreateClaim } from '../components/claims/SectionCreateClaim';
import { HeaderPool } from '../components/layout/HeaderPool';
import { FormatTokenAmount } from '../components/primitives/FormattedNumber';
import { StatLine } from '../components/StatLine';
// Type
import { ClaimData, UserCover } from '../types';
// Functions
import {
  makeIpfsUrl,
  formatTimeToText,
} from '../functions/utils/formatting';
// Context
import { useAppDataContext } from '../hooks/useAppDataProvider';
import { useWeb3Context } from '../hooks/useWeb3ContextProvider';

const DAY_IN_MS = 24 * 60 * 60 * 1000;

const statusContent = (isExpired: boolean) =>
  isExpired ? (
    <p className="text-orange-500">Expired</p>
  ) : (
    <p className="text-lime-500">Active</p>
  );

const periodContent = (
  userPolicy: UserCover | undefined,
  isExpired: boolean
) => {
  const { startDate, duration } = userPolicy || {
    startDate: Date.now(),
    duration: 0,
  };
  return `${new Date(startDate).toLocaleDateString()} - ${
    isExpired
      ? new Date(startDate + duration * DAY_IN_MS).toLocaleDateString()
      : 'now'
  }`;
};

export function ClaimCreate() {
  usePageTitle(`Create claim`);
  const { coverId } = useParams();
  const { currentAccount } = useWeb3Context();
  const {
    getPoolNameById,
    findCoverById,
    findClaimsByCoverId,
    challengeDelay,
    arbitrationCost,
    collateralAmount,
    usePoolAgreement,
  } = useAppDataContext();

  const policy = findCoverById(coverId);
  const coverClaims = findClaimsByCoverId(coverId);

  const poolAgreement = usePoolAgreement(policy?.poolId);
  const poolAgreementUrl = poolAgreement ? makeIpfsUrl(poolAgreement) : '';

  const isExpired = policy?.endDate !== 0;

  const latestCoverClaim = coverClaims.reduce(
    (acc: ClaimData | undefined, claim) =>
      !acc || acc.creationDate < claim.creationDate ? claim : acc,
    undefined
  );

  const statLineData = [
    {
      title: 'Policy Status',
      content: statusContent(isExpired),
    },
    {
      title: 'Amount Covered',
      content: FormatTokenAmount(
        policy?.amountCovered || BigNumber.from(0),
        'USD'
      ),
    },
    {
      title: 'Cover Period',
      content: periodContent(policy, isExpired),
    },
    {
      title: 'Challenge Period',
      content: `${challengeDelay / (24 * 60 * 60 * 1000)} days`,
    },
    {
      title: 'Arbitration Cost',
      content: FormatTokenAmount(arbitrationCost, 'ETH'),
    },
    {
      title: 'Dispute Collateral',
      content: FormatTokenAmount(collateralAmount, 'ETH'),
    },
  ];

  return (
    <>
      <HeaderPool poolId={policy?.poolId} />

      <StatLine title="Create Claim" data={statLineData} className="mb-6">
        <a href={poolAgreementUrl} target="_blank" rel="noreferrer">
          <Button
            type="default"
            disabled={!poolAgreementUrl}
            className="w-[220px]"
          >
            View Pool Agreement
          </Button>
        </a>
      </StatLine>

      <Col span={24} className="content-card p-4">
        <div className="flex">
          <span className="font-bold text-lg theme-secondary">
            Protocol :
          </span>
          <span className="ml-2 text-lg">
            {getPoolNameById(policy?.poolId)}
          </span>
        </div>
        <div className="flex">
          <span className="font-bold text-lg theme-secondary">
            Claimant :
          </span>
          <span className="ml-2 text-lg">{currentAccount}</span>
        </div>
        <div className="flex">
          <span className="font-bold text-lg theme-secondary">
            Creation Date:
          </span>
          <span className="ml-2 text-lg">
            {formatTimeToText(Date.now())}
          </span>
        </div>

        <hr
          style={{
            marginLeft: -16,
            width: 'calc(100% + 32px)',
            marginTop: 16,
          }}
        />

        <SectionCreateClaim coverId={coverId} />
      </Col>
    </>
  );
}
