import makeBlockie from 'ethereum-blockies-base64';

export function Blockie({
  account,
  size = 28,
}: {
  account: string;
  size?: number;
}) {
  return (
    <span
      className="flex-none flex items-center"
      style={{
        width: size,
        height: size,
        borderRadius: '50%',
        overflow: 'hidden',
      }}
    >
      <img
        src={makeBlockie(!account ? 'default' : account)}
        alt="account-blockie"
      />
    </span>
  );
}
