// Components
import { Transition } from '@headlessui/react';

export function AnimateFadeInOut({
  children,
  isOpen,
  ...props
}: {
  children: React.ReactNode | string;
  isOpen: boolean;
  [key: string]: any;
}) {
  return (
    <Transition
      show={isOpen}
      enter="transition ease-out duration-150"
      enterFrom="opacity-0 translate-y-1"
      enterTo="opacity-100 translate-y-0"
      leave="transition ease-in duration-150"
      leaveFrom="opacity-100 translate-y-0"
      leaveTo="opacity-0 translate-y-1"
      //
      {...props}
    >
      {children}
    </Transition>
  );
}
