import { Button } from 'antd';
import { useState } from 'react';
import { Link } from 'react-router-dom';
// Hooks
import { useAppDataContext } from '../../hooks/useAppDataProvider';
import { usePageTitle } from '../../hooks/usePageTitle';
// Components
import { LevelTag } from '../../components/LevelTag';
import { StatLine, StatLineData } from '../../components/StatLine';
import { TokenInput } from '../../components/TokenInput';
// import {
//   ApproveAtenButton,
//   StakingDepositButton,
//   StakingWithdrawButton,
//   StakingWithdrawRewardsButton,
// } from '../../components/contracts';
import { TableLogoWrapper } from '../../components/icons/TableLogoWrapper';
import {
  FormatPercent,
  FormatTokenAmount,
} from '../../components/primitives/FormattedNumber';
import { BaseTable } from '../../components/tables/BaseTable';
// Type
import { PolicyRefundConfigItem, UserCover } from '../../types';
// Functions
import { formatTimeToText } from '../../functions/utils/formatting';

const ONE_YEAR_IN_MS = 365 * 24 * 60 * 60 * 1000;

const statusContent = (isExpired: boolean) =>
  isExpired ? (
    <p className="text-orange-500">Expired</p>
  ) : (
    <p className="text-lime-500">Active</p>
  );

const columnConfigStaking = (
  refundShortCoverDuration: number,
  findCoverById: (coverId?: string | undefined) => UserCover | undefined
): PolicyRefundConfigItem[] => [
  {
    title: 'Contract',
    key: 'protocol',
    render: (_, record) => {
      const poolId = findCoverById(record.coverId)?.poolId;
      return <TableLogoWrapper poolId={poolId} />;
    },
  },
  {
    title: 'Status',
    key: 'amount',
    render: (_, record) => statusContent(record.endTimestamp !== 0),
  },
  {
    title: 'Amount Staked',
    key: 'amount',
    render: (_, record) => FormatTokenAmount(record.stakedAmount, 'ATEN'),
  },
  {
    title: 'Refund Rate',
    key: 'amount',
    render: (_, record) => FormatPercent(record.rate),
  },
  {
    title: 'Rewards',
    key: 'amount',
    render: (_, record) => FormatTokenAmount(record.earnedRewards, 'ATEN'),
  },
  {
    title: 'Penalty End',
    key: 'amount',
    cellContent: (record) =>
      `${formatTimeToText(
        record.initTimestamp + refundShortCoverDuration
      )}`,
  },
  {
    title: '',
    key: 'action',
    render: (_, record) => (
      <Link
        className="mx-2"
        to={`/dashboard/cover/${record.coverId}/#cover-refund`}
      >
        <Button type="default">View Cover Refund</Button>
      </Link>
    ),
  },
];

export function DashboardStaking() {
  usePageTitle('My Staking');

  const [stakeAmount, setStakeAmount] = useState('0');
  const [unstakeAmount, setUnstakeAmount] = useState('0');

  const {
    userGeneralStaking,
    userSuppliedCapital,
    userAtenBalance,
    userAthenaAtenAllowance,
    userCoverRefunds,
    findCoverById,
    refundShortCoverDuration,
    getAtenStakingApr,
    getAtenStakingLevel,
  } = useAppDataContext();

  const coverRefundWithdrawableIds = userCoverRefunds
    .filter(
      (policy) =>
        policy.endTimestamp != 0 &&
        (policy.stakedAmount.gt(0) || policy.earnedRewards.gt(0))
    )
    .map((policy) => policy.coverId);

  const userStakingAprLevel = getAtenStakingLevel(userSuppliedCapital);

  const statLineData: StatLineData[] = [
    {
      title: 'Staking APR Level',
      content: <LevelTag level={userStakingAprLevel} />,
    },
    {
      title: 'User Staking APR',
      content: FormatPercent(getAtenStakingApr(userSuppliedCapital)),
    },
    {
      title: 'Amount Staked',
      content: FormatTokenAmount(userGeneralStaking.amount, 'ATEN'),
      tooltipLabel: 'test',
    },
    {
      title: 'Current Rewards',
      content: FormatTokenAmount(
        userGeneralStaking.accruedRewards,
        'ATEN',
        4
      ),
    },
  ];

  const configStaking = columnConfigStaking(
    refundShortCoverDuration,
    findCoverById
  );

  const makeNavUrl = (record: UserCover) =>
    `/dashboard/cover/${record.coverId}/#cover-refund`;

  return (
    <>
      <StatLine
        title="My General Staking"
        data={statLineData}
        className="mb-6"
      >
        {userAthenaAtenAllowance.gt(0) ? (
          <div className="flex flex-col w-[220px]">
            <TokenInput
              token="ATEN"
              value={stakeAmount}
              maxValue={userAtenBalance}
              onChange={setStakeAmount}
            />
            {/* <StakingDepositButton
              params={{ amount: stakeAmount }}
              className="min-w-[180px] mt-2"
            /> */}
            <Button>STAKE</Button>
          </div>
        ) : (
          // <ApproveAtenButton className="min-w-[220px] mt-auto" />
          <Button>APPROVE</Button>
        )}

        {/* <StakingWithdrawRewardsButton
          params={{}}
          className="min-w-[220px] mt-auto mx-4"
        /> */}
        <Button>TAKE INTERESTS</Button>

        <div className="flex flex-col w-[220px]">
          <TokenInput
            token="ATEN"
            value={unstakeAmount}
            maxValue={userGeneralStaking.amount}
            onChange={setUnstakeAmount}
          />
          {/* <StakingWithdrawButton
            params={{ amount: unstakeAmount }}
            className="min-w-[180px] mt-2"
          /> */}
          <Button>UNSTAKE</Button>
        </div>
      </StatLine>

      <BaseTable
        id="cover-refund"
        title="My Cover Refunds"
        rowKey="coverId"
        config={configStaking}
        data={userCoverRefunds}
        isLoading={false}
        makeNavUrl={makeNavUrl}
        // @bw disabled for now untill better UI placement is found
        // tableButton={
        //   !!coverRefundWithdrawableIds.length && (
        //     <RefundCloseMultiPositionsButton
        //       params={{ policyIds: coverRefundWithdrawableIds }}
        //       disabled={coverRefundWithdrawableIds.length === 0}
        //       className="absolute right-4 top-4 z-10"
        //     />
        //   )
        // }
      />
    </>
  );
}
