// Hooks
import { useState } from 'react';
import { useClickAway } from '../../hooks/utils/useClickAway';
// Context
import { useWeb3Context } from '../../hooks/useWeb3ContextProvider';
// Components
import { Blockie } from './Blockie';
import { AccountInfo } from './AccountInfo';
import { AnimateFadeInOut } from '../animation/AnimateFadeInOut';
// Icon
import { ChevronDownIcon } from '../icons/ChevronDownIcon';
import { WalletIcon } from '../icons/WalletIcon';
// Functions
import { textCenterEllipsis } from '../../functions/utils/formatting';

export function MenuWallet() {
  const { currentAccount, connectWallet, isConnected } = useWeb3Context();

  const [isOpen, setIsOpen] = useState(false);
  const ref = useClickAway(() => setIsOpen(false));
  const [viewHistory, setViewHistory] = useState(false);

  const ellipsisAddress = textCenterEllipsis(currentAccount, 4, 4);

  return !isConnected ? (
    <button
      onClick={connectWallet}
      className="theme-primary rounded-sm text-sm theme-highlight-border flex items-center space-x-2 p-3"
    >
      <WalletIcon />
      <span className="hidden md:inline">Connect Wallet</span>
    </button>
  ) : (
    <div ref={ref} className="theme-primary">
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="flex-1 text-sm flex items-center space-x-2 rounded-sm theme-highlight-border p-3"
      >
        <Blockie account={currentAccount} size={16} />
        <div className="hidden grow md:block">{ellipsisAddress}</div>
        <ChevronDownIcon
          className={`fa-fw shrink transition-transform ${
            isOpen ? 'rotate-180' : ''
          }`}
        />
      </button>

      <AnimateFadeInOut
        isOpen={isOpen}
        className="right-0 top-16 lg:top-[4.63rem] absolute overflow-hidden rounded-sm border-white/[.3] theme-secondary-bg p-4 text-left text-sm drop-shadow-2xl"
      >
        <AccountInfo
          blockieAddress={currentAccount}
          ellipsisAddress={ellipsisAddress}
          AccountIcon={Blockie}
          setViewHistory={setViewHistory}
        />
      </AnimateFadeInOut>
    </div>
  );
}
