import { PoolLogo } from './PoolLogo';
import { useAppDataContext } from '../../hooks/useAppDataProvider';
import { useScreenSize } from '../../hooks/utils/useScreenSize';
import { EllipsisIcon } from '../icons/EllipsisIcon';

export const TableMiniLogo = ({
  poolIds,
  className,
  logoClassName,
  maxLogosOverride,
}: {
  poolIds: (string | number)[];
  className?: string;
  logoClassName?: string;
  maxLogosOverride?: number;
}) => {
  const { getPoolNameById } = useAppDataContext();
  const screenSize = useScreenSize();

  let maxLogos = 10;
  if (!maxLogosOverride && screenSize === 'xs') maxLogos = 2;
  else if (!maxLogosOverride && screenSize === 'sm') maxLogos = 6;
  else if (!maxLogosOverride && screenSize === 'md') maxLogos = 6;
  else if (!maxLogosOverride && screenSize === 'lg') maxLogos = 7;
  else if (!maxLogosOverride && screenSize === 'xl') maxLogos = 9;
  else if (maxLogosOverride) maxLogos = maxLogosOverride;

  return (
    <div className={`flex items-center ml-3 ${className || ''}`}>
      {1 === poolIds.length && (
        <div className="relative group">
          <div className="text-xs flex items-center">
            <PoolLogo
              poolId={poolIds[0].toString()}
              className={`-ml-2 mr-2 ${logoClassName}`}
              size={30}
            />
            <span className="max-w-[100px]">
              {getPoolNameById(poolIds[0].toString()) || 'Unknown'}
            </span>
          </div>
        </div>
      )}
      {1 < poolIds.length &&
        poolIds.slice(0, maxLogos).map((el, i) => {
          const protocolName = getPoolNameById(el.toString());
          return (
            <div key={i} className="relative group">
              <div className="absolute -left-2 bottom-full mb-3.5 hidden theme-disabled-bg-dark theme-dark theme-disabled-border-light text-nowrap group-hover:block text-xs text-center p-2 rounded-sm">
                <div className="absolute left-2 top-full w-2 h-2 theme-disabled-bg-dark theme-disabled-border-light !border-l-0 !border-t-0 transform rotate-45 -translate-y-1" />
                {protocolName}
              </div>
              <div className="cursor-pointer">
                <PoolLogo
                  poolId={el.toString()}
                  className={`-ml-2 ${logoClassName} transition duration-300 ease-in-out transform hover:-translate-y-1.5 hover:scale-110 hover:brightness-110 hover:rotate-12 hover:shadow-sm`}
                  size={25}
                />
              </div>
            </div>
          );
        })}
      {poolIds.length > maxLogos && (
        <div className="relative group flex items-center text-xs">
          <div className="z-50 absolute bottom-full hidden theme-disabled-bg theme-dark theme-disabled-border-light group-hover:block text-center p-2 rounded-sm ">
            <div className="absolute top-full w-2 h-2 theme-disabled-bg theme-disabled-border-light !border-l-0 !border-t-0 transform rotate-45 -translate-y-1"></div>
            {poolIds.slice(maxLogos).map((poolId, i) => (
              <div key={i} className="text-nowrap text-left flex p-0.5">
                <PoolLogo
                  poolId={poolId.toString()}
                  size={15}
                  className="mr-1"
                />
                {getPoolNameById(poolId.toString())}
                <br />
              </div>
            ))}
          </div>

          <div
            className={`cursor-pointer h-[30px] w-[30px] flex flex-col rounded-full theme-highlight items-center text-xs justify-center ${logoClassName}`}
          >
            <EllipsisIcon className="!h-5 !w-5" />
            <span className="absolute -bottom-1">
              ({poolIds.length - maxLogos})
            </span>
          </div>
        </div>
      )}
    </div>
  );
};
