import { useState } from 'react';
import { CloseOutlined } from '@ant-design/icons';

import { Button, Col, Row, Select, Space, Typography } from 'antd';
// Hooks
import { usePageTitle } from '../hooks/usePageTitle';
// Context
import { useAppDataContext } from '../hooks/useAppDataProvider';
import { useBasketContext } from '../hooks/useBasketProvider';
// Components
import { BackButton } from '../components/BackButton';
import { BasketCardSupply } from '../components/BasketCardSupply';
import { BasketTable } from '../components/tables/BasketTable';
import { BasketSupplyData } from '../components/data/BasketSupplyData';
import { TableLogoWrapper } from '../components/icons/TableLogoWrapper';
import { TokenInput } from '../components/TokenInput';
// Types
import {
  UserSupplyBasket,
  SupplyBasketItem,
  SupplyBasketTableConfigItem,
} from '../types';

const columnConfig = (
  supplyBasket: UserSupplyBasket,
  removeFromSupplyBasket: (poolId: string) => void
): SupplyBasketTableConfigItem[] => [
  {
    title: 'Protocol',
    key: 'protocol',
    render: (_, record) => <TableLogoWrapper poolId={record.poolId} />,
  },
  {
    title: 'Cover Amount',
    key: 'amount',
    render: (_, record) => <BasketSupplyData poolId={record.poolId} />,
  },
  {
    title: '',
    key: 'remove',
    render: (_, record) => (
      <Space size="middle">
        <Button
          type="text"
          className="close-button"
          onClick={() => removeFromSupplyBasket(record.poolId)}
          icon={<CloseOutlined />}
        ></Button>
      </Space>
    ),
  },
];

export function SupplyBasket() {
  usePageTitle('Supply Basket');
  const { supplyBasket, removeFromSupplyBasket, emptySupplyBasket } =
    useBasketContext();
  const { userUsdtBalance } = useAppDataContext();

  const [depositAmount, setDepositAmount] = useState<string>('0');

  const supplyBasketItems = supplyBasket.poolIds.map(
    (poolId) => ({ poolId } as SupplyBasketItem)
  );
  const hasItems = !!supplyBasketItems.length;

  const config = columnConfig(supplyBasket, removeFromSupplyBasket);

  return (
    <Col span={24} className="content-card p-4">
      <Typography.Title
        level={3}
        className="mt-2 flex justify-start items-center"
      >
        <BackButton />
        Supply Cover
      </Typography.Title>
      <Row>
        <Col span={17} className="p-4 pr-6">
          <div className="mb-4">
            <div className="flex">
              <div className="font-bold min-w-[150px]">Reminder: </div>
              Select multiple insurance policies to cover multiple
              protocols in one transaction and save on gas fees.
            </div>
            <div className="flex items-center mt-4">
              <div className="font-bold min-w-[150px]">
                Cover Currency:{' '}
              </div>
              <Select defaultValue="usdt" onChange={() => ''}>
                <Select.Option value="usdt">USDT</Select.Option>
              </Select>
            </div>
            <div className="flex items-center mt-8">
              <div className="font-bold min-w-[150px]">Cover Amount: </div>
              <TokenInput
                token="USDT"
                value={depositAmount}
                maxValue={userUsdtBalance}
                onChange={setDepositAmount}
                className="max-w-[250px]"
              />
            </div>
          </div>
          <BasketTable
            config={config}
            data={supplyBasketItems}
            emptyBasket={emptySupplyBasket}
            itemsInBasket={supplyBasketItems.length}
          />
        </Col>
        <Col span={7}>
          <BasketCardSupply
            amountSupplied={depositAmount}
            setDepositAmount={setDepositAmount}
            hasItems={hasItems}
          />
        </Col>
      </Row>
    </Col>
  );
}
