import { useCallback, useEffect, useState } from 'react';
import { CONFIG } from '../../appConfig';
import { useWeb3Context } from '../useWeb3ContextProvider';
import {
  DatabaseMetaEvidenceEntry,
  MetaEvidenceBaseParams,
} from '../../types';

export function useMetaEvidence(coverId: string | undefined) {
  const { chainId } = useWeb3Context();

  const [metaEvidenceData, setMetaEvidenceData] =
    useState<DatabaseMetaEvidenceEntry>();

  const fetchData = useCallback<() => () => void>(() => {
    if (!coverId || !chainId) {
      setMetaEvidenceData(undefined);
      return () => null;
    }
    // reset
    setMetaEvidenceData(undefined);

    const data: MetaEvidenceBaseParams = {
      chainId: chainId.toString(),
      coverId,
    };

    let fetchPromise: Promise<DatabaseMetaEvidenceEntry | {}> = fetch(
      `${CONFIG.API_ENDPOINT}/meta-evidence/`,
      {
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'POST',
        body: JSON.stringify(data),
      }
    ).then((res) => res.json());

    fetchPromise
      .then((data) => {
        const anyData = data as any;

        if (!anyData.fileURI || !anyData.ipfsUriHashSignature) {
          throw Error('useMetaEvidence(): Invalid data');
        } else {
          setMetaEvidenceData(data as DatabaseMetaEvidenceEntry);
        }
      })
      .catch((e) => {
        console.error('useMetaEvidence(): Failed to fetch', e);
      });

    return () => null;
  }, [coverId, chainId]);

  useEffect(() => {
    fetchData();
  }, [coverId, chainId]);

  return metaEvidenceData;
}
