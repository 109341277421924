const link = (text: string, url: string) => (
  <a
    className="theme-secondary"
    href={url}
    target="_blank"
    rel="noreferrer"
  >
    {text}
  </a>
);

const bold = (text: string) => <strong>{text}</strong>;

const tooltipData: { [key: string]: string | JSX.Element } = {
  test: (
    <>
      Hello {bold('bla bla')} is very nice and should be used in all
      projects. Check it out here : <br />
      {link('nicee', 'http://example.com')}
    </>
  ),
};

export const getTooltip = (label: string) => tooltipData[label];
