import { Tag } from 'antd';
import {
  ReserveRateTimeRange,
  reserveRateTimeRangeOptions,
} from '../../types';

export type GraphTimeRangeSelectorProps = {
  disabled?: boolean;
  timeRange: ReserveRateTimeRange;
  handleTimeRangeChanged: (value: ReserveRateTimeRange) => void;
};

export const GraphTimeRangeSelector = ({
  timeRange,
  handleTimeRangeChanged,
}: GraphTimeRangeSelectorProps) => {
  const handleChange = (newInterval: ReserveRateTimeRange) => {
    if (newInterval !== null) {
      handleTimeRangeChanged(newInterval);
    }
  };

  return (
    <div>
      {reserveRateTimeRangeOptions.map((interval, i) => {
        return (
          <Tag
            key={i}
            color={
              interval === timeRange
                ? 'theme-secondary-bg'
                : 'theme-disabled-bg'
            }
            className={`cursor-pointer ${
              interval === timeRange ? 'theme-secondary-bg' : ''
            }`}
            onClick={(e) => {
              e.preventDefault();
              handleChange(interval);
            }}
          >
            {interval}
          </Tag>
        );
      })}
    </div>
  );
};
