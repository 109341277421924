import { utils } from 'ethers';
import { TransactionStatus, useContractFunction } from '@usedapp/core';
import { BigNumber, ethers } from 'ethers';
import { ExecuteReturn } from '../../../../types';
import { useProtocolDataContext } from '../../../useProtocolDataProvider';
import { LiquidityManager } from '../../../../types/typechain';

const MAX_UINT256 = BigNumber.from(2).pow(256).sub(1);

const { parseUnits } = utils;

export type ParamsUpdateCoverClose = {
  coverId: number;
  amountTokenDecimals: number;
  coverToAdd: string;
  coverToRemove: string;
  premiumsTokenDecimals: number;
  premiumsToAdd: string;
  premiumsToRemove: string;
};

type FormattedParams = {
  coverId: number;
  coverToAdd: BigNumber;
  coverToRemove: BigNumber;
  premiumsToAdd: BigNumber;
  premiumsToRemove: BigNumber;
};

function formatParams(params: ParamsUpdateCoverClose): FormattedParams {
  return {
    coverId: params.coverId,
    coverToAdd: parseUnits(params.coverToAdd, params.amountTokenDecimals),
    coverToRemove: parseUnits(
      params.coverToRemove,
      params.amountTokenDecimals
    ),
    premiumsToAdd: parseUnits(
      params.premiumsToAdd,
      params.premiumsTokenDecimals
    ),
    premiumsToRemove: BigNumber.from(params.premiumsToRemove), // don't parse max uint
  };
}

type Instance = {
  send: (
    ...args: Parameters<LiquidityManager['updateCover']>
  ) => Promise<ethers.providers.TransactionReceipt | undefined>;
  state: TransactionStatus;
};

function makeInstance(): Instance {
  const { chainContracts } = useProtocolDataContext();
  const contract = chainContracts?.LiquidityManager;

  const { send, state } = useContractFunction(contract, 'updateCover', {
    transactionName: 'updateCover',
    gasLimitBufferPercentage: 10,
  });

  return { send, state };
}

function checkParams(params: ParamsUpdateCoverClose) {
  // do checks relative to this fn
  const { coverToAdd, coverToRemove, premiumsToAdd, premiumsToRemove } =
    formatParams(params);

  if (!premiumsToRemove.eq(MAX_UINT256)) {
    return 'Bad premiumsToRemove input';
  }
  if (coverToAdd.gt(0) || coverToRemove.gt(0) || premiumsToAdd.gt(0)) {
    return 'Should not have other inputs';
  }
}

function execute(
  instance: Instance,
  params: ParamsUpdateCoverClose
): ExecuteReturn {
  const {
    coverId,
    coverToAdd,
    coverToRemove,
    premiumsToAdd,
    premiumsToRemove,
  } = formatParams(params);

  const txReceiptPromise = instance.send(
    coverId,
    coverToAdd,
    coverToRemove,
    premiumsToAdd,
    premiumsToRemove
  );

  return {
    txReceipt: txReceiptPromise,
    txStatus: instance.state,
  };
}

export const configUpdateCoverClose = {
  makeInstance: makeInstance,
  checkParams: checkParams,
  execute: execute,
};
