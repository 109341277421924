import { BigNumber } from 'ethers';
import React, {
  PropsWithChildren,
  useContext,
  useEffect,
  useState,
} from 'react';
import {
  availableMarkets,
  CustomMarket,
  MarketDataType,
  marketsData,
  chainIdToMarket,
} from '../functions/utils/marketsAndNetworksConfig';
import {
  genContracts,
  ContractsInterface,
} from '../functions/utils/addressAndContracts';
import { CONFIG } from '../appConfig';
import { useWeb3Context } from './useWeb3ContextProvider';

const LS_KEY = 'selectedMarket';

export type ProtocolContextData = {
  chainContracts: ContractsInterface | undefined;
  currentMarket: CustomMarket | undefined;
  currentMarketData: MarketDataType | undefined;
  // currently selected one
  currentChainId: number | undefined;
};

const ProtocolDataContext = React.createContext({} as ProtocolContextData);

function returnValidMarket({
  marketName,
  chainId,
}: {
  marketName?: string | null;
  chainId?: number;
}): CustomMarket | undefined {
  if (chainId) {
    const typeMarketName = Object.entries(marketsData).find(
      ([, market]) => market.chainId === chainId
    );
    if (typeMarketName) return typeMarketName[0] as CustomMarket;
  }

  if (
    marketName &&
    availableMarkets.includes(marketName as CustomMarket)
  ) {
    return marketName as CustomMarket;
  }
}

export function ProtocolDataProvider({ children }: PropsWithChildren<{}>) {
  const { chainId } = useWeb3Context();

  const [currentMarket, setCurrentMarket] = useState<CustomMarket>();
  const [currentMarketData, setCurrentMarketData] =
    useState<MarketDataType>();

  const [contracts, setContracts] = useState<ContractsInterface>();

  const handleSetMarketAndContracts = (newMarket: CustomMarket) => {
    setCurrentMarket(newMarket);
    const newMarketData = marketsData[newMarket];
    setCurrentMarketData(newMarketData);

    const currentChainId = newMarketData?.chainId;

    if (currentChainId) {
      const newContracts = genContracts(currentChainId);
      setContracts(newContracts);
    }
  };

  function initMarketFromChainId(marketChainId: number | undefined): void {
    if (!marketChainId) return;

    const market = chainIdToMarket(marketChainId);

    if (!market) return;

    if (market !== currentMarket) {
      localStorage.setItem('chainId', marketChainId.toString());
    }

    handleSetMarketAndContracts(market);
  }

  // If chain ID is modified then update the contracts
  useEffect(() => {
    initMarketFromChainId(chainId);
  }, [chainId]);

  // set the last selected market onload
  useEffect(() => {
    // @bw competing with web3 provider doing the same thing
    const cachedMarket = localStorage.getItem('chainId');

    const marketName =
      cachedMarket &&
      returnValidMarket({
        chainId: Number(cachedMarket),
      });

    if (marketName) {
      handleSetMarketAndContracts(marketName);
    } else {
      const defaultChainId = CONFIG.DEFAULT_CHAIN;
      initMarketFromChainId(defaultChainId);
    }
  }, []);

  return (
    <ProtocolDataContext.Provider
      value={{
        // Typed contract instances
        chainContracts: contracts,
        // Market config
        currentMarket,
        currentChainId: chainId,
        currentMarketData: currentMarketData,
      }}
    >
      {children}
    </ProtocolDataContext.Provider>
  );
}

export const useProtocolDataContext = () =>
  useContext(ProtocolDataContext);
