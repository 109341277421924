export function EllipsisIcon({
  className,
  size,
}: {
  className?: string;
  size?: number;
}) {
  return (
    <svg
      className={`w-4 h-4 ${className}`}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 448 512"
      style={{
        height: `${(size || 4) / 4}em`,
        width: `${(size || 4) / 4}em`,
      }}
    >
      <path
        fill="currentColor"
        d="M8 256a56 56 0 1 1 112 0A56 56 0 1 1 8 256zm160 0a56 56 0 1 1 112 0 56 56 0 1 1 -112 0zm216-56a56 56 0 1 1 0 112 56 56 0 1 1 0-112z"
      ></path>
    </svg>
  );
}
