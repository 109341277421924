export function AthenaLogo({
  className,
  color,
}: {
  className?: string;
  color?: string;
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      viewBox="0 0 464.11 107.09"
    >
      <defs>
        <style>{`.cls-1,.cls-2{fill:${
          color ? `var(--color-${color})` : 'var(--color-primary)'
        };}.cls-2{font-size:15px;font-family:Ubuntu-Bold, Ubuntu;font-weight:700;letter-spacing:0.14em;}.cls-3{letter-spacing:0.12em;}.cls-4{letter-spacing:0.12em;}.cls-5{letter-spacing:0.14em;}.cls-6{letter-spacing:0.16em;}.cls-7{letter-spacing:0.11em;}.cls-8{letter-spacing:0.14em;}.cls-9{letter-spacing:0.12em;}.cls-10{letter-spacing:0.17em;}`}</style>
      </defs>
      <g id="Layer_2" data-name="Layer 2">
        <g id="Layer_1-2" data-name="Layer 1-2">
          <path
            className="cls-1"
            d="m34.9,81.9c-4.5-2.5-8,.5-12.4,4.3,6.3,8,12.3,8.3,17.2,8.8.4-8.4-1.7-11.5-4.8-13.1Z"
          />
          <path
            className="cls-1"
            d="m56.1,64.9l-3.6-6.1,12.9,2c1-4.8,1.8-8.8,2.3-11.6l-3.6-11.1c-5.7,1.9-9.4,5.5-10.8,11.3-.7,2.7-.7,5.6-1.4,8.4-1.6,5.9-2.4,11.7.4,17.6.1.2-3,2.6-3.8,1-1.9-3.6-2.6-7.8-2-11.8s1.3-7.9,2-11.8c.5-3-.3-5.2-4.1-6.8l-4.8,4.8c2.7,2.1,3.4,3.5,5.9,5.5-3.9,7.9-6.1,13.6-9.7,20.1,8.9,3.7,17.5,7.2,26.1,10.7.7.3,4.6,1.5,5.3,1.6-7-6.2-12.3-14.5-11.1-23.8Z"
          />
          <path
            className="cls-1"
            d="m82.4,87l-14.8-5c7.1,7.4,11,8.9,14.8,5Z"
          />
          <path
            className="cls-1"
            d="m48.1,42.8l2.4,1.5c4.2-7.2,10.9-10.2,17.9-12.5.2-1.1.2-2.1.3-3.2,0-.4-.5-.8-.7-1.1-8.5,2.2-20.6,11.3-19.9,15.3Z"
          />
          <path
            className="cls-1"
            d="m59.6,25.8l-5.5-2.1h0c-10-3-20.9,3.4-22.7,13.6-.8,4.5-1.3,9.1-1.9,13.8h6.8l-.6-1.1c6.5-8.8,13.2-17.6,23.9-24.2Z"
          />
          <path
            className="cls-1"
            d="m53.5,0C24,0,0,24,0,53.5s24,53.5,53.5,53.5,53.5-24,53.5-53.5h0C107.1,24,83.1,0,53.5,0Zm.5,100.1c4.9-1.1,11.4-3.6,16.8-5.2l-27.3-10.9c.5,2,1,3.9,1.3,5.9.5,3.2,1.3,6.3,2.2,9.4,0,.1.1.2.1.3-11.4-1.6-21.8-7.3-29.2-16.1,4.5-4.6,9.8-9.2,13.8-11.1,0,0,5.1-15.9,4.8-16.2-2.5,4-11.5,16.6-19.1,26.8-1.7-2.1-3.2-4.2-4.5-6.6,7.6-9.5,16.2-21.2,16.2-21.2,0,0-9.2,6.8-19.7,13.2-.8-2.4-1.4-4.9-1.8-7.5,8.9-4.8,16.6-8.9,16.9-9.1.7-.5.1-2.4.1-2.4,0,0-8.3,1-17.6,2.1.1-2.2.4-4.5.8-6.7,8.8-.3,16.6-.5,18.7-.5v-5.3c-1.7-.4-8.2-1.7-16-3.2.7-1.7,1.5-3.4,2.5-5.1,6.9,2,12.9,3.7,14.4,4.1.8-1.9,1.4-3.1,2.1-4.6-1.9-1-6.4-3.8-11.4-6.8,1.9-2.2,3.9-4.2,6.2-6.1,3.4,4.5,6.3,8.3,7.3,9.1l5-3.8c-.7-1.2-3-5.2-5.7-9.8,1.8-1,3.6-1.8,5.5-2.6,1.8,5,3.2,9,3.7,9.9l5.9-1.5c-.2-1.1-.9-5.4-1.9-10.8,1.8-.4,3.6-.6,5.5-.8.2,5.5.4,9.7.5,10.6l4.7,1.9c.3-1.2,1.3-6.1,2.5-12.5,2.3.2,4.5.5,6.8,1-2.7,6.2-4.8,11-5.1,12.2l3.9,2.9c1.1-1.5,4.5-6.6,8.7-12.7,1.5.7,3,1.4,4.4,2.2l-.2.1-1.1,1.1c-2.4,2.1-4.6,4.4-6.7,6.9l-.9,1.3,1.2.9c3.2,2.3,3.4,5.5,3.6,9.2,0,.4,0,.8.1,1.2l-4.7,3.3,4.3,12.7c-1,4.2-2.1,8.6-3.3,13.4l-9.8,2,.8,7c.3,3.3,2.7,6.1,5.9,7,6.7,1.9,17.1,4.8,22.8,6.4-8.8,9.4-21.1,14.9-34,15Zm36-17.6l-1-.3v-24.4l4-9.7-6-14.5-6,14.5,4,9.7v23.3c-5.8-1.6-13.8-3.9-19.2-5.4-2-.6-3.5-2.3-3.7-4.4l-.4-4.3,9-1.8.2-.9c1.3-5.3,2.5-10.1,3.7-14.7l.1-.4-3.9-11.4,4.3-3-.1-1c-.1-.6-.1-1.3-.1-1.9-.2-3.5-.4-7.4-3.6-10.4,1.3-1.4,3.4-3.4,5.5-5.4l1.1-1.1.8-.7c21.6,13.9,28,42.7,14.1,64.3-.9,1.4-1.8,2.6-2.8,3.9Z"
          />
          <g>
            <path
              className="cls-1"
              d="m152.7,70.91c-.3-1.1-.7-2.2-1.1-3.3l-1.2-3.4h-12l-1.2,3.4c-.4,1.1-.8,2.2-1.1,3.3h-7.2c1.1-3.3,2.2-6.4,3.3-9.2,1-2.8,2.1-5.4,3.1-7.9s2-4.8,2.9-7.1,2-4.4,3-6.5h6.6c1,2.1,2,4.3,3,6.5s2,4.6,3,7.1,2,5.1,3.1,7.9,2.1,5.9,3.3,9.2h-7.5Zm-8.3-23.8c-.1.4-.4,1-.7,1.8l-1,2.7c-.4,1-.8,2.1-1.3,3.3s-.9,2.5-1.4,3.8h8.8c-.5-1.3-.9-2.6-1.4-3.8s-.9-2.3-1.2-3.3-.8-1.9-1-2.7-.6-1.4-.8-1.8h0Z"
            />
            <path
              className="cls-1"
              d="m190.3,40.21v5.9h-9.3v24.8h-6.9v-24.8h-9.3v-5.9h25.5Z"
            />
            <path
              className="cls-1"
              d="m220.3,40.21h6.9v30.7h-6.9v-13h-11.6v13h-6.9v-30.7h6.9v11.7h11.6v-11.7Z"
            />
            <path
              className="cls-1"
              d="m241.4,70.91v-30.7h20.7v5.8h-13.8v6h12.3v5.7h-12.3v7.4h14.8v5.8h-21.7Z"
            />
            <path
              className="cls-1"
              d="m296,70.91c-2-3.5-4.1-7-6.4-10.4s-4.7-6.6-7.4-9.7v20.1h-6.8v-30.7h5.6c1,1,2,2.2,3.2,3.6s2.4,2.9,3.6,4.5c1.2,1.6,2.5,3.3,3.7,5s2.4,3.4,3.4,5v-18.1h6.9v30.7h-5.8Z"
            />
            <path
              className="cls-1"
              d="m336.5,70.91c-.3-1.1-.7-2.2-1.1-3.3l-1.2-3.4h-12l-1.2,3.4c-.4,1.1-.8,2.2-1.1,3.3h-7.2c1.2-3.3,2.3-6.4,3.3-9.2s2-5.5,3-7.9,2-4.8,2.9-7.1,2-4.4,3-6.5h6.7c1,2.1,2,4.3,3,6.5s2,4.6,3,7.1,2,5.1,3,7.9,2.1,5.9,3.3,9.2h-7.4Zm-8.3-23.8c-.1.4-.4,1-.7,1.8l-1,2.7c-.4,1-.8,2.1-1.3,3.3s-1,2.5-1.4,3.8h8.8c-.5-1.3-.9-2.6-1.4-3.8s-.9-2.3-1.2-3.3-.7-1.9-1-2.7-.6-1.4-.8-1.8Z"
            />
            <path
              className="cls-1"
              d="m372.7,40.21h6.9v30.7h-6.9v-30.7Z"
            />
            <path
              className="cls-1"
              d="m414.4,70.91c-2-3.5-4.1-7-6.4-10.4s-4.7-6.6-7.4-9.7v20.1h-6.8v-30.7h5.6c1,1,2.1,2.2,3.2,3.6s2.4,2.9,3.6,4.5c1.2,1.6,2.4,3.3,3.6,5s2.4,3.4,3.4,5v-18.1h6.9v30.7h-5.7Z"
            />
            <path
              className="cls-1"
              d="m442.7,65.61c.8,0,1.6-.1,2.4-.2.5-.1,1.1-.3,1.5-.7.4-.2.6-.6.8-1,.2-.4.3-.8.3-1.3,0-1-.5-2-1.4-2.5-1.5-.9-3.2-1.7-4.9-2.2-1-.4-2-.8-3-1.2s-1.9-1-2.7-1.7c-.8-.7-1.5-1.5-2-2.5-.5-1.1-.8-2.4-.8-3.6,0-1.3.3-2.6.8-3.8.5-1.1,1.3-2.1,2.3-2.9,1.1-.8,2.3-1.5,3.5-1.8,1.5-.4,3.1-.7,4.7-.6,3.2-.1,6.3.7,9.1,2.1l-2,5.5c-.9-.5-1.9-.9-2.9-1.2-1.2-.4-2.5-.6-3.8-.6-1.2-.1-2.5.1-3.6.7-.7.4-1.1,1.3-1.1,2.1,0,.5.1,1,.4,1.4s.7.7,1.1,1c.5.3,1.1.6,1.7.8.6.3,1.3.5,2.1.8,1.6.6,3,1.2,4.2,1.8,1.1.5,2.1,1.2,3,2,.8.8,1.4,1.7,1.8,2.7.4,1.2.6,2.5.6,3.8.1,2.6-1,5.1-3,6.7-2,1.6-5,2.4-9.1,2.4-1.2,0-2.5-.1-3.7-.2-1-.1-1.9-.3-2.9-.6-.7-.2-1.5-.5-2.2-.8-.5-.2-1-.5-1.5-.8l2-5.5c1.1.6,2.2,1,3.4,1.3,1.6.4,3.3.6,4.9.6Z"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}
