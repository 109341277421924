import { Input, Tag } from 'antd';
import { ethers } from 'ethers';
import { BigNumber } from 'ethers';

type TokenInputProps = {
  token: 'ATEN' | 'USDT';
  value: string | BigNumber;
  maxValue?: string | BigNumber;
  isWholeTokens?: boolean;
  onChange: Function;
  maxSetter?: Function;
  disabled?: boolean;
  className?: string;
};

const format = (
  value: string | BigNumber | undefined,
  token: 'ATEN' | 'USDT'
) => {
  if (!value) return;

  // Return string decimal since they are already formatted
  if (typeof value === 'string' && value.indexOf('.')) return value;

  if (
    typeof value !== 'string' &&
    BigNumber.from(value).toString() === '0'
  ) {
    // Patch to fix BigNumber displaying weird behavior by sometimes returning [object Object] when value is 0
    return '0';
  } else if (typeof value !== 'string') {
    // BigNumbers are always considered non-formatted values
    return ethers.utils
      .formatUnits(BigNumber.from(value), token === 'ATEN' ? 18 : 6)
      .toString();
  } else {
    return value;
  }
};

export function TokenInput({
  token,
  value,
  maxValue,
  onChange,
  maxSetter,
  disabled,
  className,
}: TokenInputProps) {
  const formattedMaxValue = format(maxValue, token);
  const formattedValue = format(value, token);

  // Leave option for max setter to be another function
  const fnSetMax = maxSetter || onChange;

  return (
    <span className={`flex flex-col relative ${className}`}>
      {maxValue !== undefined && !disabled && (
        <Tag
          style={{
            borderColor: '#eeee2a',
            color: '#eeee2a',
          }}
          className="self-end text-center cursor-pointer w-12 absolute bottom-10 mr-0"
          color="transparent"
          onClick={() => !disabled && fnSetMax(formattedMaxValue)}
        >
          Max
        </Tag>
      )}
      <Input
        addonAfter={token}
        value={formattedValue}
        disabled={disabled}
        onChange={(e) => onChange(e.target.value)}
      />
    </span>
  );
}
