import { TransactionStatus, useContractFunction } from '@usedapp/core';
import { Contract, ethers } from 'ethers';
import { ExecuteReturn } from '../../../../types';
import { ERC20, ERC20__factory } from '../../../../types/typechain';
import { BigNumber, utils } from 'ethers';

const { parseUnits } = utils;

export type ParamsTransfer = {
  to: string;
  amount: string;
  amountDecimals: number;
};

type FormattedParams = {
  to: string;
  amount: BigNumber;
};

function formatParams(params: ParamsTransfer): FormattedParams {
  return {
    to: params.to,
    amount: parseUnits(params.amount, params.amountDecimals),
  };
}

type Instance = {
  send: (
    ...args: Parameters<ERC20['transfer']>
  ) => Promise<ethers.providers.TransactionReceipt | undefined>;
  state: TransactionStatus;
};

function makeInstance(address: string | undefined): Instance {
  const { send, state } = useContractFunction(
    address && new Contract(address, ERC20__factory.abi),
    'transfer',
    {
      transactionName: 'transfer',
      gasLimitBufferPercentage: 10,
    }
  );

  return { send, state };
}

function checkParams(params: ParamsTransfer): string | undefined {
  // do checks relative to this fn
  const { to, amount } = formatParams(params);

  if (!to) {
    return 'Missing premiums input';
  }
  if (!amount || amount.eq(0)) {
    return 'Missing amount input';
  }
}

function execute(
  instance: Instance,
  params: {
    to: string;
    amount: string;
  }
): ExecuteReturn {
  const txReceiptPromise = instance.send(params.to, params.amount);

  return {
    txReceipt: txReceiptPromise,
    txStatus: instance.state,
  };
}

export const configTransfer = {
  makeInstance,
  checkParams,
  execute,
};
