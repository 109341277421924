import { useState } from 'react';
// Components
import { RegisterEvidence } from './EvidenceRegister';
import { EvidenceUpload } from './EvidenceUpload';
// Type
import { EvidenceAPIResponseItem, UploadAPIResponse } from '../../types';
// Hooks
import { useClaimEvidence } from '../../hooks/api';
// Context
import { useAppDataContext } from '../../hooks/useAppDataProvider';
import { useWeb3Context } from '../../hooks/useWeb3ContextProvider';

export function SectionUploadEvidence({
  claimId,
}: {
  claimId: string | undefined;
}) {
  const { currentAccount } = useWeb3Context();
  const { findClaimById, useGetClaimEvidence } = useAppDataContext();
  const claim = findClaimById(claimId);

  const canUpload =
    !!claimId &&
    !!currentAccount &&
    (claim?.status === 'Initiated' || claim?.status === 'Disputed') &&
    (claim?.userAddress.toLowerCase() === currentAccount.toLowerCase() ||
      claim?.challenger.toLowerCase() === currentAccount.toLowerCase());

  // === Proof Files Saved ===
  // Evidence on IPFS server
  const evidenceOnIpfs = useClaimEvidence(claimId);
  // Evidence registered on chain
  const evidenceOnChainUris = useGetClaimEvidence(claimId);

  // Evidence uploaded to IPFS but not yet registered - to be sent by register tx
  const [newUploads, setNewUploads] = useState<EvidenceAPIResponseItem[]>(
    []
  );
  const claimEvidence = evidenceOnChainUris.reduce(
    (acc, evidence) => {
      const evidenceIndex = acc.findIndex((el) => el.fileURI === evidence);

      // This serves as a double check to avoid duplicate registrations
      if (evidenceIndex !== -1 && !acc[evidenceIndex].isOnChain) {
        const updatedEvidence = {
          ...acc[evidenceIndex],
          isOnChain: true,
        };
        acc.splice(evidenceIndex, 1, updatedEvidence);
      }

      return acc;
    },
    [...evidenceOnIpfs, ...newUploads]
  );

  const handleNewUpload = (file: UploadAPIResponse) => {
    if (!file.success || !file.data) return;

    const { data } = file;
    const evidenceIndex = claimEvidence.findIndex(
      (el) => el.fileURI === data.fileURI
    );
    // This serves as a double check to avoid duplicate registrations
    if (evidenceIndex !== -1) return;

    setNewUploads((prev) => [...prev, data]);
  };

  return (
    <div className="flex flex-col space-y-4">
      <h3 className="flex items-center">{`Upload Evidence`}</h3>

      <p>
        Please provide any relevant evidence. This will insure no
        challenger is tempted to dispute your claim so that your
        compensation can be processed as fast as possible. Any information
        that proves an incident took place and that you cover was active
        during the incident will help expedite your payout.
      </p>

      <p>
        If possible use publicly verifiable information such as:
        <ul>
          <li className="ml-4 text-sm">
            <span className="theme-highlight"> • </span> Transaction IDs
          </li>
          <li className="ml-4 text-sm">
            <span className="theme-highlight"> • </span> Official
            statements
          </li>
          <li className="ml-4 text-sm">
            <span className="theme-highlight"> • </span> Incident Reports
          </li>
          <li className="ml-4 text-sm">
            <span className="theme-highlight"> • </span> Proof of Ownership
          </li>
        </ul>
      </p>
      <p>
        After the evidence is uploaded, you will need to register the
        evidence on-chain for it to be publicly associated to you claim.
      </p>

      <EvidenceUpload
        disabled={!canUpload}
        claimId={claimId}
        handleNewUpload={handleNewUpload}
      />

      <h3 className="flex items-center">Register Evidence</h3>
      <p>
        Once your documents have been uploaded, check that the IPFS link is
        working correctly and proceed to register it.
      </p>
      {/* <p className="font-bold theme-error-bg-light theme-primary text-center rounded-sm my-4 p-2">
        Uploaded evidence must be registered within 24 hours or it may be
        deleted. Only registered evidence counts towards the evaluation the
        case.
      </p> */}
      <p>
        After your transaction is confirmed then the evidence is
        permanently and publicly linked to the claim. Wait untill the
        challenge period has elapsed to withdraw your payout. Remain
        vigilant for any updates on the status of your claim.
      </p>

      {/* <RegisterEvidence
        claimId={claimId}
        claimEvidence={claimEvidence}
        canUpload={canUpload}
      /> */}
    </div>
  );
}
