// Type
import { CustomMarket } from './marketsConfig';

export type ContractName =
  | 'AthenaCoverToken'
  | 'AthenaPositionToken'
  | 'ATEN'
  | 'USDT'
  | 'LiquidityManager'
  | 'ClaimManager'
  | 'EcclesiaDao'
  | 'Staking'
  | 'FarmingRange';

// Maps chain names and contract names to addresses
// Chains are mandatory but must indicate all contracts
export const ADDRESSES: {
  [key in CustomMarket]: {
    [key in ContractName]: string;
  };
} = {
  mainnet: {
    AthenaCoverToken: '0x65E2fe35C30eC218b46266F89847c63c2eDa7Dc7',
    AthenaPositionToken: '0x45CaEE014eE6684Bb05e1f09ebe5e55D98aa35c6',
    ATEN: '0x45CaEE014eE6684Bb05e1f09ebe5e55D98aa35c6',
    USDT: '0x65E2fe35C30eC218b46266F89847c63c2eDa7Dc7',
    LiquidityManager: '0x3f5ed23A9Dd5De3f7d1100D68aA3951F8b67Fe23',
    ClaimManager: '0xB9464b0af8931a170C66c19f6B90368199D8Bc0A',
    EcclesiaDao: '0x77C197C1b366020a79A3fF2036AC6F7A914E1371',
    Staking: '0x7c0E8199EEb86B6d70bebaD1bDCa07357B351235',
    FarmingRange: '0x4f371edfFa351b4cdAF817ce0f5023394b1549fC',
  },
  hardhat: {
    AthenaCoverToken: '0x65E2fe35C30eC218b46266F89847c63c2eDa7Dc7',
    AthenaPositionToken: '0x45CaEE014eE6684Bb05e1f09ebe5e55D98aa35c6',
    ATEN: '0x45CaEE014eE6684Bb05e1f09ebe5e55D98aa35c6',
    USDT: '0x65E2fe35C30eC218b46266F89847c63c2eDa7Dc7',
    LiquidityManager: '0x3f5ed23A9Dd5De3f7d1100D68aA3951F8b67Fe23',
    ClaimManager: '0xB9464b0af8931a170C66c19f6B90368199D8Bc0A',
    EcclesiaDao: '0x77C197C1b366020a79A3fF2036AC6F7A914E1371',
    Staking: '0x7c0E8199EEb86B6d70bebaD1bDCa07357B351235',
    FarmingRange: '0x4f371edfFa351b4cdAF817ce0f5023394b1549fC',
  },
};
