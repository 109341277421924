export function ArrowTopRightIcon({
  className,
  size,
}: {
  className?: string;
  size?: number;
}) {
  return (
    <svg
      className={`${className}`}
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 448 512"
      style={{
        height: `${(size || 4) / 4}em`,
        width: `${(size || 4) / 4}em`,
      }}
    >
      <g transform="translate(224 256)">
        <g transform="translate(0, 0)  scale(1, 1)  rotate(-45 0 0)">
          <path
            fill="currentColor"
            d="M438.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L338.8 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l306.7 0L233.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160z"
            transform="translate(-224 -256)"
          />
        </g>
      </g>
    </svg>
  );
}
