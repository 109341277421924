import { useAtom } from 'jotai';
import { RESET } from 'jotai/utils';
import React, { PropsWithChildren, useContext } from 'react';
import { useProtocolDataContext } from './useProtocolDataProvider';

// Types
import {
  UserBuyBasket,
  BuyBasketUpdate,
  UserSupplyBasket,
} from '../types';
import {
  getSupplyBasketAtom,
  getBuyBasketAtom,
  defaultSupplyState,
  defaultBuyState,
} from './atoms/useChainBasket';

export type BasketContextType = {
  supplyBasket: UserSupplyBasket;
  buyBasket: UserBuyBasket;
  updateSupplyBasketAmount: (amount: string) => void;
  updateBuyBasket: (update: BuyBasketUpdate) => void;
  addToBuyBasket: (poolId: string) => void;
  addToSupplyBasket: (poolId: string) => void;
  removeFromSupplyBasket: (poolId: string) => void;
  removeFromBuyBasket: (poolId: string) => void;
  emptySupplyBasket: () => void;
  emptyBuyBasket: () => void;
};

const BasketContext = React.createContext<BasketContextType>(
  {} as BasketContextType
);

/**
 * This is the only provider you'll ever need.
 * It fetches reserves /incentives & walletbalances & keeps them updated.
 */
export function BasketProvider({ children }: PropsWithChildren<{}>) {
  const { currentChainId } = useProtocolDataContext();

  const [supplyBasket, setSupplyBasket] = useAtom(
    getSupplyBasketAtom(currentChainId)
  );
  const [buyBasket, setBuyBasket] = useAtom(
    getBuyBasketAtom(currentChainId)
  );

  // Update
  /////////////

  const updateSupplyBasketAmount = (amount: string) => {
    const newBasket = { ...supplyBasket };
    newBasket.amount = amount;
    setSupplyBasket(newBasket);
  };

  const updateBuyBasket = (update: BuyBasketUpdate) => {
    // Check if item is in the basket to update it if it exists
    const findItemIndex = buyBasket.poolIds.findIndex(
      (el: string) => el === update.poolId
    );

    const newBasket = { ...buyBasket };

    if (findItemIndex !== -1) {
      // Update existing item in basket
      if (update.coverAmount !== undefined) {
        newBasket.coverAmounts[findItemIndex] = update.coverAmount;
      }
      if (update.premiumAmount !== undefined) {
        newBasket.premiumAmounts[findItemIndex] = update.premiumAmount;
      }
      if (update.stakeAmount !== undefined) {
        newBasket.stakeAmounts[findItemIndex] = update.stakeAmount;
      }
    } else {
      // Add new item to basket since it doesn't exist
      newBasket.poolIds.push(update.poolId);
      newBasket.coverAmounts.push(update.coverAmount || '0');
      newBasket.premiumAmounts.push(update.premiumAmount || '0');
      newBasket.stakeAmounts.push(update.stakeAmount || '0');
    }

    setBuyBasket(newBasket);
  };

  // Add
  /////////////

  const addToSupplyBasket = (poolId: string) => {
    const newBasket = { ...supplyBasket };
    newBasket.poolIds.push(poolId);
    setSupplyBasket(newBasket);
  };

  const addToBuyBasket = (poolId: string) => {
    updateBuyBasket({ poolId });
  };

  // Remove
  /////////////

  const removeFromBuyBasket = (poolId: string) => {
    const findItemIndex = buyBasket.poolIds.findIndex(
      (el: string) => el === poolId
    );

    if (findItemIndex !== -1) {
      const newBasket = { ...buyBasket };

      newBasket.poolIds.splice(findItemIndex, 1);
      newBasket.coverAmounts.splice(findItemIndex, 1);
      newBasket.premiumAmounts.splice(findItemIndex, 1);
      newBasket.stakeAmounts.splice(findItemIndex, 1);

      setBuyBasket(newBasket);
    }
  };

  const removeFromSupplyBasket = (poolId: string) => {
    const newBasket = { ...supplyBasket };
    setSupplyBasket({
      ...newBasket,
      poolIds: supplyBasket.poolIds.filter((el) => el !== poolId),
    });
  };

  // Empty
  /////////////

  const emptySupplyBasket = () => setSupplyBasket(defaultSupplyState);
  const emptyBuyBasket = () => setBuyBasket(defaultBuyState);

  return (
    <BasketContext.Provider
      value={{
        supplyBasket,
        buyBasket,
        //
        addToBuyBasket,
        addToSupplyBasket,
        removeFromSupplyBasket,
        removeFromBuyBasket,
        //
        updateSupplyBasketAmount,
        updateBuyBasket,
        //
        emptySupplyBasket,
        emptyBuyBasket,
      }}
    >
      {children}
    </BasketContext.Provider>
  );
}

export const useBasketContext = () => useContext(BasketContext);
