// Store
import { atomWithStorage } from 'jotai/utils';
// Types
import { UserBuyBasket, UserSupplyBasket } from '../../types';
import { getSupportedChainIds } from '../../functions/utils/marketsAndNetworksConfig';

export const defaultSupplyState = {
  poolIds: [],
  amount: '0',
};

export const defaultBuyState = {
  poolIds: [],
  coverAmounts: [],
  premiumAmounts: [],
  stakeAmounts: [],
};

const mainnetSupplyAtom = atomWithStorage<UserSupplyBasket>(
  'supplyBasket-1',
  defaultSupplyState
);
const mainnetBuyAtom = atomWithStorage<UserBuyBasket>(
  'buyBasket-1',
  defaultBuyState
);

const goerliSupplyAtom = atomWithStorage<UserSupplyBasket>(
  'supplyBasket-5',
  defaultSupplyState
);
const goerliBuyAtom = atomWithStorage<UserBuyBasket>(
  'buyBasket-5',
  defaultBuyState
);

const bscSupplyAtom = atomWithStorage<UserSupplyBasket>(
  'supplyBasket-56',
  defaultSupplyState
);
const bscBuyAtom = atomWithStorage<UserBuyBasket>(
  'buyBasket-56',
  defaultBuyState
);

const testSupplyAtom = atomWithStorage<UserSupplyBasket>(
  'supplyBasket-test',
  defaultSupplyState
);
const testBuyAtom = atomWithStorage<UserBuyBasket>(
  'buyBasket-test',
  defaultBuyState
);

export const getSupplyBasketAtom = (chainId: number | undefined) => {
  if (chainId === 1) return mainnetSupplyAtom;
  if (chainId === 5) return goerliSupplyAtom;
  if (chainId === 56) return bscSupplyAtom;
  return testSupplyAtom;
};
export const getBuyBasketAtom = (chainId: number | undefined) => {
  if (chainId === 1) return mainnetBuyAtom;
  if (chainId === 5) return goerliBuyAtom;
  if (chainId === 56) return bscBuyAtom;
  return testBuyAtom;
};
