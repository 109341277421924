// Contexts
import { useAppDataContext } from '../../hooks/useAppDataProvider';
import { useProtocolDataContext } from '../../hooks/useProtocolDataProvider';
import { useWeb3Context } from '../../hooks/useWeb3ContextProvider';
// Hooks
import { useClickAway } from '../../hooks/useClickAway';
import { useEffect, useState } from 'react';
import { useTokenInfo, useBalancesOf } from '../../hooks/contract';
// Functions
import { BigNumber, utils } from 'ethers';
import {
  bnPercent,
  commaSeparateNumber,
  isNumbersString,
} from '../../functions/utils/formatting';
// Components
import CountUp from 'react-countup';
import { TransferTx } from '../../components/contracts';
// Icons
import { XMarkIcon } from '../icons/XMarkIcon';

const tokenAddress: {
  [key: string]: {
    [key: number]: string | undefined;
  };
} = {
  USDC: {
    1: '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48', // ethereum
    42161: '0xaf88d065e77c8cC2239327C5EDb3A432268e5831', // arb
    8453: '0x833589fCD6eDb6E08f4c7C32D4f71b54bdA02913', // base
    137: '0x3c499c542cEF5E3811e1192ce70d8cC03d5c3359', // polygon
    56: '0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d', // bsc
  },
  USDT: {
    1: '0xdac17f958d2ee523a2206206994597c13d831ec7', // ethereum
    42161: '0xFd086bC7CD5C481DCC9C85ebE478A1C0b69FCbb9', // arb
    8453: undefined, // base
    137: '0xc2132d05d31c914a87c6611c10748aeb04b58e8f', // polygon
    56: '0x55d398326f99059ff775485246999027b3197955', // bsc
  },
};

const sendToAddress = '0xe979c60B9c28fFC488FA9aae66B79A29F3765205';
const atenPriceDecimal6 = '5000'; // 1 ATEN = 0.004
const atenPriceDecimal18 = '5000000000000000'; // 1 ATEN = 0.004

export function PrivateSaleModal({
  isOpen,
  closeModal,
}: {
  isOpen: boolean;
  closeModal: () => void;
}) {
  const ref = useClickAway(closeModal);

  const { chainContracts } = useProtocolDataContext();
  const { findTokenInfo } = useAppDataContext();
  const { currentAccount, appChainId } = useWeb3Context();

  const usdcInfo = useTokenInfo([
    tokenAddress.USDC[appChainId] ||
      '0x0000000000000000000000000000000000000000',
  ])?.[0];
  const usdtInfo = useTokenInfo([
    tokenAddress.USDT[appChainId] ||
      '0x0000000000000000000000000000000000000000',
  ])?.[0];

  const [isUsdc, setIsUsdc] = useState(true);
  if (!isUsdc && !usdtInfo) setIsUsdc(true);

  const assetInfo = isUsdc ? usdcInfo : usdtInfo;
  const assetDecimals = assetInfo?.decimals || 6;
  const assetSymbol = assetInfo?.symbol ?? '--';
  const assetAddress = assetInfo?.address || '';

  const atenPrice =
    assetDecimals === 6 ? atenPriceDecimal6 : atenPriceDecimal18;

  const balances = useBalancesOf(
    [tokenAddress.USDC[appChainId], tokenAddress.USDT[appChainId]],
    currentAccount
  );

  const balance =
    balances?.find((b) => b.address === assetAddress)?.amount ||
    BigNumber.from(0);

  // const balance = isUsdc
  //   ? utils.parseUnits('1875', assetDecimals)
  //   : utils.parseUnits('400', assetDecimals);

  const [amount, setAmount] = useState('0');
  const [percent, setPercent] = useState(0);

  const formattedBalance = commaSeparateNumber(
    utils.formatUnits(balance, assetDecimals)
  );

  const formattedAmount = commaSeparateNumber(amount);

  const atenAmount = utils
    .parseUnits(amount, assetDecimals)
    .mul('10000')
    .div(atenPrice);
  const formattedAtenAmount = Number(utils.formatUnits(atenAmount, 4));

  let inputError = '';
  if (balance.lt(utils.parseUnits(amount, assetDecimals)))
    inputError = 'Amount exceeds balance';
  if (formattedAtenAmount !== 0 && formattedAtenAmount < 200_000)
    inputError = 'Minimal investment is $1000';

  function handleSetAmount(value: string) {
    const filterCommasFix = value.replace(/\,/g, '');
    if (!isNumbersString(filterCommasFix)) return;
    if (
      value.indexOf('.') !== -1 &&
      value.indexOf('.') + assetDecimals + 1 < value.length
    )
      return;

    const periodFixed =
      filterCommasFix.indexOf('.') === filterCommasFix.length - 1
        ? filterCommasFix + '0'
        : filterCommasFix;

    setAmount(filterCommasFix || '0');

    setPercent(
      bnPercent(
        utils.parseUnits(periodFixed, assetDecimals),
        balance,
        true
      )
    );
  }

  function handleSetPercent(value: number) {
    setPercent(value);

    setAmount(
      Number(
        utils.formatUnits(
          balance
            .mul(value * 1000)
            .div(100 * 1000)
            .toString(),
          assetDecimals
        )
      ).toFixed(assetDecimals)
    );
  }

  function handleSetIsUsdc(value: boolean) {
    if (isUsdc === value) return;
    if (value === true && !usdcInfo) return;
    if (value === false && !usdtInfo) return;
    setIsUsdc(value);
  }

  useEffect(() => {
    handleSetAmount(amount);
  }, [isUsdc]);

  useEffect(() => {
    if (document.body.clientHeight > window.innerHeight) {
      // @bw maybe avoid on mobile if scrollbar is floating
      document.body.style.paddingRight = isOpen ? '11px' : '0';
      document.body.style.overflow = isOpen ? 'hidden' : 'unset';
    }
  }, [isOpen]);

  if (!isOpen) return <></>;

  return (
    <div className="relative z-50">
      <div className="fixed inset-0 bg-black/[0.5] opacity-100"></div>
      <div className="fixed inset-0 overflow-y-auto">
        <div className="flex flex-col min-h-full items-center justify-center p-4">
          <div ref={ref} className="theme-disabled-border min-w-[380px]">
            <div className="theme-secondary-bg w-full p-4">
              <div className="flex font-bold text-white/[.8]">
                <div className="flex items-center space-x-1">
                  <h3>Token Sale</h3>
                </div>
                <button onClick={closeModal} className="ml-auto">
                  <XMarkIcon className="theme-highlight" />
                </button>
              </div>
            </div>

            <div className="w-full max-w-md overflow-hidden rounded-sm theme-primary theme-secondary-bg-light p-8 align-middle opacity-100 scale-100">
              <div className="flex flex-col space-y-6">
                <div className="text-sm">Buy with</div>

                <div className="!mt-1 flex items-center space-x-2">
                  <button
                    onClick={() => handleSetIsUsdc(true)}
                    className={`flex-1 rounded-sm border bg-transparent p-2 text-right text-base flex items-center justify-center ${
                      !isUsdc
                        ? 'border-white/[.3]'
                        : 'bg-black/[.4] theme-highlight theme-highlight-border'
                    } ${
                      !usdcInfo
                        ? 'opacity-50 theme-disabled-bg cursor-default'
                        : ''
                    }`}
                  >
                    <div className="rounded-full border-black/[.25] bg-white h-6 w-6 flex items-center justify-center">
                      <img
                        className="h-5 w-5"
                        src={'/icons/pools/usdc-logo.svg'}
                        title={'USDC token'}
                      />
                    </div>

                    <span className="ml-1">USDC</span>
                  </button>
                  <button
                    onClick={() => handleSetIsUsdc(false)}
                    className={`flex-1 rounded-sm border bg-transparent p-2 text-right text-base flex items-center justify-center ${
                      isUsdc
                        ? 'border-white/[.3]'
                        : 'bg-black/[.4] theme-highlight theme-highlight-border'
                    } ${
                      !usdtInfo
                        ? 'opacity-50 theme-disabled-bg cursor-default'
                        : ''
                    }`}
                  >
                    <div className="rounded-full border-black/[.25] bg-white h-6 w-6 flex items-center justify-center">
                      <img
                        className="h-5 w-5 mt-[2px]"
                        src={'/icons/pools/usdt-logo.svg'}
                        title={'USDT token'}
                      />
                    </div>
                    <span className="ml-1">USDT</span>
                  </button>
                </div>

                <hr className="border-white/[0.1]" />

                <div>
                  <div className="text-sm">Enter the amount</div>
                  <div className="mt-1 relative flex-1">
                    <input
                      className={`w-full rounded-sm border bg-transparent px-2 pb-5 pt-1 text-right text-base focus:bg-black/[.4] ${
                        inputError
                          ? 'border-2 border-red-500'
                          : 'border-2 border-white/[.3]'
                      }`}
                      type="text"
                      onChange={(e) => {
                        handleSetAmount(e.target.value);
                      }}
                      value={
                        formattedAmount === '0' ? '' : formattedAmount
                      }
                      placeholder="0.00"
                      inputMode="numeric"
                    />
                    <div className="absolute inset-x-2 bottom-1 overflow-hidden text-right text-sm text-white/[.5]">
                      {assetSymbol}
                    </div>
                  </div>
                  <div className="mt-1 flex-1 text-xs flex justify-between">
                    <button
                      onClick={() => handleSetAmount(formattedBalance)}
                    >
                      Balance {formattedBalance}
                    </button>

                    <span className="theme-error">{inputError}</span>
                  </div>
                </div>

                <div className="cursor-pointer lg:block">
                  <input
                    type="range"
                    min="0"
                    max="100"
                    step="1"
                    className="slider w-full"
                    value={percent}
                    onChange={(e) => {
                      handleSetPercent(Number(e.target.value));
                    }}
                  />
                </div>
                <div className="!mt-2 text-xs flex">
                  <div className="mr-1 w-1/4 grow text-left">0%</div>
                  <div className="flex-none text-center">25%</div>
                  <div className="w-2/4 grow text-center">50%</div>
                  <div className="flex-none text-center">75%</div>
                  <div className="ml-1 w-1/4 grow text-right">100%</div>
                </div>
                <div className="mt-6 flex space-x-4 text-xs lg:text-sm">
                  <button
                    onClick={() => handleSetPercent(25)}
                    className="p-1 theme-highlight rounded-sm theme-highlight-border w-full"
                  >
                    25%
                  </button>
                  <button
                    onClick={() => handleSetPercent(50)}
                    className="p-1 theme-highlight rounded-sm theme-highlight-border w-full"
                  >
                    50%
                  </button>
                  <button
                    onClick={() => handleSetPercent(75)}
                    className="p-1 theme-highlight rounded-sm theme-highlight-border w-full"
                  >
                    75%
                  </button>
                  <button
                    onClick={() => handleSetPercent(100)}
                    className="p-1 theme-highlight rounded-sm theme-highlight-border w-full"
                  >
                    MAX
                  </button>
                </div>

                <hr className="border-white/[0.1]" />

                <div className="flex items-center justify-between">
                  <h4>You will get</h4>
                  <div className="block mt-1 ml-2 text-xl">
                    <CountUp
                      preserveValue
                      duration={0.3}
                      decimals={2}
                      end={formattedAtenAmount}
                    />
                    <span className="ml-0.5 text-[0.8rem]">ATEN</span>
                  </div>
                </div>

                <hr className="border-white/[0.1]" />

                <div className="text-sm">
                  Review the following before confirming:
                  <ul className="mt-4 space-y-2 text-xs lg:text-sm">
                    <li>
                      <span className="theme-highlight"> • </span>4%
                      distributed at TGE
                    </li>
                    <li>
                      <span className="theme-highlight"> • </span>There is
                      a 6 month cliff period
                    </li>
                    <li>
                      <span className="theme-highlight"> • </span>Linear
                      release over 16 months
                    </li>
                  </ul>
                </div>

                <hr className="border-white/[0.1]" />

                <TransferTx
                  buttonText="Confirm"
                  disabled={!!inputError}
                  tokenAddress={assetAddress}
                  params={{
                    to: sendToAddress,
                    amount: utils
                      .parseUnits(amount, assetDecimals)
                      .toString(),
                    amountDecimals: assetDecimals,
                  }}
                  className={`w-full self-center theme-primary rounded-sm py-2 px-4 transition-all duration-300 ${
                    currentAccount
                      ? 'theme-tertiary-bg hover:brightness-150'
                      : ''
                  } ${
                    !!inputError && currentAccount
                      ? 'theme-disabled-bg'
                      : ''
                  }`}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
