import { Tag } from 'antd';

export const LevelTag = ({ level }: { level: number }) => (
  <Tag
    style={{
      color: '#eeee2a',
      borderColor: '#eeee2a',
      backgroundColor: '#3f139a',
      marginTop: 6,
    }}
  >
    Level {level}
  </Tag>
);
