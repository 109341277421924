import { ChainId } from './chainId';
import { ContractName, ADDRESSES } from './addresses';

export enum CustomMarket {
  hardhat = 'hardhat',
  mainnet = 'mainnet',
  // bsc = 'bsc',
  // polygon = 'polygon',
  // mumbai = 'mumbai',
}

export type MarketDataType = {
  marketTitle: string;
  chainId: ChainId;
  enabledFeatures: { [key: string]: boolean };
  addresses: { [key in ContractName]?: string };
};

export const marketsData: {
  [key in CustomMarket]: MarketDataType;
} = {
  // Default market
  [CustomMarket.hardhat]: {
    marketTitle: 'Hardhat',
    chainId: ChainId.hardhat,
    enabledFeatures: {},
    addresses: ADDRESSES[CustomMarket.hardhat],
  },
  [CustomMarket.mainnet]: {
    marketTitle: 'Ethereum',
    chainId: ChainId.mainnet,
    enabledFeatures: {},
    addresses: ADDRESSES[CustomMarket.mainnet],
  },
  // [CustomMarket.bsc]: {
  //   marketTitle: 'BNB (ex BSC)',
  //   chainId: ChainId.bsc,
  //   enabledFeatures: {},
  //   addresses: ADDRESSES[CustomMarket.bsc],
  // },
  // [CustomMarket.polygon]: {
  //   marketTitle: 'Polygon',
  //   chainId: ChainId.polygon,
  //   enabledFeatures: {},
  //   addresses: ADDRESSES[CustomMarket.polygon],
  // },
  // [CustomMarket.mumbai]: {
  //   marketTitle: 'Polygon Mumbai',
  //   chainId: ChainId.mumbai,
  //   enabledFeatures: {},
  //   addresses: ADDRESSES[CustomMarket.mumbai],
  // },
};
