import { useAtom } from 'jotai';
import { BigNumber } from 'ethers';
import { Link } from 'react-router-dom';
import { Row, Col, Space, Typography, Button } from 'antd';
// Components
import { BackButton } from '../BackButton';
import { FormattedNumber } from '../primitives/FormattedNumber';
import { ChainLogo } from '../icons/ChainLogo';
import { PoolLogo } from '../icons/PoolLogo';
import { HeaderIcon } from '../icons/HeaderIcon';
import {
  FormatTokenAmount,
  FormatPercent,
} from '../primitives/FormattedNumber';
// Context
import { useAppDataContext } from '../../hooks/useAppDataProvider';
import { useWeb3Context } from '../../hooks/useWeb3ContextProvider';
import { useBasketContext } from '../../hooks/useBasketProvider';

export const wrapInLink = (content: JSX.Element, poolId?: string) => {
  if (!poolId) return content;
  return <Link to={'/pool/' + poolId}>{content}</Link>;
};

export function HeaderPool({ poolId }: { poolId?: string }) {
  const { buyBasket, supplyBasket } = useBasketContext();
  const { getPoolNameById, findPoolById } = useAppDataContext();
  const { currentNetworkConfig, isConnected, chainId } = useWeb3Context();

  const poolName = getPoolNameById(poolId);
  const poolData = findPoolById(poolId);

  let marketTitle;
  if (currentNetworkConfig.name !== 'Unsupported chain') {
    marketTitle = `${currentNetworkConfig.name} Market`;
  } else if (!isConnected) {
    marketTitle = 'Not connected';
  } else {
    marketTitle = currentNetworkConfig.name;
  }

  let totalCapacity = BigNumber.from(0);
  let availableCapacity = BigNumber.from(0);
  let utilizationRate = 0;
  let premiumRate = 0;
  let supplyRate = 0;
  if (poolData) {
    // availableCapacity = poolData.availableCapacity;
    // utilizationRate = poolData.utilizationRate;
    // premiumRate = poolData.premiumRate;
    // supplyRate = poolData.supplyRate;
    // totalCapacity = poolData.totalCapacity;
  }

  const marketMetrics = [
    {
      icon: 'pie',
      title: 'Total Pool Size',
      content: FormatTokenAmount(
        totalCapacity,
        'USD',
        undefined,
        'large',
        true
      ),
    },
    {
      icon: 'check',
      title: 'Total Available',
      content: FormatTokenAmount(
        availableCapacity,
        'USD',
        undefined,
        'large',
        true
      ),
    },
    {
      icon: 'shield',
      title: 'Utilisation Rate',
      content: FormatPercent(utilizationRate, 'large', true),
    },
    {
      icon: 'tag',
      title: 'Premium Rate',
      content: FormatPercent(premiumRate, 'large', true),
    },
    {
      icon: 'grow',
      title: 'Supply APR',
      content: FormatPercent(supplyRate, 'large', true),
    },
  ];

  const nbBuyBasket = buyBasket.poolIds.length;
  const nbSupplyBasket = supplyBasket.poolIds.length;

  return (
    <Row className="content-card flex align-center p-4 mb-12">
      <Col className="mb-4" span={24}>
        <Space wrap align="center">
          {/* Back button  */}
          <BackButton />
          {/* Pool Title  */}
          {wrapInLink(
            <Typography.Title
              level={3}
              className="theme-secondary shrink-0 uppercase flex flex-nowrap break-normal items-center"
            >
              <PoolLogo className="mr-2" poolId={poolId} />
              {poolName}
            </Typography.Title>,
            poolId
          )}

          {/* Market Title  */}
          <Typography.Title
            level={5}
            className="ml-2 flex items-center shrink-0"
          >
            {'in'}
            <ChainLogo className="ml-3 mr-1" chainId={chainId} size={36} />
            {marketTitle}
          </Typography.Title>
        </Space>
      </Col>

      <Col span={24}>
        <Space wrap align="center">
          {/* Market metrics  */}
          {marketMetrics.map((metric: any, i: number) => {
            return (
              <div
                key={i}
                className={`header-content ${i !== 0 ? 'ml-8' : 'ml-4'}`}
              >
                <HeaderIcon iconName={metric.icon} />
                <span className="ml-1">
                  {metric.title}

                  <Typography.Title
                    level={5}
                    className="mb-0 font-bold text-xl"
                  >
                    {metric.content}
                  </Typography.Title>
                </span>
              </div>
            );
          })}
        </Space>
      </Col>
    </Row>
  );
}
