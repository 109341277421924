import { Button, Col, Table, Typography } from 'antd';

// import { ClaimOpenDisputeButton } from '../contracts';
import { useAppDataContext } from '../../hooks/useAppDataProvider';
import { useWeb3Context } from '../../hooks/useWeb3ContextProvider';
import { EvidenceAPIResponseItem, ClaimStatus } from '../../types';

export const ChallengeClaim = ({
  claimId,
  claimStatus,
  claimantAddres,
}: {
  claimId: string | undefined;
  claimStatus: ClaimStatus;
  claimantAddres: string;
}) => {
  const { arbitrationCost } = useAppDataContext();
  const { currentAccount } = useWeb3Context();

  const canChallenge =
    claimStatus === 'Initiated' &&
    currentAccount.toLowerCase() !== claimantAddres.toLowerCase();

  return (
    <Col span={24} className="content-card flex flex-col mt-8 py-8 px-24">
      <Typography.Title
        className="flex self-center items-center"
        level={3}
      >
        Challenge this Claim
      </Typography.Title>
      <p className="mt-6">
        If you have reasons to believe this claim is illegitimate,
        irregular or fraudulent then you can challenge it. If the claim is
        rejected following your challenge then you will be paid the
        collateral deposited by the claimant.
      </p>
      <p className="my-3">
        You are required to fund the arbitration fee in order to open the
        dispute. This fee is paid back to you in case you win the dispute
        as the ruling rejects the claim. This fee is used to pay for the
        arbitration costs in case the claimant wins and your challenge is
        ruled as unfounded.
      </p>
      <p className="mb-3">
        Once you challenge a dispute you may upload evidence to support
        your position. Athena may also submit additional evidence it may
        have that would support your argument.
      </p>

      {/* <ClaimOpenDisputeButton
        params={{
          claimId: claimId,
          value: arbitrationCost,
        }}
        disabled={!canChallenge}
        className="w-[220px] self-center"
      /> */}
      <button>DISPUTE</button>
    </Col>
  );
};
