import { useNavigate } from 'react-router-dom';
import { Col, Space, Table, Typography } from 'antd';
// types
import type { ColumnsType } from 'antd/lib/table';
import { BaseTableConfigItem, BaseTableDataItem } from '../../types';

const configToColumn = (col: any) => {
  return {
    ...col,
    dataIndex: col.key,
    align: col.align || 'center',
    render: (_: string, record: any) => {
      if (col.render) return col.render(_, record);
      if (col.cellContent) return <>{col.cellContent(record)}</>;
      else console.error('No render or cellContent provided for column');
    },
    defaultSortOrder: col.defaultSortOrder || 'descend',
  };
};

export const BaseTable = ({
  title,
  description,
  data,
  config,
  isLoading,
  tableButton,
  rowKey,
  id,
  className,
  makeNavUrl,
}: {
  title: string | JSX.Element;
  description?: string;
  data: BaseTableDataItem[];
  config: BaseTableConfigItem[];
  isLoading: boolean;
  tableButton?: JSX.Element | boolean;
  rowKey?: string;
  id?: string;
  className?: string;
  makeNavUrl?: (record: any) => string;
}) => {
  const navigate = useNavigate();
  const formatedColumns = config.map((col: BaseTableConfigItem) =>
    configToColumn(col)
  );

  return (
    <Col
      id={id}
      span={24}
      className={`content-card p-4 mb-6 ${className}`}
    >
      <Typography.Title className="flex items-center" level={3}>
        {title}
      </Typography.Title>
      {description && <p className="my-4 px-2">{description}</p>}
      <Table
        rowKey={rowKey || 'key'}
        className={`mt-2 ${!!makeNavUrl && !!data.length && 'nav-table'}`}
        columns={formatedColumns as ColumnsType<any>}
        dataSource={data}
        size="small"
        loading={isLoading}
        pagination={false}
        showSorterTooltip={false}
        onRow={(record, rowIndex) => {
          return {
            onClick: (e) => {
              const eventTarget = e.target as Element;

              // We want to check the click target is not a link or button &
              // that the table is made to be navigated
              if (
                !!makeNavUrl &&
                !eventTarget.className.includes('table-link') &&
                !eventTarget.parentElement?.className.includes(
                  'table-link'
                ) &&
                !eventTarget.parentElement?.parentElement?.className.includes(
                  'table-link'
                )
              ) {
                navigate(makeNavUrl(record));
              }
            },
          };
        }}
      />
      {!!tableButton && tableButton}
    </Col>
  );
};
