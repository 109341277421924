import { Col, Typography, Row } from 'antd';
import { TooltipInfo } from './button/TooltipInfo';

export type StatLineData = {
  title: string;
  content: any;
  tooltipLabel?: string;
};

const DEFAULT_TOTAL_WIDTH = 24;
const DEFAULT_ITEM_WIDTH = 5;

export const StatLine = ({
  data,
  children,
  totalWidth,
  itemWidth,
  className,
  title,
  id,
}: {
  title: string;
  data: StatLineData[];
  children?: boolean | JSX.Element | (JSX.Element | boolean)[];
  totalWidth?: number;
  itemWidth?: number;
  id?: string;
  className?: string;
}) => {
  const totalWidthUsed = totalWidth || DEFAULT_TOTAL_WIDTH;
  const itemWidthUsed = itemWidth || DEFAULT_ITEM_WIDTH;

  const offsetUsed = totalWidth
    ? (DEFAULT_TOTAL_WIDTH - totalWidthUsed) / 2
    : 0;

  return (
    <Col
      id={id}
      span={totalWidthUsed}
      offset={offsetUsed}
      className={`content-card ${className || ''}`}
    >
      {title && (
        <Typography.Title className="pt-8 flex justify-center" level={3}>
          {title}
        </Typography.Title>
      )}
      <Row className={`flex justify-center pt-8`}>
        {data.map((el, i) => {
          return (
            <Col
              key={i}
              span={itemWidthUsed}
              className={`flex flex-col items-center min-w-[180px] mb-8 mx-4`}
            >
              <p className="text-lg font-bold">
                {el.title}
                {el.tooltipLabel && (
                  <TooltipInfo label={el.tooltipLabel} />
                )}
              </p>
              <hr className="w-full" />
              <span>{el.content}</span>
            </Col>
          );
        })}

        {children && (
          <Col className={`flex justify-center mb-8 mt-4`} span={24}>
            {children}
          </Col>
        )}
      </Row>
    </Col>
  );
};
