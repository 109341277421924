import { useCall } from '@usedapp/core';
import { useState } from 'react';
import { formatUserCover } from '../../../../functions/athenaDataFormat';
import { UserPosition } from '../../../../types';
import { useProtocolDataContext } from '../../../useProtocolDataProvider';
import { useSingleCall } from '../../../useSingleCall';

// Value that is returned if the hook encounters an error
const defaultValue: UserPosition[] = [];

export const usePositionsByAccount = (account: string) => {
  return defaultValue;

  // const [previousValue, setPreviousValue] = useState(defaultValue);
  // const [canCall, isCalled] = useSingleCall();

  // const { chainContracts } = useProtocolDataContext();
  // const contract = chainContracts?.POSITIONS_MANAGER;

  // const conditionsOk = !!account && canCall;

  // const { value, error } =
  //   useCall(
  //     conditionsOk &&
  //       contract && {
  //         contract: contract,
  //         method: 'allPositionsOfOwner', // Method to be called
  //         args: [account], // Method arguments
  //       }
  //   ) ?? {};

  // if (error) {
  //   console.error(error.message);
  //   return defaultValue;
  // } else if (value?.[0]) {
  //   const formattedResult = formatUserCover(value[0]);
  //   setPreviousValue(formattedResult);
  //   isCalled();
  //   return formattedResult;
  // } else if (previousValue.length) {
  //   return previousValue;
  // } else {
  //   return defaultValue;
  // }
};
