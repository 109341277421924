import { useCall } from '@usedapp/core';
import { useProtocolDataContext } from '../../../useProtocolDataProvider';
import { useWeb3Context } from '../../../useWeb3ContextProvider';

const defaultValue = '';

export const useLatestCoverClaimId = (coverId: string | undefined) => {
  const { chainContracts } = useProtocolDataContext();

  const contract = chainContracts?.ClaimManager;

  const conditionsOk = !!coverId;

  const { value, error } =
    useCall(
      conditionsOk &&
        contract && {
          contract: contract,
          method: 'latestCoverClaimId', // Method to be called
          args: [coverId], // Method arguments
        },
      { refresh: 'everyBlock' }
    ) ?? {};
  if (error) {
    console.error(error.message);
    return defaultValue;
  }
  if (value?.[0]) {
    return value?.[0].toNumber();
  }
  return defaultValue;
};
