import { useCallback, useEffect, useState } from 'react';
import { CONFIG } from '../../appConfig';
import { useWeb3Context } from '../useWeb3ContextProvider';
import {
  EvidenceAPIResponseItem,
  ClaimEvidencesAPIResponse,
} from '../../types';

const { EVIDENCE_REGISTER_WINDOW } = CONFIG;

export function useClaimEvidence(claimId: string | undefined) {
  const { chainId } = useWeb3Context();

  const [evidenceData, setEvidenceData] = useState<
    EvidenceAPIResponseItem[]
  >([]);

  const fetchData = useCallback<() => () => void>(() => {
    if (!claimId || !chainId) {
      setEvidenceData([]);
      return () => null;
    }
    // reset
    setEvidenceData([]);

    let fetchPromise: Promise<ClaimEvidencesAPIResponse> = fetch(
      `${CONFIG.API_ENDPOINT}/evidence/?chainId=${chainId}&claimId=${claimId}`,
      {
        method: 'GET',
      }
    )
      .then((res) => res.json())
      .catch((e) => {
        setEvidenceData([]);
        return () => null;
      });

    fetchPromise
      .then((data) => {
        if (!data?.ipfsClaimEvidence?.length) return [];

        const validatedData = data.ipfsClaimEvidence.reduce(
          (acc: EvidenceAPIResponseItem[], el) => {
            // We underestimate the time left to upload evidence for resilience
            const enoughTimeLeft =
              Date.now() < el.uploadTimestamp + EVIDENCE_REGISTER_WINDOW;

            if (!enoughTimeLeft) {
              return acc;
            } else if (
              el.fileURI &&
              el.name &&
              el.uploadTimestamp &&
              el.docId
            ) {
              return [...acc, el];
            } else {
              console.warn('useClaimEvidence: incomplete data');
              return acc;
            }
          },
          []
        );

        setEvidenceData(validatedData);
      })
      .catch((e) => {
        console.error('useClaimEvidence: Failed to fetch', e);
        return () => null;
      });

    return () => null;
  }, [claimId, chainId]);

  useEffect(() => {
    fetchData();
  }, [claimId, chainId]);

  return evidenceData;
}
