import { Button, Col } from 'antd';
import cx from 'classnames';
import { useState } from 'react';
import { Link } from 'react-router-dom';
// Hooks
import { usePageTitle } from '../hooks/usePageTitle';
// Context
import { useAppDataContext } from '../hooks/useAppDataProvider';
import { useBasketContext } from '../hooks/useBasketProvider';
// Functions
import { bnSort } from '../functions/utils/formatting';
// Components
import { HeaderMarket } from '../components/layout/HeaderMarket';
import { PoolTable } from '../components/tables/PoolTable';
import { TableLogoWrapper } from '../components/icons/TableLogoWrapper';
import {
  FormatPercent,
  FormatTokenAmount,
} from '../components/primitives/FormattedNumber';
// types
import {
  UserSupplyBasket,
  FormattedPool,
  PoolTableConfigItem,
} from '../types';

// Colums Table
// const columnConfig = (
//   basket: UserSupplyBasket,
//   addToSupplyBasket: (poolId: string) => void,
//   removeFromSupplyBasket: (poolId: string) => void
// ): PoolTableConfigItem[] => [
//   {
//     title: 'Protocol',
//     key: 'protocol',
//     width: 250,
//     render: (_, record) => (
//       <TableLogoWrapper className="ml-2" poolId={record.poolId} />
//     ),
//     defaultSortOrder: 'descend',
//     sorter: (a: FormattedPool, b: FormattedPool) =>
//       a.name < b.name,
//   },
//   {
//     title: 'Supplied Capital',
//     key: 'available',
//     align: 'center',
//     render: (_, record) => FormatTokenAmount(record.totalCapacity, 'USD'),
//     defaultSortOrder: 'descend',
//     sorter: (a: FormattedPool, b: FormattedPool) =>
//       bnSort(a.totalCapacity, b.totalCapacity),
//   },
//   {
//     title: 'Value Covered',
//     key: 'coverage',
//     align: 'center',
//     render: (_, record) => FormatTokenAmount(record.insuredCapital, 'USD'),
//     defaultSortOrder: 'descend',
//     sorter: (a: FormattedPool, b: FormattedPool) =>
//       bnSort(a.insuredCapital, b.insuredCapital),
//   },
//   {
//     title: 'Utilization Rate',
//     key: 'utilization',
//     align: 'center',
//     render: (_, record) => FormatPercent(record.utilizationRate),
//     defaultSortOrder: 'descend',
//     sorter: (a: FormattedPool, b: FormattedPool) =>
//       a.utilizationRate - b.utilizationRate,
//   },
//   {
//     title: 'APR',
//     key: 'apr',
//     align: 'center',
//     render: (_, record) => FormatPercent(record.supplyRate),
//     defaultSortOrder: 'descend',
//     sorter: (a: FormattedPool, b: FormattedPool) =>
//       a.supplyRate - b.supplyRate,
//   },
//   {
//     title: '',
//     key: 'action',
//     width: 190,
//     render: (_, record) => {
//       const selected = !!basket.poolIds.find((el) => el === record.poolId);

//       const actionToPerform = () =>
//         selected
//           ? removeFromSupplyBasket(record.poolId)
//           : addToSupplyBasket(record.poolId);

//       return (
//         <div className="flex justify-end">
//           <Button
//             type="text"
//             onClick={actionToPerform}
//             className="table-link no-underline"
//           >
//             {selected ? 'Remove' : 'Add'}
//           </Button>
//           <Link className="mx-2" to={'/pool/' + record.poolId}>
//             <Button type="default">View Pool</Button>
//           </Link>
//         </div>
//       );
//     },
//   },
// ];

export function SupplyCover() {
  usePageTitle('Supply Cover');
  const [isMobileView] = useState(false);

  const {
    supplyBasket,
    addToSupplyBasket,
    removeFromSupplyBasket,
    emptySupplyBasket,
  } = useBasketContext();
  const { athenaPools } = useAppDataContext();

  // const config = columnConfig(
  //   supplyBasket,
  //   addToSupplyBasket,
  //   removeFromSupplyBasket
  // );

  return (
    <>
      <HeaderMarket title="Supply Cover" type="supply" />

      {/* <Col span={24} className="mt4">
        {isMobileView ? (
          true || '<CoverCards config={config} data={data} />'
        ) : (
          <PoolTable
            config={config}
            data={athenaPools}
            emptyBasket={emptySupplyBasket}
            itemsInBasket={supplyBasket.poolIds.length}
          />
        )}
      </Col> */}
    </>
  );
}
