import { useCalls } from '@usedapp/core';
import { Contract, BigNumber } from 'ethers';
import { ERC20, ERC20__factory } from '../../../../types/typechain';

export function useBalancesOf(
  tokenAddresses: (string | undefined)[] | undefined,
  account: string
):
  | {
      amount: BigNumber;
      address: string;
    }[]
  | undefined {
  const tokensFiltered = [...new Set(tokenAddresses)].filter(
    (address) =>
      address && address !== '0x0000000000000000000000000000000000000000'
  ) as string[];

  const calls = account
    ? tokensFiltered?.map((address) => ({
        contract: new Contract(address, ERC20__factory.abi) as ERC20,
        method: 'balanceOf',
        args: [account],
      }))
    : [];

  const results = useCalls(calls) ?? [];

  results.forEach((result, i) => {
    if (result && result.error) {
      console.error(
        `Error while fetching token balances for ${calls[i]?.contract?.address}: ${result.error.message}`
      );
    }
  });

  return results.map((result, i) => ({
    amount: result?.value?.[0] || BigNumber.from(0),
    address: calls[i].contract?.address,
  }));
}
