import {
  Config,
  useUpdateConfig,
  // Chains
  BSC,
  Mainnet,
  Polygon,
  Mumbai,
  Hardhat,
  Goerli,
  Arbitrum,
  Base,
} from '@usedapp/core';
// Config
import { CONFIG } from './appConfig';
import { getDefaultProvider } from 'ethers';

const {
  DEFAULT_CHAIN,
  PROVIDER_ETHEREUM,
  PROVIDER_ARBITRUM,
  PROVIDER_POLYGON,
  PROVIDER_BSC,
  PROVIDER_BASE,
  // Testnet
  PROVIDER_MUMBAI,
  PROVIDER_GOERLI,
  PROVIDER_HARDHAT,
  ALLOW_FORK,
} = CONFIG;

export const configDappProvider: Config = {
  readOnlyChainId: DEFAULT_CHAIN,
  multicallVersion: 2,
  multicallAddresses: {
    [Mainnet.chainId]: '0xcA11bde05977b3631167028862bE2a173976CA11',
    [Arbitrum.chainId]: '0xcA11bde05977b3631167028862bE2a173976CA11',
    [Polygon.chainId]: '0xcA11bde05977b3631167028862bE2a173976CA11',
    [BSC.chainId]: '0xcA11bde05977b3631167028862bE2a173976CA11',
    [Base.chainId]: '0xcA11bde05977b3631167028862bE2a173976CA11',
    // Testnet
    // [Mumbai.chainId]: '0xcA11bde05977b3631167028862bE2a173976CA11',
    // [Goerli.chainId]: '0xcA11bde05977b3631167028862bE2a173976CA11',
  },
  readOnlyUrls: {
    [Mainnet.chainId]: PROVIDER_ETHEREUM,
    [Arbitrum.chainId]: PROVIDER_ARBITRUM,
    [Polygon.chainId]: PROVIDER_POLYGON,
    [Base.chainId]: PROVIDER_BASE,
    [BSC.chainId]: PROVIDER_BSC,
    // Testnet
    // [Mumbai.chainId]: PROVIDER_MUMBAI,
    // [Goerli.chainId]: PROVIDER_GOERLI,
  },
  networks: [
    Mainnet,
    Arbitrum,
    Polygon,
    Base,
    BSC,
    // Testnet
    // Mumbai,
    // Goerli,
  ],
  autoConnect: true,
  refresh: 'never',
  pollingInterval: 600000,
};

if (
  ALLOW_FORK &&
  configDappProvider.multicallAddresses &&
  configDappProvider.readOnlyUrls &&
  configDappProvider.networks
) {
  configDappProvider.multicallAddresses = {
    ...configDappProvider.multicallAddresses,
    // Hardhat possible chainIds
    [Hardhat.chainId]: '0xcA11bde05977b3631167028862bE2a173976CA11',
    31337: '0xcA11bde05977b3631167028862bE2a173976CA11',
    1337: '0xcA11bde05977b3631167028862bE2a173976CA11',
  };
  configDappProvider.readOnlyUrls[Hardhat.chainId] = PROVIDER_HARDHAT;
  configDappProvider.networks.push(Hardhat);
}
