import { BigNumber } from 'ethers';
import { useAppDataContext } from '../../hooks/useAppDataProvider';
// Wrapper
import { TxButtonWrapper, TxButtonWrapperProps } from './TxButtonWrapper';
// Button configs
import {
  ParamsOpenPosition,
  configOpenPosition,
  ParamsAddLiquidity,
  configAddLiquidity,
  ParamsTakeInterests,
  configTakeInterests,
  ParamsCommitRemoveLiquidity,
  configCommitRemoveLiquidity,
  ParamsUncommitRemoveLiquidity,
  configUncommitRemoveLiquidity,
  ParamsRemoveLiquidity,
  configRemoveLiquidity,
  //
  ParamsTransfer,
  configTransfer,
  ParamsOpenCover,
  configOpenCover,
  ParamsUpdateCover,
  configUpdateCover,
  ParamsUpdateCoverClose,
  configUpdateCoverClose,
} from '../../hooks/contract';

type TxButtonProps<T> = Omit<TxButtonWrapperProps, 'buttonConfig'> & {
  params: T;
};

type TxTokenButtonProps<T> = Omit<TxButtonWrapperProps, 'buttonConfig'> & {
  params: T;
  tokenAddress: string;
};

export const TransferTx = (props: TxTokenButtonProps<ParamsTransfer>) => {
  return (
    <TxButtonWrapper
      {...props}
      buttonConfig={{
        ...configTransfer,
        makeInstance: () =>
          configTransfer.makeInstance(props.tokenAddress),
      }}
    />
  );
};

export const OpenPositionTx = (
  props: TxButtonProps<ParamsOpenPosition>
) => {
  return <TxButtonWrapper {...props} buttonConfig={configOpenPosition} />;
};

export const AddLiquidityTx = (
  props: TxButtonProps<ParamsAddLiquidity>
) => {
  return <TxButtonWrapper {...props} buttonConfig={configAddLiquidity} />;
};

export const TakeInterestsTx = (
  props: TxButtonProps<ParamsTakeInterests>
) => {
  return <TxButtonWrapper {...props} buttonConfig={configTakeInterests} />;
};

export const CommitRemoveLiquidityTx = (
  props: TxButtonProps<ParamsCommitRemoveLiquidity>
) => {
  return (
    <TxButtonWrapper
      {...props}
      buttonConfig={configCommitRemoveLiquidity}
    />
  );
};

export const UncommitRemoveLiquidityTx = (
  props: TxButtonProps<ParamsUncommitRemoveLiquidity>
) => {
  return (
    <TxButtonWrapper
      {...props}
      buttonConfig={configUncommitRemoveLiquidity}
    />
  );
};

export const RemoveLiquidityTx = (
  props: TxButtonProps<ParamsRemoveLiquidity>
) => {
  return (
    <TxButtonWrapper {...props} buttonConfig={configRemoveLiquidity} />
  );
};

export const OpenCoverTx = (props: TxButtonProps<ParamsOpenCover>) => {
  return <TxButtonWrapper {...props} buttonConfig={configOpenCover} />;
};

export const UpdateCoverTx = (props: TxButtonProps<ParamsUpdateCover>) => {
  return <TxButtonWrapper {...props} buttonConfig={configUpdateCover} />;
};

export const UpdateCoverCloseTx = (
  props: TxButtonProps<ParamsUpdateCoverClose>
) => {
  return (
    <TxButtonWrapper {...props} buttonConfig={configUpdateCoverClose} />
  );
};
