import { removeUrlTrailingSlash } from './functions/utils/formatting';
import { networkConfigs } from './data/networksConfig';

type Config = {
  RATES_DAILY_POINTS: number;
  EVIDENCE_REGISTER_WINDOW: number;
  PROVIDER_ARBITRUM: string;
  PROVIDER_ETHEREUM: string;
  PROVIDER_BASE: string;
  PROVIDER_POLYGON: string;
  PROVIDER_BSC: string;
  //
  PROVIDER_GOERLI: string;
  PROVIDER_MUMBAI: string;
  PROVIDER_HARDHAT: string;
  DEFAULT_CHAIN: number;
  DEV_MODE: boolean;
  MOCK_DATA: boolean;
  MOCK_API: boolean;
  ALLOW_FORK: boolean;
  INCLUDE_TESTNETS: boolean;
  API_ENDPOINT: string;
  IPFS_ENDPOINT: string;
};

export const CONFIG: Config = {
  // @dev This is the number of data points observed by the api each day
  RATES_DAILY_POINTS: 6,
  // @dev This is the window for the user to upload evidence on-chain
  EVIDENCE_REGISTER_WINDOW: 24 * 60 * 60 * 1000,
  //
  PROVIDER_ETHEREUM:
    process.env.REACT_APP_PROVIDER_ETHEREUM ??
    networkConfigs[1].publicJsonRPCUrl[0],
  PROVIDER_ARBITRUM:
    process.env.REACT_APP_PROVIDER_ARBITRUM ??
    networkConfigs[42161].publicJsonRPCUrl[0],
  PROVIDER_POLYGON:
    process.env.REACT_APP_PROVIDER_POLYGON ??
    networkConfigs[137].publicJsonRPCUrl[0],
  PROVIDER_BSC:
    process.env.REACT_APP_PROVIDER_BSC ??
    networkConfigs[56].publicJsonRPCUrl[0],
  PROVIDER_BASE:
    process.env.REACT_APP_PROVIDER_BASE ??
    networkConfigs[8453].publicJsonRPCUrl[0],
  //
  PROVIDER_GOERLI: process.env.REACT_APP_PROVIDER_GOERLI ?? '',
  PROVIDER_MUMBAI: process.env.REACT_APP_PROVIDER_MUMBAI ?? '',
  PROVIDER_HARDHAT: process.env.REACT_APP_PROVIDER_HARDHAT ?? '',

  DEFAULT_CHAIN: parseInt(process.env.REACT_APP_DEFAULT_CHAIN ?? '0'),
  // Inject mock data to simulate live app without connecting
  DEV_MODE: process.env.REACT_APP_DEV_MODE === 'true',
  MOCK_DATA: process.env.REACT_APP_MOCK_DATA === 'true',
  MOCK_API: process.env.REACT_APP_MOCK_API === 'true',
  // Allow using hardhat forked chains
  ALLOW_FORK: process.env.REACT_APP_ALLOW_FORK === 'true',
  INCLUDE_TESTNETS: process.env.REACT_APP_INCLUDE_TESTNETS === 'true',

  API_ENDPOINT: removeUrlTrailingSlash(process.env.REACT_APP_API_ENDPOINT),
  IPFS_ENDPOINT: removeUrlTrailingSlash(
    process.env.REACT_APP_IPFS_ENDPOINT
  ),
};
