import { providers as ethersProviders } from 'ethers';
import { ChainId, ChainIdToNetwork } from '../../data/chainId';
import { CONFIG } from '../../appConfig';
import {
  CustomMarket,
  MarketDataType,
  marketsData as _marketsData,
} from '../../data/marketsConfig';

import {
  BaseNetworkConfig,
  ExplorerLinkBuilderConfig,
  ExplorerLinkBuilderProps,
  NetworkConfig,
  networkConfigs as _networkConfigs,
} from '../../data/networksConfig';

/**
 * Generates network configs based on networkConfigs & fork settings.
 * Forks will have a rpcOnly clone of their underlying base network config.
 */
export const networkConfigs = Object.keys(_networkConfigs).reduce(
  (acc, value) => {
    acc[value] = _networkConfigs[value];
    return acc;
  },
  {} as { [key: string]: BaseNetworkConfig }
);

/**
 * Fetches network configs based on networkConfigs & fork settings.
 */
export function getNetworkConfigs(): BaseNetworkConfig[] {
  return Object.values(networkConfigs)
    .reduce((acc: BaseNetworkConfig[], networkConfig) => {
      if (CONFIG.INCLUDE_TESTNETS || !networkConfig.isTestnet) {
        acc.push(networkConfig);
      }
      return acc;
    }, [])
    .sort((a, b) => (a.order || 0) - (b.order || 0));
}

/**
 * Generates network configs based on marketsData & fork settings.
 * Fork markets are generated for all markets on the underlying base chain.
 */
export const marketsData = Object.keys(_marketsData).reduce(
  (acc, value) => {
    acc[value] = _marketsData[value as keyof typeof CustomMarket];
    return acc;
  },
  {} as { [key: string]: MarketDataType }
);

export function getDefaultChainId() {
  return marketsData[availableMarkets[0]].chainId;
}

export function getSupportedChainIds(): number[] {
  return Array.from(
    Object.keys(marketsData).reduce(
      (acc, value) =>
        acc.add(marketsData[value as keyof typeof CustomMarket].chainId),
      new Set<number>()
    )
  );
}

/**
 * selectable markets (markets in a available network + forks when enabled)
 */
export const availableMarkets = Object.keys(marketsData).filter((key) =>
  getSupportedChainIds().includes(
    marketsData[key as keyof typeof CustomMarket].chainId
  )
) as CustomMarket[];

export const chainIdToMarket = (chainId: number | undefined) => {
  const foundMarket = Object.keys(marketsData).find(
    (key) =>
      marketsData[key as keyof typeof CustomMarket].chainId === chainId
  );
  if (foundMarket !== undefined) {
    return foundMarket as CustomMarket;
  }
};

const linkBuilder = ({
  baseUrl,
  addressPrefix = 'address',
  txPrefix = 'tx',
}: ExplorerLinkBuilderConfig) => {
  return ({ tx, address }: ExplorerLinkBuilderProps): string => {
    if (tx) {
      return `${baseUrl}/${txPrefix}/${tx}`;
    }
    if (address) {
      return `${baseUrl}/${addressPrefix}/${address}`;
    }
    return baseUrl;
  };
};

const blockCalculator = (blockTime: number) => {
  return (secs: number): number => {
    const secsToMs = secs * 1000;
    if (blockTime === 0 || secsToMs < blockTime) return 1;
    return Math.ceil(secsToMs / blockTime);
  };
};

export function getNetworkConfig(
  chainId: ChainId | undefined
): NetworkConfig {
  // usedapp 'blocks' unconfigured chains
  if (!chainId) {
    return {
      name: 'Unsupported chain',
      isCompatibleNetwork: false,
      secondsToBlocks: (secs: number) => undefined,
    } as unknown as NetworkConfig;
  }

  const config = networkConfigs[chainId];
  if (!config) {
    // this case can only ever occure when a wallet is connected with a unknown chainId which will not allow interaction
    const name = ChainIdToNetwork[chainId]
      ? `${ChainIdToNetwork[chainId][0].toUpperCase()}${ChainIdToNetwork[
          chainId
        ].slice(1)}`
      : undefined;
    return {
      name: name || `Unknown chainId: ${chainId}`,
      isCompatibleNetwork: false,
      secondsToBlocks: (secs: number) => undefined,
    } as unknown as NetworkConfig;
  }
  return {
    ...config,
    explorerLinkBuilder: linkBuilder({ baseUrl: config.explorerLink }),
    secondsToBlocks: blockCalculator(config.blockTime),
  };
}

const providers: { [network: string]: ethersProviders.Provider } = {};

/**
 * Created a fallback rpc provider in which providers are prioritized from top to bottom.
 * @param chainId
 * @returns provider or fallbackprovider in case multiple rpcs are configured
 */
export const getProvider = (
  chainId: ChainId | undefined
): ethersProviders.Provider | undefined => {
  if (!chainId) return;
  if (!providers[chainId]) {
    const config = getNetworkConfig(chainId);
    const chainProviders: ethersProviders.FallbackProviderConfig[] = [];
    if (config?.publicJsonRPCUrl?.length) {
      config.publicJsonRPCUrl.map((rpc, ix) =>
        chainProviders.push({
          provider: new ethersProviders.StaticJsonRpcProvider(
            rpc,
            chainId
          ),
          priority: ix + 1,
        })
      );
    }
    if (!chainProviders.length) {
      console.log(`WARN - ${chainId} has no jsonRPCUrl configured`);
      return;
    }
    if (chainProviders.length === 1) {
      providers[chainId] = chainProviders[0].provider;
    } else {
      providers[chainId] = new ethersProviders.FallbackProvider(
        chainProviders,
        1
      );
    }
  }
  return providers[chainId];
};

// reexport so we can forbid config import
export { CustomMarket };
export type { MarketDataType, NetworkConfig, ChainId };
