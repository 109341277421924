import { BigNumber } from 'ethers';
import { useCall } from '@usedapp/core';
import { useProtocolDataContext } from '../../../useProtocolDataProvider';

// Value that is returned if the hook encounters an error
const defaultValue = BigNumber.from(0);

export const useTotalSupply = (tokenName: 'ATEN' | 'USDT') => {
  const { chainContracts } = useProtocolDataContext();

  const contract = chainContracts?.[tokenName];

  const { value, error } =
    useCall(
      contract && {
        contract: contract,
        method: 'totalSupply', // Method to be called
        args: [], // Method arguments
      }
    ) ?? {};
  if (error) {
    console.error(error.message);
    return defaultValue;
  }
  if (value?.[0]) {
    return value?.[0];
  }
  return defaultValue;
};
