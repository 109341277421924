import { Typography } from 'antd';
import { Button } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import { ethers } from 'ethers';
import { useState, useEffect } from 'react';
// Components
import { TokenInput } from '../TokenInput';
// import { ClaimInitiateButton } from '../contracts';
// Hooks
import { useMetaEvidence } from '../../hooks/api';
// Context
import { useAppDataContext } from '../../hooks/useAppDataProvider';
// Type
import { ClaimData, DatabaseMetaEvidenceEntry } from '../../types';

export function SectionCreateClaim({
  coverId,
}: {
  coverId: string | undefined;
}) {
  const {
    findCoverById,
    findClaimsByCoverId,
    arbitrationCost,
    collateralAmount,
    useLatestCoverClaimId,
  } = useAppDataContext();
  const navigate = useNavigate();

  const userCover = findCoverById(coverId);
  const coverClaims = findClaimsByCoverId(coverId);

  const [metaEvidence, setMetaEvidence] =
    useState<DatabaseMetaEvidenceEntry>();
  const fetchMetaEvidence = useMetaEvidence(coverId);
  if (!metaEvidence && fetchMetaEvidence)
    setMetaEvidence(fetchMetaEvidence);

  const latestCoverClaim = coverClaims.reduce(
    (acc: ClaimData | undefined, claim) =>
      !acc || acc.creationDate < claim.creationDate ? claim : acc,
    undefined
  );

  const hasOngoingClaim =
    coverClaims.length != 0 &&
    latestCoverClaim?.status !== 'RejectedWithDispute' &&
    latestCoverClaim?.status !== 'CompensatedAfterAcceptation';

  const arbitrationAndCollateralAmount =
    arbitrationCost.add(collateralAmount);

  const [amountClaimed, setAmountClaimed] = useState('0');
  const amountInputValue =
    hasOngoingClaim && latestCoverClaim?.amountClaimed
      ? ethers.utils.formatUnits(latestCoverClaim.amountClaimed, 6)
      : amountClaimed;

  // For redirect after successful claim creation
  const latestClaimId = useLatestCoverClaimId(coverId);
  const [creationSuccess, setCreationSuccess] = useState(false);

  if (creationSuccess) {
    navigate(`/add-evidence/${latestClaimId}`);
  }

  // Choose button do display at the bottom of the section
  const chooseButton = () => {
    if (
      (hasOngoingClaim && latestCoverClaim?.status === 'Initiated') ||
      creationSuccess
    )
      return (
        <Link
          to={`/add-evidence/${latestClaimId}`}
          className="mt-8 mb-4 self-center"
        >
          <Button type="primary" className="min-w-[220px]">
            Add Evidence
          </Button>
        </Link>
      );
    else if (hasOngoingClaim) {
      return (
        <Link
          to={`/claim/${latestClaimId}`}
          className="mt-8 mb-4 self-center"
        >
          <Button type="primary" className="min-w-[220px]">
            View Claim
          </Button>
        </Link>
      );
    } else {
      return (
        // <ClaimInitiateButton
        //   params={{
        //     value: arbitrationAndCollateralAmount,
        //     coverId,
        //     amountClaimed,
        //     fileURI: metaEvidence?.fileURI,
        //     ipfsUriHashSignature: metaEvidence?.ipfsUriHashSignature,
        //   }}
        //   disabled={!userCover || !metaEvidence}
        //   className="self-center w-[220px] mb-2"
        //   onSuccess={() => setCreationSuccess(true)}
        // />
        <button>CLAIM</button>
      );
    }
  };

  return (
    <div className="flex flex-col items-center p-10 pb-0">
      {/*
       * Create Claim
       */}
      <Typography.Title className="flex items-center" level={3}>
        Create the Claim
      </Typography.Title>

      <p className="my-3">
        Before proceeding to create your claim on-chain be sure to gather
        any evidence you think might be necessary to illustrate the
        legitimacy of your claim against potential challengers.
      </p>
      <p className="my-3">
        You may add evidence at any point during the process but providing
        conclusive evidence when your claim is create can help avoid
        disputes and ensure your claim is processed faster.
      </p>
      <p className="my-3">
        To create your claim you will also be required to deposit the
        arbitration cost and the dispute collateral. When your claim is
        validated you will receive a full refund of these amounts alongside
        your insurance payout.
      </p>

      <div className="self-center mt-4">
        <p className="text-lg font-bold mb-2">Amount Claimed</p>
        <TokenInput
          token="USDT"
          value={amountInputValue}
          maxValue={userCover?.amountCovered}
          onChange={setAmountClaimed}
          disabled={!userCover || hasOngoingClaim}
          className="self-center w-[300px]"
        />
      </div>

      {chooseButton()}
    </div>
  );
}
