import { useEffect } from 'react';
import { useAtom } from 'jotai';
import { Button, Row, Space } from 'antd';
import { Link, Outlet, useLocation } from 'react-router-dom';
// Hooks
import { usePageTitle } from '../hooks/usePageTitle';
// Store
import { dashboardPageAtom } from '../functions/storage';
// Components
import { HeaderMarket } from '../components/layout/HeaderMarket';

export function Dashboard() {
  usePageTitle('Dashboard');
  const location = useLocation();
  const [dashboardPage, setDashboardPage] = useAtom(dashboardPageAtom);

  useEffect(() => {
    // Set global state as path else user default
    setDashboardPage(location.pathname.slice(11) || 'policies');
  }, []);

  // Menu items
  const navItems = [
    { label: 'My Positions', key: 'positions', path: 'positions' },
    { label: 'My Covers', key: 'covers', path: 'covers' },
    { label: 'My Staking', key: 'staking', path: 'staking' },
    { label: 'My Claims', key: 'claims', path: 'claims' },
  ];

  return (
    <>
      <HeaderMarket title={'Dashboard'} />

      <Space className="flex items-center justify-center pt-4 mb-6">
        {navItems.map((el, i) => {
          const isCurrent = dashboardPage === el.key;
          return (
            <Link key={i} to={`${el.path}`}>
              <Button
                type="default"
                className={`dashboard-btn min-w-[150px] ${
                  isCurrent ? 'theme-light-bg theme-primary' : ''
                }`}
                disabled={isCurrent}
                onClick={() => setDashboardPage(el.key)}
              >
                {el.label}
              </Button>
            </Link>
          );
        })}
      </Space>

      <Row>
        <Outlet />
      </Row>
    </>
  );
}
