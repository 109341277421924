import { Button, Space, Table } from 'antd';
// types
import {
  SupplyBasketItem,
  BuyBasketItem,
  SupplyBasketTableConfigItem,
  BuyBasketTableConfigItem,
} from '../../types';
import type { ColumnsType } from 'antd/lib/table';

const configToColumn = (
  col: SupplyBasketTableConfigItem | BuyBasketTableConfigItem
) => {
  return {
    ...col,
    dataIndex: col.key,
    render: (_: string, record: any) => {
      if (col.render) return col.render(_, record);
      if (col.cellContent) return <>{col.cellContent(record)}</>;
      else return <p>error</p>;
    },
    defaultSortOrder: col.defaultSortOrder || 'descend',
    // responsive: ['lg'],
  };
};

export const BasketTable = ({
  data,
  config,
  emptyBasket,
  itemsInBasket,
}: {
  data: SupplyBasketItem[] | BuyBasketItem[];
  config: SupplyBasketTableConfigItem[] | BuyBasketTableConfigItem[];
  emptyBasket: any;
  itemsInBasket: number;
}) => {
  const formatedColumns = config.map(
    (col: SupplyBasketTableConfigItem | BuyBasketTableConfigItem) =>
      configToColumn(col)
  );

  return (
    <div className="flex flex-col">
      <Table
        rowKey={'poolId'}
        columns={
          formatedColumns as ColumnsType<SupplyBasketItem | BuyBasketItem>
        }
        dataSource={data}
        size="small"
        pagination={false}
        showSorterTooltip={false}
      />
      {!!itemsInBasket && (
        <Button
          type="text"
          onClick={emptyBasket}
          className="mx-auto mt-4 justify-center"
        >
          Empty Basket
        </Button>
      )}
    </div>
  );
};
