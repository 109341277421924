import { BigNumber } from 'ethers';
import { useAtom } from 'jotai';
import { Layout, Col, Space, Typography } from 'antd';
// Components
import { FormattedNumber } from '../primitives/FormattedNumber';
import { ChainLogo } from '../icons/ChainLogo';
import { HeaderIcon } from '../icons/HeaderIcon';
// Context
import { useAppDataContext } from '../../hooks/useAppDataProvider';
import { useWeb3Context } from '../../hooks/useWeb3ContextProvider';
import { useBasketContext } from '../../hooks/useBasketProvider';
// Types
import { MarketType, FormattedPool } from '../../types';

// @bw move to context
export function getMarketData(allPools: FormattedPool[]) {
  return {
    marketSize: BigNumber.from(0),
    available: BigNumber.from(0),
    active: BigNumber.from(0),
  };
  // return allPools
  //   ? allPools.reduce(
  //       (acc: MarketType, curr: FormattedPool) => ({
  //         marketSize: acc.marketSize
  //           .add(curr.availableCapacity)
  //           .add(curr.insuredCapital),
  //         available: acc.available.add(curr.availableCapacity),
  //         active: acc.active.add(curr.insuredCapital),
  //       }),
  //       {
  //         marketSize: BigNumber.from(0),
  //         available: BigNumber.from(0),
  //         active: BigNumber.from(0),
  //       }
  //     )
  //   : {
  //       marketSize: BigNumber.from(0),
  //       available: BigNumber.from(0),
  //       active: BigNumber.from(0),
  //     };
}

export function HeaderMarket({
  title,
  type,
}: {
  title: string;
  type?: 'buy' | 'supply';
}) {
  const { buyBasket, supplyBasket } = useBasketContext();
  const { athenaPools } = useAppDataContext();
  const { currentNetworkConfig, isConnected, chainId } = useWeb3Context();

  let marketTitle;
  if (currentNetworkConfig.name !== 'Unsupported chain') {
    marketTitle = `${currentNetworkConfig.name} Market`;
  } else if (!isConnected) {
    marketTitle = 'Not connected';
  } else {
    marketTitle = currentNetworkConfig.name;
  }

  const marketData = getMarketData(athenaPools);

  const marketMetrics = [
    {
      icon: 'pie',
      title: 'Total market size',
      value: marketData.marketSize,
      tokenDecimals: 6,
    },
    {
      icon: 'check',
      title: 'Total Available',
      value: marketData.available,
      tokenDecimals: 6,
    },
    {
      icon: 'shield',
      title: 'Total active cover',
      value: marketData.active,
      tokenDecimals: 6,
    },
  ];

  const nbBuyBasket = buyBasket.poolIds.length;
  const nbSupplyBasket = supplyBasket.poolIds.length;

  return (
    <Col span={24} className="content-card flex align-center p-4 mb-12">
      <Space wrap align="center">
        <Typography.Title
          level={3}
          className="ml-2 flex items-center uppercase w-[13rem]"
        >
          <div className="rounded-full theme-secondary-bg w-4 h-4 mr-2" />
          {title}
        </Typography.Title>

        {/* Market Title  */}
        <Typography.Title
          level={4}
          className="theme-secondary shrink-0 flex flex-nowrap break-normal items-center"
        >
          <ChainLogo className="mr-2" chainId={chainId} />
          {marketTitle}
        </Typography.Title>

        {/* Market metrics  */}
        {marketMetrics.map((metric: any, i: number) => (
          <div key={i} className="header-content ml-8">
            <HeaderIcon iconName={metric.icon} />
            <span className="ml-1">
              {metric.title}

              <Typography.Title
                level={5}
                className="mb-0 font-bold text-xl"
              >
                <FormattedNumber
                  value={metric.value}
                  tokenDecimals={metric.tokenDecimals}
                  symbol="USD"
                  size="larger"
                  bold={true}
                  compact
                />
              </Typography.Title>
            </span>
          </div>
        ))}
      </Space>
    </Col>
  );
}
