import { useCall } from '@usedapp/core';
import { BigNumber } from 'ethers';
import { useProtocolDataContext } from '../../../useProtocolDataProvider';

// @bw to delete
// Value that is returned if the hook encounters an error
const defaultValue = BigNumber.from(1);

// Your staking APR according to your supplied capital
export const useAtenPrice = () => {
  const { chainContracts } = useProtocolDataContext();

  return defaultValue;
};
