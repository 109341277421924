// Hooks
import { usePageTitle } from '../../hooks/usePageTitle';
// Components
import { BackButton } from '../../components/BackButton';
import { PageTitle } from '../../components/layout/PageTitle';

export const ErrorPage = () => {
  usePageTitle('Not found | Athena');

  return (
    <div className="flex flex-col items-center">
      <PageTitle
        title="404 - Not found"
        subtitle={['This page could not be found.', '']}
      />

      <BackButton className="mt-8" />
    </div>
  );
};
