import React from 'react';
import { BrowserRouter, Route, Link } from 'react-router-dom';
import type { ColumnsType } from 'antd/lib/table';
import {
  Space,
  Menu,
  Layout,
  Row,
  Col,
  Typography,
  Button,
  Card,
  Table,
  Tag,
} from 'antd';
const { Header, Content, Footer } = Layout;
// Components
import { HeaderMarket } from '../components/layout/HeaderMarket';

export function Governance() {
  return (
    <>
      <HeaderMarket title={'Governance'} />
      <Content className="content-card row content mt-4">
        GOVERNANCE
      </Content>
    </>
  );
}
