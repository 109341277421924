// Hooks
import { useState } from 'react';
import { usePageTitle } from '../../hooks/usePageTitle';
// Components
import { PageTitle } from '../../components/layout/PageTitle';
import { PrivateSaleModal } from '../../components/modal/PrivateSaleModal';
import { BigNumber } from 'ethers';

function checkPassword(password: string | undefined) {
  try {
    return (
      password &&
      BigNumber.from(password).mod('10122600').toString() === '424242'
    );
  } catch (error) {
    return false;
  }
}

export const TokenSalePage = () => {
  usePageTitle('Seed Sale | Athena');

  const [isOpen, setIsOpen] = useState(false);

  const [password, setPassword] = useState<string>();
  const passwordOk = checkPassword(password);

  return (
    <div className="flex flex-col items-center">
      <PageTitle
        title="Seed Sale"
        subtitle={['Welcome to the ATEN seed sale.', '']}
      />

      <div className="flex flex-col items-center w-full theme-secondary-bg-light rounded-sm max-w-[600px]">
        <div className="w-full max-w-md overflow-hidden rounded-sm theme-primary theme-secondary-bg-light p-8 align-middle opacity-100 scale-100">
          <div className="flex flex-col space-y-4">
            <div className="text-sm self-center">
              The seed sale is currently invitation only. Please enter the
              personal invitation code you received to participate.
            </div>

            <div className="mt-1 relative flex-1">
              <input
                className={`w-full rounded-sm border bg-transparent px-2 pb-1 pt-1 text-base focus:bg-black/[.4] ${
                  password && !passwordOk
                    ? 'border-2 border-red-500'
                    : 'border-2 border-white/[.3]'
                }`}
                type="text"
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
                placeholder="Invitation code"
              />
            </div>

            <div className="!mt-1 min-h-6 flex-1 text-xs flex justify-between">
              <span className="theme-error-vlight">
                {password && !passwordOk ? 'Invalid invitation code' : ''}
              </span>
            </div>

            {passwordOk ? (
              <button
                onClick={() => setIsOpen(true)}
                className={`self-center w-[150px] theme-primary rounded-sm flex items-center justify-center py-2 px-4 transition-all duration-300 hover:brightness-150 theme-tertiary-bg`}
              >
                Participate
              </button>
            ) : (
              <button
                disabled
                className={`self-center w-[150px] theme-primary rounded-sm flex items-center justify-center py-2 px-4 transition-all duration-300 theme-disabled-bg`}
              >
                Participate
              </button>
            )}
          </div>
        </div>
      </div>

      <PrivateSaleModal
        isOpen={isOpen}
        closeModal={() => setIsOpen(false)}
      />
    </div>
  );
};
