import { Col } from 'antd';
import { BigNumber } from 'ethers';
import { useParams } from 'react-router-dom';
// Hooks
import { usePageTitle } from '../hooks/usePageTitle';
// Components
import { SectionUploadEvidence } from '../components/claims/SectionUploadEvidence';
import { HeaderPool } from '../components/layout/HeaderPool';
import { Countdown } from '../components/primitives/Countdown';
import { FormatTokenAmount } from '../components/primitives/FormattedNumber';
import { StatLine } from '../components/StatLine';
// Functions
import {
  isClaimAccepted,
  isClaimResolved,
} from '../functions/checkAthenaData';
import { formatTimeToText } from '../functions/utils/formatting';
// Context
import { useAppDataContext } from '../hooks/useAppDataProvider';
import { useWeb3Context } from '../hooks/useWeb3ContextProvider';
// Type
import { ClaimStatus, UserCover } from '../types';

const DAY_IN_MS = 24 * 60 * 60 * 1000;

const statusContent = (isExpired: boolean) =>
  isExpired ? (
    <p className="text-orange-500">Expired</p>
  ) : (
    <p className="text-lime-500">Active</p>
  );

const periodContent = (
  userPolicy: UserCover | undefined,
  isExpired: boolean
) => {
  const { startDate, duration } = userPolicy || {
    startDate: Date.now(),
    duration: 0,
  };
  return `${new Date(startDate).toLocaleDateString()} - ${
    isExpired
      ? new Date(startDate + duration * DAY_IN_MS).toLocaleDateString()
      : 'now'
  }`;
};

function makeStatusTag(claimStatus: ClaimStatus) {
  let statusColor = '';
  if (isClaimAccepted(claimStatus)) {
    statusColor = 'text-lime-500';
  } else if (claimStatus === 'Disputed') {
    statusColor = 'text-orange-500';
  } else if (
    isClaimResolved(claimStatus) &&
    !isClaimAccepted(claimStatus)
  ) {
    statusColor = 'text-red-500';
  } else {
    statusColor = 'theme-secondary';
  }
  return <p className={`${statusColor}`}>{`${claimStatus}`}</p>;
}

export function AddClaimEvidence() {
  usePageTitle(`Add evidence`);
  const { claimId } = useParams();
  const { currentAccount } = useWeb3Context();
  const {
    getPoolNameById,
    findCoverById,
    findClaimById,
    challengeDelay,
    arbitrationCost,
    collateralAmount,
  } = useAppDataContext();

  const userClaim = findClaimById(claimId);
  const userCover = findCoverById(userClaim?.coverId);

  const poolId = userClaim?.poolId || undefined;
  const claimStatus = userClaim?.status || 'RejectedWithDispute';
  const creationDate = userClaim?.creationDate || Date.now();
  const isCoverExpired = userCover?.endDate !== 0;

  const userAddress = userClaim?.userAddress || '';
  const challengerAddress = userClaim?.challenger || '';
  const isCurrentUserClaim =
    userAddress.toLowerCase() === currentAccount.toLowerCase();
  const isChallengerUser =
    challengerAddress.toLowerCase() === currentAccount.toLowerCase();

  const statLineData = [
    {
      title: 'Claim ID',
      content: claimId,
    },
    {
      title: 'Dispute ID',
      content: userClaim?.disputeId || 'N/A',
    },
    {
      title: 'Amount Claimed',
      content: FormatTokenAmount(
        userClaim?.amountClaimed || BigNumber.from(0),
        'USD'
      ),
    },
    {
      title: 'Status',
      content: makeStatusTag(claimStatus),
    },
    {
      title: 'Policy Status',
      content: statusContent(isCoverExpired),
    },
    {
      title: 'Amount Covered',
      content: FormatTokenAmount(
        userCover?.amountCovered || BigNumber.from(0),
        'USD'
      ),
    },
    {
      title: 'Cover Period',
      content: periodContent(userCover, isCoverExpired),
    },
    {
      title: 'Challenge Window',
      content: (
        <Countdown
          eventTimestamp={creationDate}
          delay={challengeDelay - 30_000} // -5min for security
          withDays
          withSeconds
        />
      ),
    },
    {
      title: 'Arbitration Cost',
      content: FormatTokenAmount(arbitrationCost, 'ETH'),
    },
    {
      title: 'Dispute Collateral',
      content: FormatTokenAmount(collateralAmount, 'ETH'),
    },
  ];

  const titleLineData = [
    {
      title: 'Protocol',
      content: getPoolNameById(poolId),
    },
    {
      title: 'Claimant',
      content: `${userAddress} ${isCurrentUserClaim ? '(you)' : ''}`,
    },
    {
      title: 'Challenger',
      content: `${challengerAddress} ${isChallengerUser ? '(you)' : ''}`,
    },
    {
      title: 'Creation Date',
      content: `${formatTimeToText(creationDate)}`,
    },
  ];

  return (
    <>
      <HeaderPool poolId={userCover?.poolId} />

      <StatLine title="Claim" data={statLineData} className="mb-6" />

      <Col span={24} className="content-card p-4">
        {titleLineData.map((el, i) => (
          <div key={i} className="flex">
            <span className="font-bold text-lg theme-secondary">
              {el.title}:
            </span>
            <span className="ml-2 text-lg">{el.content}</span>
          </div>
        ))}

        <hr
          style={{
            marginLeft: -16,
            width: 'calc(100% + 32px)',
            marginTop: 16,
          }}
        />

        <div className="flex flex-col pt-8 px-20 pb-12">
          {/*
           * Add Evidence
           */}
          <SectionUploadEvidence claimId={claimId} />
        </div>
      </Col>
    </>
  );
}
