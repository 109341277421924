import { utils } from 'ethers';
import { Button, Checkbox, Typography } from 'antd';
import { useState } from 'react';
// Components
// import { PositionDepositButton, ApproveUsdtButton } from './contracts';
import {
  FormatTokenAmount,
  FormatPercent,
} from '../components/primitives/FormattedNumber';
// Context
import { useWeb3Context } from '../hooks/useWeb3ContextProvider';
import { useAppDataContext } from '../hooks/useAppDataProvider';
import { useBasketContext } from '../hooks/useBasketProvider';

export const BasketCardSupply = ({
  amountSupplied,
  setDepositAmount,
  hasItems,
}: {
  amountSupplied: string;
  setDepositAmount: (amount: string) => void;
  hasItems: boolean;
}) => {
  const { supplyBasket, emptySupplyBasket } = useBasketContext();
  const { currentAccount } = useWeb3Context();
  const { userUsdtBalance, athenaPools, userAthenaUsdtAllowance } =
    useAppDataContext();
  const [checked, setChecked] = useState(false);

  const bnAmountSupplied = utils.parseUnits(amountSupplied || '0', 6);

  const aprTotal = 0;

  // const aprTotal = athenaPools.reduce((acc, pool) => {
  //   const isItemInBasket = supplyBasket.poolIds.includes(pool.poolId);
  //   if (isItemInBasket) {
  //     return acc + pool.supplyRate;
  //   } else {
  //     return acc;
  //   }
  // }, 0);

  let commitDelay = 0;
  // athenaPools.map((pool) => {
  //   const isItemInBasket = supplyBasket.poolIds.includes(pool.poolId);
  //   if (isItemInBasket && commitDelay < pool.commitDelay) {
  //     commitDelay = pool.commitDelay;
  //   }
  // });

  const commitDelayDays = Math.round(commitDelay / (24 * 60 * 60 * 1000));

  const disabled =
    !currentAccount ||
    !hasItems ||
    !checked ||
    bnAmountSupplied.eq(0) ||
    bnAmountSupplied.gt(userAthenaUsdtAllowance);

  const handleCheckbox = (e: any) => {
    setChecked(e.target.checked);
  };

  const onSuccess = () => {
    emptySupplyBasket();
    setDepositAmount('0');
  };

  return (
    <div className="content-card p-4">
      <Typography.Title className="text-center" level={3}>
        Supply Information
      </Typography.Title>
      <hr
        style={{
          marginLeft: -16,
          width: 'calc(100% + 32px)',
          marginTop: 16,
        }}
      />

      <div className="flex w-full mt-3 mb-1 px-1 justify-between">
        <span>Wallet balance</span>
        <span>{FormatTokenAmount(userUsdtBalance, 'USDT')}</span>
      </div>
      <hr />
      <div className="flex w-full mt-3 mb-1 px-1 justify-between">
        <span>Amount Supplied</span>
        <span>{FormatTokenAmount(bnAmountSupplied, 'USDT')}</span>
      </div>
      <hr />
      <div className="flex w-full mt-3 mb-1 px-1 justify-between">
        <span>Global APR</span>
        <span>{FormatPercent(aprTotal)}</span>
      </div>
      <hr />
      <div className="flex w-full mt-3 mb-1 px-1 justify-between">
        <span>Commit Delay</span>
        <span>{`${commitDelayDays} days`}</span>
      </div>
      <hr />

      <p className="mt-5 mb-2 text-[12px]">Why do I need to approve ?</p>
      {/* <ApproveUsdtButton className="mt-0" /> */}
      <button>APPROVE</button>

      <div className="mt-4">
        <p className="mb-2" color="white">
          Please ensure you read and understand the following documents:
        </p>
        <p className="theme-secondary">
          <a href="#" target="_blank">
            Smart Contract Vulnerability Cover
          </a>
        </p>
        <p className="theme-secondary mt-1">
          <a href="#" target="_blank">
            Claim Process
          </a>
        </p>
      </div>

      <Checkbox className="mt-4" onChange={handleCheckbox}>
        <p className="text-[14px]">I agree to the Terms and Conditions</p>
      </Checkbox>

      {/* <PositionDepositButton
        disabled={disabled}
        params={{
          amount: amountSupplied,
          poolIds: supplyBasket.poolIds,
        }}
        onSuccess={() => onSuccess()}
      /> */}
      <button>DEPOSIT</button>
    </div>
  );
};
