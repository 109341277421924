import React, {
  useContext,
  PropsWithChildren,
  useState,
  useEffect,
} from 'react';
import { BigNumber } from 'ethers';
import { useProtocolDataContext } from './useProtocolDataProvider';
import { useWeb3Context } from './useWeb3ContextProvider';
import {
  useBalance,
  useAllowance,
  useGetAllProtocols,
  useLinearClaimsView,
  usePoliciesByAccount,
  usePositionsByAccount,
  useClaimsByAccount,
  useGetPositionFeeLevels,
  useGetStakingRewardsLevels,
  useGetRefundPositionsByAccount,
  useGetUserStakingPosition,
  useChallengeDelay,
  useArbitrationCost,
  useCollateralAmount,
  useAtenPrice,
  useTotalSupply,
  useRefundRate,
  useBasePenaltyRate,
  useDurationPenaltyRate,
  useShortCoverDuration,
  useGetClaimEvidence,
  useGetClaimCounterEvidence,
  usePoolAgreement,
  useLatestCoverClaimId,
  //
  useTokenInfo,
} from './contract';
// Helpers
import {
  userAtenStakingApr,
  userAtenStakingLevel,
  userCoverSupplyBonus,
  userCoverSupplyLevel,
} from '../functions/utils/stakingBonus';
// Types
import {
  FormattedPool,
  FormattedCover,
  FormattedPosition,
  UserCover,
  UserExpiredPolicy,
  UserPosition,
  ClaimData,
  UserPolicyRefund,
  GeneralStakingData,
  FeeLevels,
  StakingLevels,
} from '../types';
import { CONFIG } from '../appConfig';
// Mock data
import { getMockPools } from '../data/mockData/pools';
import { mockCovers } from '../data/mockData/covers';
import { mockPositions } from '../data/mockData/positions';

export type AppDataContextType = {
  loading: boolean;
  // Token
  findTokenInfo: (address: string | undefined) =>
    | {
        name: string;
        symbol: string;
        decimals: number;
        address: string;
      }
    | undefined;
  userUsdtBalance: BigNumber;
  userAtenBalance: BigNumber;
  userAthenaUsdtAllowance: BigNumber;
  userAthenaAtenAllowance: BigNumber;
  atenTotalSupply: BigNumber;
  atenPrice: BigNumber;
  // Athena
  athenaPools: FormattedPool[];
  userPositionsNew: FormattedPosition[];
  userCovers: FormattedCover[];
  athenaClaims: ClaimData[];
  challengeDelay: number;
  arbitrationCost: BigNumber;
  collateralAmount: BigNumber;
  amountStakedAtenGp: BigNumber;
  refundRate: number;
  refundBasePenaltyRate: number;
  refundDurationPenaltyRate: number;
  refundShortCoverDuration: number;
  athenaFeeLevels: FeeLevels;
  athenaStakingLevels: StakingLevels;
  // User
  userPolicies: UserCover[];
  userExpiredPolicies: UserCover[];
  userPositions: UserPosition[];
  userClaims: ClaimData[];
  userCoverRefunds: UserPolicyRefund[];
  userGeneralStaking: GeneralStakingData;
  // Computed
  userSuppliedCapital: BigNumber;
  userUsedSuppliedCapital: BigNumber;
  // Helper
  getPoolNameById: (poolId?: string) => string | undefined;
  findPoolById: (poolId?: string) => FormattedPool | undefined;
  findCoverById: (coverId?: string) => UserCover | undefined;
  findCoverRefundByCoverId: (
    coverId?: string
  ) => UserPolicyRefund | undefined;
  findClaimById: (claimId?: string) => ClaimData | undefined;
  findUserClaimById: (claimId?: string) => ClaimData | undefined;
  findClaimsByCoverId: (coverId?: string) => ClaimData[];
  findPositionById: (positionId?: string) => UserPosition | undefined;
  // Levels
  getAtenStakingLevel: (amountSuppliedUsd: BigNumber) => number;
  getCoverSupplyLevel: (amountStakedAten: BigNumber) => number;
  getAtenStakingApr: (amountSuppliedUsd: BigNumber) => number;
  getCoverSupplyBonus: (amountStakedAten: BigNumber) => number;
  // Calc
  calcPositionApr: (position?: UserPosition) => number;
  calcPositionUsageRate: (position?: UserPosition) => number;
  calcCoverDailyCost: (policy?: UserCover) => BigNumber;
  // Hooks
  useGetClaimEvidence: (claimId?: string) => string[];
  useGetClaimCounterEvidence: (claimId?: string) => string[];
  usePoolAgreement: (poolId: string | undefined) => string;
  useLatestCoverClaimId: (coverId: string | undefined) => number | '';
};

const AppDataContext = React.createContext<AppDataContextType>(
  {} as AppDataContextType
);

/**
 * This is the only provider you'll ever need.
 * It fetches reserves /incentives & walletbalances & keeps them updated.
 */
export function AppDataProvider({ children }: PropsWithChildren<{}>) {
  const { currentAccount, chainId } = useWeb3Context();
  const { currentMarketData } = useProtocolDataContext();

  //============ Usedapp Hooks ============//
  // const athenaPools = getMockPools(chainId);
  const athenaPools = [] as FormattedPool[];
  const tokenInfo = useTokenInfo(
    athenaPools
      .map((pool) => [
        pool.paymentAsset,
        pool.underlyingAsset,
        pool.wrappedAsset,
      ])
      ?.flat()
  );

  function findTokenInfo(address: string | undefined) {
    if (!address) return;

    return tokenInfo?.find(
      (token) => token?.address?.toLowerCase() === address?.toLowerCase()
    );
  }

  // const userPositionsNew = mockPositions;
  // const userCovers = mockCovers;

  const userPositionsNew = [] as FormattedPosition[];
  const userCovers = [] as FormattedCover[];

  // Tokens
  ////////////////////
  const userUsdtBalance = BigNumber.from(0);
  const userAtenBalance = BigNumber.from(0);
  const userAthenaUsdtAllowance = BigNumber.from(0);
  const userAthenaAtenAllowance = BigNumber.from(0);
  const atenTotalSupply = BigNumber.from(0);
  // const userUsdtBalance = useBalance('USDT', currentAccount);
  // const userAtenBalance = useBalance('ATEN', currentAccount);
  // const userAthenaUsdtAllowance = useAllowance(
  //   'USDT',
  //   currentAccount,
  //   currentMarketData?.addresses?.ATHENA
  // );
  // const userAthenaAtenAllowance = useAllowance(
  //   'ATEN',
  //   currentAccount,
  //   currentMarketData?.addresses?.ATHENA
  // );
  // const atenTotalSupply = useTotalSupply('ATEN');

  // @dev The amount of ATEN in wei per 1$
  // ex: atenPrice = 100_000 -> 10 ATEN = 1 USDT
  const atenPrice = BigNumber.from(0);
  // const atenPrice = useAtenPrice();

  // Athena Data
  //////////////////////////////
  // const athenaPools = MOCK_DATA ? mockPools : useGetAllProtocols();

  const athenaClaims = [] as ClaimData[];
  // const athenaClaims = useLinearClaimsView();

  const athenaFeeLevels = useGetPositionFeeLevels();
  const athenaStakingLevels = useGetStakingRewardsLevels();

  // const challengeDelay = useChallengeDelay();
  // const arbitrationCost = useArbitrationCost();
  // const collateralAmount = useCollateralAmount();
  const challengeDelay = 1;
  const arbitrationCost = BigNumber.from(0);
  const collateralAmount = BigNumber.from(0);

  const amountStakedAtenGp = BigNumber.from(0);
  // const amountStakedAtenGp = useBalance(
  //   'ATEN',
  //   currentMarketData?.addresses?.STAKING_GP
  // );

  const refundRate = 0;
  const refundBasePenaltyRate = 0;
  const refundDurationPenaltyRate = 0;
  const refundShortCoverDuration = 0;
  // const refundRate = useRefundRate();
  // const refundBasePenaltyRate = useBasePenaltyRate();
  // const refundDurationPenaltyRate = useDurationPenaltyRate();
  // const refundShortCoverDuration = useShortCoverDuration();

  // User Data
  //////////////////////////////
  const rawCovers = usePoliciesByAccount(currentAccount);
  const userPolicies = rawCovers.filter(
    (policy) => policy.endDate === 0 && !policy.premiumLeft.isZero()
  );
  const userExpiredPolicies = rawCovers.filter(
    (policy) => 0 < policy.endDate || policy.premiumLeft.isZero()
  );
  const userPositions = usePositionsByAccount(currentAccount);
  const userClaims = useClaimsByAccount(currentAccount);

  const userCoverRefunds = useGetRefundPositionsByAccount(currentAccount);
  const userGeneralStaking = useGetUserStakingPosition(currentAccount);

  // Helpers
  //////////////////////////////
  const getPoolNameById = (poolId: string | undefined) => {
    if (!poolId) return;
    if (!athenaPools?.length) return 'Unknown Pool';

    return (
      athenaPools.find((pool) => pool.poolId === Number(poolId))?.name ||
      'Unknown Pool'
    );
  };

  const findPoolById = (poolId?: string) => {
    if (!poolId) return;
    return athenaPools.find((el) => el.poolId === Number(poolId));
  };
  const findClaimById = (claimId?: string) => {
    if (!claimId) return;
    return athenaClaims.find((el) => el.claimId === claimId);
  };
  const findClaimsByCoverId = (coverId?: string) => {
    if (!coverId) return [];
    return athenaClaims.filter((el) => el.coverId === coverId);
  };
  const findCoverById = (coverId?: string) => {
    if (!coverId) return;
    return rawCovers.find((el) => el.coverId === coverId);
  };
  const findCoverRefundByCoverId = (coverId?: string) => {
    if (!coverId) return;
    return userCoverRefunds.find((el) => el.coverId === coverId);
  };
  const findUserClaimById = (claimId?: string) => {
    if (!claimId) return;
    return userClaims.find((el) => el.claimId === claimId);
  };
  const findPositionById = (positionId?: string) => {
    if (!positionId) return;
    return userPositions.find((el) => el.positionId === positionId);
  };

  const calcPositionApr = (position?: UserPosition) => {
    return 0;
    // if (!position) return 0;
    // return position.poolIds.reduce((accUsage, poolId) => {
    //   const pool = findPoolById(poolId);

    //   return accUsage + (pool?.supplyRate || 0);
    // }, 0);
  };

  const calcPositionUsageRate = (position?: UserPosition) => {
    return 0;
    if (!position) return 0;

    // return (
    //   position.poolIds.reduce((accUsage, poolId) => {
    //     const pool = findPoolById(poolId);

    //     return accUsage + (pool?.utilizationRate || 0);
    //   }, 0) / position.poolIds.length
    // );
  };

  const calcCoverDailyCost = (policy?: UserCover) => {
    return BigNumber.from(0);
    // const pool = findPoolById(policy?.poolId);
    // if (!policy || !pool) return BigNumber.from(0);

    // return policy.amountCovered
    //   .mul(Math.round(pool.premiumRate * 1_000_000))
    //   .div(365 * 1_000_000);
  };

  // Levels
  //////////////////////////////
  function getAtenStakingLevel(amountSuppliedUsd: BigNumber): number {
    return userAtenStakingLevel(amountSuppliedUsd, athenaStakingLevels);
  }
  function getAtenStakingApr(amountSuppliedUsd: BigNumber): number {
    return userAtenStakingApr(amountSuppliedUsd, athenaStakingLevels);
  }
  function getCoverSupplyLevel(amountStakedAten: BigNumber): number {
    return userCoverSupplyLevel(amountStakedAten, athenaFeeLevels);
  }
  function getCoverSupplyBonus(amountStakedAten: BigNumber): number {
    return userCoverSupplyBonus(amountStakedAten, athenaFeeLevels);
  }

  // Computed Data
  //////////////////////////////
  const userSuppliedCapital = userPositions.reduce(
    (acc, el) => acc.add(el.amountSupplied),
    BigNumber.from(0)
  );

  const userUsedSuppliedCapital = !athenaPools.length
    ? BigNumber.from(0)
    : userPositions
        .reduce((accPos, position) => {
          const positionUsageRate = calcPositionUsageRate(position);

          const usageRateMulCapital = position.amountSupplied.mul(
            Math.round(positionUsageRate * 1_000_000)
          );

          return accPos.add(usageRateMulCapital);
        }, BigNumber.from(0))
        .div(1_000_000);

  return (
    <AppDataContext.Provider
      value={{
        loading: true,
        // Tokens
        findTokenInfo,
        userUsdtBalance,
        userAtenBalance,
        userAthenaUsdtAllowance,
        userAthenaAtenAllowance,
        atenTotalSupply,
        atenPrice,
        // Athena data
        athenaPools,
        athenaClaims,
        challengeDelay,
        arbitrationCost,
        collateralAmount,
        refundRate,
        refundBasePenaltyRate,
        refundDurationPenaltyRate,
        refundShortCoverDuration,
        athenaFeeLevels,
        athenaStakingLevels,
        // Athena user data
        userPolicies,
        userExpiredPolicies,
        userPositions,
        userClaims,
        // Staking
        userCoverRefunds,
        userGeneralStaking,
        amountStakedAtenGp,
        // Computed data
        userSuppliedCapital,
        userUsedSuppliedCapital,
        // Helpers
        getPoolNameById,
        findPoolById,
        findCoverById,
        findCoverRefundByCoverId,
        findClaimById,
        findUserClaimById,
        findClaimsByCoverId,
        findPositionById,
        // Levels
        getAtenStakingLevel,
        getCoverSupplyLevel,
        getAtenStakingApr,
        getCoverSupplyBonus,
        // Calculations
        calcPositionApr,
        calcPositionUsageRate,
        calcCoverDailyCost,
        // Hooks
        useGetClaimEvidence,
        useGetClaimCounterEvidence,
        usePoolAgreement,
        useLatestCoverClaimId,
        //
        userPositionsNew,
        userCovers,
      }}
    >
      {children}
    </AppDataContext.Provider>
  );
}

export const useAppDataContext = () => useContext(AppDataContext);
