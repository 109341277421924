import { ClaimStatus } from '../types';

// return value <false> means claim is ongoing
export const isClaimResolved = (status: ClaimStatus): boolean =>
  status !== 'Initiated' && status !== 'Disputed';

// return value <false> means claim is ongoing
export const isClaimDisputed = (status: ClaimStatus): boolean =>
  status === 'Disputed';

// return value <false> means claim is rejected or ongoing
export const isClaimAccepted = (status: ClaimStatus): boolean =>
  status === 'Accepted' ||
  status === 'AcceptedWithDispute' ||
  status === 'CompensatedAfterAcceptation';

export const isClaimPayable = (status: ClaimStatus): boolean =>
  status === 'Accepted' || status === 'AcceptedWithDispute';

const claimStatusOrderingValue: { [key in ClaimStatus]: number } = {
  CompensatedAfterAcceptation: 6,
  AcceptedWithDispute: 5,
  Accepted: 4,
  Initiated: 3,
  Disputed: 2,
  RejectedWithDispute: 1,
};

export const getClaimOrderingValue = (status: ClaimStatus): number =>
  claimStatusOrderingValue[status];
