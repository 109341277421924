import { BigNumber } from 'ethers';
import { Button, Col, Row, Tag } from 'antd';
import { Link } from 'react-router-dom';
// Hooks
import { usePageTitle } from '../hooks/usePageTitle';
import { useAppDataContext } from '../hooks/useAppDataProvider';
// Components
import { HeaderMarket } from '../components/layout/HeaderMarket';
import { StatLine } from '../components/StatLine';
import { BaseTable } from '../components/tables/BaseTable';
import { TableLogoWrapper } from '../components/icons/TableLogoWrapper';
import { FormatTokenAmount } from '../components/primitives/FormattedNumber';
// Type
import { ClaimTableConfigItem, ClaimData } from '../types';
// Functions
import { formatTimeToText } from '../functions/utils/formatting';
import { bnSort } from '../functions/utils/formatting';
import {
  getClaimOrderingValue,
  isClaimAccepted,
  isClaimResolved,
  isClaimPayable,
  isClaimDisputed,
} from '../functions/checkAthenaData';

const columnConfigCover = (
  getPoolNameById: Function
): ClaimTableConfigItem[] => [
  {
    title: 'Protocol',
    key: 'protocol',
    width: 300,
    render: (_, record) => (
      <TableLogoWrapper className="ml-2" poolId={record.poolId} />
    ),
    defaultSortOrder: 'descend',
    sorter: (a: ClaimData, b: ClaimData) =>
      getPoolNameById(a.poolId) < getPoolNameById(b.poolId),
  },
  {
    title: 'Creation Date',
    key: 'date',
    cellContent: (record) => `${formatTimeToText(record.creationDate)}`,
    defaultSortOrder: 'descend',
    sorter: (a: ClaimData, b: ClaimData) =>
      a.creationDate - b.creationDate,
  },
  {
    title: 'Amount Claimed',
    key: 'available',
    render: (_, record) => FormatTokenAmount(record.amountClaimed, 'USD'),
    sorter: (a: ClaimData, b: ClaimData) =>
      bnSort(a.amountClaimed, b.amountClaimed),
  },
  {
    title: 'Status',
    key: 'status',
    cellContent: (record) => record.status,
    sorter: (a: ClaimData, b: ClaimData) =>
      getClaimOrderingValue(a.status) - getClaimOrderingValue(b.status),
  },
  {
    title: '',
    key: 'action',
    width: 190,
    render: (_, record) => (
      <div className="flex justify-end">
        <Link className="mx-2" to={'/claim/' + record.claimId}>
          <Button type="default">View Claim</Button>
        </Link>
      </div>
    ),
  },
];

export function Claims() {
  usePageTitle('Claims');
  const { getPoolNameById, athenaClaims } = useAppDataContext();

  const ongoingClaims = athenaClaims
    .filter((el) => !isClaimResolved(el.status))
    .sort((a, b) => b.creationDate - a.creationDate);
  const disputedClaims = athenaClaims
    .filter((el) => isClaimDisputed(el.status))
    .sort((a, b) => b.creationDate - a.creationDate);
  const resolvedClaims = athenaClaims
    .filter((el) => isClaimResolved(el.status))
    .sort((a, b) => b.creationDate - a.creationDate);

  const claimedRefunds = ongoingClaims.reduce(
    (sum, claim) => sum.add(claim.amountClaimed),
    BigNumber.from(0)
  );
  const acceptedRefunds = athenaClaims.reduce(
    (sum, claim) =>
      isClaimPayable(claim.status) ? sum.add(claim.amountClaimed) : sum,
    BigNumber.from(0)
  );
  const paidRefunds = athenaClaims
    .reduce(
      (sum, claim) =>
        isClaimAccepted(claim.status) ? sum.add(claim.amountClaimed) : sum,
      BigNumber.from(0)
    )
    .sub(acceptedRefunds);

  const statLineData = [
    {
      title: 'Claimed Refunds',
      content: FormatTokenAmount(claimedRefunds, 'USD'),
    },
    {
      title: 'Accepted Refunds',
      content: FormatTokenAmount(acceptedRefunds, 'USD'),
    },
    {
      title: 'Paid Refunds',
      content: FormatTokenAmount(paidRefunds, 'USD'),
    },
    {
      title: 'Ongoing Claims',
      content: ongoingClaims.length,
    },
    {
      title: 'Disputed Claims',
      content: disputedClaims.length,
    },
    {
      title: 'Resolved Claims',
      content: resolvedClaims.length,
    },
  ];

  const configCover = columnConfigCover(getPoolNameById);
  const makeNavUrl = (record: ClaimData) => `/claim/${record.claimId}`;

  return (
    <>
      <HeaderMarket title={'Claims'} />

      <StatLine
        title="Claims & Disputes"
        data={statLineData}
        className="mb-6"
      />

      <BaseTable
        title="All claims"
        rowKey="claimId"
        config={configCover}
        data={athenaClaims}
        isLoading={false}
        makeNavUrl={makeNavUrl}
      />
    </>
  );
}
