import { Input, Space } from 'antd';
import { BigNumber, utils } from 'ethers';
import {
  FormatTokenAmount,
  FormatPercent,
} from '../primitives/FormattedNumber';
import { TokenInput } from '../TokenInput';
// Context
import { useAppDataContext } from '../../hooks/useAppDataProvider';

const DAY_IN_MS = 24 * 60 * 60 * 1000;

const formatTime = (period: number) =>
  new Date(Date.now() + period * DAY_IN_MS).toLocaleDateString(undefined, {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  });

export const BasketBuyInputCover = ({
  poolId,
  value,
  updateAmount,
}: {
  poolId: string;
  value: string;
  updateAmount: Function;
}) => {
  const { findPoolById } = useAppDataContext();

  const pool = findPoolById(poolId);

  let dailyCost = BigNumber.from(0);
  let capacity = BigNumber.from(0);
  if (pool) {
    // dailyCost = pool.dailyPriceOneK;
    // capacity = pool.availableCapacity;
  }

  return (
    <Space size="middle">
      <div className="flex flex-col">
        <TokenInput token="USDT" value={value} onChange={updateAmount} />

        <span className="mt-1">
          Daily Cost Per 1k$: {FormatTokenAmount(dailyCost, 'USD')}
        </span>
        <span>Capacity: {FormatTokenAmount(capacity, 'USDT')}</span>
      </div>
    </Space>
  );
};

export const BasketBuyInputAten = ({
  value,
  updateAmount,
}: {
  value: string;
  updateAmount: Function;
}) => {
  const { userAtenBalance } = useAppDataContext();

  return (
    <Space size="middle">
      <div className="flex flex-col">
        <TokenInput
          token="ATEN"
          value={value}
          maxValue={userAtenBalance}
          onChange={updateAmount}
        />
      </div>
    </Space>
  );
};

export const BasketBuyInputPremium = ({
  poolId,
  value,
  coverAmount,
  updateAmount,
}: {
  poolId: string;
  value: string;
  coverAmount: string;
  updateAmount: Function;
}) => {
  const { findPoolById } = useAppDataContext();

  const pool = findPoolById(poolId);

  let nbDaysCovered = 0;
  let durationOverride = '';
  if (pool) {
    const bnPremiumAmount = utils.parseUnits(value || '0', 6);
    const bnCoverAmount = utils.parseUnits(coverAmount || '0', 6);

    // The amount of USDT the cover will cost per day
    // A dailyCost of 1_000_000 means 1 USDT per day
    // const dailyCost = pool.dailyPriceOneK.mul(bnCoverAmount).div(1000);
    const dailyCost = BigNumber.from(0);

    nbDaysCovered = bnPremiumAmount
      .mul(10 ** 6)
      .div(dailyCost.eq(0) ? 1 : dailyCost)
      .toNumber();

    if (dailyCost.eq(0)) {
      durationOverride = '∞';
    } else if (bnPremiumAmount.gt(0) && nbDaysCovered === 0) {
      durationOverride = '<1';
    }
  }

  return (
    <Space size="middle">
      <div className="flex flex-col">
        <TokenInput token="USDT" value={value} onChange={updateAmount} />

        <span className="mt-1">
          Est. Duration: {durationOverride || nbDaysCovered} days
        </span>
        <span>Est. End : {formatTime(nbDaysCovered)} </span>
      </div>
    </Space>
  );
};
