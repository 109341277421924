import { Typography } from 'antd';

type GraphLegendProps = {
  labels: { text: string; color: string }[];
};

export function GraphLegend({ labels }: GraphLegendProps) {
  return (
    <div>
      {labels.map((label, i) => (
        <div
          key={i}
          style={{
            display: 'inline-flex',
            alignItems: 'center',
            marginRight: 12,
          }}
        >
          <div
            style={{
              width: 8,
              height: 8,
              backgroundColor: label.color,
              marginRight: 6,
              borderRadius: '50%',
            }}
          />
          <Typography style={{ color: '#2B2D3C' }}>
            {label.text}
          </Typography>
        </div>
      ))}
    </div>
  );
}
