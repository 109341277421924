import { BigNumber, ethers } from 'ethers';
import { StakingLevels, FeeLevels } from '../../types';

const formatBonus = (bonus: { [key: number]: number }) =>
  Object.keys(bonus)
    .map((el) => parseInt(el))
    .sort((a, b) => b - a);

// Bonus %
/////////////////

const computeUserPercent = (
  amountToken: BigNumber,
  levels: StakingLevels | FeeLevels,
  tokenDecimals: number
): number => {
  const formatAmount = parseFloat(
    ethers.utils.formatUnits(amountToken, tokenDecimals)
  );

  const level = formatBonus(levels).find((limit) => limit < formatAmount);

  if (!level) return levels[0];
  return levels[level];
};

// Levels
/////////////////

const computeUserLevel = (
  amountToken: BigNumber,
  levels: StakingLevels | FeeLevels,
  tokenDecimals: number
) => {
  const formatAmount = parseFloat(
    ethers.utils.formatUnits(amountToken, tokenDecimals)
  );
  const level = formatBonus(levels).findIndex(
    (limit) => limit <= formatAmount
  );

  const nbLevels = Object.keys(levels).length;

  if (level === -1) return 1;
  return nbLevels - level;
};

// Supply cover get ATEN staking bonus
export function userAtenStakingApr(
  amountSuppliedUsd: BigNumber,
  levels: StakingLevels
) {
  return computeUserPercent(amountSuppliedUsd, levels, 6);
}
export function userAtenStakingLevel(
  amountSuppliedUsd: BigNumber,
  levels: StakingLevels
) {
  return computeUserLevel(amountSuppliedUsd, levels, 6);
}
// Stake ATEN get cover supply bonus
export function userCoverSupplyBonus(
  amountStakedAten: BigNumber,
  levels: FeeLevels
) {
  return computeUserPercent(amountStakedAten, levels, 18);
}
export function userCoverSupplyLevel(
  amountStakedAten: BigNumber,
  levels: FeeLevels
) {
  return computeUserLevel(amountStakedAten, levels, 18);
}
