import { Col, Row } from 'antd';

export function DisclaimerRow() {
  return (
    <Row>
      <Col span={22} offset={1} className="">
        <div className="mt-4 content-card rounded p-4">
          <p className="mb-2" color="white">
            Please ensure you read and understand the following documents:
          </p>

          <p className="theme-secondary">
            <a href="#" target="_blank">
              Smart Contract Vulnerability Cover
            </a>
          </p>

          <p className="theme-secondary mt-1">
            <a href="#" target="_blank">
              Claim Process
            </a>
          </p>
        </div>
      </Col>
    </Row>
  );
}
