import { useState } from 'react';
import { BigNumber } from 'ethers';
// Hooks
import { useAppDataContext } from '../hooks/useAppDataProvider';
// Components
import {
  FormatPercent,
  FormatTokenAmount,
} from './primitives/FormattedNumber';
import { StatLine, StatLineData } from './StatLine';
import { TokenInput } from './TokenInput';
// import {
//   RefundAddToStakeButton,
//   RefundWithdrawStakedAtenButton,
//   RefundWithdrawRewardsButton,
//   RefundClosePositionButton,
//   RefundOpenCoverRefundButton,
//   ApproveAtenButton,
// } from './contracts';

export const CoverRefundStatLine = ({
  coverId,
}: {
  coverId: string | undefined;
}) => {
  const {
    findCoverRefundByCoverId,
    refundShortCoverDuration,
    userAtenBalance,
    userAthenaAtenAllowance,
  } = useAppDataContext();

  const coverRefund = findCoverRefundByCoverId(coverId);

  const hasCoverRefund =
    !!coverId && !!coverRefund && coverRefund.initTimestamp !== 0;

  const rate = coverRefund?.rate || 0;
  const atenPrice = coverRefund?.atenPrice || BigNumber.from(0);
  const earnedRewards = coverRefund?.earnedRewards || BigNumber.from(0);
  const stakedAmount = coverRefund?.stakedAmount || BigNumber.from(0);
  const initTimestamp = coverRefund?.initTimestamp || 0;

  const [openAmount, setOpenAmount] = useState('0');
  const [stakeAmount, setStakeAmount] = useState('0');
  const [unstakeAmount, setUnstakeAmount] = useState('0');

  const statLineData: StatLineData[] = [
    {
      title: 'Bound Refund Rate',
      content: FormatPercent(rate),
    },
    {
      title: 'Bound ATEN Price',
      content: <>1$ = {FormatTokenAmount(atenPrice, 'ATEN')}</>,
    },
    {
      title: 'Penalty Period End',
      content: `${
        initTimestamp
          ? Math.round(
              (initTimestamp + refundShortCoverDuration - Date.now()) /
                (24 * 60 * 60 * 1000)
            )
          : 0
      } days`,
    },
    {
      title: 'Staked Amount',
      content: FormatTokenAmount(stakedAmount, 'ATEN'),
    },
    {
      title: 'Current Rewards',
      content: FormatTokenAmount(earnedRewards, 'ATEN', 4),
    },
  ];

  return (
    <StatLine
      id="cover-refund"
      title="Cover Refund Staking"
      data={statLineData}
      className="my-6"
    >
      {/* {!hasCoverRefund && (
        <div className="flex flex-col w-[220px] mx-2">
          <TokenInput
            token="ATEN"
            value={openAmount}
            maxValue={userAtenBalance}
            onChange={setOpenAmount}
          />
          {userAthenaAtenAllowance.gt(openAmount) ? (
            <RefundOpenCoverRefundButton
              params={{ coverId: coverId ?? '', amount: openAmount }}
              className="min-w-[180px] mt-2"
            />
          ) : (
            <ApproveAtenButton className="min-w-[220px] mt-auto" />
          )}
        </div>
      )}

      <div className="flex flex-col w-[220px] mx-2">
        <TokenInput
          token="ATEN"
          value={stakeAmount}
          maxValue={initTimestamp ? userAtenBalance : '0'}
          onChange={setStakeAmount}
        />
        {userAthenaAtenAllowance.gt(stakeAmount) ? (
          <RefundAddToStakeButton
            params={{ coverId: coverId ?? '', amount: stakeAmount }}
            className="min-w-[180px] mt-2"
          />
        ) : (
          <ApproveAtenButton className="min-w-[220px] mt-auto" />
        )}
      </div>

      <RefundWithdrawRewardsButton
        params={{ coverId: coverId ?? '' }}
        className="min-w-[220px] mt-auto mx-2"
      />

      <div className="flex flex-col w-[220px] mx-2">
        <TokenInput
          token="ATEN"
          value={unstakeAmount}
          maxValue={stakedAmount}
          onChange={setUnstakeAmount}
        />
        <RefundWithdrawStakedAtenButton
          params={{ coverId: coverId ?? '', amount: unstakeAmount }}
          className="min-w-[180px] mt-2"
        />
      </div>

      {hasCoverRefund && (
        <RefundClosePositionButton
          params={{ coverId: coverId ?? '' }}
          className="min-w-[220px] mt-auto mx-2"
        />
      )} */}
    </StatLine>
  );
};
