import { Skeleton } from 'antd';
import { Link } from 'react-router-dom';

// const poolIdToLogo: { [key: string]: string } = {
//   default: '/logo-small.png',
//   //
//   0: '/icons/pools/usdt-logo.svg',
//   1: '/icons/pools/usdp-logo.svg',
//   2: '/icons/pools/busd-logo.svg',
//   3: '/icons/pools/cake-logo.svg',
//   4: '/icons/pools/celo-logo.svg',
//   5: '/icons/pools/cvx-logo.svg',
//   6: '/icons/pools/dot-logo.svg',
//   7: '/icons/pools/eth-logo.svg',
//   8: '/icons/pools/ftx-logo.svg',
//   9: '/icons/pools/link-logo.svg',
//   10: '/icons/pools/sol-logo.svg',
//   11: '/icons/pools/uni-logo.svg',
//   // round
//   13: '/icons/pools/round-aave-logo.svg',
//   14: '/icons/pools/round-apecoin-logo.svg',
//   15: '/icons/pools/round-atom-logo.svg',
//   16: '/icons/pools/round-grt-logo.svg',
//   17: '/icons/pools/round-kcs-logo.svg',
//   18: '/icons/pools/round-mkr-logo.svg',
//   19: '/icons/pools/round-usdc-logo.svg',
// };

const poolIdToLogo: { [key: string]: string } = {
  default: '/logo-small.png',
  //
  0: '/icons/pools/round-aave-logo.svg',
  1: '/icons/pools/uni-logo.svg',
  2: '/icons/pools/round-mkr-logo.svg',
  3: '/icons/pools/round-usdc-logo.svg',
  4: '/icons/pools/uni-logo.svg',
  5: '/icons/pools/uni-logo.svg',
};

export const wrapInLink = (
  icon: JSX.Element,
  poolId: string | undefined
) => {
  return (
    <Link
      className="table-link flex items-center justify-center"
      to={`/pool/${poolId}`}
    >
      {icon}
    </Link>
  );
};

export const PoolLogo = ({
  poolId,
  size = 48,
  className,
  imgTitle,
  linkToPool,
}: {
  poolId: string | undefined;
  size?: number;
  className?: string;
  imgTitle?: string;
  linkToPool?: boolean;
}) => {
  let content: JSX.Element;

  if (poolId && poolIdToLogo[poolId]) {
    const isRound =
      poolIdToLogo[poolId].includes('/round-') || poolId === 'default';

    content = (
      <span
        style={{ height: size, width: size }}
        className={`${
          !isRound && 'bg-slate-200'
        } flex rounded-full items-center justify-center ${
          className || ''
        }`}
      >
        <img
          style={
            isRound ? {} : { height: size * 0.67, width: size * 0.67 }
          }
          src={poolIdToLogo[poolId]}
          title={imgTitle || undefined}
        />
      </span>
    );
  } else {
    content = <Skeleton.Avatar size={size} className={className || ''} />;
  }

  // wrap in the link to pool if necessary
  if (poolId && linkToPool) {
    return wrapInLink(content, poolId);
  } else {
    return content;
  }
};
