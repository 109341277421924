import { useCall } from '@usedapp/core';
import { BigNumber } from 'ethers';
import { formatClaimData } from '../../../../functions/athenaDataFormat';
import { useProtocolDataContext } from '../../../useProtocolDataProvider';
import { useSingleCall } from '../../../useSingleCall';
import { useState } from 'react';
import { ClaimData } from '../../../../types';

// Value that is returned if the hook encounters an error
const defaultNextIndex = BigNumber.from(0);

const useNextClaimId = () => {
  const { chainContracts } = useProtocolDataContext();
  const contract = chainContracts?.ClaimManager;

  const { value, error } =
    useCall(
      contract && {
        contract: contract,
        method: 'nextClaimId', // Method to be called
        args: [], // Method arguments
      }
    ) ?? {};
  if (error) {
    console.error(error.message);
    return defaultNextIndex;
  }
  if (value?.[0]) {
    return value?.[0];
  }
  return defaultNextIndex;
};

// Value that is returned if the hook encounters an error
const defaultValue: ClaimData[] = [];

export const useLinearClaimsView = () => {
  return defaultValue;

  // const [previousValue, setPreviousValue] = useState(defaultValue);
  // const [canCall, isCalled] = useSingleCall();

  // const nextClaimId = useNextClaimId();

  // const { chainContracts } = useProtocolDataContext();
  // const contract = chainContracts?.ClaimManager;

  // const conditionsOk = canCall && !nextClaimId.eq(0);

  // const { value, error } =
  //   useCall(
  //     conditionsOk &&
  //       contract && {
  //         contract: contract,
  //         method: 'linearClaimsView', // Method to be called
  //         args: [0, nextClaimId], // Method arguments
  //       }
  //   ) ?? {};

  // if (error) {
  //   console.error(error.message);
  //   return defaultValue;
  // } else if (value?.[0]) {
  //   const formattedResult = formatClaimData(value[0]);
  //   setPreviousValue(formattedResult);
  //   isCalled();
  //   return formattedResult;
  // } else if (previousValue.length) {
  //   return previousValue;
  // } else {
  //   return defaultValue;
  // }
};
