import { useCall } from '@usedapp/core';
import { useProtocolDataContext } from '../../../useProtocolDataProvider';
import { useWeb3Context } from '../../../useWeb3ContextProvider';

const defaultValue: string[] = [];

export const useGetClaimCounterEvidence = (
  claimId: string | undefined
) => {
  const { chainContracts } = useProtocolDataContext();
  const { currentNetworkConfig } = useWeb3Context();
  const blocksRefresh = currentNetworkConfig.secondsToBlocks(20);

  const contract = chainContracts?.ClaimManager;

  const conditionsOk = !!claimId && !!blocksRefresh;

  const { value, error } =
    useCall(
      conditionsOk &&
        contract && {
          contract: contract,
          // @bw awaiting redeploy of ClaimManager
          // method: 'getClaimCounterEvidence', // Method to be called
          method: 'getClaimEvidence', // Method to be called
          args: [claimId], // Method arguments
        },
      { refresh: blocksRefresh }
    ) ?? {};
  if (error) {
    console.error(error.message);
    return defaultValue;
  }
  if (value?.[0]) {
    return value?.[0];
  }
  return defaultValue;
};
