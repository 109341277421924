import { useCall } from '@usedapp/core';
import { useProtocolDataContext } from '../../../useProtocolDataProvider';
import { useWeb3Context } from '../../../useWeb3ContextProvider';

const defaultValue = '';

export const usePoolAgreement = (poolId: string | undefined) => {
  const { chainContracts } = useProtocolDataContext();
  const { currentNetworkConfig } = useWeb3Context();
  const blocksRefresh = currentNetworkConfig.secondsToBlocks(20);

  const contract = chainContracts?.ClaimManager;

  const conditionsOk = !!poolId && !!blocksRefresh;

  const { value, error } =
    useCall(
      conditionsOk &&
        contract && {
          contract: contract,
          method: 'poolIdToCoverTerms', // Method to be called
          args: [poolId], // Method arguments
        },
      { refresh: blocksRefresh }
    ) ?? {};
  if (error) {
    console.error(error.message);
    return defaultValue;
  }
  if (value?.[0]) {
    return value?.[0];
  }
  return defaultValue;
};
