export type ApyGraphQueryType = 'simple' | 'composed';
export type Field = 'supplyRate' | 'premiumRate' | 'utilizationRate';

export type FieldData = { name: Field; color: string; text: string };

export const reserveRateTimeRangeOptions = ['1y', '6m', '1m'] as const;
export type ReserveRateTimeRange =
  typeof reserveRateTimeRangeOptions[number];

export type HumanTimestamp = {
  year: number;
  month: number;
  day: number;
  hour: number;
  minute: number;
};

export type RatesHistorySimpleParams = {
  chainId: number;
  timeRange: ReserveRateTimeRange;
  poolId: string;
  populate: {
    supplyRate?: boolean;
    premiumRate?: boolean;
    utilizationRate?: boolean;
  };
};

export type RatesHistoryComposedParams = {
  chainId: number;
  timeRange: ReserveRateTimeRange;
  poolIds: string[];
};

export type RateHistoryAPIResponse = {
  supplyRate?: number;
  premiumRate?: number;
  utilizationRate?: number;
  timestamp: HumanTimestamp;
};

export type RateHistoryComposedAPIResponse = {
  supplyRate: number;
  timestamp: HumanTimestamp;
  byPool: { [key: string]: number };
};

export type FormattedReserveHistoryItem = {
  date: number;
  supplyRate?: number;
  premiumRate?: number;
  utilizationRate?: number;
  byPool?: { [key: string]: number };
};

export type MetaEvidenceBaseParams = {
  chainId: string;
  coverId: string;
};

export type EvidenceBaseParams = {
  chainId: string;
  claimId: string;
};

export type EvidenceAPIResponseItem = {
  docId: string;
  name: string;
  fileURI: string;
  uploadTimestamp: number;
  isOnChain: boolean;
  validationTimestamp?: number;
  party?: 'claimant' | 'challenger';
};

export type ClaimEvidencesAPIResponse = {
  ipfsClaimEvidence: EvidenceAPIResponseItem[];
};

// ERC-1497 Evidence Standard - MetaEvidence
export type KlerosMetaEvidence = {
  _v: string;
  arbitrableChainID: string;
  fileURI: string;
  fileTypeExtension: string;
  category: string;
  title: string;
  description: string;
  aliases: {
    [address: string]: 'Claimant' | 'Claim Manager';
  };
  question: string;
  rulingOptions: {
    type: string;
    precision: number;
    titles: [string, string];
    descriptions: [string, string];
  };
  evidenceDisplayInterfaceURI: string;
};

export type DatabaseMetaEvidenceEntry = {
  evidenceData: KlerosMetaEvidence;
  chainId: string;
  coverId: string;
  coverClaimIndex: string;
  //
  docId: string;
  fileURI: string;
  ipfsUriHashSignature: string;
  //
  isOnChain: boolean;
  claimId?: string;
  validationTimestamp?: number;
};

// ERC-1497 Evidence Standard - Evidence
export type KlerosEvidence = {
  fileURI: string;
  fileHash: string;
  fileTypeExtension: string;
  name: string;
  description: string;
};

export type DatabaseEvidenceEntry = {
  evidenceData: KlerosEvidence;
  chainId: string;
  claimId: string;
  docId: string;
  //
  isOnChain: boolean;
  validationTimestamp?: number;
};

export type UploadAPIResponse = {
  data: EvidenceAPIResponseItem | undefined;
  success: boolean;
};

export type ExistingEvidenceFile = {
  url: string;
  type: string;
  name: string;
  status: 'done';
  docId: number;
  thumbUrl: string;
};

export type { UploadFile } from 'antd';
// For reference: Antd UploadFile
// export interface UploadFile<T = any> {
//   docId: string;
//   size?: number;
//   name: string;
//   fileName?: string;
//   lastModified?: number;
//   lastModifiedDate?: Date;
//   url?: string;
//   status?: UploadFileStatus;
//   percent?: number;
//   thumbUrl?: string;
//   crossOrigin?: React.ImgHTMLAttributes<HTMLImageElement>['crossOrigin'];
//   originFileObj?: RcFile;
//   response?: T;
//   error?: any;
//   linkProps?: any;
//   type?: string;
//   xhr?: T;
//   preview?: string;
// }
