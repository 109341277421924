import { Layout } from 'antd';

export function PageFooter() {
  return <></>;
  // return (
  //   <Layout.Footer className="mt-auto footer flex items-center self-end justify-center bottom-0 w-full theme-light">
  //     Athena ©2024
  //   </Layout.Footer>
  // );
}
