import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const dashboardMenus = [
  '/dashboard/covers',
  '/dashboard/positions',
  '/dashboard/staking',
  '/dashboard/claims',
];

export function ScrollToTop() {
  const { pathname } = useLocation();
  const [location, setLocation] = useState('');

  if (pathname !== location) {
    setLocation(pathname);

    const fromToDashboard =
      dashboardMenus.includes(location) &&
      dashboardMenus.includes(pathname);

    if (!fromToDashboard) window.scrollTo(0, 0);
  }

  return null;
}
