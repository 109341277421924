/**
 * This hook is used for getting historical reserve data, and it is primarily used with charts.
 * In particular, this hook is used in the ApyGraph.
 */
import { useCallback, useEffect, useState } from 'react';
import { useWeb3Context } from '../useWeb3ContextProvider';
import { CONFIG } from '../../appConfig';
import reservesRateHistory from '../../data/mockData/reservesRateHistory';
import { fromHumanTimestamp } from '../../functions/utils/formatting';
import {
  reserveRateTimeRangeOptions,
  ReserveRateTimeRange,
  RatesHistorySimpleParams,
  RatesHistoryComposedParams,
  RateHistoryAPIResponse,
  RateHistoryComposedAPIResponse,
  ApyGraphQueryType,
  FormattedReserveHistoryItem,
} from '../../types';

// Re export for simplicity
export { reserveRateTimeRangeOptions, type ReserveRateTimeRange };

const { RATES_DAILY_POINTS } = CONFIG;

const dataLengths: { [key in ReserveRateTimeRange]: number } = {
  '1y': 365 * RATES_DAILY_POINTS,
  '6m': 183 * RATES_DAILY_POINTS,
  '1m': 30 * RATES_DAILY_POINTS,
};

const adjustDataPointsLength = (
  data: FormattedReserveHistoryItem[],
  timeRange: ReserveRateTimeRange
): FormattedReserveHistoryItem[] => {
  let sliceAmount = data.length;

  if (dataLengths[timeRange] < data.length) {
    sliceAmount = dataLengths[timeRange];
    return data.slice(-1 * sliceAmount);
  }
  if (data.length && data.length < dataLengths[timeRange]) {
    const byPoolData = Object.keys(data[0].byPool ?? {}).reduce(
      (acc, key) => ({ [key]: 0, ...acc }),
      {}
    );

    const dataStart = data[0].date;
    const dataSpacing = (24 * 60 * 60 * 1000) / RATES_DAILY_POINTS;
    const nbMissingEntries = dataLengths[timeRange] - data.length;

    // Fill missing data with 0
    const paddingData = Array(nbMissingEntries)
      .fill('')
      .map((_, i) => ({
        date: dataStart - (nbMissingEntries - (i + 1)) * dataSpacing,
        supplyRate: 0,
        premiumRate: 0,
        utilizationRate: 0,
        byPool: byPoolData,
      }));

    // Merge padding data with actual data
    return [...paddingData, ...data];
  }
  return data;
};

const fetchRateHistory = async (
  params: RatesHistorySimpleParams
): Promise<RateHistoryAPIResponse[]> => {
  try {
    const url = `${CONFIG.API_ENDPOINT}/rates-history/simple`;

    const result = await fetch(url, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(params),
    });

    const { data } = await result.json();
    return data;
  } catch (e) {
    return [];
  }
};

const fetchComposedRateHistory = async (
  params: RatesHistoryComposedParams
): Promise<RateHistoryComposedAPIResponse[]> => {
  try {
    const url = `${CONFIG.API_ENDPOINT}/rates-history/composed`;
    const result = await fetch(url, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(params),
    });

    const { data } = await result.json();
    return data;
  } catch (e) {
    return [];
  }
};

export function useReserveRatesHistory(
  queryType: ApyGraphQueryType,
  timeRange: ReserveRateTimeRange,
  poolIds: string[],
  populate?: {
    supplyRate?: boolean;
    premiumRate?: boolean;
    utilizationRate?: boolean;
  }
) {
  const { chainId } = useWeb3Context();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [data, setData] = useState<FormattedReserveHistoryItem[]>([]);

  // If API mocking is activated then return local graph data
  if (CONFIG.MOCK_API) {
    return {
      data: adjustDataPointsLength(reservesRateHistory, timeRange),
    };
  }

  const refetchData = useCallback<() => () => void>(() => {
    // Avoid rerender when data has been fetched
    if (data.length || (data.length && loading)) return () => null;

    if (!chainId || !poolIds.length) {
      setData([]);
      return () => null;
    }
    // reset
    setLoading(true);
    setError(false);
    setData([]);

    let fetchPromise: Promise<FormattedReserveHistoryItem[]>;
    if (queryType === 'simple' && populate) {
      fetchPromise = fetchRateHistory({
        chainId: chainId,
        timeRange,
        poolId: poolIds[0],
        populate,
      }).then((data) =>
        data
          .filter((el) => !!el)
          .map((d) => ({
            date: fromHumanTimestamp(d.timestamp),
            supplyRate: d.supplyRate,
            premiumRate: d.premiumRate,
            utilizationRate: d.utilizationRate,
          }))
      );
    } else {
      fetchPromise = fetchComposedRateHistory({
        chainId: chainId,
        timeRange,
        poolIds,
      }).then((data) =>
        data
          .filter((el) => !!el)
          .map((d) => ({
            date: fromHumanTimestamp(d.timestamp),
            supplyRate: d.supplyRate,
            byPool: d.byPool,
          }))
      );
    }

    fetchPromise
      .then((data) => {
        setData(data);
        setLoading(false);
      })
      .catch((e) => {
        console.error(
          'useReserveRatesHistory: Failed to fetch historical reserve data.',
          e
        );
        setError(true);
        setLoading(false);
      });

    setLoading(false);
    return () => null;
  }, [queryType, populate, poolIds, timeRange, chainId]);

  useEffect(() => {
    const cancel = refetchData();
    return () => cancel();
  }, [refetchData]);

  return {
    loading: loading || (data.length === 0 && !error),
    data: adjustDataPointsLength(data, timeRange),
    error,
    refetch: refetchData,
  };
}
