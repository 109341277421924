import { Col, Row } from 'antd';
import { useEffect, useRef, useState } from 'react';
// Hooks
import { useAppDataContext } from '../hooks/useAppDataProvider';
// Types
import { UserPosition } from '../types';
// Components
import { ActionCard, ActionCardParams } from '../components/ActionCard';
import { ApyGraphContainer } from '../components/graphs/ApyGraphContainer';
import {
  FormatPercent,
  FormatTokenAmount,
} from '../components/primitives/FormattedNumber';
import { TokenInput } from '../components/TokenInput';
// import {
//   PositionAddLiquidityButton,
//   PositionCommitWithdrawButton,
//   PositionWithdrawButton,
// } from './contracts';

const DAY_IN_MS = 24 * 60 * 60 * 1000;

export const ActionSectionPosition = ({
  positionId,
  position,
}: {
  positionId: string | undefined;
  position: UserPosition | undefined;
}) => {
  const { userUsdtBalance, calcPositionApr } = useAppDataContext();

  const [depositAmount, setDepositAmount] = useState('0');

  const withdrawCommitTimestamp = position?.withdrawCommitTimestamp || 0;
  const commitDelay = position?.commitDelay || 0;
  const apr = calcPositionApr(position);
  const poolIds = position?.poolIds || [];

  // Graph height
  const [height, setHeight] = useState(0);
  const elementRef = useRef(null);
  useEffect(() => {
    if (height === 0) setHeight((elementRef.current as any).clientHeight);
  }, []);

  // Commit and withdrawal
  let hasCommit = false;
  let canExecWithdrawal = false;
  let commitDelayHoursLeft = 0;
  if (withdrawCommitTimestamp) {
    hasCommit = true;
    commitDelayHoursLeft = Math.round(
      (withdrawCommitTimestamp + commitDelay - Date.now()) / (60 * 60)
    );
    if (commitDelayHoursLeft < 0) canExecWithdrawal = true;
  }

  const CoverCardsData: ActionCardParams[] = [
    {
      title: 'Supply',
      infoData: [
        {
          name: 'Wallet balance:',
          data: FormatTokenAmount(userUsdtBalance, 'USDT'),
        },
        {
          name: 'Current APR:',
          data: FormatPercent(apr),
        },
      ],
      bottomActions: (
        <>
          <TokenInput
            token="USDT"
            value={depositAmount}
            maxValue={userUsdtBalance}
            onChange={setDepositAmount}
          />
          {/* <PositionAddLiquidityButton
            className="mt-2 display-block w-full"
            params={{
              tokenId: positionId || '',
              amount: depositAmount,
            }}
          /> */}
          <button>ADD LIQUIDITY</button>
        </>
      ),
    },
    {
      title: 'Withdraw',
      text: `You will be able to recover your funds ${
        commitDelay / DAY_IN_MS
      } days after you commit your withdrawal request. Please bear in mind that claims can still affect your deposit during this period.`,
      bottomActions: hasCommit ? (
        <>
          <span className="text-sm">
            Commit delay left: {commitDelayHoursLeft} hours
          </span>
          {/* <PositionWithdrawButton
            params={{ tokenId: positionId || '' }}
            disabled={!canExecWithdrawal}
            className="w-full"
          /> */}
          <button>WITHDRAW</button>
        </>
      ) : (
        // <PositionCommitWithdrawButton
        //   params={{ tokenId: positionId || '' }}
        //   className="w-full"
        // />
        <button>COMMIT WITHDRAWAL</button>
      ),
    },
  ];

  return (
    <Row ref={elementRef} className="mt-6">
      <Col span={8} offset={1} className="">
        {CoverCardsData.map((cardData, i) => {
          return <ActionCard key={i} cardData={cardData} index={i} />;
        })}
      </Col>

      <Col offset={1} span={13}>
        <ApyGraphContainer
          queryType="composed"
          populate={{
            supplyRate: true,
          }}
          poolIds={poolIds}
          height={height}
        />
      </Col>
    </Row>
  );
};
