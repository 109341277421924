import { ChainId } from './chainId';

export type ExplorerLinkBuilderProps = {
  tx?: string;
  address?: string;
};

export type ExplorerLinkBuilderConfig = {
  baseUrl: string;
  addressPrefix?: string;
  txPrefix?: string;
};

export type NetworkConfig = {
  order?: number;
  name: string;
  chainId: ChainId;
  publicJsonRPCUrl: readonly string[];
  publicJsonRPCWSUrl: string;
  baseAssetName: string;
  baseAssetSymbol: string;
  wrappedBaseAssetSymbol: string;
  baseAssetDecimals: number;
  blockTime: number; // in milliseconds
  explorerLink: string;
  explorerLinkBuilder?: (props: ExplorerLinkBuilderProps) => string;
  secondsToBlocks: (blockTime: number) => number;
  isCompatibleNetwork: boolean;
  isImplemented?: boolean;
  isTestnet?: boolean;
  isForked?: boolean;
  logoPath: string;
  ensCompatible?: boolean;
};

export type BaseNetworkConfig = Omit<
  NetworkConfig,
  'explorerLinkBuilder' | 'secondsToBlocks'
>;

export const networkConfigs: { [chain: string]: BaseNetworkConfig } = {
  [ChainId.mainnet]: {
    order: 2,
    name: 'Ethereum',
    chainId: ChainId.mainnet,
    publicJsonRPCUrl: ['https://cloudflare-eth.com/v1/mainnet'],
    publicJsonRPCWSUrl: 'wss://eth-mainnet.alchemyapi.io/v2/demo',
    baseAssetName: 'Ethereum',
    baseAssetSymbol: 'ETH',
    wrappedBaseAssetSymbol: 'WETH',
    baseAssetDecimals: 18,
    blockTime: 12000,
    explorerLink: 'https://etherscan.io',
    logoPath: 'img/networks/ethereum.svg',
    isCompatibleNetwork: true,
    isImplemented: true,
    ensCompatible: true,
  },
  [ChainId.arbitrum_one]: {
    order: 1,
    name: 'Arbitrum',
    chainId: ChainId.arbitrum_one,
    publicJsonRPCUrl: ['https://arbitrum-one.publicnode.com'],
    publicJsonRPCWSUrl: 'wss://arbitrum-one.publicnode.com  ',
    baseAssetName: 'Ethereum',
    baseAssetSymbol: 'ETH',
    wrappedBaseAssetSymbol: 'WETH',
    baseAssetDecimals: 18,
    blockTime: 260,
    explorerLink: 'https://arbiscan.io/',
    logoPath: 'img/networks/arbitrum.svg',
    isCompatibleNetwork: true,
    isImplemented: true,
    ensCompatible: false,
  },
  [ChainId.base]: {
    order: 3,
    name: 'Base',
    chainId: ChainId.base,
    publicJsonRPCUrl: ['https://base.publicnode.com'],
    publicJsonRPCWSUrl: 'wss://base.publicnode.com',
    baseAssetName: 'Ethereum',
    baseAssetSymbol: 'ETH',
    wrappedBaseAssetSymbol: 'WETH',
    baseAssetDecimals: 18,
    blockTime: 2000,
    explorerLink: 'https://basescan.org',
    logoPath: 'img/networks/base.svg',
    isCompatibleNetwork: true,
    isImplemented: true,
    ensCompatible: false,
  },
  [ChainId.polygon]: {
    order: 4,
    name: 'Polygon',
    chainId: ChainId.polygon,
    publicJsonRPCUrl: [
      'https://polygon-rpc.com/',
      'https://polygon.llamarpc.com',
      'https://polygon-mainnet.public.blastapi.io',
    ],
    publicJsonRPCWSUrl: 'wss://polygon.g.alchemy.com/v2/demo',
    baseAssetName: 'Matic',
    baseAssetSymbol: 'MATIC',
    wrappedBaseAssetSymbol: 'WMATIC',
    baseAssetDecimals: 18,
    blockTime: 2100,
    explorerLink: 'https://polygonscan.com',
    logoPath: 'img/networks/polygon.svg',
    isCompatibleNetwork: true,
    isImplemented: true,
    ensCompatible: false,
  },
  [ChainId.bsc]: {
    order: 5,
    name: 'BNB (BSC)',
    chainId: ChainId.bsc,
    publicJsonRPCUrl: [
      'https://binance.llamarpc.com',
      'https://bsc-rpc.publicnode.com',
    ],
    publicJsonRPCWSUrl: 'wss://bsc-rpc.publicnode.com',
    baseAssetName: 'BNB',
    baseAssetSymbol: 'BNB',
    wrappedBaseAssetSymbol: 'WBNB',
    baseAssetDecimals: 18,
    blockTime: 3000,
    explorerLink: 'https://bscscan.com',
    logoPath: 'img/networks/bnb.svg',
    isCompatibleNetwork: true,
    isImplemented: true,
    ensCompatible: false,
  },
  // ====================== //
  // ====== Testnets ====== //
  // ====================== //
  // [ChainId.goerli]: {
  //   name: 'Göerli',
  //   chainId: ChainId.goerli,
  //   publicJsonRPCUrl: ['https://ethereum-goerli.publicnode.com'],
  //   publicJsonRPCWSUrl: 'wss://ethereum-goerli.publicnode.com',
  //   baseAssetName: 'Göerli',
  //   baseAssetSymbol: 'ETH',
  //   wrappedBaseAssetSymbol: 'WETH',
  //   baseAssetDecimals: 18,
  //   blockTime: 12000,
  //   explorerLink: 'https://goerli.etherscan.io',
  //   logoPath: 'img/networks/ethereum.svg',
  //   isTestnet: true,
  //   isCompatibleNetwork: true,
  //   ensCompatible: false,
  // },
  // [ChainId.mumbai]: {
  //   name: 'Mumbai',
  //   chainId: ChainId.mumbai,
  //   publicJsonRPCUrl: ['https://polygon-mumbai.g.alchemy.com/v2/demo'],
  //   publicJsonRPCWSUrl: 'wss://polygon-mumbai.g.alchemy.com/v2/demo',
  //   baseAssetName: 'Mumbai Matic',
  //   baseAssetSymbol: 'MATIC',
  //   wrappedBaseAssetSymbol: 'WMATIC',
  //   baseAssetDecimals: 18,
  //   blockTime: 5000,
  //   explorerLink: 'https://explorer-mumbai.maticvigil.com',
  //   logoPath: 'img/networks/polygon.svg',
  //   isTestnet: true,ensCompatible: false,
  // },
};
