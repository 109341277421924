import { message, Upload, UploadProps } from 'antd';
import { DocumentAdd } from '../icons/DocumentAdd';
// Config
import { CONFIG } from '../../appConfig';
// Types
import type { UploadChangeParam, UploadFile } from 'antd/lib/upload';
import { UploadAPIResponse } from '../../types';
// Context
import { useWeb3Context } from '../../hooks/useWeb3ContextProvider';

const { Dragger } = Upload;

export function EvidenceUpload({
  disabled,
  claimId,
  handleNewUpload,
}: {
  disabled?: boolean;
  claimId: string | undefined;
  //
  handleNewUpload: (file: UploadAPIResponse) => void;
}) {
  const { chainId } = useWeb3Context();

  const handleUploadChange = (
    info: UploadChangeParam<UploadFile<any>>
  ) => {
    const { status } = info.file;

    if (status === 'error') {
      message.error(`${info.file.name} file upload failed.`, 6);
    } else if (status === 'done') {
      message.success(`${info.file.name} file uploaded successfully.`, 6);

      const uploadResponse = info.file.response
        ?.uploadResponse?.[0] as UploadAPIResponse;

      handleNewUpload(uploadResponse);
    }
  };

  const props: UploadProps = {
    name: 'file',
    multiple: true,
    disabled,
    accept:
      'application/pdf,image/png,image/jpeg,text/plain,application/json,text/csv',
    onChange: handleUploadChange,
    listType: undefined,
    showUploadList: {
      showDownloadIcon: false,
      showRemoveIcon: false,
      showPreviewIcon: false,
    },
    // Request config props
    action: `${CONFIG.API_ENDPOINT}/evidence/upload`,
    data: {
      chainId: chainId?.toString() || '',
      claimId: claimId || '',
    },
  };

  return (
    <Dragger
      {...props}
      className="mt-2 mb-10 w-full h-full flex-wrap flex rounded-sm theme-disabled-bg overflow-hidden"
    >
      <p className="ant-upload-text theme-highlight">
        Click or drag file to this area to upload
      </p>
      <p className="text-white mt-3 italic text-xs">
        Each file must be under 2 MB. Accepted types: .pdf, .png, .jpg,
        .jpeg, .txt, .json and .csv
      </p>
    </Dragger>
  );
}
