import { Typography, Button } from 'antd';
// Hooks
import { useGetMetaEvidence } from '../../hooks/api';
import { useAppDataContext } from '../../hooks/useAppDataProvider';
// Function
import { makeIpfsUrl } from '../../functions/utils/formatting';

// _v: '1.0.0',
// arbitrableChainID: chainId,
// fileURI: poolAgreement,
// fileTypeExtension: 'pdf',
// category: 'Insurance Claim',
// title:
//   'The Claim Manager has been seized to challenge the legitimacy of the Claimants request for payout.',
// description:
//   'The Claimant has filed a request to Athena Ins. for an insurance payout after taking out an insurance policy. Someone has challenged the insurance claim as they believe the request for payout to be illegitimate, irregular and/or fraudulent.',
// question: 'Is the Claimant eligible for a payout ?',
// aliases: {
//   [claimManagerAddress]: 'Claim Manager',
//   [ownerOfToken]: 'Claimant',
// },
// rulingOptions: {
//   type: 'single-select',
//   precision: 1,
//   titles: ['Accept', 'Reject'],
//   descriptions: [
//     'Select this if the request for payout is legitimate and well-founded. The Claimant will be compensated.',
//     'Select this if the request for payout is illegitimate, irregular and/or fraudulent. The Claimant will not be compensated.',
//   ],
// },
// evidenceDisplayInterfaceURI: `https://app.athena-co.io/claim-evidence?chainId=${chainId}&coverId=${coverId}&claimIndex=${coverClaimIndex}`,
// };

// export type DatabaseMetaEvidenceEntry = {
//   evidenceData: KlerosMetaEvidence;
//   chainId: string;
//   coverId: string;
//   coverClaimIndex: string;
//   //
//   docId: string;
//   fileURI: string;
//   ipfsUriHashSignature: string;
//   //
//   isOnChain: boolean;
//   claimId?: string;
//   validationTimestamp?: number;
// };

export function ClaimContextData({
  claimId,
}: {
  claimId: string | undefined;
}) {
  const metaEvidence = useGetMetaEvidence(claimId);

  const poolAgreementUrl = metaEvidence?.evidenceData?.fileURI
    ? makeIpfsUrl(metaEvidence?.evidenceData?.fileURI)
    : '';
  const metaEvidenceIpfsUrl = metaEvidence?.fileURI
    ? makeIpfsUrl(metaEvidence.fileURI)
    : '';

  return (
    <div className="flex flex-col p-8">
      <Typography.Title
        className="flex self-center items-center"
        level={3}
      >
        Claim Context
      </Typography.Title>

      <div className="flex justify-evenly pt-8">
        <div className="flex flex-col items-center justify-between px-8 max-w-[50%]">
          <Typography.Title level={4}>Pool Agreement</Typography.Title>
          <p className="mt-4 mb-8 text-justify ">
            The pool argreement defined the rules applicable to users who
            subscribe to a cover. It establishes guidelines to verify that
            a payout is legitimate and has respected due process. Read this
            carefully before challenging any claim.
          </p>
          <a href={poolAgreementUrl} target="_blank" rel="noreferrer">
            <Button
              type="default"
              disabled={!poolAgreementUrl}
              className="w-[220px]"
            >
              View Pool Agreement
            </Button>
          </a>
        </div>

        <div className="flex flex-col items-center justify-between px-8 max-w-[50%]">
          <Typography.Title level={4}>Meta-Evidence</Typography.Title>
          <p className="mt-4 mb-8 text-justify ">
            The meta-evidence serves as a standardized global reference for
            potential disputes. It establishes the ruling options for the
            arbitrator and indicates the pool agreement applicable to the
            claimant's cover.
          </p>
          <a href={metaEvidenceIpfsUrl} target="_blank" rel="noreferrer">
            <Button
              type="default"
              disabled={!metaEvidenceIpfsUrl}
              className="w-[220px]"
            >
              View Meta Evidence
            </Button>
          </a>
        </div>
      </div>
    </div>
  );
}
