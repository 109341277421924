import { Button } from 'antd';
import { ChevronDownIcon } from './icons/ChevronDownIcon';

export function BackButton({ className }: { className?: string }) {
  return (
    <button
      className={`max-w-[180px] theme-tertiary-bg theme-primary rounded-sm flex items-center justify-center py-2 px-4 transition-all duration-300 hover:brightness-150 ${
        className || ''
      }`}
      onClick={() => window.history.back()}
    >
      <ChevronDownIcon className="rotate-90 mr-2" />
      <span>Go Back</span>
    </button>
  );
}
