export function PageTitle({
  title,
  subtitle,
}: {
  title: string;
  subtitle: [string, string];
}) {
  return (
    <div className="flex justify-center px-2 pb-12 pt-16 lg:px-0">
      <div className="flex flex-1 flex-col items-center justify-center max-w-[700px] text-center">
        <div className="absolute inset-0 -z-10 bg-gradient-to-t from-dark to-dark/[.1]"></div>
        <h1 className="theme-disabled !text-sm uppercase mb-2">{title}</h1>
        <h2 className="theme-light text-[2.5rem] font-light -tracking-[.05rem] leading-relaxed">
          {subtitle[0]}{' '}
          <span className="theme-highlight font-bold">{subtitle[1]}</span>
        </h2>
      </div>
    </div>
  );
}
