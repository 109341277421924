import { TransactionStatus, useContractFunction } from '@usedapp/core';
import { Contract, ethers } from 'ethers';
import { ExecuteReturn } from '../../../../types';
import { ERC20, ERC20__factory } from '../../../../types/typechain';

type Instance = {
  send: (
    ...args: Parameters<ERC20['approve']>
  ) => Promise<ethers.providers.TransactionReceipt | undefined>;
  state: TransactionStatus;
};

function makeInstance(address: string | undefined): Instance | undefined {
  if (!address) return;

  const { send, state } = useContractFunction(
    new Contract(address, ERC20__factory.abi),
    'approve',
    {
      transactionName: 'approve',
      gasLimitBufferPercentage: 10,
    }
  );

  return { send, state };
}

function execute(
  instance: Instance,
  params: {
    spender: string;
    amount: string;
  }
): ExecuteReturn {
  const txReceiptPromise = instance.send(params.spender, params.amount);

  return {
    txReceipt: txReceiptPromise,
    txStatus: instance.state,
  };
}

export const tokenApprove = {
  makeInstance,
  execute,
};
