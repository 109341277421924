import { CONFIG } from '../../appConfig';
// Hooks
import { useLayoutEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
// Context
import { useWeb3Context } from '../../hooks/useWeb3ContextProvider';
// Components
import { Link } from 'react-router-dom';
import { MenuEllipsis } from '../wallet/MenuEllipsis';
import { MenuBurger } from '../wallet/MenuBurger';
import { MenuNetwork } from '../wallet/MenuNetwork';
import { MenuWallet } from '../wallet/MenuWallet';
// Icon
import { BetaIcon } from '../icons/BetaIcon';
import { AthenaLogo } from '../icons/AthenaLogo';

const pathList: {
  name: string;
  path: string;
}[] = [
  // { name: 'Dashboard', path: '/dash' },
  // { name: 'Market', path: '/market' },
  // { name: 'Court', path: '/court' },
  // { name: 'Farming', path: '/farming' },
  // { name: 'Staking', path: '/staking' },
  // { name: 'Governance', path: '/governance' },
  // { name: 'Jobs', path: '/jobs' },
  // { name: 'Whitepaper', path: '/whitepaper' },
  // { name: 'Documentation', path: '/docs' },
];

CONFIG.DEV_MODE &&
  pathList.push(
    { name: 'DASHBOARD', path: '/dashboard' },
    { name: 'BUY COVER', path: '/buycover' },
    { name: 'SUPPLY COVER', path: '/supplycover' },
    { name: 'STAKE', path: '/stake' },
    { name: 'CLAIMS', path: '/claims' }
  );

export function NavHeader() {
  const { pathname } = useLocation();
  const { isConnected, connectWallet } = useWeb3Context();

  const [hasScrolled, setHasScrolled] = useState(window.scrollY);

  const nbItemsBeforeEllipsis = 5;

  useLayoutEffect(() => {
    const handleScroll = () => {
      setHasScrolled(window.scrollY);
    };

    handleScroll();
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <nav className="flex z-50 w-full border-b border-white/[.1] theme-secondary-bg p-4 md:px-8">
      <div className="relative w-full flex items-center justify-between">
        <div className="relative z-10 shrink">
          <Link to="/">
            <AthenaLogo className="h-8 md:h-10" />
          </Link>
          <div className="absolute -bottom-2 left-10 md:left-12 text-xs theme-highlight lg:hidden">
            {/* <div className="flex items-center space-x-2">$0.0123</div> */}
          </div>
        </div>

        <div className="inset-0 z-0 hidden xl:block">
          {/* <div className="flex h-full w-full items-center justify-center space-x-6 text-lg">
            {pathList.slice(0, nbItemsBeforeEllipsis).map((item) => (
              <Link
                key={item.name}
                to={item.path}
                className={`transition-all duration-300 ${
                  pathname === item.path
                    ? 'text-white header-underline header-underline-active'
                    : 'text-white/[.6] hover:text-white'
                }`}
              >
                {item.name}
              </Link>
            ))}

            {nbItemsBeforeEllipsis < pathList.length && (
              <MenuEllipsis
                ellipsisMenuItems={pathList.slice(nbItemsBeforeEllipsis)}
              />
            )}
          </div> */}
        </div>

        <div className="z-10 flex shrink items-center space-x-2">
          <div className="hidden text-sm text-white lg:block">
            <div className="flex items-center space-x-2">
              {/* <img
                src={require('../../assets/img/ATEN-icon.svg').default}
                alt="aten-icon"
                className="h-4"
              /> */}
              {/* <div>$0.0321</div> */}
            </div>
          </div>

          <div className="flex items-center space-x-2">
            <MenuNetwork />
            <MenuWallet />
          </div>

          {/* <MenuBurger pathList={pathList} /> */}
        </div>
      </div>
    </nav>
  );
}
