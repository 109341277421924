export function DotIcon({ className }: { className?: string }) {
  return (
    <svg
      data-icon="circle"
      className={`h-4 w-4 ${className}`}
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
    >
      <path
        fill="currentColor"
        d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512z"
      />
    </svg>
  );
}
