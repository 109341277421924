import { utils } from 'ethers';
import { TransactionStatus, useContractFunction } from '@usedapp/core';
import { BigNumber, ethers } from 'ethers';
import { ExecuteReturn } from '../../../../types';
import { useProtocolDataContext } from '../../../useProtocolDataProvider';
import { LiquidityManager } from '../../../../types/typechain';

export type ParamsTakeInterests = {
  positionId: number;
};

type FormattedParams = {
  positionId: number;
};

function formatParams(params: ParamsTakeInterests): FormattedParams {
  return {
    positionId: params.positionId,
  };
}

type Instance = {
  send: (
    ...args: Parameters<LiquidityManager['takeInterests']>
  ) => Promise<ethers.providers.TransactionReceipt | undefined>;
  state: TransactionStatus;
};

function makeInstance(): Instance {
  const { chainContracts } = useProtocolDataContext();
  const contract = chainContracts?.LiquidityManager;

  const { send, state } = useContractFunction(contract, 'takeInterests', {
    transactionName: 'takeInterests',
    gasLimitBufferPercentage: 10,
  });

  return { send, state };
}

function checkParams(params: ParamsTakeInterests): string | undefined {
  // do checks relative to this fn
  return;
}

function execute(
  instance: Instance,
  params: ParamsTakeInterests
): ExecuteReturn {
  const { positionId } = formatParams(params);

  const txReceiptPromise = instance.send(positionId);

  return {
    txReceipt: txReceiptPromise,
    txStatus: instance.state,
  };
}

export const configTakeInterests = {
  makeInstance: makeInstance,
  checkParams: checkParams,
  execute: execute,
};
