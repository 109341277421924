import { FormattedReserveHistoryItem } from '../../types';

const mockData = <FormattedReserveHistoryItem[]>baseData().map((el) => {
  return {
    ...el,
    premiumRate: el.premiumRate * 3,
    supplyRate: el.supplyRate * 8,
  };
});

export default mockData;

function baseData() {
  return [
    {
      supplyRate: 0.03405086348005152,
      premiumRate: 0.05249667973925232,
      utilizationRate: 0.69620789,
      date: new Date(2021, 9, 17, 0).valueOf(),
    },
    {
      supplyRate: 0.024106057511470515,
      premiumRate: 0.036491718378621765,
      utilizationRate: 0.7122208044444445,
      date: new Date(2021, 9, 18, 0).valueOf(),
    },
    {
      supplyRate: 0.027434826349196206,
      premiumRate: 0.04087555675511556,
      utilizationRate: 0.7226105136111111,
      date: new Date(2021, 9, 19, 0).valueOf(),
    },
    {
      supplyRate: 0.018729033141225,
      premiumRate: 0.027711273838267412,
      utilizationRate: 0.7299380519791667,
      date: new Date(2021, 9, 20, 0).valueOf(),
    },
    {
      supplyRate: 0.28208905755197766,
      premiumRate: 0.32565807173745914,
      utilizationRate: 0.8359722034027778,
      date: new Date(2021, 9, 21, 0).valueOf(),
    },
    {
      supplyRate: 0.2837678269215422,
      premiumRate: 0.3447647964289532,
      utilizationRate: 0.8461863544097222,
      date: new Date(2021, 9, 22, 0).valueOf(),
    },
    {
      supplyRate: 0.03431656275782184,
      premiumRate: 0.048101892069257995,
      utilizationRate: 0.7676364194444445,
      date: new Date(2021, 9, 23, 0).valueOf(),
    },
    {
      supplyRate: 0.024727653805899946,
      premiumRate: 0.03446033286477381,
      utilizationRate: 0.7758427803472222,
      date: new Date(2021, 9, 24, 0).valueOf(),
    },
    {
      supplyRate: 0.08263290101123102,
      premiumRate: 0.11193084338086237,
      utilizationRate: 0.7941404419097222,
      date: new Date(2021, 9, 25, 0).valueOf(),
    },
    {
      supplyRate: 0.1066010570333771,
      premiumRate: 0.14346682076542988,
      utilizationRate: 0.8009165334722222,
      date: new Date(2021, 9, 26, 0).valueOf(),
    },
    {
      supplyRate: 0.07515221394556143,
      premiumRate: 0.10273187656795937,
      utilizationRate: 0.7919413532638888,
      date: new Date(2021, 9, 27, 0).valueOf(),
    },
    {
      supplyRate: 0.15222768557642644,
      premiumRate: 0.1974055491381612,
      utilizationRate: 0.8118544714930556,
      date: new Date(2021, 9, 28, 0).valueOf(),
    },
    {
      supplyRate: 0.24876786509267682,
      premiumRate: 0.3025393224952431,
      utilizationRate: 0.8264125821527777,
      date: new Date(2021, 9, 29, 0).valueOf(),
    },
    {
      supplyRate: 0.11291998276920386,
      premiumRate: 0.15236894075867605,
      utilizationRate: 0.8028755955902778,
      date: new Date(2021, 9, 30, 0).valueOf(),
    },
    {
      supplyRate: 0.07632819648229477,
      premiumRate: 0.10285317458284288,
      utilizationRate: 0.7972205836111111,
      date: new Date(2021, 9, 31, 0).valueOf(),
    },
    {
      supplyRate: 0.15328413456921264,
      premiumRate: 0.20492982176422,
      utilizationRate: 0.8063270061805556,
      date: new Date(2021, 10, 1, 0).valueOf(),
    },
    {
      supplyRate: 0.13940204744085352,
      premiumRate: 0.18228743935350053,
      utilizationRate: 0.8076483177777778,
      date: new Date(2021, 10, 2, 0).valueOf(),
    },
    {
      supplyRate: 0.13280211351110144,
      premiumRate: 0.17371382919083916,
      utilizationRate: 0.8066893801041667,
      date: new Date(2021, 10, 3, 0).valueOf(),
    },
    {
      supplyRate: 0.0676906950249782,
      premiumRate: 0.09216435010719222,
      utilizationRate: 0.7785863963194444,
      date: new Date(2021, 10, 4, 0).valueOf(),
    },
    {
      supplyRate: 0.026895148339887136,
      premiumRate: 0.03845197937076303,
      utilizationRate: 0.7541525371527777,
      date: new Date(2021, 10, 5, 0).valueOf(),
    },
    {
      supplyRate: 0.018378635556046023,
      premiumRate: 0.02643508828640127,
      utilizationRate: 0.7473139496875,
      date: new Date(2021, 10, 6, 0).valueOf(),
    },
    {
      supplyRate: 0.029813595324661726,
      premiumRate: 0.0428093609384653,
      utilizationRate: 0.7492919530902777,
      date: new Date(2021, 10, 7, 0).valueOf(),
    },
    {
      supplyRate: 0.033298963988434474,
      premiumRate: 0.04761887346394214,
      utilizationRate: 0.7521394303819444,
      date: new Date(2021, 10, 8, 0).valueOf(),
    },
    {
      supplyRate: 0.012525514319795565,
      premiumRate: 0.017579774272029884,
      utilizationRate: 0.7678862777777778,
      date: new Date(2021, 10, 9, 0).valueOf(),
    },
    {
      supplyRate: 0.03925963345445735,
      premiumRate: 0.0546586837367028,
      utilizationRate: 0.7809851814583334,
      date: new Date(2021, 10, 10, 0).valueOf(),
    },
    {
      supplyRate: 0.06521404227673055,
      premiumRate: 0.08990953247128843,
      utilizationRate: 0.7785948843055555,
      date: new Date(2021, 10, 11, 0).valueOf(),
    },
    {
      supplyRate: 0.02645600132868252,
      premiumRate: 0.03691236927587292,
      utilizationRate: 0.7690592320833333,
      date: new Date(2021, 10, 12, 0).valueOf(),
    },
    {
      supplyRate: 0.03500976155444466,
      premiumRate: 0.04886721747970312,
      utilizationRate: 0.7734871906597223,
      date: new Date(2021, 10, 13, 0).valueOf(),
    },
    {
      supplyRate: 0.04234981789701817,
      premiumRate: 0.05776145225045733,
      utilizationRate: 0.7852980838541667,
      date: new Date(2021, 10, 14, 0).valueOf(),
    },
    {
      supplyRate: 0.12745972782906198,
      premiumRate: 0.17228386954698136,
      utilizationRate: 0.7991072536805555,
      date: new Date(2021, 10, 15, 0).valueOf(),
    },
    {
      supplyRate: 0.027689250275320383,
      premiumRate: 0.038144523829224,
      utilizationRate: 0.7825640928125001,
      date: new Date(2021, 10, 16, 0).valueOf(),
    },
    {
      supplyRate: 0.03812413736208843,
      premiumRate: 0.05281514909340774,
      utilizationRate: 0.7631337175,
      date: new Date(2021, 10, 17, 0).valueOf(),
    },
    {
      supplyRate: 0.03303879123147411,
      premiumRate: 0.04714315339053495,
      utilizationRate: 0.7529607209722222,
      date: new Date(2021, 10, 18, 0).valueOf(),
    },
    {
      supplyRate: 0.0301749760188542,
      premiumRate: 0.04299119354250829,
      utilizationRate: 0.7538474070833333,
      date: new Date(2021, 10, 19, 0).valueOf(),
    },
    {
      supplyRate: 0.024493949991012193,
      premiumRate: 0.03488064563651397,
      utilizationRate: 0.7554529884375001,
      date: new Date(2021, 10, 20, 0).valueOf(),
    },
    {
      supplyRate: 0.03355267498780266,
      premiumRate: 0.04739069235073758,
      utilizationRate: 0.7625261088888888,
      date: new Date(2021, 10, 21, 0).valueOf(),
    },
    {
      supplyRate: 0.015273824144531264,
      premiumRate: 0.021612612019063455,
      utilizationRate: 0.7581899358333334,
      date: new Date(2021, 10, 22, 0).valueOf(),
    },
    {
      supplyRate: 0.03792933684347343,
      premiumRate: 0.053830287319876005,
      utilizationRate: 0.7566894218055555,
      date: new Date(2021, 10, 23, 0).valueOf(),
    },
    {
      supplyRate: 0.03131949572180014,
      premiumRate: 0.04407100177917474,
      utilizationRate: 0.7651942299652778,
      date: new Date(2021, 10, 24, 0).valueOf(),
    },
    {
      supplyRate: 0.02869672658184297,
      premiumRate: 0.040154604534582604,
      utilizationRate: 0.769269717361111,
      date: new Date(2021, 10, 25, 0).valueOf(),
    },
    {
      supplyRate: 0.0198717692061498,
      premiumRate: 0.027758705933519083,
      utilizationRate: 0.7415169692708333,
      date: new Date(2021, 10, 26, 0).valueOf(),
    },
    {
      supplyRate: 0.018078168684245326,
      premiumRate: 0.02842029884939796,
      utilizationRate: 0.6777272320138888,
      date: new Date(2021, 10, 27, 0).valueOf(),
    },
    {
      supplyRate: 0.01957078440633192,
      premiumRate: 0.030683631449307178,
      utilizationRate: 0.6846985000694444,
      date: new Date(2021, 10, 28, 0).valueOf(),
    },
    {
      supplyRate: 0.027638885573336627,
      premiumRate: 0.042471952272185566,
      utilizationRate: 0.6973853821527778,
      date: new Date(2021, 10, 29, 0).valueOf(),
    },
    {
      supplyRate: 0.02604457586291256,
      premiumRate: 0.040149607076888624,
      utilizationRate: 0.6666064235416667,
      date: new Date(2021, 10, 30, 0).valueOf(),
    },
    {
      supplyRate: 0.025970337539784602,
      premiumRate: 0.04324715110534212,
      utilizationRate: 0.6252664520486111,
      date: new Date(2021, 11, 1, 0).valueOf(),
    },
    {
      supplyRate: 0.01122637338762348,
      premiumRate: 0.019563930579733484,
      utilizationRate: 0.5992321911458334,
      date: new Date(2021, 11, 2, 0).valueOf(),
    },
    {
      supplyRate: 0.00034732533993408,
      premiumRate: 0.00060238733511024,
      utilizationRate: 0.60147662,
      date: new Date(2021, 11, 3, 0).valueOf(),
    },
    {
      supplyRate: 0.03656627048179065,
      premiumRate: 0.063259520446374,
      utilizationRate: 0.6216605504513889,
      date: new Date(2021, 11, 4, 0).valueOf(),
    },
    {
      supplyRate: 0.01939530234447819,
      premiumRate: 0.03153970099006967,
      utilizationRate: 0.6460779236805556,
      date: new Date(2021, 11, 5, 0).valueOf(),
    },
    {
      supplyRate: 0.03372579928397383,
      premiumRate: 0.053026111895628184,
      utilizationRate: 0.6827802778819445,
      date: new Date(2021, 11, 6, 0).valueOf(),
    },
    {
      supplyRate: 0.022979285064993197,
      premiumRate: 0.0350358109370126,
      utilizationRate: 0.6955990121875,
      date: new Date(2021, 11, 7, 0).valueOf(),
    },
    {
      supplyRate: 0.01226485038536142,
      premiumRate: 0.018729610438890598,
      utilizationRate: 0.6953522715277778,
      date: new Date(2021, 11, 8, 0).valueOf(),
    },
    {
      supplyRate: 0.03530006837097062,
      premiumRate: 0.053715312091073567,
      utilizationRate: 0.6972745146180556,
      date: new Date(2021, 11, 9, 0).valueOf(),
    },
    {
      supplyRate: 0.025281488824363995,
      premiumRate: 0.038574248765639065,
      utilizationRate: 0.6939173547569444,
      date: new Date(2021, 11, 10, 0).valueOf(),
    },
    {
      supplyRate: 0.01207527044747565,
      premiumRate: 0.018378996691490623,
      utilizationRate: 0.6959845372569444,
      date: new Date(2021, 11, 11, 0).valueOf(),
    },
    {
      supplyRate: 0.01278725330553942,
      premiumRate: 0.019460207888736182,
      utilizationRate: 0.7333857968055555,
      date: new Date(2021, 11, 12, 0).valueOf(),
    },
    {
      supplyRate: 0.05126941946127875,
      premiumRate: 0.07355098183432052,
      utilizationRate: 0.7573054717361111,
      date: new Date(2021, 11, 13, 0).valueOf(),
    },
    {
      supplyRate: 0.07851569339596702,
      premiumRate: 0.10669570268170213,
      utilizationRate: 0.7937244079166667,
      date: new Date(2021, 11, 14, 0).valueOf(),
    },
    {
      supplyRate: 0.08782783931136466,
      premiumRate: 0.11879865774377957,
      utilizationRate: 0.7869356456944445,
      date: new Date(2021, 11, 15, 0).valueOf(),
    },
    {
      supplyRate: 0.0604790204221008,
      premiumRate: 0.0836365666812036,
      utilizationRate: 0.7769040866666667,
      date: new Date(2021, 11, 16, 0).valueOf(),
    },
    {
      supplyRate: 0.042885029803779166,
      premiumRate: 0.05851366637910939,
      utilizationRate: 0.7883665061805556,
      date: new Date(2021, 11, 17, 0).valueOf(),
    },
    {
      supplyRate: 0.14387095401305788,
      premiumRate: 0.19465759366530458,
      utilizationRate: 0.7911908999652778,
      date: new Date(2021, 11, 18, 0).valueOf(),
    },
    {
      supplyRate: 0.13647764185579378,
      premiumRate: 0.18168558615554245,
      utilizationRate: 0.8034268592361111,
      date: new Date(2021, 11, 19, 0).valueOf(),
    },
    {
      supplyRate: 0.06576570361915896,
      premiumRate: 0.08975048000248446,
      utilizationRate: 0.7855850178125,
      date: new Date(2021, 11, 20, 0).valueOf(),
    },
    {
      supplyRate: 0.03489215584230705,
      premiumRate: 0.04781503754052375,
      utilizationRate: 0.7918168457291667,
      date: new Date(2021, 11, 21, 0).valueOf(),
    },
    {
      supplyRate: 0.1803232839290911,
      premiumRate: 0.2414745765950293,
      utilizationRate: 0.792344239375,
      date: new Date(2021, 11, 22, 0).valueOf(),
    },
    {
      supplyRate: 0.016670146261919865,
      premiumRate: 0.026078003741103358,
      utilizationRate: 0.6514704977430555,
      date: new Date(2021, 11, 23, 0).valueOf(),
    },
    {
      supplyRate: 0.011085761398560481,
      premiumRate: 0.019749685202228518,
      utilizationRate: 0.6398062225694444,
      date: new Date(2021, 11, 24, 0).valueOf(),
    },
    {
      supplyRate: 0.03206322954421842,
      premiumRate: 0.04949157023780136,
      utilizationRate: 0.6737473452777778,
      date: new Date(2021, 11, 25, 0).valueOf(),
    },
    {
      supplyRate: 0.022075899817429124,
      premiumRate: 0.034971095470168106,
      utilizationRate: 0.6720903825694444,
      date: new Date(2021, 11, 26, 0).valueOf(),
    },
    {
      supplyRate: 0.03472177112739909,
      premiumRate: 0.0536564752212311,
      utilizationRate: 0.6994140898958334,
      date: new Date(2021, 11, 27, 0).valueOf(),
    },
    {
      supplyRate: 0.0034286872433328596,
      premiumRate: 0.004946954847869819,
      utilizationRate: 0.7462440936805556,
      date: new Date(2021, 11, 28, 0).valueOf(),
    },
    {
      supplyRate: 0.0394499314426041,
      premiumRate: 0.0569209287154491,
      utilizationRate: 0.7469856502083333,
      date: new Date(2021, 11, 29, 0).valueOf(),
    },
    {
      supplyRate: 0.03418792801405376,
      premiumRate: 0.04898110611051722,
      utilizationRate: 0.7542268766666667,
      date: new Date(2021, 11, 30, 0).valueOf(),
    },
    {
      supplyRate: 0.029722582159875357,
      premiumRate: 0.04246035572448288,
      utilizationRate: 0.75446844,
      date: new Date(2021, 11, 31, 0).valueOf(),
    },
    {
      supplyRate: 0.02589084803535744,
      premiumRate: 0.036947416687804185,
      utilizationRate: 0.7558126645138888,
      date: new Date(2022, 0, 1, 0).valueOf(),
    },
    {
      supplyRate: 0.03639946226469963,
      premiumRate: 0.04990830337027798,
      utilizationRate: 0.7755826994097222,
      date: new Date(2022, 0, 2, 0).valueOf(),
    },
    {
      supplyRate: 0.06096936919473492,
      premiumRate: 0.08383376302265101,
      utilizationRate: 0.7837342183680556,
      date: new Date(2022, 0, 3, 0).valueOf(),
    },
    {
      supplyRate: 0.020238936721119406,
      premiumRate: 0.02814825353039865,
      utilizationRate: 0.7781098661805556,
      date: new Date(2022, 0, 4, 0).valueOf(),
    },
    {
      supplyRate: 0.02334235278987807,
      premiumRate: 0.032484114195338615,
      utilizationRate: 0.7795398085416667,
      date: new Date(2022, 0, 5, 0).valueOf(),
    },
    {
      supplyRate: 0.014688074502609748,
      premiumRate: 0.0203790367719072,
      utilizationRate: 0.77390401875,
      date: new Date(2022, 0, 6, 0).valueOf(),
    },
    {
      supplyRate: 0.04716507751736637,
      premiumRate: 0.07010827289319786,
      utilizationRate: 0.6652049987152778,
      date: new Date(2022, 0, 7, 0).valueOf(),
    },
    {
      supplyRate: 0.01636143812162655,
      premiumRate: 0.02678076663719451,
      utilizationRate: 0.6475971092013889,
      date: new Date(2022, 0, 8, 0).valueOf(),
    },
    {
      supplyRate: 0.025478206048736612,
      premiumRate: 0.04143835345708317,
      utilizationRate: 0.6522138003125,
      date: new Date(2022, 0, 9, 0).valueOf(),
    },
    {
      supplyRate: 0.012296618286663464,
      premiumRate: 0.01976365795441293,
      utilizationRate: 0.6390671190972221,
      date: new Date(2022, 0, 10, 0).valueOf(),
    },
    {
      supplyRate: 0.017929444500070932,
      premiumRate: 0.030934772850045946,
      utilizationRate: 0.6074003823611112,
      date: new Date(2022, 0, 11, 0).valueOf(),
    },
    {
      supplyRate: 0.00711054563098884,
      premiumRate: 0.01225766653752876,
      utilizationRate: 0.6124403383333333,
      date: new Date(2022, 0, 12, 0).valueOf(),
    },
    {
      supplyRate: 0.033824885573595975,
      premiumRate: 0.05789557916799079,
      utilizationRate: 0.6145399186805556,
      date: new Date(2022, 0, 13, 0).valueOf(),
    },
    {
      supplyRate: 0.02513402347527549,
      premiumRate: 0.0427120203551029,
      utilizationRate: 0.6203727664930555,
      date: new Date(2022, 0, 14, 0).valueOf(),
    },
    {
      supplyRate: 0.012366879890104111,
      premiumRate: 0.020651825293802578,
      utilizationRate: 0.6141711105555555,
      date: new Date(2022, 0, 15, 0).valueOf(),
    },
    {
      supplyRate: 0.0018319968115761602,
      premiumRate: 0.00324450586565424,
      utilizationRate: 0.58460601,
      date: new Date(2022, 0, 16, 0).valueOf(),
    },
    {
      supplyRate: 0.05561742650522347,
      premiumRate: 0.08451195562904609,
      utilizationRate: 0.6012295105902777,
      date: new Date(2022, 0, 17, 0).valueOf(),
    },
    {
      supplyRate: 0.023498488215897794,
      premiumRate: 0.044408789693279854,
      utilizationRate: 0.479404623125,
      date: new Date(2022, 0, 18, 0).valueOf(),
    },
    {
      supplyRate: 0,
      premiumRate: 0,
      utilizationRate: 0.31756156,
      date: new Date(2022, 0, 19, 0).valueOf(),
    },
    {
      supplyRate: 0.010186094940738931,
      premiumRate: 0.03218748864579523,
      utilizationRate: 0.319205669375,
      date: new Date(2022, 0, 20, 0).valueOf(),
    },
    {
      supplyRate: 0.00674351140853073,
      premiumRate: 0.021493140761045384,
      utilizationRate: 0.3133221628819445,
      date: new Date(2022, 0, 21, 0).valueOf(),
    },
    {
      supplyRate: 0.004377801491901465,
      premiumRate: 0.013545459449968546,
      utilizationRate: 0.31464518923611107,
      date: new Date(2022, 0, 22, 0).valueOf(),
    },
    {
      supplyRate: 0.00501890751876636,
      premiumRate: 0.016048772657599922,
      utilizationRate: 0.30170472881944443,
      date: new Date(2022, 0, 23, 0).valueOf(),
    },
    {
      supplyRate: 0.00484921883537649,
      premiumRate: 0.016123545355342845,
      utilizationRate: 0.29022246802083335,
      date: new Date(2022, 0, 24, 0).valueOf(),
    },
    {
      supplyRate: 0.006300226298611635,
      premiumRate: 0.02114522086936536,
      utilizationRate: 0.2882579548611111,
      date: new Date(2022, 0, 25, 0).valueOf(),
    },
    {
      supplyRate: 0.005522135387998515,
      premiumRate: 0.0181033389228186,
      utilizationRate: 0.2976306692361111,
      date: new Date(2022, 0, 26, 0).valueOf(),
    },
    {
      supplyRate: 0.00462213186883515,
      premiumRate: 0.014982324569761486,
      utilizationRate: 0.3011542434027778,
      date: new Date(2022, 0, 27, 0).valueOf(),
    },
    {
      supplyRate: 0.0023724310675067102,
      premiumRate: 0.01177790719989357,
      utilizationRate: 0.1637724125,
      date: new Date(2022, 0, 28, 0).valueOf(),
    },
    {
      supplyRate: 0.001642014035486145,
      premiumRate: 0.008499259873465288,
      utilizationRate: 0.2000621645833333,
      date: new Date(2022, 0, 29, 0).valueOf(),
    },
    {
      supplyRate: 0.00278660260171956,
      premiumRate: 0.010875575135802644,
      utilizationRate: 0.26174168493055555,
      date: new Date(2022, 0, 30, 0).valueOf(),
    },
    {
      supplyRate: 0.00552780635958897,
      premiumRate: 0.020607576899754884,
      utilizationRate: 0.2778415963888889,
      date: new Date(2022, 0, 31, 0).valueOf(),
    },
    {
      supplyRate: 0.002505236437389135,
      premiumRate: 0.008750678848165244,
      utilizationRate: 0.30150053708333335,
      date: new Date(2022, 1, 1, 0).valueOf(),
    },
    {
      supplyRate: 0.0036288852063806696,
      premiumRate: 0.01233192003710268,
      utilizationRate: 0.30546545631944444,
      date: new Date(2022, 1, 2, 0).valueOf(),
    },
    {
      supplyRate: 0.006656286745306275,
      premiumRate: 0.022818547365751292,
      utilizationRate: 0.27617981802083336,
      date: new Date(2022, 1, 3, 0).valueOf(),
    },
    {
      supplyRate: 0.004492613686249171,
      premiumRate: 0.018296629902675842,
      utilizationRate: 0.24808785541666667,
      date: new Date(2022, 1, 4, 0).valueOf(),
    },
    {
      supplyRate: 0.0028158423790468953,
      premiumRate: 0.011323642431945466,
      utilizationRate: 0.2521008202777778,
      date: new Date(2022, 1, 5, 0).valueOf(),
    },
    {
      supplyRate: 0.00324803181423501,
      premiumRate: 0.012904874927114895,
      utilizationRate: 0.25484569475694446,
      date: new Date(2022, 1, 6, 0).valueOf(),
    },
    {
      supplyRate: 0.004336920982631565,
      premiumRate: 0.016833197682930887,
      utilizationRate: 0.26392144788194444,
      date: new Date(2022, 1, 7, 0).valueOf(),
    },
    {
      supplyRate: 0.00261667624772121,
      premiumRate: 0.009474178085624431,
      utilizationRate: 0.28781011565972225,
      date: new Date(2022, 1, 8, 0).valueOf(),
    },
    {
      supplyRate: 0.003933249541439084,
      premiumRate: 0.013178154194620439,
      utilizationRate: 0.3098867207638889,
      date: new Date(2022, 1, 9, 0).valueOf(),
    },
    {
      supplyRate: 0.006251180291805015,
      premiumRate: 0.02108680029643674,
      utilizationRate: 0.3055841236111111,
      date: new Date(2022, 1, 10, 0).valueOf(),
    },
    {
      supplyRate: 0.005490166129756949,
      premiumRate: 0.01834243964771565,
      utilizationRate: 0.3098822819791667,
      date: new Date(2022, 1, 11, 0).valueOf(),
    },
    {
      supplyRate: 0.005730684746809695,
      premiumRate: 0.019070371770048195,
      utilizationRate: 0.3108018776388889,
      date: new Date(2022, 1, 12, 0).valueOf(),
    },
    {
      supplyRate: 0.00299729724629115,
      premiumRate: 0.009936192071822716,
      utilizationRate: 0.3143402335416667,
      date: new Date(2022, 1, 13, 0).valueOf(),
    },
    {
      supplyRate: 0.06067694491724718,
      premiumRate: 0.08443404396630996,
      utilizationRate: 0.5566751320138889,
      date: new Date(2022, 1, 14, 0).valueOf(),
    },
    {
      supplyRate: 0.09018018387291722,
      premiumRate: 0.11928225849065233,
      utilizationRate: 0.7970343961805555,
      date: new Date(2022, 1, 15, 0).valueOf(),
    },
    {
      supplyRate: 0.030466296213838156,
      premiumRate: 0.04258350880165226,
      utilizationRate: 0.7492688590277778,
      date: new Date(2022, 1, 16, 0).valueOf(),
    },
    {
      supplyRate: 0.023251947049155047,
      premiumRate: 0.03447225711489711,
      utilizationRate: 0.7444302376388889,
      date: new Date(2022, 1, 17, 0).valueOf(),
    },
    {
      supplyRate: 0.02696188207865688,
      premiumRate: 0.03946571357593116,
      utilizationRate: 0.7266901472222221,
      date: new Date(2022, 1, 18, 0).valueOf(),
    },
    {
      supplyRate: 0.03156896517147256,
      premiumRate: 0.04787026495420779,
      utilizationRate: 0.7020865312152779,
      date: new Date(2022, 1, 19, 0).valueOf(),
    },
    {
      supplyRate: 0.019778905098602265,
      premiumRate: 0.029313508808582744,
      utilizationRate: 0.7270764735069445,
      date: new Date(2022, 1, 20, 0).valueOf(),
    },
    {
      supplyRate: 0.024678093920010555,
      premiumRate: 0.03710592261531897,
      utilizationRate: 0.7106582703125,
      date: new Date(2022, 1, 21, 0).valueOf(),
    },
    {
      supplyRate: 0.01879388822592951,
      premiumRate: 0.028523846571650507,
      utilizationRate: 0.7072890795833334,
      date: new Date(2022, 1, 22, 0).valueOf(),
    },
    {
      supplyRate: 0.02164872550032822,
      premiumRate: 0.0328162154720748,
      utilizationRate: 0.7062044429166667,
      date: new Date(2022, 1, 23, 0).valueOf(),
    },
    {
      supplyRate: 0.03408418665897089,
      premiumRate: 0.05294916715143408,
      utilizationRate: 0.6822451592361111,
      date: new Date(2022, 1, 24, 0).valueOf(),
    },
    {
      supplyRate: 0.021569057766463107,
      premiumRate: 0.03268969708608775,
      utilizationRate: 0.709312682986111,
      date: new Date(2022, 1, 25, 0).valueOf(),
    },
    {
      supplyRate: 0.02687954385508191,
      premiumRate: 0.040310360937526796,
      utilizationRate: 0.7171412650694444,
      date: new Date(2022, 1, 26, 0).valueOf(),
    },
    {
      supplyRate: 0.021686400888784874,
      premiumRate: 0.032353553734504936,
      utilizationRate: 0.7180096296527778,
      date: new Date(2022, 1, 27, 0).valueOf(),
    },
    {
      supplyRate: 0.030006345464555342,
      premiumRate: 0.04397418893232926,
      utilizationRate: 0.7364663505902778,
      date: new Date(2022, 1, 28, 0).valueOf(),
    },
    {
      supplyRate: 0.029910962835397976,
      premiumRate: 0.04311156422191608,
      utilizationRate: 0.7294755888541666,
      date: new Date(2022, 2, 1, 0).valueOf(),
    },
    {
      supplyRate: 0.015549247082355765,
      premiumRate: 0.02748233149418424,
      utilizationRate: 0.6013736194444445,
      date: new Date(2022, 2, 2, 0).valueOf(),
    },
    {
      supplyRate: 0.00675513902715516,
      premiumRate: 0.01177600204721925,
      utilizationRate: 0.6094947052083334,
      date: new Date(2022, 2, 3, 0).valueOf(),
    },
    {
      supplyRate: 0.026477830628931137,
      premiumRate: 0.04611519692489247,
      utilizationRate: 0.610167954375,
      date: new Date(2022, 2, 4, 0).valueOf(),
    },
    {
      supplyRate: 0.014934553607841496,
      premiumRate: 0.02596948169843676,
      utilizationRate: 0.6132711270486112,
      date: new Date(2022, 2, 5, 0).valueOf(),
    },
    {
      supplyRate: 0.019900565583218552,
      premiumRate: 0.034249295440273275,
      utilizationRate: 0.6178093752083333,
      date: new Date(2022, 2, 6, 0).valueOf(),
    },
    {
      supplyRate: 0.017454632613901858,
      premiumRate: 0.03002883175937823,
      utilizationRate: 0.6181793100694445,
      date: new Date(2022, 2, 7, 0).valueOf(),
    },
    {
      supplyRate: 0.02385575909504292,
      premiumRate: 0.0409006363593858,
      utilizationRate: 0.6218862702430555,
      date: new Date(2022, 2, 8, 0).valueOf(),
    },
    {
      supplyRate: 0.019473084214767407,
      premiumRate: 0.033034274013886936,
      utilizationRate: 0.6281644558680556,
      date: new Date(2022, 2, 9, 0).valueOf(),
    },
    {
      supplyRate: 0.023282200650184905,
      premiumRate: 0.039519835644983764,
      utilizationRate: 0.6265138236805555,
      date: new Date(2022, 2, 10, 0).valueOf(),
    },
    {
      supplyRate: 0.01353989175647202,
      premiumRate: 0.02341317977869503,
      utilizationRate: 0.6141187694097222,
      date: new Date(2022, 2, 11, 0).valueOf(),
    },
    {
      supplyRate: 0.015681148458522674,
      premiumRate: 0.02701379036293197,
      utilizationRate: 0.6305722592708334,
      date: new Date(2022, 2, 12, 0).valueOf(),
    },
    {
      supplyRate: 0.015485706614056318,
      premiumRate: 0.02572074836787984,
      utilizationRate: 0.6423923693055555,
      date: new Date(2022, 2, 13, 0).valueOf(),
    },
    {
      supplyRate: 0.03419689956174875,
      premiumRate: 0.05677946037374334,
      utilizationRate: 0.6430780227083334,
      date: new Date(2022, 2, 14, 0).valueOf(),
    },
    {
      supplyRate: 0.016540553783848874,
      premiumRate: 0.027263803378522335,
      utilizationRate: 0.6480307073958333,
      date: new Date(2022, 2, 15, 0).valueOf(),
    },
    {
      supplyRate: 0.05254002429662173,
      premiumRate: 0.0717912997987299,
      utilizationRate: 0.6768358189583332,
      date: new Date(2022, 2, 16, 0).valueOf(),
    },
    {
      supplyRate: 0.13951293374582016,
      premiumRate: 0.18363557580615408,
      utilizationRate: 0.8080763733680556,
      date: new Date(2022, 2, 17, 0).valueOf(),
    },
    {
      supplyRate: 0.0442881137999811,
      premiumRate: 0.06125309537532439,
      utilizationRate: 0.7665891953125,
      date: new Date(2022, 2, 18, 0).valueOf(),
    },
    {
      supplyRate: 0.027422631501131146,
      premiumRate: 0.03902246989459356,
      utilizationRate: 0.7598072245833333,
      date: new Date(2022, 2, 19, 0).valueOf(),
    },
    {
      supplyRate: 0.026804443048360202,
      premiumRate: 0.03844858313885081,
      utilizationRate: 0.7531184777083334,
      date: new Date(2022, 2, 20, 0).valueOf(),
    },
    {
      supplyRate: 0.026909077198362538,
      premiumRate: 0.03866645024456139,
      utilizationRate: 0.7516691757638889,
      date: new Date(2022, 2, 21, 0).valueOf(),
    },
    {
      supplyRate: 0.02637458840603277,
      premiumRate: 0.03790303251501856,
      utilizationRate: 0.7515548601041666,
      date: new Date(2022, 2, 22, 0).valueOf(),
    },
    {
      supplyRate: 0.022523428276346895,
      premiumRate: 0.03243048101762317,
      utilizationRate: 0.7547015504513889,
      date: new Date(2022, 2, 23, 0).valueOf(),
    },
    {
      supplyRate: 0.057668557812619765,
      premiumRate: 0.07929481562171739,
      utilizationRate: 0.7863662739583334,
      date: new Date(2022, 2, 24, 0).valueOf(),
    },
    {
      supplyRate: 0.2373550393854127,
      premiumRate: 0.28596921050284485,
      utilizationRate: 0.7650657893055555,
      date: new Date(2022, 2, 25, 0).valueOf(),
    },
    {
      supplyRate: 0.07039843940645182,
      premiumRate: 0.09843537256481395,
      utilizationRate: 0.6899082184375,
      date: new Date(2022, 2, 26, 0).valueOf(),
    },
    {
      supplyRate: 0.09977119833829204,
      premiumRate: 0.1345767350026288,
      utilizationRate: 0.8064970260416666,
      date: new Date(2022, 2, 27, 0).valueOf(),
    },
    {
      supplyRate: 0.08531875127183958,
      premiumRate: 0.1154104133907788,
      utilizationRate: 0.7999306675347222,
      date: new Date(2022, 2, 28, 0).valueOf(),
    },
    {
      supplyRate: 0.0762821016077237,
      premiumRate: 0.10428986941918035,
      utilizationRate: 0.7961362040277777,
      date: new Date(2022, 2, 29, 0).valueOf(),
    },
    {
      supplyRate: 0.040182260063653136,
      premiumRate: 0.055725464066386184,
      utilizationRate: 0.7827822153819445,
      date: new Date(2022, 2, 30, 0).valueOf(),
    },
    {
      supplyRate: 0.047117945853524176,
      premiumRate: 0.0647448161030747,
      utilizationRate: 0.7915693653472222,
      date: new Date(2022, 2, 31, 0).valueOf(),
    },
    {
      supplyRate: 0.05184945110770314,
      premiumRate: 0.07096651738248783,
      utilizationRate: 0.7912109768402779,
      date: new Date(2022, 3, 1, 0).valueOf(),
    },
    {
      supplyRate: 0.037929800393716157,
      premiumRate: 0.052559488822273466,
      utilizationRate: 0.7853238887152778,
      date: new Date(2022, 3, 2, 0).valueOf(),
    },
    {
      supplyRate: 0.06596750940976652,
      premiumRate: 0.09082703941896707,
      utilizationRate: 0.7904009369444445,
      date: new Date(2022, 3, 3, 0).valueOf(),
    },
    {
      supplyRate: 0.04961353473561702,
      premiumRate: 0.06844150752079997,
      utilizationRate: 0.7812232264236111,
      date: new Date(2022, 3, 4, 0).valueOf(),
    },
    {
      supplyRate: 0.02271650258164359,
      premiumRate: 0.03207828469409492,
      utilizationRate: 0.7621416148958333,
      date: new Date(2022, 3, 5, 0).valueOf(),
    },
    {
      supplyRate: 0.06249681761524198,
      premiumRate: 0.0867083207101351,
      utilizationRate: 0.7728912695833333,
      date: new Date(2022, 3, 6, 0).valueOf(),
    },
    {
      supplyRate: 0.029947755770669233,
      premiumRate: 0.0424860479106738,
      utilizationRate: 0.7595728106944445,
      date: new Date(2022, 3, 7, 0).valueOf(),
    },
    {
      supplyRate: 0.07910445268627364,
      premiumRate: 0.10755001070596197,
      utilizationRate: 0.7855822173958333,
      date: new Date(2022, 3, 8, 0).valueOf(),
    },
    {
      supplyRate: 0.022877171458924424,
      premiumRate: 0.0326570921043499,
      utilizationRate: 0.7603883492708333,
      date: new Date(2022, 3, 9, 0).valueOf(),
    },
    {
      supplyRate: 0.01981768627353381,
      premiumRate: 0.02825995319740467,
      utilizationRate: 0.7620702698611111,
      date: new Date(2022, 3, 10, 0).valueOf(),
    },
    {
      supplyRate: 0.03252706076165377,
      premiumRate: 0.04637402317265187,
      utilizationRate: 0.7553792082291666,
      date: new Date(2022, 3, 11, 0).valueOf(),
    },
    {
      supplyRate: 0.02848537283984049,
      premiumRate: 0.04187512135385779,
      utilizationRate: 0.7354724359722221,
      date: new Date(2022, 3, 12, 0).valueOf(),
    },
    {
      supplyRate: 0.026480055859305643,
      premiumRate: 0.03889962996044448,
      utilizationRate: 0.7390631841666667,
      date: new Date(2022, 3, 13, 0).valueOf(),
    },
    {
      supplyRate: 0.030358357711588742,
      premiumRate: 0.042877660600980014,
      utilizationRate: 0.76970339625,
      date: new Date(2022, 3, 14, 0).valueOf(),
    },
    {
      supplyRate: 0.02855842546021806,
      premiumRate: 0.04026045618180351,
      utilizationRate: 0.7706764147222223,
      date: new Date(2022, 3, 15, 0).valueOf(),
    },
    {
      supplyRate: 0.028175375571902774,
      premiumRate: 0.03957610167349335,
      utilizationRate: 0.7737227686458333,
      date: new Date(2022, 3, 16, 0).valueOf(),
    },
    {
      supplyRate: 0.02875535218478458,
      premiumRate: 0.040344762748387254,
      utilizationRate: 0.7746778917708332,
      date: new Date(2022, 3, 17, 0).valueOf(),
    },
    {
      supplyRate: 0.025133020395260733,
      premiumRate: 0.03528115088017821,
      utilizationRate: 0.7733341809375,
      date: new Date(2022, 3, 18, 0).valueOf(),
    },
    {
      supplyRate: 0.031949543926410896,
      premiumRate: 0.04496570735899277,
      utilizationRate: 0.7731148887847223,
      date: new Date(2022, 3, 19, 0).valueOf(),
    },
    {
      supplyRate: 0.03362840989725531,
      premiumRate: 0.04672872557234845,
      utilizationRate: 0.7831305962152778,
      date: new Date(2022, 3, 20, 0).valueOf(),
    },
    {
      supplyRate: 0.03584755582060183,
      premiumRate: 0.04963909175835974,
      utilizationRate: 0.7856468957291667,
      date: new Date(2022, 3, 21, 0).valueOf(),
    },
    {
      supplyRate: 0.03531224196625842,
      premiumRate: 0.048996311518012856,
      utilizationRate: 0.7837545887847222,
      date: new Date(2022, 3, 22, 0).valueOf(),
    },
    {
      supplyRate: 0.03381593535758175,
      premiumRate: 0.04683881581919935,
      utilizationRate: 0.7857673099305555,
      date: new Date(2022, 3, 23, 0).valueOf(),
    },
    {
      supplyRate: 0.03218564505456185,
      premiumRate: 0.045845327143479185,
      utilizationRate: 0.7530519976388889,
      date: new Date(2022, 3, 24, 0).valueOf(),
    },
    {
      supplyRate: 0.01936607662419255,
      premiumRate: 0.028664627799859812,
      utilizationRate: 0.7334185685069444,
      date: new Date(2022, 3, 25, 0).valueOf(),
    },
    {
      supplyRate: 0.029947248158211242,
      premiumRate: 0.04399126121013305,
      utilizationRate: 0.7378651204513889,
      date: new Date(2022, 3, 26, 0).valueOf(),
    },
    {
      supplyRate: 0.015338124758073763,
      premiumRate: 0.022821271456606857,
      utilizationRate: 0.7253623366666666,
      date: new Date(2022, 3, 27, 0).valueOf(),
    },
    {
      supplyRate: 0.03695642291520804,
      premiumRate: 0.054946556411311534,
      utilizationRate: 0.7290696513541667,
      date: new Date(2022, 3, 28, 0).valueOf(),
    },
    {
      supplyRate: 0.00951485439555381,
      premiumRate: 0.014292985821390661,
      utilizationRate: 0.7226706753125,
      date: new Date(2022, 3, 29, 0).valueOf(),
    },
    {
      supplyRate: 0.03814514383372372,
      premiumRate: 0.05691555891374817,
      utilizationRate: 0.7252389852083333,
      date: new Date(2022, 3, 30, 0).valueOf(),
    },
    {
      supplyRate: 0.03044125938959403,
      premiumRate: 0.04211625459878507,
      utilizationRate: 0.748828607013889,
      date: new Date(2022, 4, 1, 0).valueOf(),
    },
    {
      supplyRate: 0.0514810338027079,
      premiumRate: 0.07114105471083787,
      utilizationRate: 0.7888300645486112,
      date: new Date(2022, 4, 2, 0).valueOf(),
    },
    {
      supplyRate: 0.021976367723424435,
      premiumRate: 0.030928670210544837,
      utilizationRate: 0.7712353442361111,
      date: new Date(2022, 4, 3, 0).valueOf(),
    },
    {
      supplyRate: 0.03641062357107195,
      premiumRate: 0.05143064468181571,
      utilizationRate: 0.7695960913194444,
      date: new Date(2022, 4, 4, 0).valueOf(),
    },
    {
      supplyRate: 0.02262913152001579,
      premiumRate: 0.03276467501384433,
      utilizationRate: 0.7405370721180556,
      date: new Date(2022, 4, 5, 0).valueOf(),
    },
    {
      supplyRate: 0.026063076354639884,
      premiumRate: 0.040068198984248835,
      utilizationRate: 0.6996027730208333,
      date: new Date(2022, 4, 6, 0).valueOf(),
    },
    {
      supplyRate: 0.023956580657969566,
      premiumRate: 0.03769147829055451,
      utilizationRate: 0.6840745711111111,
      date: new Date(2022, 4, 7, 0).valueOf(),
    },
    {
      supplyRate: 0.01848570959527566,
      premiumRate: 0.030329023450323806,
      utilizationRate: 0.6516623769791666,
      date: new Date(2022, 4, 8, 0).valueOf(),
    },
    {
      supplyRate: 0.02071008672039699,
      premiumRate: 0.03394288078159059,
      utilizationRate: 0.6519131830902778,
      date: new Date(2022, 4, 9, 0).valueOf(),
    },
    {
      supplyRate: 0.014218808372394028,
      premiumRate: 0.023575169039902515,
      utilizationRate: 0.6374922715972222,
      date: new Date(2022, 4, 10, 0).valueOf(),
    },
    {
      supplyRate: 0.023484695845503042,
      premiumRate: 0.03891765243928405,
      utilizationRate: 0.6445449915625,
      date: new Date(2022, 4, 11, 0).valueOf(),
    },
    {
      supplyRate: 0.015313854185578905,
      premiumRate: 0.028209916042329627,
      utilizationRate: 0.5675499030208333,
      date: new Date(2022, 4, 12, 0).valueOf(),
    },
    {
      supplyRate: 0.014026870474517956,
      premiumRate: 0.026228011052646272,
      utilizationRate: 0.5572711422569445,
      date: new Date(2022, 4, 13, 0).valueOf(),
    },
    {
      supplyRate: 0.01670728523611929,
      premiumRate: 0.03157531700864496,
      utilizationRate: 0.5517797815625001,
      date: new Date(2022, 4, 14, 0).valueOf(),
    },
    {
      supplyRate: 0.01704605119252542,
      premiumRate: 0.032135102270346466,
      utilizationRate: 0.5532090302777778,
      date: new Date(2022, 4, 15, 0).valueOf(),
    },
    {
      supplyRate: 0.016286417249321207,
      premiumRate: 0.03096883058977536,
      utilizationRate: 0.5492943398958334,
      date: new Date(2022, 4, 16, 0).valueOf(),
    },
    {
      supplyRate: 0.011878288364459234,
      premiumRate: 0.022542274749019948,
      utilizationRate: 0.5494771434375001,
      date: new Date(2022, 4, 17, 0).valueOf(),
    },
    {
      supplyRate: 0.016886851497240795,
      premiumRate: 0.03233235372395528,
      utilizationRate: 0.5419072851388889,
      date: new Date(2022, 4, 18, 0).valueOf(),
    },
    {
      supplyRate: 0.01318003272034182,
      premiumRate: 0.02590613903500524,
      utilizationRate: 0.5279383815625001,
      date: new Date(2022, 4, 19, 0).valueOf(),
    },
    {
      supplyRate: 0.015789969221112657,
      premiumRate: 0.031190764804899282,
      utilizationRate: 0.5259848507638889,
      date: new Date(2022, 4, 20, 0).valueOf(),
    },
    {
      supplyRate: 0.013370436581742359,
      premiumRate: 0.02631497463163827,
      utilizationRate: 0.5305102129861111,
      date: new Date(2022, 4, 21, 0).valueOf(),
    },
    {
      supplyRate: 0.016462379603473812,
      premiumRate: 0.032418250044487576,
      utilizationRate: 0.5242721507291667,
      date: new Date(2022, 4, 22, 0).valueOf(),
    },
    {
      supplyRate: 0.011790359049958815,
      premiumRate: 0.024663874545305775,
      utilizationRate: 0.49227394565972227,
      date: new Date(2022, 4, 23, 0).valueOf(),
    },
    {
      supplyRate: 0.01391954857341081,
      premiumRate: 0.02872081622705202,
      utilizationRate: 0.5003470211111112,
      date: new Date(2022, 4, 24, 0).valueOf(),
    },
    {
      supplyRate: 0.008361326780421676,
      premiumRate: 0.01704931727786553,
      utilizationRate: 0.5061165111111111,
      date: new Date(2022, 4, 25, 0).valueOf(),
    },
    {
      supplyRate: 0.015613065607704122,
      premiumRate: 0.031910349653841236,
      utilizationRate: 0.5053153189930556,
      date: new Date(2022, 4, 26, 0).valueOf(),
    },
    {
      supplyRate: 0.01274198988008304,
      premiumRate: 0.027339373609330662,
      utilizationRate: 0.47476055649305554,
      date: new Date(2022, 4, 27, 0).valueOf(),
    },
    {
      supplyRate: 0.00641944999152129,
      premiumRate: 0.014399681423452996,
      utilizationRate: 0.4516381631944444,
      date: new Date(2022, 4, 28, 0).valueOf(),
    },
    {
      supplyRate: 0.01339945605888093,
      premiumRate: 0.030177355528776286,
      utilizationRate: 0.4502200177083333,
      date: new Date(2022, 4, 29, 0).valueOf(),
    },
    {
      supplyRate: 0.00991741209197346,
      premiumRate: 0.0224191935746916,
      utilizationRate: 0.44805605472222226,
      date: new Date(2022, 4, 30, 0).valueOf(),
    },
    {
      supplyRate: 0.012777914083959915,
      premiumRate: 0.02871890010695844,
      utilizationRate: 0.45135463072916665,
      date: new Date(2022, 4, 31, 0).valueOf(),
    },
    {
      supplyRate: 0.0067913708890068,
      premiumRate: 0.015195870585495331,
      utilizationRate: 0.4533737428472222,
      date: new Date(2022, 5, 1, 0).valueOf(),
    },
    {
      supplyRate: 0.009381663815384864,
      premiumRate: 0.020967272642612864,
      utilizationRate: 0.45388571739583333,
      date: new Date(2022, 5, 2, 0).valueOf(),
    },
    {
      supplyRate: 0.01506315298960293,
      premiumRate: 0.0336499751158995,
      utilizationRate: 0.45468340392361106,
      date: new Date(2022, 5, 3, 0).valueOf(),
    },
    {
      supplyRate: 0.013008638924629664,
      premiumRate: 0.028865781136321544,
      utilizationRate: 0.4573009040277778,
      date: new Date(2022, 5, 4, 0).valueOf(),
    },
    {
      supplyRate: 0.006574207620268859,
      premiumRate: 0.014587780201518614,
      utilizationRate: 0.4569782681597222,
      date: new Date(2022, 5, 5, 0).valueOf(),
    },
    {
      supplyRate: 0.0122595471165153,
      premiumRate: 0.027235082307203114,
      utilizationRate: 0.4405332899652778,
      date: new Date(2022, 5, 6, 0).valueOf(),
    },
    {
      supplyRate: 0.00734237896103586,
      premiumRate: 0.018135749866852065,
      utilizationRate: 0.39523645524305556,
      date: new Date(2022, 5, 7, 0).valueOf(),
    },
    {
      supplyRate: 0.013787856574866796,
      premiumRate: 0.03425955151352805,
      utilizationRate: 0.4001241086111111,
      date: new Date(2022, 5, 8, 0).valueOf(),
    },
    {
      supplyRate: 0.0036432265896537003,
      premiumRate: 0.008990303866397702,
      utilizationRate: 0.4018048430208333,
      date: new Date(2022, 5, 9, 0).valueOf(),
    },
    {
      supplyRate: 0.000199240475253495,
      premiumRate: 0.000496796792311395,
      utilizationRate: 0.40019290909722227,
      date: new Date(2022, 5, 10, 0).valueOf(),
    },
    {
      supplyRate: 0.008828245068122971,
      premiumRate: 0.02285197709706096,
      utilizationRate: 0.3719214075694444,
      date: new Date(2022, 5, 11, 0).valueOf(),
    },
    {
      supplyRate: 0.00600836782517784,
      premiumRate: 0.01802435191000857,
      utilizationRate: 0.3127729988888889,
      date: new Date(2022, 5, 12, 0).valueOf(),
    },
    {
      supplyRate: 0.00597408366819984,
      premiumRate: 0.018429597082016788,
      utilizationRate: 0.29997600614583336,
      date: new Date(2022, 5, 13, 0).valueOf(),
    },
    {
      supplyRate: 0.00521893118773395,
      premiumRate: 0.0164231394608844,
      utilizationRate: 0.2940946846180556,
      date: new Date(2022, 5, 14, 0).valueOf(),
    },
    {
      supplyRate: 0.00435651916222911,
      premiumRate: 0.01404196642357761,
      utilizationRate: 0.28413903947916663,
      date: new Date(2022, 5, 15, 0).valueOf(),
    },
    {
      supplyRate: 0.006062897945900505,
      premiumRate: 0.019690700020042067,
      utilizationRate: 0.2821330032638889,
      date: new Date(2022, 5, 16, 0).valueOf(),
    },
    {
      supplyRate: 0.00471061943483178,
      premiumRate: 0.015007649771787587,
      utilizationRate: 0.28883197479166667,
      date: new Date(2022, 5, 17, 0).valueOf(),
    },
    {
      supplyRate: 0.0060582680983182445,
      premiumRate: 0.018184339191839597,
      utilizationRate: 0.3023583141319445,
      date: new Date(2022, 5, 18, 0).valueOf(),
    },
    {
      supplyRate: 0.003880123671559215,
      premiumRate: 0.012555763216774408,
      utilizationRate: 0.28049587961805555,
      date: new Date(2022, 5, 19, 0).valueOf(),
    },
    {
      supplyRate: 0.004776030472762185,
      premiumRate: 0.015569085689580165,
      utilizationRate: 0.27868356250000004,
      date: new Date(2022, 5, 20, 0).valueOf(),
    },
    {
      supplyRate: 0.00525657555257505,
      premiumRate: 0.017168010370347087,
      utilizationRate: 0.27766361277777774,
      date: new Date(2022, 5, 21, 0).valueOf(),
    },
    {
      supplyRate: 0.00454425255002724,
      premiumRate: 0.01489818815328411,
      utilizationRate: 0.2759774985763889,
      date: new Date(2022, 5, 22, 0).valueOf(),
    },
    {
      supplyRate: 0.004740781303470705,
      premiumRate: 0.01566079255762992,
      utilizationRate: 0.2718973082986111,
      date: new Date(2022, 5, 23, 0).valueOf(),
    },
    {
      supplyRate: 0.0047486535283575455,
      premiumRate: 0.01599866397468412,
      utilizationRate: 0.26592295315972225,
      date: new Date(2022, 5, 24, 0).valueOf(),
    },
    {
      supplyRate: 0.004225915420250235,
      premiumRate: 0.014222130061029313,
      utilizationRate: 0.2664740610416667,
      date: new Date(2022, 5, 25, 0).valueOf(),
    },
    {
      supplyRate: 0.00650708836849656,
      premiumRate: 0.02214953174740449,
      utilizationRate: 0.2621646501388889,
      date: new Date(2022, 5, 26, 0).valueOf(),
    },
    {
      supplyRate: 0.005085222093645075,
      premiumRate: 0.016451298761855925,
      utilizationRate: 0.27869032923611115,
      date: new Date(2022, 5, 27, 0).valueOf(),
    },
    {
      supplyRate: 0.00556576192189488,
      premiumRate: 0.017687074994784738,
      utilizationRate: 0.28469210270833334,
      date: new Date(2022, 5, 28, 0).valueOf(),
    },
    {
      supplyRate: 0.0030407476821506397,
      premiumRate: 0.00961483796818944,
      utilizationRate: 0.28722326673611115,
      date: new Date(2022, 5, 29, 0).valueOf(),
    },
    {
      supplyRate: 0.007317422883802125,
      premiumRate: 0.022949277892846096,
      utilizationRate: 0.2893675782638889,
      date: new Date(2022, 5, 30, 0).valueOf(),
    },
    {
      supplyRate: 0.006038270595641655,
      premiumRate: 0.018917084850355363,
      utilizationRate: 0.289215035,
      date: new Date(2022, 6, 1, 0).valueOf(),
    },
    {
      supplyRate: 0.0059130463755495,
      premiumRate: 0.018510004595509034,
      utilizationRate: 0.28917669836805554,
      date: new Date(2022, 6, 2, 0).valueOf(),
    },
    {
      supplyRate: 0.004536851780742195,
      premiumRate: 0.014279386595553688,
      utilizationRate: 0.28729445899305556,
      date: new Date(2022, 6, 3, 0).valueOf(),
    },
    {
      supplyRate: 0.0049710044025862805,
      premiumRate: 0.015640127487891255,
      utilizationRate: 0.2869957722222222,
      date: new Date(2022, 6, 4, 0).valueOf(),
    },
    {
      supplyRate: 0.004763307130312095,
      premiumRate: 0.015338388156002894,
      utilizationRate: 0.2793700466319444,
      date: new Date(2022, 6, 5, 0).valueOf(),
    },
    {
      supplyRate: 0.006264608365443075,
      premiumRate: 0.01985440739056374,
      utilizationRate: 0.28868432868055555,
      date: new Date(2022, 6, 6, 0).valueOf(),
    },
    {
      supplyRate: 0.0037694011798244547,
      premiumRate: 0.01117707113577375,
      utilizationRate: 0.31897051034722224,
      date: new Date(2022, 6, 7, 0).valueOf(),
    },
    {
      supplyRate: 0.010031758471262565,
      premiumRate: 0.026734857696892003,
      utilizationRate: 0.3513513229861111,
      date: new Date(2022, 6, 8, 0).valueOf(),
    },
    {
      supplyRate: 0.0035585384849568156,
      premiumRate: 0.00944120998272408,
      utilizationRate: 0.3519200487847222,
      date: new Date(2022, 6, 9, 0).valueOf(),
    },
    {
      supplyRate: 0.004802899234337565,
      premiumRate: 0.012760741253753715,
      utilizationRate: 0.3512627235763889,
      date: new Date(2022, 6, 10, 0).valueOf(),
    },
    {
      supplyRate: 0.011936628358766565,
      premiumRate: 0.03187506887775911,
      utilizationRate: 0.34763182065972226,
      date: new Date(2022, 6, 11, 0).valueOf(),
    },
    {
      supplyRate: 0.006026948509701016,
      premiumRate: 0.016488933828581173,
      utilizationRate: 0.3355596982638889,
      date: new Date(2022, 6, 12, 0).valueOf(),
    },
    {
      supplyRate: 0.008356916885575919,
      premiumRate: 0.023427779183164677,
      utilizationRate: 0.3261578867013889,
      date: new Date(2022, 6, 13, 0).valueOf(),
    },
    {
      supplyRate: 0.006550915768185525,
      premiumRate: 0.018413228750605706,
      utilizationRate: 0.3252820669791667,
      date: new Date(2022, 6, 14, 0).valueOf(),
    },
    {
      supplyRate: 0.003273521828549085,
      premiumRate: 0.009201275472581295,
      utilizationRate: 0.3239634482638889,
      date: new Date(2022, 6, 15, 0).valueOf(),
    },
    {
      supplyRate: 0.00493273664384706,
      premiumRate: 0.013824426882544306,
      utilizationRate: 0.3274648439930556,
      date: new Date(2022, 6, 16, 0).valueOf(),
    },
    {
      supplyRate: 0.008503594835902859,
      premiumRate: 0.02368035420770514,
      utilizationRate: 0.3285174210416667,
      date: new Date(2022, 6, 17, 0).valueOf(),
    },
    {
      supplyRate: 0.005877426077022165,
      premiumRate: 0.016431322100312146,
      utilizationRate: 0.32730900739583335,
      date: new Date(2022, 6, 18, 0).valueOf(),
    },
    {
      supplyRate: 0.006415000020803385,
      premiumRate: 0.01792830519701589,
      utilizationRate: 0.3280806513541667,
      date: new Date(2022, 6, 19, 0).valueOf(),
    },
    {
      supplyRate: 0.006284916048224355,
      premiumRate: 0.01748816047583529,
      utilizationRate: 0.32953044020833333,
      date: new Date(2022, 6, 20, 0).valueOf(),
    },
    {
      supplyRate: 0.00596075197369581,
      premiumRate: 0.01652278989130476,
      utilizationRate: 0.33344340864583333,
      date: new Date(2022, 6, 21, 0).valueOf(),
    },
    {
      supplyRate: 0.0092353631926026,
      premiumRate: 0.025608037681605363,
      utilizationRate: 0.3260476497222222,
      date: new Date(2022, 6, 22, 0).valueOf(),
    },
    {
      supplyRate: 0.00805943578604121,
      premiumRate: 0.022820575553061422,
      utilizationRate: 0.3215979937847222,
      date: new Date(2022, 6, 23, 0).valueOf(),
    },
    {
      supplyRate: 0.00355819697097708,
      premiumRate: 0.01007149620450315,
      utilizationRate: 0.3217729250347222,
      date: new Date(2022, 6, 24, 0).valueOf(),
    },
    {
      supplyRate: 0.010514450470460866,
      premiumRate: 0.029712114314729823,
      utilizationRate: 0.3229774516319444,
      date: new Date(2022, 6, 25, 0).valueOf(),
    },
    {
      supplyRate: 0.00622830798556185,
      premiumRate: 0.01742005204039758,
      utilizationRate: 0.3276049933680556,
      date: new Date(2022, 6, 26, 0).valueOf(),
    },
    {
      supplyRate: 0.00670898569594995,
      premiumRate: 0.01867107760866876,
      utilizationRate: 0.32921474194444444,
      date: new Date(2022, 6, 27, 0).valueOf(),
    },
    {
      supplyRate: 0.010410147965896396,
      premiumRate: 0.02683932985919172,
      utilizationRate: 0.3721764773611111,
      date: new Date(2022, 6, 28, 0).valueOf(),
    },
    {
      supplyRate: 0.007984201842777615,
      premiumRate: 0.02103842226942288,
      utilizationRate: 0.3530512223958333,
      date: new Date(2022, 6, 29, 0).valueOf(),
    },
    {
      supplyRate: 0.00462640557842469,
      premiumRate: 0.015852106130687056,
      utilizationRate: 0.2697085142708333,
      date: new Date(2022, 6, 30, 0).valueOf(),
    },
    {
      supplyRate: 0.0026660728773012002,
      premiumRate: 0.00917786768279805,
      utilizationRate: 0.26756811381944445,
      date: new Date(2022, 6, 31, 0).valueOf(),
    },
    {
      supplyRate: 0.006437721641649225,
      premiumRate: 0.022230182123592256,
      utilizationRate: 0.2669139573611111,
      date: new Date(2022, 7, 1, 0).valueOf(),
    },
    {
      supplyRate: 0.00412402323630285,
      premiumRate: 0.014227043579758335,
      utilizationRate: 0.26742237086805554,
      date: new Date(2022, 7, 2, 0).valueOf(),
    },
    {
      supplyRate: 0.005879869339749554,
      premiumRate: 0.02021750652682995,
      utilizationRate: 0.26847794520833335,
      date: new Date(2022, 7, 3, 0).valueOf(),
    },
    {
      supplyRate: 0.00371271424679103,
      premiumRate: 0.01246138749546054,
      utilizationRate: 0.2795581765625,
      date: new Date(2022, 7, 4, 0).valueOf(),
    },
    {
      supplyRate: 0.0047583315243045305,
      premiumRate: 0.01546537555134531,
      utilizationRate: 0.288533414375,
      date: new Date(2022, 7, 5, 0).valueOf(),
    },
    {
      supplyRate: 0.004886304030540885,
      premiumRate: 0.015914661927333193,
      utilizationRate: 0.2878892476388889,
      date: new Date(2022, 7, 6, 0).valueOf(),
    },
    {
      supplyRate: 0.00645417525384702,
      premiumRate: 0.020959435988472525,
      utilizationRate: 0.28838032739583336,
      date: new Date(2022, 7, 7, 0).valueOf(),
    },
    {
      supplyRate: 0.00343129087593432,
      premiumRate: 0.01111542553050918,
      utilizationRate: 0.28896183559027777,
      date: new Date(2022, 7, 8, 0).valueOf(),
    },
    {
      supplyRate: 0.00654968967573927,
      premiumRate: 0.021146962538937164,
      utilizationRate: 0.2928754629861111,
      date: new Date(2022, 7, 9, 0).valueOf(),
    },
    {
      supplyRate: 0.00396272827150602,
      premiumRate: 0.012651001406846971,
      utilizationRate: 0.2958249765625,
      date: new Date(2022, 7, 10, 0).valueOf(),
    },
    {
      supplyRate: 0.006889340383539794,
      premiumRate: 0.02197910782090788,
      utilizationRate: 0.29595832677083334,
      date: new Date(2022, 7, 11, 0).valueOf(),
    },
    {
      supplyRate: 0.005092964893522095,
      premiumRate: 0.016311225185898195,
      utilizationRate: 0.29276955600694443,
      date: new Date(2022, 7, 12, 0).valueOf(),
    },
    {
      supplyRate: 0.00402648970542135,
      premiumRate: 0.01352532312177393,
      utilizationRate: 0.28183780281250004,
      date: new Date(2022, 7, 13, 0).valueOf(),
    },
    {
      supplyRate: 0.004818911367480555,
      premiumRate: 0.018447546653251004,
      utilizationRate: 0.21851218760416666,
      date: new Date(2022, 7, 14, 0).valueOf(),
    },
    {
      supplyRate: 0.00170513325085575,
      premiumRate: 0.009149553104381355,
      utilizationRate: 0.16869325729166668,
      date: new Date(2022, 7, 15, 0).valueOf(),
    },
    {
      supplyRate: 0.0016215924676163999,
      premiumRate: 0.00867225417814701,
      utilizationRate: 0.1705525140625,
      date: new Date(2022, 7, 16, 0).valueOf(),
    },
    {
      supplyRate: 0.001734752519930655,
      premiumRate: 0.00912595331460879,
      utilizationRate: 0.1734154593402778,
      date: new Date(2022, 7, 17, 0).valueOf(),
    },
    {
      supplyRate: 0.00216201777019842,
      premiumRate: 0.0113226596245866,
      utilizationRate: 0.1742176479166667,
      date: new Date(2022, 7, 18, 0).valueOf(),
    },
    {
      supplyRate: 0.0023333544765832353,
      premiumRate: 0.012192592521254085,
      utilizationRate: 0.1745825998263889,
      date: new Date(2022, 7, 19, 0).valueOf(),
    },
    {
      supplyRate: 0.00190912599528828,
      premiumRate: 0.0099329132584227,
      utilizationRate: 0.1750646731597222,
      date: new Date(2022, 7, 20, 0).valueOf(),
    },
    {
      supplyRate: 0.001871048923240275,
      premiumRate: 0.00966674881256358,
      utilizationRate: 0.17727002427083333,
      date: new Date(2022, 7, 21, 0).valueOf(),
    },
    {
      supplyRate: 0.0021186278488613103,
      premiumRate: 0.01079689692022947,
      utilizationRate: 0.18015824645833334,
      date: new Date(2022, 7, 22, 0).valueOf(),
    },
    {
      supplyRate: 0.0024447703993632896,
      premiumRate: 0.01093423088759388,
      utilizationRate: 0.21321200600694445,
      date: new Date(2022, 7, 23, 0).valueOf(),
    },
    {
      supplyRate: 0.0033695960294888253,
      premiumRate: 0.0143914843246926,
      utilizationRate: 0.22388652628472222,
      date: new Date(2022, 7, 24, 0).valueOf(),
    },
    {
      supplyRate: 0.00268659874310736,
      premiumRate: 0.012244600054461105,
      utilizationRate: 0.21023922069444445,
      date: new Date(2022, 7, 25, 0).valueOf(),
    },
    {
      supplyRate: 0.00217440918974859,
      premiumRate: 0.009981400914185565,
      utilizationRate: 0.20856592239583333,
      date: new Date(2022, 7, 26, 0).valueOf(),
    },
    {
      supplyRate: 0.002673326708181465,
      premiumRate: 0.01222840030722537,
      utilizationRate: 0.20716212118055555,
      date: new Date(2022, 7, 27, 0).valueOf(),
    },
    {
      supplyRate: 0.00200053100866617,
      premiumRate: 0.010956296426808555,
      utilizationRate: 0.16666061045138889,
      date: new Date(2022, 7, 28, 0).valueOf(),
    },
    {
      supplyRate: 0.00187590154653036,
      premiumRate: 0.009882690637361309,
      utilizationRate: 0.176104401875,
      date: new Date(2022, 7, 29, 0).valueOf(),
    },
    {
      supplyRate: 0.00205321545533076,
      premiumRate: 0.010330336400332034,
      utilizationRate: 0.1861065420486111,
      date: new Date(2022, 7, 30, 0).valueOf(),
    },
    {
      supplyRate: 0.00228120266808042,
      premiumRate: 0.01147476051761271,
      utilizationRate: 0.18625789708333335,
      date: new Date(2022, 7, 31, 0).valueOf(),
    },
    {
      supplyRate: 0.00194450803971996,
      premiumRate: 0.00973270651863453,
      utilizationRate: 0.18772577475694444,
      date: new Date(2022, 8, 1, 0).valueOf(),
    },
    {
      supplyRate: 0.00234952499812662,
      premiumRate: 0.011573883689255896,
      utilizationRate: 0.19121507402777776,
      date: new Date(2022, 8, 2, 0).valueOf(),
    },
    {
      supplyRate: 0.002462589329682165,
      premiumRate: 0.01173468502428264,
      utilizationRate: 0.19381770777777776,
      date: new Date(2022, 8, 3, 0).valueOf(),
    },
    {
      supplyRate: 0.00209461692823269,
      premiumRate: 0.009984467424860715,
      utilizationRate: 0.19859702399305557,
      date: new Date(2022, 8, 4, 0).valueOf(),
    },
    {
      supplyRate: 0.002314728143863215,
      premiumRate: 0.01091669699357031,
      utilizationRate: 0.20095463524305554,
      date: new Date(2022, 8, 5, 0).valueOf(),
    },
    {
      supplyRate: 0.002457316953720645,
      premiumRate: 0.011535294735451111,
      utilizationRate: 0.20245373506944445,
      date: new Date(2022, 8, 6, 0).valueOf(),
    },
    {
      supplyRate: 0.00279185145332523,
      premiumRate: 0.01275384466224564,
      utilizationRate: 0.2091986385763889,
      date: new Date(2022, 8, 7, 0).valueOf(),
    },
    {
      supplyRate: 0.0020488818697253853,
      premiumRate: 0.00913645449831486,
      utilizationRate: 0.21475932489583335,
      date: new Date(2022, 8, 8, 0).valueOf(),
    },
    {
      supplyRate: 0.003332811904842705,
      premiumRate: 0.014946300057998983,
      utilizationRate: 0.2132830321875,
      date: new Date(2022, 8, 9, 0).valueOf(),
    },
    {
      supplyRate: 0.002866244234659875,
      premiumRate: 0.01281954741797082,
      utilizationRate: 0.21429164541666668,
      date: new Date(2022, 8, 10, 0).valueOf(),
    },
    {
      supplyRate: 0.00176494060037079,
      premiumRate: 0.00779384157577878,
      utilizationRate: 0.21826536677083333,
      date: new Date(2022, 8, 11, 0).valueOf(),
    },
    {
      supplyRate: 0.002642786878671765,
      premiumRate: 0.01159372810267071,
      utilizationRate: 0.2188872138888889,
      date: new Date(2022, 8, 12, 0).valueOf(),
    },
    {
      supplyRate: 0.0034645013551968897,
      premiumRate: 0.014579106778968195,
      utilizationRate: 0.23098143843750002,
      date: new Date(2022, 8, 13, 0).valueOf(),
    },
    {
      supplyRate: 0.0036666859641828597,
      premiumRate: 0.01438902070603128,
      utilizationRate: 0.24872502020833334,
      date: new Date(2022, 8, 14, 0).valueOf(),
    },
    {
      supplyRate: 0.003946307602996425,
      premiumRate: 0.015525318878985271,
      utilizationRate: 0.24562901003472223,
      date: new Date(2022, 8, 15, 0).valueOf(),
    },
    {
      supplyRate: 0.0032126766127756803,
      premiumRate: 0.012322218624002535,
      utilizationRate: 0.2550973713888889,
      date: new Date(2022, 8, 16, 0).valueOf(),
    },
    {
      supplyRate: 0.0034972973393505896,
      premiumRate: 0.013320180850334866,
      utilizationRate: 0.2565755744791667,
      date: new Date(2022, 8, 17, 0).valueOf(),
    },
    {
      supplyRate: 0.003999073303860075,
      premiumRate: 0.01536736463013831,
      utilizationRate: 0.25006624708333336,
      date: new Date(2022, 8, 18, 0).valueOf(),
    },
    {
      supplyRate: 0.00227496926783046,
      premiumRate: 0.010748658387677894,
      utilizationRate: 0.19705667399305557,
      date: new Date(2022, 8, 19, 0).valueOf(),
    },
    {
      supplyRate: 0.002493184923604245,
      premiumRate: 0.01150013479750158,
      utilizationRate: 0.20319972454861113,
      date: new Date(2022, 8, 20, 0).valueOf(),
    },
    {
      supplyRate: 0.0027998535892518753,
      premiumRate: 0.0126455704033272,
      utilizationRate: 0.20889619034722223,
      date: new Date(2022, 8, 21, 0).valueOf(),
    },
    {
      supplyRate: 0.002546966224305315,
      premiumRate: 0.011502193143269954,
      utilizationRate: 0.2085058026388889,
      date: new Date(2022, 8, 22, 0).valueOf(),
    },
    {
      supplyRate: 0.002341580937259455,
      premiumRate: 0.010518300521320155,
      utilizationRate: 0.2103058917361111,
      date: new Date(2022, 8, 23, 0).valueOf(),
    },
    {
      supplyRate: 0.0023023961506769847,
      premiumRate: 0.010226022871581315,
      utilizationRate: 0.2123741710763889,
      date: new Date(2022, 8, 24, 0).valueOf(),
    },
    {
      supplyRate: 0.00305489907356142,
      premiumRate: 0.013460572586159415,
      utilizationRate: 0.21455595472222222,
      date: new Date(2022, 8, 25, 0).valueOf(),
    },
    {
      supplyRate: 0.0023635048357341,
      premiumRate: 0.010258970489228235,
      utilizationRate: 0.2187711301736111,
      date: new Date(2022, 8, 26, 0).valueOf(),
    },
    {
      supplyRate: 0.003210660885248265,
      premiumRate: 0.01387672213635153,
      utilizationRate: 0.21911466003472221,
      date: new Date(2022, 8, 27, 0).valueOf(),
    },
    {
      supplyRate: 0.00303276888481857,
      premiumRate: 0.012703018702831994,
      utilizationRate: 0.2281133828125,
      date: new Date(2022, 8, 28, 0).valueOf(),
    },
    {
      supplyRate: 0.0035777259794388602,
      premiumRate: 0.014367544794801975,
      utilizationRate: 0.24059255378472225,
      date: new Date(2022, 8, 29, 0).valueOf(),
    },
    {
      supplyRate: 0.0036691793813113196,
      premiumRate: 0.01402159615706121,
      utilizationRate: 0.2549717246875,
      date: new Date(2022, 8, 30, 0).valueOf(),
    },
    {
      supplyRate: 0.003409580933116605,
      premiumRate: 0.012889371171429749,
      utilizationRate: 0.25777981777777775,
      date: new Date(2022, 9, 1, 0).valueOf(),
    },
    {
      supplyRate: 0.005025886520613915,
      premiumRate: 0.01894736969481546,
      utilizationRate: 0.2593943745486111,
      date: new Date(2022, 9, 2, 0).valueOf(),
    },
    {
      supplyRate: 0.003353912514332085,
      premiumRate: 0.012627726351224866,
      utilizationRate: 0.2598036898611111,
      date: new Date(2022, 9, 3, 0).valueOf(),
    },
    {
      supplyRate: 0.004249956769111155,
      premiumRate: 0.01581831913993494,
      utilizationRate: 0.26328844236111115,
      date: new Date(2022, 9, 4, 0).valueOf(),
    },
    {
      supplyRate: 0.003984310417473795,
      premiumRate: 0.014831312953484641,
      utilizationRate: 0.2627844148958334,
      date: new Date(2022, 9, 5, 0).valueOf(),
    },
    {
      supplyRate: 0.00419171242997532,
      premiumRate: 0.015314808879966899,
      utilizationRate: 0.26866978041666667,
      date: new Date(2022, 9, 6, 0).valueOf(),
    },
    {
      supplyRate: 0.0038491824168343652,
      premiumRate: 0.01384709902329993,
      utilizationRate: 0.27459002440972224,
      date: new Date(2022, 9, 7, 0).valueOf(),
    },
    {
      supplyRate: 0.003905888632534185,
      premiumRate: 0.013599990237358965,
      utilizationRate: 0.2833560721180556,
      date: new Date(2022, 9, 8, 0).valueOf(),
    },
    {
      supplyRate: 0.005339501276080635,
      premiumRate: 0.018575533526381208,
      utilizationRate: 0.2838029979513889,
      date: new Date(2022, 9, 9, 0).valueOf(),
    },
    {
      supplyRate: 0.004842622202649615,
      premiumRate: 0.016727699478209082,
      utilizationRate: 0.2862901097916667,
      date: new Date(2022, 9, 10, 0).valueOf(),
    },
    {
      supplyRate: 0.0034200940428290253,
      premiumRate: 0.011836457277525015,
      utilizationRate: 0.28601288864583335,
      date: new Date(2022, 9, 11, 0).valueOf(),
    },
    {
      supplyRate: 0.00464776242387087,
      premiumRate: 0.01592987174325942,
      utilizationRate: 0.2879496953819445,
      date: new Date(2022, 9, 12, 0).valueOf(),
    },
    {
      supplyRate: 0.005527137298414725,
      premiumRate: 0.018791992061721465,
      utilizationRate: 0.29297713847222223,
      date: new Date(2022, 9, 13, 0).valueOf(),
    },
    {
      supplyRate: 0.0047468596959203396,
      premiumRate: 0.01561200512047257,
      utilizationRate: 0.30423976572916667,
      date: new Date(2022, 9, 14, 0).valueOf(),
    },
    {
      supplyRate: 0.0037104746929919846,
      premiumRate: 0.014703514708002179,
      utilizationRate: 0.18794946052083333,
      date: new Date(2022, 9, 15, 0).valueOf(),
    },
    {
      supplyRate: 0.000750759362428305,
      premiumRate: 0.00625402691550936,
      utilizationRate: 0.11623232065972222,
      date: new Date(2022, 9, 16, 0).valueOf(),
    },
    {
      supplyRate: 0.0008681751723300065,
      premiumRate: 0.007019759898050538,
      utilizationRate: 0.12087432770053476,
      date: new Date(2022, 9, 17, 0).valueOf(),
    },
  ];
}
