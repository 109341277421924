import { Button, Typography, Spin } from 'antd';
import { ParentSize } from '@visx/responsive';
import { useState } from 'react';

import {
  FieldData,
  ReserveRateTimeRange,
  ApyGraphQueryType,
} from '../../types';
import { useReserveRatesHistory } from '../../hooks/api';

import { ApyGraph } from './ApyGraph';
import { GraphLegend } from './GraphLegend';
import { GraphTimeRangeSelector } from './GraphTimeRangeSelector';

type ApyGraphContainerProps = {
  queryType: ApyGraphQueryType;
  poolIds: string[];
  populate: {
    supplyRate?: boolean;
    premiumRate?: boolean;
    utilizationRate?: boolean;
  };
  height: number;
};

/**
 * NOTES:
 * This container uses the same graph but with different fields, so we use a 'graphKey' to determine which to show
 * This graph gets its data via an external API call, thus having loading/error states
 */
export const ApyGraphContainer = ({
  queryType,
  populate,
  poolIds,
  height,
}: ApyGraphContainerProps): JSX.Element => {
  const [selectedTimeRange, setSelectedTimeRange] =
    useState<ReserveRateTimeRange>('1m');

  const CHART_HEIGHT_FIX = 62;
  const FIXED_CHART_HEIGHT = height - CHART_HEIGHT_FIX;
  const CHART_HEIGHT = height;

  let { data, loading, error, refetch } = useReserveRatesHistory(
    queryType,
    selectedTimeRange,
    poolIds,
    populate
  );

  // error = true;
  // loading = true;

  // Supply field
  const supplyFields: FieldData = {
    name: 'supplyRate',
    color: '#2EBAC6',
    text: 'Supply APR',
  };
  // Premium field
  const premiumFields: FieldData = {
    name: 'premiumRate',
    color: '#B6509E',
    text: 'Premium Rate',
  };
  // Utilization field
  const utilizationFields: FieldData = {
    name: 'utilizationRate',
    color: '#B214f3',
    text: 'Utilization Rate',
  };

  let fields: FieldData[] = [];
  if (populate.supplyRate === true) {
    fields.push(supplyFields);
  }
  if (populate.premiumRate === true) {
    fields.push(premiumFields);
  }
  if (populate.utilizationRate === true) {
    fields.push(utilizationFields);
  }

  const graphLoading = (
    <div
      style={{
        height: FIXED_CHART_HEIGHT,
        width: 'auto',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Spin size="large" tip="Loading data..." />
    </div>
  );

  const graphError = (
    <div
      style={{
        height: FIXED_CHART_HEIGHT,
        width: 'auto',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Typography>Something went wrong</Typography>
      <Typography className="mb-1">
        Data couldn&apos;t be fetched, please reload graph.
      </Typography>
      <Button type="primary" onClick={refetch}>
        Reload
      </Button>
    </div>
  );

  return (
    <div
      className="mb-2 p-4 rounded-md"
      style={{
        backdropFilter: 'blur(3px)',
        background: 'rgba(255,255,255,0.8)',
        maxHeight: CHART_HEIGHT,
      }}
    >
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          marginBottom: 4,
        }}
      >
        <GraphLegend labels={fields} />
        <GraphTimeRangeSelector
          disabled={loading || error}
          timeRange={selectedTimeRange}
          handleTimeRangeChanged={setSelectedTimeRange}
        />
      </div>
      <ParentSize>
        {({ width }: { width: number }) => {
          if (loading) return graphLoading;
          if (error) return graphError;
          if (!loading && !error && data.length > 0)
            return (
              <ApyGraph
                width={width}
                height={FIXED_CHART_HEIGHT}
                data={data}
                fields={fields}
                selectedTimeRange={selectedTimeRange}
              />
            );
        }}
      </ParentSize>
    </div>
  );
};
