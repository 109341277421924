import { Button } from 'antd';
import cx from 'classnames';
import { utils } from 'ethers';
import { Link, useParams } from 'react-router-dom';
// Hooks
import { useAppDataContext } from '../hooks/useAppDataProvider';
import { useBasketContext } from '../hooks/useBasketProvider';
import { usePageTitle } from '../hooks/usePageTitle';
// Components
import { ActionSectionCover } from '../components/ActionSectionCover';
import { OtherClaimsTable } from '../components/claims/OtherClaimsTable';
import { HeaderPool } from '../components/layout/HeaderPool';
import { CoverRefundStatLine } from '../components/CoverRefundStatLine';
import { DisclaimerRow } from '../components/DisclaimerRow';
import {
  FormatPercent,
  FormatTokenAmount,
} from '../components/primitives/FormattedNumber';
import { StatLine } from '../components/StatLine';
// import { PolicyWithdrawButton } from '../components/contracts';
// Functions
import { bnPercent } from '../functions/utils/formatting';
// Types
import { ClaimData, UserCover } from '../types';

const DAY_IN_MS = 24 * 60 * 60 * 1000;

const statusContent = (isExpired: boolean) =>
  isExpired ? (
    <p className="text-orange-500">Expired</p>
  ) : (
    <p className="text-lime-500">Active</p>
  );

export function MyCover() {
  const { coverId } = useParams();
  usePageTitle(`My Policy n°${coverId}`);

  const { findClaimsByCoverId, findCoverById } = useAppDataContext();
  const { buyBasket, removeFromBuyBasket, updateBuyBasket } =
    useBasketContext();

  const policy = findCoverById(coverId);

  const selected =
    !!policy &&
    !!buyBasket.poolIds.find((el: string) => el === policy.poolId);

  const coverClaims = findClaimsByCoverId(coverId);

  const latestCoverClaim = coverClaims.reduce(
    (acc: ClaimData | undefined, claim) =>
      !acc || acc.creationDate < claim.creationDate ? claim : acc,
    undefined
  );
  const hasOngoingClaim =
    coverClaims.length != 0 &&
    (latestCoverClaim?.status === 'Initiated' ||
      latestCoverClaim?.status === 'Disputed');

  const handleBuyAgain = () => {
    if (!policy) return;
    selected
      ? removeFromBuyBasket(policy.poolId)
      : updateBuyBasket({
          poolId: policy.poolId,
          coverAmount: utils.formatUnits(policy.amountCovered, 6),
          stakeAmount: '0',
          premiumAmount: utils.formatUnits(policy.premiumDeposit, 6),
        });
  };

  const isExpired = policy ? policy.premiumLeft.eq(0) : false;
  const averageRate = policy
    ? bnPercent(policy.dailyCost.mul(365), policy.amountCovered)
    : 0;

  const coverPeriod = policy
    ? `${new Date(policy.startDate).toLocaleDateString()} - ${
        isExpired
          ? new Date(
              policy.startDate + policy.duration
            ).toLocaleDateString()
          : 'now'
      }`
    : '-';
  const data = policy ? statLineData(policy) : [];

  function statLineData(userPolicy: UserCover) {
    return [
      {
        title: 'Status',
        content: statusContent(isExpired),
      },
      {
        title: 'Amount Covered',
        content: FormatTokenAmount(userPolicy.amountCovered, 'USD'),
      },
      {
        title: 'Cover Period',
        content: coverPeriod,
      },
      {
        ...(isExpired
          ? {
              title: 'Duration',
              content: `${Math.round(
                userPolicy.duration / DAY_IN_MS
              )} days`,
            }
          : {
              title: 'Remaining',
              content: `${Math.floor(
                bnPercent(userPolicy.premiumLeft, userPolicy.dailyCost)
              )} days`,
            }),
      },
      //
      {
        title: 'Initial Premium',
        content: FormatTokenAmount(userPolicy.premiumDeposit, 'USD'),
      },
      {
        title: 'Premium Left',
        content: FormatTokenAmount(userPolicy.premiumLeft, 'USD'),
      },
      {
        title: 'Daily Cost',
        content: FormatTokenAmount(userPolicy.dailyCost, 'USD'),
      },
      {
        title: 'Average Rate',
        content: FormatPercent(averageRate),
      },
    ];
  }

  return (
    <>
      <HeaderPool poolId={policy?.poolId} />

      <StatLine title="Cover" data={data}>
        {isExpired && (
          <Button
            type="default"
            onClick={() => handleBuyAgain()}
            className="w-[220px] mt-[24px] mr-2"
          >
            {selected ? 'Remove from Basket' : 'Add to Basket'}
          </Button>
        )}

        {/* <PolicyWithdrawButton
          params={{
            coverId: coverId ?? '',
          }}
          disabled={isExpired && !coverId}
          className="w-[220px]"
        /> */}
        <button>WITHDRAW</button>

        {hasOngoingClaim ? (
          <Link
            className="ml-2"
            to={`/dashboard/claim/${latestCoverClaim?.claimId ?? ''}`}
          >
            <Button type="default" className="w-[220px] mt-[24px]">
              View Claim
            </Button>
          </Link>
        ) : (
          <Link
            className="ml-2"
            to={`/dashboard/openclaim/${coverId ?? ''}`}
          >
            <Button type="primary" className="w-[220px] mt-[24px]">
              Open Claim
            </Button>
          </Link>
        )}
      </StatLine>

      <ActionSectionCover coverId={coverId} cover={policy} />

      <CoverRefundStatLine coverId={coverId} />

      <OtherClaimsTable
        title="Associated Claims"
        coverClaims={coverClaims}
      />

      <DisclaimerRow />
    </>
  );
}
