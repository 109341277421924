import { Contract } from 'ethers';
import {
  AthenaCoverToken,
  AthenaPositionToken,
  AthenaToken,
  ClaimManager,
  EcclesiaDao,
  ERC20,
  FarmingRange,
  LiquidityManager,
  Staking,
  TetherToken,
  //
  AthenaCoverToken__factory,
  AthenaPositionToken__factory,
  ClaimManager__factory,
  EcclesiaDao__factory,
  ERC20__factory,
  FarmingRange__factory,
  LiquidityManager__factory,
  Staking__factory,
} from '../../types/typechain';
import { CustomMarket } from '../../data/marketsConfig';
import { ContractName, ADDRESSES } from '../../data/addresses';
import { ChainIdToNetwork } from '../../data/chainId';

export type ContractsInterface = {
  AthenaCoverToken: AthenaCoverToken;
  AthenaPositionToken: AthenaPositionToken;
  ATEN: ERC20;
  USDT: ERC20;
  LiquidityManager: LiquidityManager;
  ClaimManager: ClaimManager;
  EcclesiaDao: EcclesiaDao;
  Staking: Staking;
  FarmingRange: FarmingRange;
};

export const genContracts = (
  chainId: number | undefined
): ContractsInterface | undefined => {
  if (!chainId) return;

  const market = ChainIdToNetwork[chainId];
  if (!(market in CustomMarket)) return;
  const typedMarket = market as CustomMarket;

  const AthenaCoverToken = new Contract(
    ADDRESSES[typedMarket].AthenaCoverToken,
    AthenaCoverToken__factory.abi
  );
  const AthenaPositionToken = new Contract(
    ADDRESSES[typedMarket].AthenaPositionToken,
    AthenaPositionToken__factory.abi
  );
  const ATEN = new Contract(
    ADDRESSES[typedMarket].ATEN,
    ERC20__factory.abi
  );
  const USDT = new Contract(
    ADDRESSES[typedMarket].USDT,
    ERC20__factory.abi
  );
  const LiquidityManager = new Contract(
    ADDRESSES[typedMarket].LiquidityManager,
    LiquidityManager__factory.abi
  );
  const ClaimManager = new Contract(
    ADDRESSES[typedMarket].ClaimManager,
    ClaimManager__factory.abi
  );
  const EcclesiaDao = new Contract(
    ADDRESSES[typedMarket].EcclesiaDao,
    EcclesiaDao__factory.abi
  );
  const Staking = new Contract(
    ADDRESSES[typedMarket].Staking,
    Staking__factory.abi
  );
  const FarmingRange = new Contract(
    ADDRESSES[typedMarket].FarmingRange,
    FarmingRange__factory.abi
  );

  return {
    AthenaCoverToken: AthenaCoverToken as AthenaCoverToken,
    AthenaPositionToken: AthenaPositionToken as AthenaPositionToken,
    ATEN: ATEN as ERC20,
    USDT: USDT as ERC20,
    LiquidityManager: LiquidityManager as LiquidityManager,
    ClaimManager: ClaimManager as ClaimManager,
    EcclesiaDao: EcclesiaDao as EcclesiaDao,
    Staking: Staking as Staking,
    FarmingRange: FarmingRange as FarmingRange,
  };
};

// reexport so we can forbid config import
export type { ContractName, ADDRESSES };
