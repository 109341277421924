import { BigNumber } from 'ethers';
import { useParams } from 'react-router-dom';
// Hooks
import { useAppDataContext } from '../hooks/useAppDataProvider';
import { usePageTitle } from '../hooks/usePageTitle';
// Types
import { UserPosition } from '../types';
// Components
import { ActionSectionPosition } from '../components/ActionSectionPosition';
import { BackButton } from '../components/BackButton';
import { DisclaimerRow } from '../components/DisclaimerRow';
import { TableMiniLogo } from '../components/icons/TableMiniLogo';
import { PoolUsageBar } from '../components/PoolUsageBar';
import {
  FormatPercent,
  FormatTokenAmount,
} from '../components/primitives/FormattedNumber';
import { StatLine } from '../components/StatLine';
// import { PositionTakeInterestsButton } from '../components/contracts';

export function MyPosition() {
  const { positionId } = useParams();
  const {
    findPositionById,

    calcPositionUsageRate,
  } = useAppDataContext();
  usePageTitle(`My Position n°${positionId}`);

  const position = findPositionById(positionId);

  const amountSupplied = position?.amountSupplied || BigNumber.from(0);
  const premiumRewards = position?.premiumRewards || BigNumber.from(0);
  const startDate = position?.startDate || Date.now();

  // @dev compute historic APR based on the ration of rewards versus capital
  const percentYearPassed = Math.round(
    ((Date.now() - startDate) / (365 * 24 * 60 * 60 * 1000)) * 100_000
  );
  const historicApr =
    percentYearPassed === 0
      ? 0
      : premiumRewards
          .mul(1_000_000_000)
          .div(amountSupplied.eq(0) ? 1 : amountSupplied)
          .div(percentYearPassed)
          .toNumber() /
        (1_000_000_000 / 100_000);

  // Pool usage of capital
  const poolUsageRate = calcPositionUsageRate(position);
  const amountUsed = amountSupplied
    .mul(Math.floor(10_000 * poolUsageRate))
    .div(10_000);

  const data = position ? statLineData(position) : [];
  function statLineData(userCover: UserPosition) {
    return [
      {
        title: 'Position ID',
        content: `n° ${userCover.positionId}`,
      },
      {
        title: 'Start Date',
        content: new Date(userCover.startDate).toLocaleDateString(),
      },
      {
        title: `Covered Protocols (${userCover.poolIds.length})`,
        content: (
          <TableMiniLogo className="mt-1" poolIds={userCover.poolIds} />
        ),
      },
      {
        title: 'Total Supplied',
        content: FormatTokenAmount(userCover.amountSupplied, 'USD'),
      },
      {
        title: 'Historic APR',
        content: FormatPercent(historicApr),
      },
      {
        title: 'Total Premiums Received',
        content: FormatTokenAmount(userCover.premiumRewards, 'USD'),
      },
    ];
  }

  return (
    <>
      <div className="pb-3">
        <BackButton />
      </div>

      <StatLine title="Position" data={data}>
        {position && (
          <div className="flex flex-col">
            <PoolUsageBar
              amountTotal={amountSupplied}
              amountUsed={amountUsed}
            />
            {/* <PositionTakeInterestsButton
              params={{ tokenId: positionId || '' }}
            /> */}
            <button>TAKE INTERESTS</button>
          </div>
        )}
      </StatLine>

      <ActionSectionPosition positionId={positionId} position={position} />

      <DisclaimerRow />
    </>
  );
}
