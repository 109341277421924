import { useCallback, useEffect, useState } from 'react';
import { CONFIG } from '../../appConfig';
import { useWeb3Context } from '../useWeb3ContextProvider';
import { DatabaseMetaEvidenceEntry } from '../../types';

export function useGetMetaEvidence(claimId: string | undefined) {
  const { chainId } = useWeb3Context();

  const [metaEvidenceData, setMetaEvidenceData] =
    useState<DatabaseMetaEvidenceEntry>();

  const fetchData = useCallback<() => () => void>(() => {
    if (!claimId || !chainId) {
      setMetaEvidenceData(undefined);
      return () => null;
    }
    // reset
    setMetaEvidenceData(undefined);

    let fetchPromise: Promise<DatabaseMetaEvidenceEntry | {}> = fetch(
      `${CONFIG.API_ENDPOINT}/meta-evidence/claim?claimId=${claimId}&chainId=${chainId}`,
      {
        method: 'GET',
      }
    )
      .then((res) => res.json())
      .catch((e) => {
        setMetaEvidenceData(undefined);
        return () => null;
      });

    fetchPromise
      .then((data) => {
        const anyData = data as any;

        if (!anyData.fileURI || !anyData.ipfsUriHashSignature) {
          throw Error('useGetMetaEvidence(): Invalid data');
        } else {
          setMetaEvidenceData(data as DatabaseMetaEvidenceEntry);
        }
      })
      .catch((e) => {
        console.error('useGetMetaEvidence(): Failed to fetch', e);
      });

    return () => null;
  }, [claimId, chainId]);

  useEffect(() => {
    fetchData();
  }, [claimId, chainId]);

  return metaEvidenceData;
}
