import { utils } from 'ethers';
import { TransactionStatus, useContractFunction } from '@usedapp/core';
import { BigNumber, ethers } from 'ethers';
import { ExecuteReturn } from '../../../../types';
import { useProtocolDataContext } from '../../../useProtocolDataProvider';
import { ClaimManager } from '../../../../types/typechain';

const { parseUnits } = utils;

export type ParamsInitiateClaim = {
  coverId: number;
  amountClaimed: string;
  tokenDecimals: number;
  ipfsMetaEvidenceCid: string;
  signature: string;
};

type FormattedParams = {
  coverId: number;
  amountClaimed: BigNumber;
  ipfsMetaEvidenceCid: string;
  signature: string;
};

function formatParams(params: ParamsInitiateClaim): FormattedParams {
  return {
    amountClaimed: parseUnits(params.amountClaimed, params.tokenDecimals),
    coverId: params.coverId,
    ipfsMetaEvidenceCid: params.ipfsMetaEvidenceCid,
    signature: params.signature,
  };
}

type Instance = {
  send: (
    ...args: Parameters<ClaimManager['initiateClaim']>
  ) => Promise<ethers.providers.TransactionReceipt | undefined>;
  state: TransactionStatus;
};

function makeInstance(): Instance {
  const { chainContracts } = useProtocolDataContext();
  const contract = chainContracts?.ClaimManager;

  const { send, state } = useContractFunction(contract, 'initiateClaim', {
    transactionName: 'initiateClaim',
    gasLimitBufferPercentage: 10,
  });

  return { send, state };
}

function checkParams(params: ParamsInitiateClaim) {
  // do checks relative to this fn
  const { amountClaimed, ipfsMetaEvidenceCid, signature } =
    formatParams(params);

  if (amountClaimed.eq(0)) {
    return 'Missing amountClaimed input';
  }
  if (!ipfsMetaEvidenceCid || !signature) {
    return 'Missing meta-evidence inputs';
  }
}

function execute(
  instance: Instance,
  params: ParamsInitiateClaim
): ExecuteReturn {
  const { coverId, amountClaimed, ipfsMetaEvidenceCid, signature } =
    formatParams(params);

  const txReceiptPromise = instance.send(
    coverId,
    amountClaimed,
    ipfsMetaEvidenceCid,
    signature
  );

  return {
    txReceipt: txReceiptPromise,
    txStatus: instance.state,
  };
}

export const configInitiateClaim = {
  makeInstance: makeInstance,
  checkParams: checkParams,
  execute: execute,
};
