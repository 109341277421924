import { useCall } from '@usedapp/core';
import { BigNumber } from 'ethers';
import { useState } from 'react';
import {
  formatGeneralStakingPosition,
  formatPolicyStakingPosition,
} from '../../../../functions/athenaDataFormat';
import { UserPolicyRefund } from '../../../../types';
import { useProtocolDataContext } from '../../../useProtocolDataProvider';
import { useSingleCall } from '../../../useSingleCall';
import { useWeb3Context } from '../../../useWeb3ContextProvider';

// Value that is returned if the hook encounters an error
const defaultValuePolicyStaking: UserPolicyRefund[] = [];

// Policy staking positions
export const useGetRefundPositionsByAccount = (account: string) => {
  return defaultValuePolicyStaking;
};

// // Value that is returned if the hook encounters an error
const defaultValueGpStaking = {
  user: '0x42',
  amount: BigNumber.from(0),
  since: Date.now(),
  accruedRewards: BigNumber.from(0),
  rate: 0,
};

// Your general pool staking position
export const useGetUserStakingPosition = (account: string) => {
  return defaultValueGpStaking;
};
