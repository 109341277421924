import { Button } from 'antd';
import { Link } from 'react-router-dom';
// Hooks
// Components
import { BaseTable } from '../tables/BaseTable';
import { FormatTokenAmount } from '../primitives/FormattedNumber';
// Functions
import { isClaimPayable } from '../../functions/checkAthenaData';
import { formatTimeToText } from '../../functions/utils/formatting';
// Types
import { ClaimTableConfigItem, ClaimData } from '../../types';

const columnConfigCoverClaims = (): ClaimTableConfigItem[] => [
  {
    title: 'Claim ID',
    key: 'id',
    cellContent: (record) => record.claimId,
  },
  {
    title: 'Creation Date',
    key: 'date',
    cellContent: (record) => `${formatTimeToText(record.creationDate)}`,
  },
  {
    title: 'Amount Claimed',
    key: 'available',
    render: (_, record) => FormatTokenAmount(record.amountClaimed, 'USD'),
  },
  {
    title: 'Status',
    key: 'status',
    cellContent: (record) => record.status,
  },
  {
    title: '',
    key: 'action',
    width: 190,
    render: (_, record) => {
      const displayWithdraw = isClaimPayable(record.status);

      return (
        <div className="flex justify-end">
          {displayWithdraw && (
            <Link
              className="mx-2 table-link"
              to={'/claim/' + record.claimId}
            >
              <Button type="primary">Withdraw</Button>
            </Link>
          )}

          <Link className="mx-2" to={'/claim/' + record.claimId}>
            <Button type="default">View Claim</Button>
          </Link>
        </div>
      );
    },
  },
];

export const OtherClaimsTable = ({
  title,
  coverClaims,
  className,
}: {
  title: string;
  coverClaims: ClaimData[];
  className?: string;
}) => {
  const configCoverClaims = columnConfigCoverClaims();
  const makeNavUrl = (record: ClaimData) => `/claim/${record.claimId}`;

  return (
    <BaseTable
      title={title}
      rowKey="coverId"
      config={configCoverClaims}
      data={coverClaims}
      isLoading={false}
      className={className}
      makeNavUrl={makeNavUrl}
    />
  );
};
