import { BigNumber, utils } from 'ethers';
import { Button, Checkbox, Typography } from 'antd';
import { useState } from 'react';
// Components
// import {
//   PolicyBuyButton,
//   ApproveUsdtButton,
//   ApproveAtenButton,
// } from './contracts';
import {
  FormatTokenAmount,
  FormatPercent,
} from '../components/primitives/FormattedNumber';
// Context
import { useWeb3Context } from '../hooks/useWeb3ContextProvider';
import { useAppDataContext } from '../hooks/useAppDataProvider';
import { useBasketContext } from '../hooks/useBasketProvider';

export const BasketCardBuy = ({ hasItems }: { hasItems: boolean }) => {
  const { buyBasket, emptyBuyBasket } = useBasketContext();
  const { currentAccount } = useWeb3Context();
  const {
    userAtenBalance,
    userUsdtBalance,
    userAthenaUsdtAllowance,
    userAthenaAtenAllowance,
  } = useAppDataContext();
  const [checked, setChecked] = useState(false);

  const coverAmountTotal = buyBasket.coverAmounts.reduce(
    (acc, amount) => acc.add(utils.parseUnits(amount || '0', 6)),
    BigNumber.from(0)
  );
  const premiumAmountTotal = buyBasket.premiumAmounts.reduce(
    (acc, amount) => acc.add(utils.parseUnits(amount || '0', 6)),
    BigNumber.from(0)
  );
  const stakeAmountTotal = buyBasket.stakeAmounts.reduce(
    (acc, amount) => acc.add(utils.parseUnits(amount || '0', 18)),
    BigNumber.from(0)
  );

  const disabled =
    !currentAccount ||
    !hasItems ||
    !checked ||
    coverAmountTotal.eq(0) ||
    premiumAmountTotal.eq(0) ||
    premiumAmountTotal.gt(userAthenaUsdtAllowance) ||
    stakeAmountTotal.gt(userAthenaAtenAllowance);

  const handleCheckbox = (e: any) => {
    setChecked(e.target.checked);
  };

  const onSuccess = () => {
    emptyBuyBasket();
  };

  return (
    <div className="content-card p-4">
      <Typography.Title className="text-center" level={3}>
        Policy Information
      </Typography.Title>
      <hr
        style={{
          marginLeft: -16,
          width: 'calc(100% + 32px)',
          marginTop: 16,
        }}
      />

      <div className="flex w-full mt-3 mb-1 px-1 justify-between">
        <span>Wallet USDT balance</span>
        <span>{FormatTokenAmount(userUsdtBalance, 'USDT')}</span>
      </div>
      <hr />
      <div className="flex w-full mt-3 mb-1 px-1 justify-between">
        <span>Wallet ATEN balance</span>
        <span>{FormatTokenAmount(userAtenBalance, 'ATEN')}</span>
      </div>
      <hr />
      <div className="flex w-full mt-3 mb-1 px-1 justify-between">
        <span>Total Cover</span>
        <span>{FormatTokenAmount(coverAmountTotal, 'USDT')}</span>
      </div>
      <hr />
      <div className="flex w-full mt-3 mb-1 px-1 justify-between">
        <span>Total Premium</span>
        <span>{FormatTokenAmount(premiumAmountTotal, 'USDT')}</span>
      </div>
      <hr />
      <div className="flex w-full mt-3 mb-1 px-1 justify-between">
        <span>ATEN to Stake</span>
        <span>{FormatTokenAmount(stakeAmountTotal, 'ATEN')}</span>
      </div>
      <hr />

      <p className="mt-5 mb-2 text-[12px]">Why do I need to approve ?</p>
      {/* <ApproveAtenButton className="mt-0" />
      <ApproveUsdtButton className="mt-[8px]" /> */}

      <button>APPROVE ATEN</button>
      <button>APPROVE USDT</button>

      <div className="mt-4">
        <p className="mb-2" color="white">
          Please ensure you read and understand the following documents:
        </p>
        <p className="theme-secondary">
          <a href="#" target="_blank">
            Smart Contract Vulnerability Cover
          </a>
        </p>
        <p className="theme-secondary mt-1">
          <a href="#" target="_blank">
            Claim Process
          </a>
        </p>
      </div>

      <Checkbox className="mt-4" onChange={handleCheckbox}>
        <p className="text-[14px]">I agree to the Terms and Conditions</p>
      </Checkbox>

      {/* <PolicyBuyButton
        disabled={disabled}
        params={{
          poolId: buyBasket.poolIds,
          amountCovered: buyBasket.coverAmounts,
          premiumDeposit: buyBasket.premiumAmounts,
          atensLocked: buyBasket.stakeAmounts,
        }}
        onSuccess={() => onSuccess()}
      /> */}
      <button>BUY</button>
    </div>
  );
};
