import { BigNumber } from 'ethers';
import { useEffect, useState } from 'react';
import { Button } from 'antd';
import { WalletIcon } from '../icons/WalletIcon';
import { ArrowTopRightIcon } from '../icons/ArrowTopRightIcon';
import { SpinIcon } from '../animation/SpinIcon';
import { useWeb3Context } from '../../hooks/useWeb3ContextProvider';
import { TransactionReceipt } from '../../types';
import { SuccessIcon } from '../icons/SuccessIcon';
import { LoadingIcon } from '../icons/LoadingIcon';
import { useAllowances, tokenApprove } from '../../hooks/contract';
import { UseCallInstance, ExecuteReturn } from '../../types';

// @dev the typing of params is purposefully abstracted from the wrapper
// the config of each button manages adequete typing
type ButtonConfig = {
  checkParams: (params: any) => string | undefined;
  makeInstance: () => UseCallInstance;
  execute: (instance: UseCallInstance, params: any) => ExecuteReturn;
};

type ApprovalOperation = {
  symbol: string;
  execute: () => ExecuteReturn;
};

export type TxButtonWrapperProps = {
  buttonConfig: ButtonConfig;
  params: any;
  buttonText: string;
  approveChecks?: {
    symbol: string;
    token?: string;
    owner: string;
    spender?: string;
    amount: string;
  }[];
  className?: string;
  disabled?: boolean;
  onSuccess?: () => void;
};

export function TxButtonWrapper({
  buttonConfig,
  params,
  buttonText,
  approveChecks,
  className,
  disabled,
  onSuccess,
}: TxButtonWrapperProps) {
  const { currentNetworkConfig, currentAccount, connectWallet } =
    useWeb3Context();

  const [localDisabled, setLocalDisabled] = useState<boolean>(false);

  const [errorMsg, setErrorMsg] = useState<string | undefined>();
  const [hash, setHash] = useState<string | undefined>();
  const [isMined, setIsMined] = useState<boolean>(false);

  const isDisabled = disabled || localDisabled;
  const loading = hash && !isMined;

  const instance = buttonConfig.makeInstance();

  const approveInstances = approveChecks?.map((check) => ({
    token: check.token,
    instance: tokenApprove.makeInstance(check.token),
  }));
  const allowances = useAllowances(
    approveChecks?.map((check) => check.token),
    currentAccount,
    approveChecks?.[0]?.spender
  );

  function getApproval(): ApprovalOperation | undefined {
    if (
      approveChecks &&
      approveChecks.length &&
      allowances &&
      allowances.length
    ) {
      for (const check of approveChecks) {
        const allowance = allowances.find(
          (allowance) => allowance.address === check.token
        );

        if (allowance?.amount?.gte(check.amount)) continue;

        const instance = approveInstances?.find(
          (instance) => instance.token === check.token
        )?.instance;

        if (!instance) continue;

        return {
          symbol: check.symbol,
          execute: () =>
            tokenApprove.execute(instance, {
              spender: check.spender || '',
              amount: BigNumber.from(check.amount)
                .sub(allowance?.amount || 0)
                .toString(),
            }),
        };
      }
    }
  }

  async function handleOnClick() {
    try {
      if (!instance) return console.error('instance is undefined');

      setHash(undefined);
      setErrorMsg(undefined);
      setIsMined(false);
      setLocalDisabled(true);

      const argError = buttonConfig.checkParams(params);
      if (argError) throw Error(argError);

      const pendingApproval = getApproval();
      const sent = pendingApproval
        ? pendingApproval.execute()
        : buttonConfig.execute(instance, params);

      if (sent.error) {
        console.error(sent.error);
        throw Error('Failed to create transaction');
      }

      if (sent.txStatus && sent.txReceipt) {
        const receipt = (await sent.txReceipt) as TransactionReceipt;

        if (!receipt) {
          console.error(receipt);
          throw Error('Failed to send transaction');
        }

        setHash(receipt.transactionHash);
        if (onSuccess) onSuccess();
      }

      // const result = await writeAsync().catch((err: any) => {
      //   const errorMsg = err.message || 'Transaction failed';
      //   if (errorMsg.includes('User rejected the request')) return;
      //   console.error('errorMsg: ', errorMsg);
      //   setError('Error while sending transaction');
      // });
    } catch (err: any) {
      setErrorMsg(err.message || 'Operation failed');
    }

    setLocalDisabled(false);
  }

  const explorerLink =
    isMined && currentNetworkConfig.explorerLinkBuilder
      ? currentNetworkConfig?.explorerLinkBuilder?.({
          tx: hash,
        })
      : '';

  return (
    <div className="flex flex-col items-center">
      {!currentAccount && (
        <button
          className={`flex items-center justify-center ${className} theme-highlight theme-highlight-border`}
          onClick={connectWallet}
        >
          <WalletIcon className="mr-2" />
          <span>Connect</span>
        </button>
      )}
      {getApproval() && (
        <button
          className={`flex items-center justify-center ${className}`}
          disabled={isDisabled}
          onClick={handleOnClick}
        >
          {loading && <SpinIcon className="mr-2 mt-[4px]" />}
          <span>{`Approve ${getApproval()?.symbol}`}</span>
        </button>
      )}
      {!getApproval() && currentAccount && (
        <button
          className={`flex items-center justify-center ${className}`}
          disabled={isDisabled}
          onClick={handleOnClick}
        >
          {loading && <SpinIcon className="mr-2 mt-[4px]" />}
          <span>{buttonText}</span>
        </button>
      )}

      <div className="mt-1 min-h-5 text-center text-sm text-red-500">
        {errorMsg}
      </div>

      {/* {!error && explorerLink && (
        <a
          href={explorerLink}
          target="_blank"
          rel="noreferrer"
          className="mt-2 min-h-4 flex justify-center items-center text-sm"
        >
          View transaction
          <ArrowTopRightIcon className="ml-1" />
        </a>
      )} */}
    </div>
  );
}
